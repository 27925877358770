<template>
  <Header v-if="currentPlatform" :menu-toggled="isNavExpanded" @toggle-menu="toggleMenu" />
  <MaintenanceMode v-if="maintenanceMode"/>
  <template v-else>
    <Nav
      v-click-outside="closeMenu"
      :expanded="isNavExpanded || (!$isMobile && navExpanded)"
      @click="closeMenu"
    />
    <main class="px-4 md:px-10" :class="pendingVisible && 'saving'">
      <LoaderFull :loader-visible="loaderVisible" />
      <Loader v-show="pendingVisible" />
      <Error403 v-if="isUnauthorized"/>
      <slot v-else-if="error !== 403 && currentPlatform && initialized" />
    </main>
  </template>
</template>

<script setup>
import { computed, inject, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Error403 from '@/components/error/Error403.vue';
import Header from '@/components/header/MainHeader.vue';
import Loader from '@/components/loader/Loader.vue';
import LoaderFull from '@/components/loader/LoaderFull.vue';
import MaintenanceMode from '@/components/error/MaintenanceMode.vue';
import Nav from '@/components/nav/Nav.vue';
  
const store = useStore();
const router = useRouter();

const $isMobile = inject('$isMobile');

const props = defineProps({
  navExpanded: {
    type: Boolean,
    default: false,
  },
  loaderVisible: Boolean,
  initialized: Boolean,
  pendingVisible: Boolean,
});

const isNavExpanded =  ref(props.navExpanded);

const currentPlatform = computed(() => store.state.platforms.currentPlatform);
const error = computed(() => store.state.auth.error);

const isUnauthorized = computed(() => {
  return error.value === 403;
});

const maintenanceMode = computed(() => {
  return (
    currentPlatform.value?.content_config?.bo_maintenance_mode &&
    !store.getters['auth/can']('tech.access')
  );
});
    
function toggleMenu() {
  isNavExpanded.value = !isNavExpanded;
};
function closeMenu(event) {
  if (!isNavExpanded.value ||
    Array.from(event?.target?.classList ?? []).includes('hamburger')
  ) {
    return;
  }
  isNavExpanded.value = false;
};

//Disconnect automatically when you have lost the session
async function logout() {
  store.dispatch('auth/logout');
  await router.push('/login');
  store.dispatch('platforms/logoutPlatform');
  store.dispatch('references/resetAll');
};

watch(isUnauthorized, (value) =>  {
  if (value) {
    logout();
  }
});
</script>
