export class Subtitle {
  constructor({
    id = null,
    url = null,
    name = null,
    file = null,
    locale = '',
    format = 'vtt',
    type = 'normal',
  } = {}) {
    this.id = id;
    this.url = url;
    this.name = name;
    this.file = file;
    this.locale = locale;
    this.format = format;
    this.type = type;
  }
}

export function createSubtitle(data) {
  return Object.freeze(new Subtitle(data));
}

export default Subtitle;
