/**
 * Block display config
 *
 * @author Jody JUSTINE <jjustine@vodfactory.com>
 */
export class DisplayConfig {
  title;

  constructor({
    title = '',
    titleStyles = { bold: null, fontSize: null, italic: null },
    style = null,
    badgeColor,
    backgroundColor = null,
    badgeStyle,
    badgeRadius = 0,
    socialNetworks = null,
    default_title = '-xyz-',
    pictureDefault = null,
    displayTitle = true,
  } = {}) {
    this.title = title;
    this.style = style;
    this.titleStyles = titleStyles;
    this.badgeColor = badgeColor;
    this.backgroundColor = backgroundColor;
    this.badgeStyle = badgeStyle;
    this.badgeRadius = parseInt(badgeRadius);
    this.socialNetworks = socialNetworks;
    this.default_title = default_title;
    this.pictureDefault = pictureDefault;
    this.displayTitle = displayTitle;
  }

  /**
   * Get type for api
   */
  getApiType() {
    return this.style;
  }

  /**
   * Get title for api
   */
  getApiTitle() {
    return this.title;
  }

  /**
   * Get font weight for api
   */
  getApiFontWeight() {
    return this.titleStyles?.bold ? 'bold' : 'normal';
  }

  /**
   * Get font style for api
   */
  getApiFontStyle() {
    return this.titleStyles.italic ? 'italic' : 'normal';
  }

  /**
   * Get font size for api
   */
  getApiFontSize() {
    let fontSize = this.titleStyles.fontSize;
    if (fontSize.substring(0, 10) == 'font-size-') {
      fontSize = fontSize.substring(10);
    }
    return fontSize;
  }

  /**
   * Get style for api
   */
  getApiStyle() {
    if (['text'].includes(this.style)) {
      return null;
    }
    return this.badgeStyle;
  }

  /**
   * Get color for api
   */
  getApiColor() {
    if (['text', 'image'].includes(this.style)) {
      return null;
    }

    let color = null;
    if (typeof this.badgeColor == 'object') {
      color =
        this.badgeColor.id === 'custom_hex'
          ? this.badgeColor.hex
          : this.badgeColor.id;
    }
    return color;
  }

  /**
   * Get background color for api
   */
  getApiBackgroundColor() {
    let color = null;
    if (typeof this.backgroundColor == 'object') {
      color =
        this.backgroundColor.id == 'custom_hex'
          ? this.backgroundColor.hex
          : this.backgroundColor.id;
    }
    return color;
  }

  /**
   * Get radius for api
   */
  getApiRadius() {
    if (['text', 'image'].includes(this.style)) {
      return 0;
    }
    return this.badgeRadius;
  }

  /**
   * Get Social networks for api
   */
  getApiSocialNetworks() {
    var retval = {};
    this.socialNetworks.forEach(
      (element) => (retval[element.id] = element.checked)
    );
    return retval;
  }

  getApiPictureDefault() {
    if (
      this.pictureDefault &&
      typeof this.pictureDefault === 'object' &&
      this.pictureDefault.id
    ) {
      return this.pictureDefault.id === 1 ? 'poster' : 'thumbnail';
    }
    return null;
  }

  /**
   * Get type for api
   */
  getApiDisplayTitle() {
    return this.displayTitle === false ? 0 : 1;
  }

  /**
   * Get hex background color
   */
  getHexBackgroundColor() {
    return this.backgroundColor?.hex ?? '#FFFFFF';
  }

  setColors(data) {
    this.colors = data;
  }

  /**
   * Get image style
   */
  getImageStyle() {
    if ('image' === this.style) {
      return this.badgeStyle;
    }
    return null;
  }

  getImageTemplate() {
    if (this.pictureDefault && typeof this.pictureDefault === 'object') {
      return this.pictureDefault.id === 1 ? 'poster' : 'thumbnail';
    }
    return null;
  }
}

export function createDisplayConfig(data) {
  return Object.freeze(new DisplayConfig(data));
}

export default DisplayConfig;
