<template>
  <div class="maintenace-mode w-full mt-10">
    <div class="grid gap-10 justify-items-center">
      <img
        class="mx-auto"
        width="450"
        src="/src/images/illustrations/error-204.svg"
        alt="maintenance mode"
      />
      <div class="text-center">
        <h2 class="text-xl font-medium leading-tight">
          {{ $t('maintenance.main.title') }}
        </h2>
        <p class="my-3" style="white-space: pre-line">
          {{ $t('maintenance.main.message') }}
        </p>
      </div>
    </div>
  </div>
</template>

