export class Currency {
  id;
  name;

  constructor({
    id = undefined,
    code = undefined,
    symbol = undefined,
    min_amount = 0,
    max_amount = 0,
    min_amount_decimal = 0,
    max_amount_decimal = 0,
    display_min_amount_with_currency = null,
    display_max_amount_with_currency = null,
    zero_decimal = false,
    three_decimal = false,
    name = 'Default Title',
  }) {
    this.id = id;

    this.name = name;
    this.code = code;
    this.symbol = symbol;
    this.min_amount = min_amount;
    this.max_amount = max_amount;
    this.min_amount_decimal = min_amount_decimal;
    this.max_amount_decimal = max_amount_decimal;
    this.display_min_amount_with_currency = display_min_amount_with_currency;
    this.display_max_amount_with_currency = display_max_amount_with_currency;
    this.zero_decimal = zero_decimal;
    this.three_decimal = three_decimal;
  }
}
