import { handleResponse } from '@/services/index.js';
import axios from 'axios';

export class ItemService {
  _endpointName;

  constructor() {
    this._endpointName = 'items';
  }

  async get(id) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_CONTENT_BASE_URL +
          '/' +
          this._endpointName +
          '/' +
          id
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async post(data) {
    try {
      const response = await axios.post(
        import.meta.env.VITE_CONTENT_BASE_URL + '/' + this._endpointName + '/',
        data
      );
      response.data.itemId = data.newItemID;
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async update(data, id) {
    try {
      const response = await axios.put(
        import.meta.env.VITE_CONTENT_BASE_URL +
          '/' +
          this._endpointName +
          '/' +
          id,
        data
      );
      response.data.itemId = id;
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async delete(id) {
    try {
      const response = await axios.delete(
        import.meta.env.VITE_CONTENT_BASE_URL +
          '/' +
          this._endpointName +
          '/' +
          id
      );
      return handleResponse(response);
    } catch (err) {
      return handleResponse(err);
    }
  }
}
