import moment from 'moment-timezone';
import { Plan } from '@/models/Plan.js';
import { Voucher } from '@/models/Voucher.js';

export class Subscription {
  id;
  customer_id;
  user_id;
  plan_id;
  subscription_type;
  trial_ends_at;
  starts_at;
  ends_at;
  created_at;
  updated_at;
  canceled_at;
  canceled_by;
  next_billing_date;
  duration;
  plan;
  trialing;
  coupon_redeemed;
  has_active_pairing;
  active;
  is_scheduled;
  has_upsell;
  subscription_from_id;
  client_country;
  coupon;
  status;

  constructor({
    id = undefined,
    customer_id = undefined,
    user_id = undefined,
    plan_id = undefined,
    subscription_type = undefined,
    trial_ends_at = null,
    starts_at = null,
    ends_at = null,
    created_at = null,
    updated_at = null,
    canceled_at = null,
    canceled_by = undefined,
    next_billing_date = null,
    duration = undefined,
    plan = undefined,
    trialing = undefined,
    coupon_redeemed = undefined,
    has_active_pairing = undefined,
    active = undefined,
    is_scheduled = undefined,
    has_upsell = undefined,
    subscription_from_id = undefined,
    client_country = undefined,
    subscriptionable = undefined,
  }) {
    this.id = id;
    this.customer_id = customer_id;
    this.user_id = user_id;
    this.plan_id = plan_id;
    this.subscription_type = subscription_type;
    this.trial_ends_at = trial_ends_at === null ? null : moment.tz(trial_ends_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
    this.starts_at = starts_at === null ? null : moment.tz(starts_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
    this.ends_at = ends_at === null ? null : moment.tz(ends_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
    this.created_at = created_at === null ? null : moment.tz(created_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
    this.updated_at = updated_at === null ? null : moment.tz(updated_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
    this.canceled_at = canceled_at === null ? null : moment.tz(canceled_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
    this.canceled_by = canceled_by;
    this.next_billing_date = next_billing_date === null ? null : moment.tz(next_billing_date, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
    this.duration = duration;
    this.plan = new Plan(plan);
    this.trialing = trialing;
    this.coupon_redeemed = coupon_redeemed;
    this.has_active_pairing = has_active_pairing;
    this.active = active;
    this.is_scheduled = is_scheduled;
    this.has_upsell = has_upsell;
    this.subscription_from_id = subscription_from_id;
    this.client_country = client_country;
    this.coupon = subscriptionable?.coupon
      ? new Voucher(subscriptionable.coupon, starts_at, ends_at, active)
      : null;
    this.stripe_subscription_id = subscriptionable.stripe_id ?? null;

  }
}

export function createSubscription(data) {
  return Object.freeze(new Subscription(data));
}

export default Subscription;