import { GenericStatisticsService } from '@/services';
import { cloneDeep } from 'lodash';
import { defaultKpisState, kpisTypes } from '@/data/kpis';
import { getField, updateField } from 'vuex-map-fields';
import genericKpisGetters from '@/store/statistics/genericKpisGetters';
import genericKpisMutations from '@/store/statistics/genericKpisMutations';

const service = new GenericStatisticsService('views/aggregate');

const {
  id,
  color,
  title,
  tooltip,
  label,
  icon = '',
  iconColor = '',
  action = '',
  actionColor = '',
  illustration = '',
  valueType,
  queryString = '',
  exportable = true,
} = kpisTypes.find((type) => type.id === 'topViewsAggregate');

const filters = cloneDeep(defaultKpisState.filters)
  .filter((f) =>
    ['device', 'program_id', 'ina_id', 'category_id', 'tag_id', 'genre_id'].includes(f.id)
  )

const state = {
  ...cloneDeep(defaultKpisState),
  id,
  filters,
  title,
  tooltip,
  label,
  color,
  icon,
  action,
  iconColor,
  actionColor,
  illustration,
  valueType,
  queryString,
  exportable
};

const actions = {

  async search({ commit, dispatch, state, rootState }, refresh) {
    const lastQueryString = state.queryString;
    const start = rootState.kpis.startDateId;
    const end = rootState.kpis.endDateId;
    const unit = rootState.kpis.granularity;
    const queryString =
      `start_date=${start}&end_date=${end}&interval=${unit}`;
    const filter = state.selectedFilters?.[0];
    const values = filter?.value ?? [];
    commit('setQueryString', queryString);
    if (
      refresh ||
      (
        lastQueryString !== state.queryString &&
        (!filter?.showAll || values.includes('all'))
      )
    ) {
      commit('setLoading', true);
      commit('resetSelectedDate');
      return service.search(state.queryString).then(
        (views) => {
          commit('getAllSuccess', views);
          commit('setLoading', false);
          commit('setError', false);
          return Promise.resolve(views);
        },
        (error) => {
          commit('setError', true);
          commit('setLoading', false);
          const alert = {
            id: 'top-views-aggregate-search-failure',
            icon: 'close',
            type: 'error',
            message: 'notifications.search.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
    } else {
      return Promise.resolve();
    }
  },
  async export({ commit, dispatch, rootState }) {
    const start = rootState.kpis.startDateId;
    const end = rootState.kpis.endDateId;
    const unit = rootState.kpis.granularity;
    const email = rootState.auth.authUser.email;
    const queryString =
      `start_date=${start}&end_date=${end}&interval=${unit}&export=1&email=${email}`;
    commit('setQueryString', queryString);

    return service.search(state.queryString).then(
      (_) => {
        const alert = {
          id: 'stats-exported',
          icon: 'check',
          type: 'valid',
          message: 'kpis.export.notifications.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (error) => {
        const alert = {
          id: 'export-failure',
          icon: 'close',
          type: 'error',
          message: 'kpis.export.notifications.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  updateField,
  ...genericKpisMutations,

  setQueryString(state, queryString) {
    state.queryString = queryString;
    const filter = state.selectedFilters?.[0];
    const values = filter?.value ?? [];
    if (filter?.id) {
      let id = filter?.id;
      if (filter?.name === 'custom_id') {
        id = filter?.name;
      }
      if (values.length > 0 && (!values.includes('all') || filter.showAll)) {
        const ids = `&${id}[]=`;
        state.queryString += (ids + values.join(ids));
      }
    }
  }
};

const getters = {
  getField,
  ...cloneDeep(genericKpisGetters),

  formattedAll: (state, getters) => {
    if (!getters.all) {
      return [];
    }
    return Object.keys(getters.all).sort().map((key) => {
      return {
        date: key,
        name: key,
        value: Array.isArray(getters.all[key])
          ? 0
          : getters.all[key]
      };
    });
  },
  filteredAll: (state, getters) => {
    if (!getters.formattedAll.length) {
      return [];
    }
    return getters.formattedAll;
  },
  summaryValue: (state, getters) => {
    return getters.cumulativeOptions;
  },

  summaryHasFooter: () => {
    return true;
  },
};

export const topViewsAggregateKpis = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
