import sorts from '@/data/sorts.js';
const state = {
  sorts: [],
  facets: [],
};

const actions = {
  initMetadataSort({ commit }) {
    commit('initMetadataSort');
  },
};

const mutations = {
  initMetadatasSort(state) {
    state.sorts = [
      {
        id: 'date:desc',
        title: 'search.added',
      },
      {
        id: 'title:asc',
        title: 'A - Z',
      },
      {
        id: 'title:desc',
        title: 'Z - A',
      },
    ];
  },
};
export const metadataFilter = {
  namespaced: true,
  state,
  actions,
  mutations,
};
