import * as Sentry from '@sentry/vue';

/**
 * Billing Services
 */
export * from './billing/coupons.service';
export * from './billing/currencies.service';
export * from './billing/customers.service';
export * from './billing/discountCampaign.service';
export * from './billing/invoices.service';
export * from './billing/subscriptions.service';
export * from './billing/transactions.service';

/**
 * Media Services
 */
export * from './media/videos.service';
export * from './media/ingests.service';
export * from './media/lives.service';

/**
 * User Services
 */
export * from './user/users.service';
export * from './user/newsletters.service';
export * from './user/admins.service';

/**
 * Content Services
 */
export * from './content/customs.service';
export * from './content/rentals.service';
export * from './content/episodes.service';
export * from './content/metadatas.service';
export * from './content/metadataDetails.service';
export * from './content/persons.service';
export * from './content/playlists.service';
export * from './content/programs.service';
export * from './content/programMetadatas.service';
export * from './content/recommendations.service';
export * from './content/seasons.service';
export * from './content/series.service';
export * from './content/templates.service';
export * from './content/blocks.service';
export * from './content/items.service';

/**
 * Platform Services
 */
export * from './platform/emails.service';
export * from './platform/legals.service';

export { GenericBillingService } from './billing/generic.billing.service';
export { GenericContentService } from './content/generic.content.service';
export { GenericPlatformService } from './platform/generic.platform.service';
export { GenericMediaService } from './media/generic.media.service';
export { GenericStatisticsService } from './statistics/generic.statistics.service.js';
export { GenericUserService } from './user/generic.user.service';

export function handleResponse(response, status = 200) {
  const data = JSON.parse(JSON.stringify(response));
  if (
    !data.status ||
    (typeof data.status === 'string' &&
      data.status !== 'success' &&
      data.status !== 'duplicated') ||
    (typeof data.status === 'number' && data.status === 504)
  ) {
    const error = {
      errors: (data && data.message) || response.statusText,
      status: data.status || status,
      details: data?.error ?? null,
    };
    if (data?.itemId) {
      error.id = data.itemId;
    }
    Sentry.withScope((scope) => {
      scope.setTag('error_category', 'api_error');
    });
    return Promise.reject(error);
  } else if (typeof data.status === 'number' && data.status == 204) {
    return Promise.resolve();
  }
  if (data?.results) {
    if (data.status === 'duplicated') {
      // cas particulier lorsque l'on chercher à créer un objet existant
      return Promise.resolve(
        Object.assign({}, data.results, { status: 'duplicated' })
      );
    } else if (data?.errors?.length > 0) {
      const results = { results: data.results, errors: data.errors };
      return Promise.resolve(results);
    } else {
      return Promise.resolve(data.results);
    }
  } else {
    return Promise.resolve(data);
  }
}

export function filter(params = {}) {
  let returnFilter = [];
  if (params?.type && params.type !== '') {
    returnFilter.push('type=' + params.type);
  }
  if (params?.page && params.page > 0) {
    returnFilter.push('page=' + parseInt(params.page));
  }
  if (params?.keyword && params.keyword !== '') {
    returnFilter.push('keyword=' + params.keyword);
  }
  if (params?.selectedSort && params.selectedSort !== '') {
    returnFilter.push('sort=' + params.selectedSort);
  }
  if (
    params?.count &&
    typeof params.count === 'number' &&
    params.count > 0
  ) {
    returnFilter.push('count=' + params.count);
  }
  if (params?.group_environment && params.group_environment !== '') {
    returnFilter.push('group_environment=' + params.group_environment);
  }
  return returnFilter;
}
