import { cloneDeep } from 'lodash';
import utils from '@/utils/utils';

export class ProgramTransformer {
  _program;
  _metadata;
  _currentPlatform;

  constructor(program, metadata, currentPlatform = null) {
    this._program = program;
    this._metadata = metadata;
    this._currentPlatform = currentPlatform;
  }

  transform() {
    let programTransformed = cloneDeep(this._program);
    programTransformed = utils.formatData(programTransformed);

    const countryCode = this._currentPlatform?.getCountryCode();
    const countryId = this._currentPlatform?.getCountryId();
    programTransformed.publication = cloneDeep(
      this._program.publication[countryCode]
    );
    if (typeof programTransformed.publication === 'object') {
      programTransformed.publication.country_id = countryId;
      if (programTransformed.publication.publish_at_utc) {
        programTransformed.publication.publish_at = programTransformed.publication.publish_at_utc;
      }
      if (programTransformed.publication.unpublish_at_utc) {
        programTransformed.publication.unpublish_at = programTransformed.publication.unpublish_at_utc;
      }
    }

    if (programTransformed.review === '') {
      delete programTransformed.review;
    }
    if (programTransformed.date === '') {
      delete programTransformed.date;
    }
    if (programTransformed.synopsis === '') {
      delete programTransformed.synopsis;
    }
    if (programTransformed.note === '') {
      delete programTransformed.note;
    }
    if (isNaN(programTransformed.version)) {
      delete programTransformed.version;
    }
    if (isNaN(programTransformed.level)) {
      delete programTransformed.level;
    }
    if (programTransformed?.gear?.length === 0) {
      delete programTransformed.gear;
    }
    if (programTransformed?.tag?.length === 0) {
      delete programTransformed.tag;
    }
    if (programTransformed?.category?.length === 0) {
      delete programTransformed.category;
    }
    if (programTransformed?.country?.length === 0) {
      delete programTransformed.country;
    }
    if (
      typeof programTransformed?.ratinglevel !== 'number' &&
      !isNaN(programTransformed?.ratinglevel?.id)
    ) {
      programTransformed.ratinglevel = parseInt(
        programTransformed.ratinglevel.id
      );
    } else if (isNaN(programTransformed.ratinglevel)) {
      delete programTransformed.ratinglevel;
    }

    programTransformed.duration = parseInt(programTransformed.duration);
    if (
      typeof programTransformed.duration !== 'number' ||
      isNaN(programTransformed.duration) ||
      programTransformed.duration === ''
    ) {
      delete programTransformed.duration;
    } else {
      programTransformed.duration = parseInt(programTransformed.duration);
    }

    // videos
    const videos = ['video', 'free_video', 'amazon_video', 'betv_video', 'molotov_video'];
    
    videos.forEach((video) => {
      if (typeof programTransformed?.[video] === 'object') {
        let main = [];
        let trailer = [];
        let bonus = [];
        if (
          typeof programTransformed[video]?.main === 'object' &&
          programTransformed[video].main.length > 0
        ) {
          main = programTransformed[video].main.map((mainVideo) => {
            if (typeof mainVideo === 'object') {
              return mainVideo.id;
            } else {
              return mainVideo;
            }
          });
        }
        if (
          typeof programTransformed[video]?.trailer === 'object' &&
          programTransformed[video].trailer.length > 0
        ) {
          trailer = programTransformed[video].trailer.map((trailerVideo) => {
            if (typeof trailerVideo == 'object') {
              return trailerVideo.id;
            } else {
              return trailerVideo;
            }
          });
        }
        if (
          typeof programTransformed[video]?.bonus === 'object' &&
          programTransformed[video].bonus.length > 0
        ) {
          bonus = programTransformed[video].bonus.map((bonusVideo) => {
            if (typeof bonusVideo == 'object') {
              return bonusVideo.id;
            } else {
              return bonusVideo;
            }
          });
        }
        if (video === 'video') {
          programTransformed.video = {
            main: main,
            trailer: trailer,
            bonus: bonus,
          };
        } else if (video === 'amazon_video') {
          programTransformed.amazon_video = {
            main: main,
            trailer: trailer,
          };
        } else {
          programTransformed[video] = {
            main: main,
          };
        }
      } else {
        delete programTransformed[video];
      }
    });

    // pictures
    if (typeof programTransformed?.picture === 'object') {
      for (const [key, value] of Object.entries(programTransformed.picture)) {
        if (value.length > 0 && value[0].id === null && value[0].key) {
          programTransformed.picture[key][0].url = null;
        }
      }
    }

    // metadatas
    this._metadata.forEach((element) => {
      const isPartnerMeta = element?.partners.length > 0;
      const elementLabel = isPartnerMeta
        ? programTransformed.partners_metas?.[element.label]
        : programTransformed?.[element.label];
      let value = null;
      if (element.metadataType === 'person') {
        if (elementLabel?.length > 0) {
          value = elementLabel.map((metadata) => {
            if (metadata?.job_id && element.metadata_label === 'cast') {
              const jobs = metadata?.job_id?.split('-') ?? []; 
              return jobs.length > 1 
                ? metadata.job_id
                : metadata.jobs.length > 0
                  ? `${metadata.id}-${metadata.jobs[0].id}`
                  : metadata.job_id;
            } else if (metadata?.id) {
              return metadata.id;
            } else {
              return metadata;
            }
          });
        } else if (!isPartnerMeta) {
          delete programTransformed?.[element.label]
        }
      } else if (element.cardinality == 1) {
        if (elementLabel?.id) {
          value = elementLabel.id;
        } else if (!elementLabel && !isPartnerMeta) {
          delete programTransformed?.[element.label]
        }
      } else if (elementLabel?.length > 0) {
        if (element.metadataType !== 'file') {
          value = elementLabel.map((metadata) => {
            return metadata?.id
              ? metadata.id
              : metadata;
          });
        }
      }
      if (isPartnerMeta) {
        programTransformed.partners_metas[element.label] = value ?? elementLabel;
      } else {
        programTransformed[element.label] = value ?? elementLabel;
      }
    });

    // partners
    if (programTransformed?.partnersPackages) {
      delete programTransformed.partnersPackages;
    }

    const amazonTerritories = this._currentPlatform?.content_config?.amazon_territories;
    if (amazonTerritories?.length > 0) {
      const publications = 
        programTransformed?.partners_publications?.amazon?.filter(
          (pub) => amazonTerritories.includes(pub.country.toUpperCase())
        ) ?? [];
      programTransformed.partners_publications.amazon = publications;
    }
    return programTransformed;
  }
}
