import { playlistService } from '@/services';
// import Movie from '@/models/Customer';
import utils from '@/utils/utils';

const state = {
  allPrograms: {},
  loading: true,
};

const actions = {
  getUserPlaylist({ commit, dispatch }, userId) {
    return playlistService.getUserPlaylist(userId).then(
      (programs) => {
        commit('getSuccessPlaylist', programs);
        return Promise.resolve(programs);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  reset(state) {
    state.allPrograms = {};
    state.loading = false;
  },

  getAllRequest(state) {
    state.loading = true;
  },

  getSuccessPlaylist(state, programs) {
    state.allPrograms = programs;
  },

  getFailure(state, error) {
    console.log('programs - getFailure');
  },
};

export const playlists = {
  namespaced: true,
  state,
  actions,
  mutations,
};
