import i18n from '@/i18n.js';
import moment from 'moment';
import utils from '@/utils/utils.js';
/*
  Vue.filter('formatDate', (date, withTime) => {
  let dateFormat = withTime ? 'tiny' : 'short';
  return i18n.d(new Date(date), dateFormat, i18n.t('date.locale'));
});

 */
export default {
  formatDate(date, withTime) {
    return moment(date).format(`DD/MM/YYYY ${withTime ? 'HH:mm' : ''}`);
  },

  timestampToDate(value) {
    return value
      ? moment.unix(value).format('DD/MM/YYYY HH:mm')
      : 'Bad-Timestamp';
  },

  formatSeconds(seconds) {
    if (!seconds) {
      return '';
    }
    let numyears = Math.floor(seconds / 31536000);
    numyears =
      numyears > 0
        ? `${numyears}${utils.toInitial(
            i18n.global.t('frequency.year.title')
          )} `
        : '';
    let numdays = Math.floor((seconds % 31536000) / 86400);
    numdays =
      numdays > 0
        ? `${numdays}${utils.toInitial(i18n.global.t('frequency.day.title'))} `
        : '';
    let numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    numhours =
      numhours > 0
        ? `${numhours}${utils.toInitial(i18n.global.t('frequency.hour.title'))}`
        : '';
    let numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    numminutes =
      numminutes > 0
        ? `${numminutes}${utils.toInitial(
            i18n.global.t('frequency.minute.title')
          )}`
        : '';
    let numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;
    numseconds =
      numseconds > 0
        ? `${numseconds}${utils.toInitial(
            i18n.global.t('frequency.second.title')
          )}`
        : '';
    return numyears + numdays + numhours + numminutes + numseconds;
  },

  formatSecondsInMinutes(seconds) {
    if (!seconds) {
      return '';
    }
    return `${Math.round(seconds / 60)} ${utils.toAbbr(
      i18n.global.t('frequency.minute.title'),
      3
    )}`;
  },

  /**
   * Get difference beetween given timestamp and current timestamp
   * @author Jody JUSTINE
   **/
  formatTimestampAgo(currentTimestamp) {
    return moment.unix(currentTimestamp).fromNow();
  },
  formatDateAgo(date) {
    return moment(date).fromNow();
  },

  formatBytes(bytes) {
    if (bytes == 0) {
      return '0';
    }
    const kk = 1024,
      dm = 2,
      sizes = ['o', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'],
      ii = Math.floor(Math.log(bytes) / Math.log(kk));
    return parseFloat((bytes / Math.pow(kk, ii)).toFixed(dm)) + ' ' + sizes[ii];
  },

  formatAmount(amount) {
    return `${parseFloat(amount).toFixed(2).replace('.', ',')} €`;
  },

  //Quick Fix Cors Problem
  corsQuickFix(url, suffix = '') {
    if (url == '' || url == null) {
      return url;
    }
    return (
      url + (url.substr(0, 4) != 'data' ? '?x-request=http-image' + suffix : '')
    );
  },
};
