import { cloneDeep } from 'lodash';
import utils from '@/utils/utils';

export class SelectionTransformer {
  _selection;
  _blockTypeId;

  constructor(selection, blockTypeId) {
    this._selection = selection;
    this._blockTypeId = blockTypeId;
  }

  transform() {
    let selectionTransformed = cloneDeep(this._selection);
    selectionTransformed = utils.formatData(selectionTransformed);

    selectionTransformed = utils.formatData(selectionTransformed);

    selectionTransformed.display_title = selectionTransformed.display_title
      ? 1
      : 0;
    selectionTransformed.display_count = selectionTransformed.display_count
      ? 1
      : 0;
    selectionTransformed.display_catalog_selection =
      selectionTransformed.display_catalog_selection ? 1 : 0;

    let style = cloneDeep(selectionTransformed.style);
    selectionTransformed.style.block_type_id = this._blockTypeId;
    selectionTransformed.style.with_duration = style.with_duration;
    selectionTransformed.style.display_title = style.with_title;
    delete selectionTransformed.style.with_title;
    selectionTransformed.style.metadatas = style.metadatas
      .filter((element) => {
        return Number.isInteger(element.id) && element.id > 0;
      })
      .map((element) => {
        let metadata = cloneDeep(element);
        metadata.text_options = metadata.textOptions;
        delete metadata.textOptions;
        metadata.text_options.font_size =
          metadata.text_options.fontSize ?? 'font-size-md';
        delete metadata.text_options.fontSize;
        return metadata;
      });

    if (
      selectionTransformed.picture &&
      typeof selectionTransformed.picture === 'object'
    ) {
      for (const [key, value] of Object.entries(selectionTransformed.picture)) {
        if (
          value.length > 0 &&
          value[0] &&
          value[0].id === null &&
          value[0].key
        ) {
          selectionTransformed.picture[key][0].url = null;
        }
      }
    }

    if ('title' in selectionTransformed && selectionTransformed.title === '') {
      delete selectionTransformed.title;
    }
    if (
      'description' in selectionTransformed &&
      selectionTransformed.description === ''
    ) {
      delete selectionTransformed.description;
    }

    if ('note' in selectionTransformed && selectionTransformed.note === '') {
      delete selectionTransformed.note;
    }
    if (
      'picture' in selectionTransformed &&
      selectionTransformed.picture.length === 0
    ) {
      delete selectionTransformed.picture;
    }
    if (
      'programs' in selectionTransformed &&
      selectionTransformed.programs.length > 0
    ) {
      let programsClean = [];
      for (
        let index = 0;
        index < selectionTransformed.programs.length;
        index++
      ) {
        let programClean = selectionTransformed.programs[index];
        let programTemp = {
          id: programClean.id,
          selection_id: selectionTransformed.id,
          rank: index,
        };
        programsClean.push(programTemp);
      }
      selectionTransformed.programs = programsClean;
    }
    return selectionTransformed;
  }
}
