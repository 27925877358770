import { cloneDeep, isNil } from 'lodash';
import { hasDifference } from '@/utils/diffTools';
import { playbackConfigurationService } from '@/services/media/playbackConfigurations.service';
import PlaybackConfiguration from '@/models/PlaybackConfiguration';

const state = {
  currentConfiguration: new PlaybackConfiguration(),
  savedConfiguration: new PlaybackConfiguration(),
  loading: true,
};

const actions = {
  get({ commit }) {
    return playbackConfigurationService.get().then(
      (config) => {
        commit('getSuccess', config);
        return Promise.resolve(config);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  update({ commit, dispatch }) {
    return playbackConfigurationService.update(state.currentConfiguration).then(
      (response) => {
        const alert = {
          id: 'playback-configuration-updated',
          icon: 'check',
          type: 'valid',
          message: 'Votre configuration a bien été mise à jour.',
        };
        dispatch('displayAlert', alert, { root: true });
        commit('getUpdateSuccess', response);
        return Promise.resolve();
      },
      (err) => {
        console.error('playback configuration updated failed', err);
        const alert = {
          id: 'playback-configuration-updated-failed',
          icon: 'close',
          type: 'error',
          message:
            "Une erreur s'est produite lors de la mise à jour de votre configuration.",
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },
  updateField({ commit }, payload) {
    commit('updateField', payload);
  },
};

const mutations = {
  getSuccess(state, config) {
    //   const newCustomer = new Customer(customer);
    state.currentConfiguration = config;
    state.savedConfiguration = cloneDeep(config);
    state.loading = false;
  },
  getUpdateSuccess(state, config) {
    state.currentConfiguration = config;
    state.savedConfiguration = cloneDeep(config);
    state.loading = false;
  },
  updateField(state, payload) {
    let { path, value } = payload;

    if (isNil(state.currentConfiguration[path])) {
      console.log('cannot update field ' + path + ' with value ' + value);
      return;
    }
    state.currentConfiguration[path] = value;
  },
};

const getters = {
  shouldSaved: (state) =>
    hasDifference(state.currentConfiguration, state.savedConfiguration),
};

export const playbackConfigurations = {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
