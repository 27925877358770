import { GenericPlatformService } from '@/services';
import { cloneDeep } from 'lodash';
import { hasDifference } from '@/utils/diffTools';
import Font from '@/models/Font';

const fontService = new GenericPlatformService('fonts', true);

const state = {
  all: [],
  savedOne: [],
  currentOne: [],
  loading: false,
};

const getters = {
  shouldSaved: (state) => hasDifference(state.currentOne, state.savedOne),
  loading: (state) => state.loading,
};

const actions = {
  get({ commit }, id) {
    // if (refresh === false && hasDifference(state.savedOne, state.currentOne)) {
    //   console.debug(`Do no refresh font ${id}: ask by user`)
    //   return Promise.resolve()
    // }

    commit('getRequest');
    return fontService.get(parseInt(id)).then(
      (font) => {
        commit('getSuccess', font);
        return Promise.resolve();
      },
      (err) => {
        commit('getFailure', err);
        Promise.reject(err);
      }
    );
  },

  /**
   * Create font
   *
   * @param {*} param0   : fontName
   *
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  create({ commit, dispatch }, params) {
    return fontService.create(params).then(
      (response) => {
        commit('getSuccess', response);
        const alert = {
          id: 'font-creation',
          icon: 'check',
          type: 'valid',
          message: 'Votre police a bien été créée.',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (error) => {
        console.error('error on font creation', error);
        const alert = {
          id: 'font-creation-failed',
          icon: 'close',
          type: 'error',
          message:
            "Une erreur s'est produite lors de la création de votre police.",
        };
        dispatch('displayAlert', alert, { root: true });
        commit('getFailure');
        return Promise.reject(error);
      }
    );
  },

  /**
   * Update font
   *
   * @return void
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  update({ commit, dispatch }, payload) {
    let { params, fontId } = payload;

    return fontService.update(fontId, params).then(
      () => {
        commit('cleanFonts');
        const alert = {
          id: 'font-updated',
          icon: 'check',
          type: 'valid',
          message: 'Votre police a bien été mise à jour.',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (err) => {
        console.error('font updated failed', err);
        const alert = {
          id: 'font-updated-failed',
          icon: 'close',
          type: 'error',
          message:
            "Une erreur s'est produite lors de la mise à jour de votre police.",
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },

  /**
   * Toggle font publication
   *
   * @return void
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  toggleFontPublicationAction({ state, dispatch }, payload) {
    const { params, fontId } = payload;
    return fontService.update(fontId, params).then(
      () => {
        let message = 'Votre police a été mise à jour !';
        const alert = {
          id: 'font-published',
          icon: 'rocket',
          type: 'valid',
          message: message,
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (err) => {
        const alert = {
          id: 'font-published-failed',
          icon: 'close',
          type: 'error',
          message:
            "Une erreur s'est produite lors de la mise en ligne de votre police.",
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },

  /**
   * Delete font
   *
   * @return void
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  delete({ commit, dispatch }, fontId) {
    return fontService.delete(fontId).then(
      (font) => {
        commit('cleanFonts');
        const alert = {
          id: 'font-removed',
          icon: 'check',
          type: 'valid',
          message: 'Votre police a bien été supprimée.',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (err) => {
        console.error('delete font error', err);
        const alert = {
          id: 'font-removed-failed',
          icon: 'close',
          type: 'error',
          message: err,
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },

  reset({ commit }) {
    commit('reset');
  },
};

const mutations = {
  setLoading(state) {
    state.loading = true;
  },
  getRequest(state) {
    state.savedOne = [];
    state.currentOne = [];
    state.loading = true;
  },
  getAllRequest(state) {
    state.all = [];
    state.loading = true;
  },
  getAllSuccess(state, items) {
    state.all = items.map((item) => new Font(item));
    state.loading = false;
  },
  getAllFailure(state) {
    state.all = [];
    state.loading = false;
  },
  getSuccess(state, item) {
    const font = new Font(item);
    state.savedOne = cloneDeep(font);
    state.currentOne = cloneDeep(font);
    state.loading = false;
  },
  getFailure(state) {
    const font = new Font();
    state.savedOne = cloneDeep(font);
    state.currentOne = cloneDeep(font);
    state.loading = false;
  },
  getUpdateSuccess(state, item) {
    state.savedOne = cloneDeep(item);
    state.currentOne = cloneDeep(item);
    state.loading = false;
  },
  cleanFonts(state) {
    state.all = [];
  },
  reset(state) {
    state.all = [];
    state.savedOne = [];
    state.currentOne = [];
    state.loading = false;
  },
};

export const fonts = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
