import { difference, hasDifference } from '@/utils/diffTools';
import { getField } from 'vuex-map-fields';
import { isNil } from 'lodash';

export default {
  getField,
  allActivated: (state) => {
    return state.all.filter((x) => x.activated);
  },
  allSavedActivated: (state) => {
    return state.savedAll.filter((x) => x.activated)
  },
  allDisabled: (state) => {
    return state.all.filter((x) => !x.activated)
  },
  allActivatedFront: (state) => {
    return state.all
      .filter((x) => x.activated && x.visible_front)
  },
  allActivatedDisplayable: (state) => {
    return state.all
      .filter((x) => x.activated && x.display)
  },
  allDisabledDisplayable: (state) => {
    const blackList = ['file'];
    return state.all
      .filter((x) => !x.activated && x.display && !blackList.includes(x.type))
  },
  filters: (state) => {
    const blackList = ['text', 'video', 'date', 'integer'];
    return state.all.filter(
      (item) =>
        item.visible_front &&
        !blackList.includes(item.type) &&
        item.filterable
    );
  },
  allFilterable: (state) =>
    state.all
      .filter(
        (item) =>
          (item.activated === true) != 0 &&
          item.type != 'text' &&
          item.filterable
      ).map((x) => x.id),
  allToUpdate: (state) =>
    state.all.filter((item) => !isNil(item.changed) && item.changed === true),
  loading: (state) => state.loading,
  shouldSaved: (state) =>
    state.all.findIndex((element) => element.changed === true) >= 0,
  shouldSaveOne: (state) => {
    return hasDifference(
      state.currentNewMetadataDetail,
      state.savedNewMetadataDetail
    );
  },
  shouldSaveOption: (state) => {
    return hasDifference(state.currentOption, state.savedOption);
  },
  shouldSaveRelatedPrograms: (state) => {
    let currentRelatedProgramIds = state.currentRelatedPrograms
      .map((x) => x.id)
    let savedRelatedProgramsProgramIds = state.savedRelatedPrograms
      .map((x) => x.id)
    let created = difference(
      currentRelatedProgramIds,
      savedRelatedProgramsProgramIds
    );
    let removed = difference(
      savedRelatedProgramsProgramIds,
      currentRelatedProgramIds
    );
    return created.length + removed.length > 0;
  },
  savedItem: (state) => (key) =>
    state.savedAll.find((element) => element.id == key),
  allActivatedWithPage: (state) => {
    /* Proposer ici les types de meta qui ont des images */
    let authorizedTypes = [
      'tag',
      'category',
      'person',
      'gear',
      'country',
      'custom',
      'level',
    ];
    return state.all
      .filter(
        (x) =>
          x.activated &&
          x.visible_front &&
          x.clickable &&
          authorizedTypes.includes(x.type)
      )
  },
};