import { cloneDeep } from 'lodash';
import adminRoles from '@/data/adminRoles';
import AdminsUtil from '@/utils/AdminsUtil';

export class RoleTransformer {
  _roles;
  _adminRoles;

  constructor(roles, adminRoles = []) {
    this._roles = roles;
    this._adminRoles = adminRoles;
  }

  // transfors an admin roles from strings to objects
  stringArrayToObjectArray() {
    const boRoles = cloneDeep(this._roles)
      .filter(str => str.includes('bo.'));

    let adminRoles_ =
      AdminsUtil.adminRolesByPermission(cloneDeep(adminRoles));
    let roles = adminRoles_.map(item => {
      let list = boRoles.filter(bo =>
        bo.includes(item.id)
      );
      item.allowAll = true;
      item.atLeastOne = false;
      item.children = item.children.map(page => {
        if (page?.actions?.length > 0) {
          page.children = page.actions.map(action => {
            action.checked = list.includes(`bo.${item.id}.${page.id}.${action.id}`);
            if (!action.checked) item.allowAll = false;
            else item.atLeastOne = true;
            return action;
          });
        } else {
          page.checked = list.includes(`bo.${item.id}.${page.id}`);
          if (!page.checked) item.allowAll = false;
          else item.atLeastOne = true;
        }
        return page;
      })
      return item;
    })
    return roles;
  }

  // transfomrs an admin roles from objects to strings
  objectArrayToStringArray() {
    let roles = cloneDeep(this._roles);
    let adminRoles = cloneDeep(this._adminRoles);
    adminRoles.forEach(item => {
      item.children.forEach(page => {
        const label = `bo.${item.id}.${page.id}`;
        if (page?.actions?.length > 0) {
          page.actions.forEach(action => {
            const actionLabel = `${label}.${action.id}`;
            if (action?.checked) {
              if (!roles.includes(actionLabel)) roles.push(actionLabel);
            } else {
              const index = roles.indexOf(actionLabel);
              if (index != -1) roles.splice(index, 1);
            }
          })
        } else if (page?.checked) {
          if (!roles.includes(label)) roles.push(label);
        } else {
          const index = roles.indexOf(label);
          if (index != -1) roles.splice(index, 1);
        }
      })
    })
    return roles;
  }
}
