import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/lives/';

export const liveService = {
  create,
  update,
  getConfig,
};

async function create(live) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_MEDIA_BASE_URL + api_url_module,
      live,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function update(live) {
  const requestOptions = {
    method: 'PUT',
  };
  try {
    const response = await axios.put(
      import.meta.env.VITE_MEDIA_BASE_URL + api_url_module + live.id,
      live,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function getConfig() {
  try {
    const response = await axios.get(
      import.meta.env.VITE_MEDIA_BASE_URL + api_url_module + 'configuration'
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}
