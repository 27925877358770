<template>
  <div class="flex flex-col gap-3">
    <div v-if="showRemoveConfirmation" class="flex items-center">
      <label class="text-xs font-light">
        {{ $t('videos.modal.processing.delete.title') }}
      </label>
      <div class="flex ml-3 gap-2 text-xs text-skin-accent">
        <button class="underline" @click="confirmRemove()">
          {{ $t('button.confirm') }}
        </button>
        <button @click="cancelRemove()">
          {{ $t('button.cancel') }}
        </button>
      </div>
    </div>
    <h4 v-if="totalVideos && ingestIndex" class="uppercase">
      {{ $t('generic.video') }} {{ ingestIndex }}/{{ totalVideos }}
    </h4>
    <Video
      :video-data="ingest"
      :video-id="ingest.id"
      :show-picture="false"
      :show-subtitles="false"
      :removable="showRemoveButton"
      :partner-id="ingest.group_environment"
      :display-partner="true"
      @click="onVideoRemoveClick()"
    />
  </div>
</template>

<script>
import Card from '@/components/card/Card.vue';
import ProgressBar from '@/components/progressBar/ProgressBar.vue';
import RemoveButton from '@/components/button/RemoveButton.vue';
import Video from '@/components/video/Video.vue';

export default {
  name: 'IngestingVideo',
  components: {
    Card,
    ProgressBar,
    RemoveButton,
    Video,
  },
  props: {
    ingest: {
      type: Object,
      required: true,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    ingestIndex: Number,
    totalVideos: [Number, String],
  },
  data() {
    return {
      showRemoveConfirmation: false,
    };
  },
  computed: {
    completed() {
      return this.ingest.status_code === 'ACT';
    },
    showRemoveButton() {
      return (
        this.removable &&
        ((this.removable && !this.completed) || this.completed || this.stopped)
      );
    },
    stopped() {
      return this.ingest.status_code === 'ERR';
    },
  },
  methods: {
    onVideoRemoveClick() {
      if (this.removable && !this.completed) {
        this.remove();
      }
      if (this.completed || this.stopped) {
        this.archive();
      }
    },
    remove() {
      this.showRemoveConfirmation = true;
    },
    archive() {
      this.$emit('archive', this.ingest);
    },
    cancelRemove() {
      this.showRemoveConfirmation = false;
    },
    confirmRemove() {
      this.showRemoveConfirmation = false;
      this.$emit('remove', this.ingest);
    },
  },
};
</script>
