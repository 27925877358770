import Picture from '@/models/Picture';

export class LightModel {
  id;
  model_type;
  type;
  status;
  title;
  picture;

  constructor({
    id = undefined,
    model_type = 'program',
    type = 'serie',
    status = 'offline',
    title = undefined,
    picture = picture,
  }) {
    this.id = id;

    this.model_type = model_type;

    this.type = type;

    this.status = status;

    this.title = title;

    this.setPicture(picture);
  }

  setPicture(picture) {
    this.picture = Picture.getDefaultPicture(
      this.model_type
    );
    const pictureTypes = Picture.getDefaultPictureTypesList(
      this.model_type
    );
    if (typeof picture === 'object') {
      pictureTypes.forEach((pictureType) => {
        this.picture[pictureType] = picture[pictureType] ?? [];
      });
    } else {
      pictureTypes.forEach((pictureType) => {
        this.picture[pictureType] = [];
      });
    }
  }
}
