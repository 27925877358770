import { isEmpty, isNil } from 'lodash';
// amount: "5.99"
// charge_id: "ch_1Ff5XzDNLxZNWzcj402DEDyN"
// coupon_redeemed: "MES20191113ARD"
// created_at: "15/11/2019 à 02h13"
// failure_reason: null
// payment_method: {type: "card", brand: "visa", last_four: "4242"}
// plan: {id: 19, name: "MENSUEL", price: 699, interval: "month", trial_period_days: 7, display_price: "6,99",…}
// status: "succeeded"

// brand: "visa"
// last_four: "4242"
// type: "card"

export class Transaction {
  constructor({
    amount = 0,
    amount_with_currency = null,
    amount_refunded = null,
    amount_refunded_with_currency = null,
    currency = 'EUR',
    charge_id = null,
    refund_id = null,
    coupon_redeemed = null,
    created_at = '',
    failure_reason = null,
    reason = null,
    payment_method = {},
    payment_details = {},
    plan = {},
    status = '',
    transaction_refunded_date = null,
    timeline = {},
    invoice_pdf = null,
  } = {}) {
    this.amount = Math.abs(amount);
    this.amount_with_currency = amount_with_currency;
    this.amount_refunded = Math.abs(amount_refunded);
    this.amount_refunded_with_currency = amount_refunded_with_currency;
    this.currency = currency;
    this.charge_id = charge_id;
    this.refund_id = refund_id;
    this.coupon_redeemed = coupon_redeemed;
    this.created_at = created_at;
    this.failure_reason = failure_reason;
    this.reason = reason;
    this.payment_method = payment_method;
    this.payment_details = payment_details;
    this.plan = plan;
    this.status = status;
    this.transaction_refunded_date = transaction_refunded_date;
    this.timeline = timeline;
    this.invoice_pdf = invoice_pdf;
  }

  getBalance() {
    switch (this.status) {
      case 'refunded':
      case 'partially_refunded':
        return -parseFloat(this.amount);
      default:
        //
        break;
    }
    return this.amount;
  }

  canBeRefound() {
    if (this.status !== 'succeeded') {
      return false;
    }

    if (isNil(this.amount_refunded) && this.amount > 0) {
      return true;
    }

    return parseFloat(this.amount) > parseFloat(this.amount_refunded);
  }
  canSeeDetails() {
    return !isNil(this.payment_method) && !isEmpty(this.payment_method);
  }
}

export default Transaction;
