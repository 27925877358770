<template>
  <div
    :data-cy="`mouse-hover-${id}`"
    class="relative"
    :class="[full && 'w-full']"
    @mousemove="moveIt($event)"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div
      v-if="active && hover"
      ref="message"
      class="fixed z-[10000] overflow-hidden truncate text-xs italic rounded-sm shadow-sm shadow-skin-ui bg-skin-ui p-2 whitespace-pre-line text-left"
      :style="messageLocation"
    >
      <Icon v-if="withIcon" :icon="icon" size="sm" class="mr-1 align-baseline" />
      {{ $t(message) }}
    </div>
    <div
      class="flex flex-1"
      :class="[
        disabledComp && `opacity-50 ${cursor}`,
        contentClass && contentClass,
      ]"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue';

export default {
  name: 'MouseHover',
  components: {
    Icon,
  },
  props: {
    id: {
      type: String,
      default: () => `id_${Math.floor(Math.random() * 10000)}`,
    },
    disable: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: null,
    },
    cursor: {
      type: String,
      default: 'cursor-not-allowed',
    },
    icon: {
      type: String,
      default: 'forbidden',
    },
    withIcon: {
      type: Boolean,
      default: true,
    },
    offsetX: {
      type: Number,
      default: 15,
    },
    offsetY: {
      type: Number,
      default: 15,
    },
    contentClass: {
      type: String,
      default: '',
    },
    full: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hover: false,
      top: 0,
      left: 0,
    };
  },
  computed: {
    disabledComp() {
      return this.active && this.disable;
    },
    messageLocation() {
      return {
        left: this.left + this.offsetX + 'px',
        top: this.top + this.offsetY + 'px',
        cursor: this.cursor,
      };
    },
  },
  methods: {
    moveIt(event) {
      const messageBox = this.$refs?.message?.getBoundingClientRect() ?? {
        width: 0,
      };
      const messageWidth = messageBox.width;
      const messageTooLong = event.pageX + messageWidth > window.outerWidth;
      this.left = event.pageX - (messageTooLong ? messageWidth : 0);
      this.top = event.pageY - window.scrollY;
    },
  },
};
</script>
