import { ItemService } from '@/services/content/items.service';
import { TemplatesService } from '@/services/content/templates.service';
import { cloneDeep, findKey, isEmpty, isNil, orderBy } from 'lodash';
import { difference, hasDifference } from '@/utils/diffTools';
import BlockBannerImage from '@/models/block/BlockBannerImage';
import BlockBannerText from '@/models/block/BlockBannerText';
import BlockEpisode from '@/models/block/BlockEpisode';
import BlockGridContents from '@/models/block/BlockGridContents';
import BlockMetadata from '@/models/block/BlockMetadata';
import BlockRecommendations from '@/models/block/BlockRecommendations';
import BlockSocial from '@/models/block/BlockSocial';
import Item from '@/models/Item';
import Template from '@/models/Template';
import utils from '@/utils/utils';

const templatesService = new TemplatesService();
const itemsService = new ItemService();

const defaultOne = {
  all: [],
  landing: {
    header: null,
    footer: null,
  },
  home: {
    header: null,
    blocks: null,
    footer: null,
  },
  program: {
    desktop: [],
    tablet: [],
    mobile: [],
    orange: [],
    free: [],
    apple_tv: [],
    android_tv: [],
    samsung_tv: [],
  },
  serie: {
    desktop: [],
    tablet: [],
    mobile: [],
    orange: [],
    free: [],
    apple_tv: [],
    android_tv: [],
    samsung_tv: [],
  },
  live: {
    desktop: [],
    tablet: [],
    mobile: [],
    orange: [],
    free: [],
    apple_tv: [],
    android_tv: [],
    samsung_tv: [],
  },
  collection: {
    desktop: [],
    tablet: [],
    mobile: [],
    orange: [],
    free: [],
    apple_tv: [],
    android_tv: [],
    samsung_tv: [],
  },
  selection: {
    header: null,
    blocks: null,
    footer: null,
  },
};
const defaultLoading = {
  landing: false,
  home: false,
  program: false,
  serie: false,
  selection: false,
  live: false,
  collection: false,
  all: true,
};

const state = {
  all: [],
  currentOne: cloneDeep(defaultOne),
  savedOne: cloneDeep(defaultOne),
  loading: defaultLoading,
};

const actions = {
  reset({ commit }, name) {
    commit('reset', name);
  },

  getAll({ commit }) {
    commit('getAllRequest');
    return templatesService.getAll().then(
      (response) => {
        commit('getAllSuccess', response);
        return Promise.resolve(response);
      },
      (error) => {
        commit('getAllFailure', error);
        return Promise.reject(error);
      }
    );
  },

  get({ getters, state, commit, rootGetters }, payload) {
    const templateWithDevice = ['program', 'serie', 'live', 'collection'];
    const mainDevice = rootGetters['platforms/mainDevice'];
    const { name, mode = mainDevice, refresh = false } = payload;

    if (isNil(name)) {
      return Promise.resolve();
    }

    if (templateWithDevice.includes(name)) {
      if (
        refresh === false &&
        hasDifference(state.savedOne[name][mode], defaultOne[name][mode])
      ) {
        return Promise.resolve();
      }

      if (
        refresh === 'after-first-load' &&
        !hasDifference(state.savedOne[name][mode], defaultOne[name][mode])
      ) {
        return Promise.resolve();
      }
    } else {
      if (
        refresh === false &&
        hasDifference(state.savedOne[name], defaultOne[name])
      ) {
        return Promise.resolve();
      }

      if (
        refresh === 'after-first-load' &&
        !hasDifference(state.savedOne[name], defaultOne[name])
      ) {
        return Promise.resolve();
      }
    }

    const currentPlatformId = getters.currentPlatformId(mode);
    commit('getRequest', { name, mode });

    return templatesService.get(name, currentPlatformId).then(
      (response) => {
        commit('getSuccess', { name, mode, response });
        return Promise.resolve(response);
      },
      (error) => {
        commit('getFailure', name);
        return Promise.reject(error);
      }
    );
  },

  /**
   * Create template
   *
   * @param {*} param0   : templateName
   *
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  createTemplate({ commit, rootGetters }, payload) {
    const { name, mode } = payload;
    const current_platform_id =
      rootGetters['platforms/currentPlatformId'](mode);
    const template_type_id = rootGetters['references/template_type_id'](name);

    const params = {
      platform_id: current_platform_id,
      name: name,
      title: name,
      template_type_id: template_type_id,
      online: true,
    };

    return templatesService.create(params, current_platform_id).then(
      (response) => {
        commit('getSuccess', { name, mode, response });
        return Promise.resolve(response);
      },
      (error) => {
        commit('getFailure', name);
        return Promise.reject(error);
      }
    );
  },

  /**
   * Create templates for main platform and child platform
   *
   * @param {*} param0   : device (desktop, tablet, ...)
   * @param {*} payload  : templatename, mode, block
   * @param {*} payload  : templatename, mode, block
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  createTemplatesForPlatformFamily({ dispatch }, template) {
    template.all = true;
    return templatesService.create(template, template.platform_id).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        const alert = {
          id: 'create-templates',
          icon: 'close',
          type: 'error',
          message: 'notifications.create.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },

  update({ state, rootState, commit, dispatch }, name) {
    commit('putRequest', name);
    switch (name) {
      case 'selection':
        var currentSelectionsBrut = rootState.selections.all.data;
        var savedSelectionsBrut = rootState.selections.allSaved.data;
        var selectionsClean = [];
        currentSelectionsBrut.forEach(function (currentSelectionBrut) {
          var id = currentSelectionBrut.id;
          var savedSelectionBrut = savedSelectionsBrut.find((x) => x.id === id);
          if (hasDifference(currentSelectionBrut, savedSelectionBrut)) {
            selectionsClean.push({
              id: currentSelectionBrut.id,
              type: 'selection',
              rank: currentSelectionBrut.rank,
              display_title: currentSelectionBrut.display_title,
              display_count: currentSelectionBrut.display_count,
              display_catalog: currentSelectionBrut.display_catalog,
            });
          }
        });
        var template = cloneDeep(state.currentOne[name]);
        template.items = selectionsClean;
        var templateId = template.id;
        return templatesService.update(template, templateId).then(
          (response) => {
            commit('putSuccess', response);
            dispatch('selections/saveState', {}, { root: true });
            const alert = {
              id: 'template-well-updated',
              icon: 'check',
              type: 'valid',
              message: 'notifications.update.success',
            };
            dispatch('displayAlert', alert, { root: true });
            return Promise.resolve(response);
          },
          (error) => {
            commit('putFailure', false);
            const alert = {
              id: 'selection-not-updated',
              icon: 'close',
              type: 'error',
              message: 'notifications.update.error',
            };
            dispatch('displayAlert', alert, { root: true });
            return Promise.reject(error);
          }
        );
      default:
        return Promise.resolve();
    }
  },

  touchTemplate({ state, commit, rootGetters }, payload) {
    const { name, mode } = payload;
    const current_platform_id =
      rootGetters['platforms/currentPlatformId'](mode);

    if (isNil(state.savedOne[name].header?.template_id)) {
      return;
    }

    const current_template_id = state.savedOne[name].header.template_id;

    if (!hasDifference(state.savedOne[name], defaultOne[name])) {
      return Promise.resolve();
    }

    const params = {
      id: current_template_id,
      platform_id: current_platform_id,
      name: name,
    };

    return templatesService.update(params, current_template_id).then(
      (response) => {
        commit('getSuccess', { name, mode, response });
        return Promise.resolve(response);
      },
      (error) => {
        commit('getFailure', name);
        return Promise.reject(error);
      }
    );
  },
  changeBlocksRank({ commit }, payload) {
    commit('changeBlocksRank', payload);
  },

  addItemsToblock({ commit }, payload) {
    commit('addItemsToblock', payload);
  },

  addBlock({ commit }, payload) {
    commit('addBlock', payload);
  },

  updateBlock({ commit }, payload) {
    commit('updateBlock', payload);
  },

  updateBlocks: ({ commit }, payload) => {
    commit('updateBlocks', payload);
  },

  /**
   * Delete block from one device template
   *
   * @param {*} param0
   * @param {*} payload  : templatename, mode, block
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  deleteBlock({ commit }, payload) {
    commit('deleteBlock', payload);
  },

  /**
   * Save blocks for one device template
   *
   * @param {*} param0   : device (desktop, tablet, ...)
   * @param {*} payload  : templatename, mode, block
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  saveBlocksData({ dispatch, state }, payload) {
    const { device, name } = payload;
    if (isNil(name)) {
      return false;
    }

    if (isNil(device)) {
      return false;
    }

    if (isNil(state.savedOne[name][device].blocks)) {
      return false;
    }

    if (isNil(state.currentOne[name][device].blocks)) {
      return false;
    }

    if (
      isNil(state.savedOne[name][device].blocks) &&
      isNil(state.currentOne[name][device].blocks)
    ) {
      return false;
    }

    let promises = [];

    for (let i = 0; i < state.currentOne[name][device].blocks.length; i++) {
      const savedKey = findKey(state.savedOne[name][device].blocks, [
        'block_id',
        state.currentOne[name][device].blocks[i].block_id,
      ]);
      const currentBlock = state.currentOne[name][device].blocks[i];
      // Update block
      if (
        !isNil(savedKey) &&
        (savedKey != i ||
          hasDifference(
            state.savedOne[name][device].blocks[savedKey],
            state.currentOne[name][device].blocks[i]
          ))
      ) {
        let params = cloneDeep(
          typeof currentBlock.getCleanDataForApi == 'function'
            ? currentBlock.getCleanDataForApi()
            : currentBlock
        );
        // Set new rank
        params.rank = i;
        let platformId = state.currentOne[name][device].blocks[i].platform_id;
        let retval = dispatch(
          'blocks/update',
          {
            blockId: state.currentOne[name][device].blocks[i].block_id,
            block: params,
            platformId: platformId,
          },
          { root: true }
        );
        promises.push(retval);
        // Add block
      } else if (isNil(savedKey)) {
        const params = cloneDeep(
          typeof currentBlock.getCleanDataForApi == 'function'
            ? currentBlock.getCleanDataForApi()
            : currentBlock
        );
        // Set new rank
        params.rank = i;
        let platformId = params.platform_id;
        let retval = dispatch(
          'blocks/add',
          { block: params, platformId: platformId },
          { root: true }
        );
        promises.push(retval);
      }
    }

    // Delete blocks
    for (let i = 0; i < state.savedOne[name][device].blocks.length; i++) {
      let savedKey = findKey(state.currentOne[name][device].blocks, [
        'block_id',
        state.savedOne[name][device].blocks[i].block_id,
      ]);
      if (isNil(savedKey)) {
        let platformId = state.savedOne[name][device].blocks[i].platform_id;
        let retval = dispatch(
          'blocks/delete',
          {
            blockId: state.savedOne[name][device].blocks[i].block_id,
            platformId: platformId,
          },
          { root: true }
        );
        promises.push(retval);
      }
    }

    return Promise.all(promises).then((x) => Promise.resolve());
  },

  updateBlocksPicture({ dispatch, state }, name) {
    let BlockName = 'header';

    if (isNil(name)) {
      return false;
    }

    if (
      isNil(state.savedOne[name][BlockName]) ||
      isNil(state.currentOne[name][BlockName])
    ) {
      return false;
    }

    if (
      !hasDifference(
        state.currentOne[name][BlockName].picture,
        state.savedOne[name][BlockName].picture
      )
    ) {
      return false;
    }

    let saved = state.savedOne[name][BlockName];
    const promises = [];
    for (let i = 0; i < state.currentOne[name][BlockName].length; i++) {
      let savedKey = findKey(saved, [
        'item_type',
        state.currentOne[name][BlockName][i].item_type,
      ]);

      if (
        hasDifference(
          state.currentOne[name][BlockName][savedKey],
          state.savedOne[name][BlockName][savedKey]
        )
      ) {
        // )
        // if (state.savedOne[name][BlockName][savedKey].rank != state.currentOne[name][BlockName][i].rank) {
        promises.push(
          dispatch(
            'blocks/updateRank',
            {
              blockId: state.currentOne[name][BlockName][i].block_id,
              picture: state.currentOne[name][BlockName][i].picture,
            },
            { root: true }
          )
        );
      }
    }
    return Promise.all(promises).then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },

  updateItems({ dispatch, state, rootGetters }) {
    let list = [
      { template: 'landing', nav: 'header' },
      { template: 'home', nav: 'header' },
      { template: 'home', nav: 'footer' },
    ];
    let promiseList = [];

    // going to save for each blocks from list
    for (let j = 0; j < list.length; j++) {
      let { template, nav } = list[j];

      // if data are not set, no need to save
      if (
        isNil(state.currentOne[template]) ||
        isNil(state.currentOne[template][nav])
      ) {
        continue;
      }

      // Do we really need to save items for this block ?
      if (
        !hasDifference(
          state.currentOne[template][nav].items,
          state.savedOne[template][nav].items
        )
      ) {
        continue;
      }

      let blockId = state.currentOne[template][nav].id;
      let blocks = state.currentOne[template][nav].items;
      let nbItem = 0;

      for (let i = 0; i < blocks.length; i++) {
        if (blocks[i].toRemove === true) {
          nbItem++;
          promiseList.push(
            itemsService.delete(blocks[i].id).then(
              (response) => Promise.resolve(),
              () => Promise.reject()
            )
          );
        } else if (blocks[i].newItem === true) {
          nbItem++;
          let itemToAdd = cloneDeep(blocks[i]);
          itemToAdd.block_id = blockId;
          itemToAdd.item_type_id =
            itemToAdd?.item_type_id
            ?? rootGetters['references/item_type_id'](itemToAdd?.type)
            ?? 4;
          itemToAdd.rank = !isNil(itemToAdd.rank) ? itemToAdd.rank : i;
          promiseList.push(
            itemsService.post(itemToAdd).then(
              (response) => Promise.resolve(),
              () => Promise.reject()
            )
          );
        } else {
          let retval = state.savedOne[template][nav].items.find((element) => {
            return (
              (!isNil(blocks[i].id) && element.id == blocks[i].id) ||
              (!isNil(blocks[i].newItemID) &&
                element.newItemID == blocks[i].newItemID)
            );
          });

          if (hasDifference(blocks[i], retval)) {
            nbItem++;
            var diff = difference(blocks[i], retval);

            // If one change, save all configuration item
            if (!isNil(diff.configuration)) {
              diff.configuration = blocks[i].configuration;
            }

            // If one change, save all picture item
            if (!isNil(diff.picture)) {
              diff.picture = blocks[i].picture;
            }

            promiseList.push(
              itemsService.update(diff, blocks[i].id).then(
                (response) => Promise.resolve(),
                () => Promise.reject()
              )
            );
          }
        }
      }
    }

    if (promiseList.length === 0) {
      return Promise.resolve();
    }

    return Promise.all(promiseList).then(
      function (values) {
        return Promise.resolve(values);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },

  /**
   * Add new block to template
   *
   * @return void
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  addBlockContent({ dispatch, state, getters }, payload) {
    const { templateName, mode, metadatadetails, blocks } = payload;
    const metadata = cloneDeep(metadatadetails);

    const platformId = this.getters['platforms/currentPlatformId'](mode);
    const templateId = state.savedOne[templateName][mode].id;

    let params = {
      block_id: `metadata-${templateName}-${mode}-${metadata.id}`,
      template_id: templateId,
      platform_id: platformId,
      socialNetworks: metadata.socialNetworks,
      rank: 1,
      title: metadata.title ?? null,
    };

    if (metadata.id === 'banner-video') {
      params.block_id = `metadata-${templateName}-${mode}-banner-video`;
      params.rank = 1;

      blocks.unshift(new BlockBannerImage(cloneDeep(params)));
      return dispatch('updateBlocks', { templateName, mode, blocks });
    } else if (metadata.id === 'banner-program') {
      params.block_id = `metadata-${templateName}-${mode}-banner-program`;
      params.rank = 0;

      blocks.unshift(new BlockBannerText(cloneDeep(params),
        this.state.platforms.currentPlatform.content_config.colors.default)
      );
      return dispatch('updateBlocks', { templateName, mode, blocks });
    } else if (metadata.id === 'grid-contents') {
      params.rank = 999;
      params.block_id = `metadata-${templateName}-${mode}-${metadata.id}`;
      blocks.push(new BlockGridContents(cloneDeep(params)));
      return dispatch('updateBlocks', { templateName, mode, blocks });
    } else if (metadata.id === 'social') {
      params.rank = 999;

      blocks.push(new BlockSocial(cloneDeep(params)));
      return dispatch('updateBlocks', { templateName, mode, blocks });
    } else if (metadata.id === 'episode') {
      params.rank = 2;

      blocks.push(new BlockEpisode(cloneDeep(params)));
      return dispatch('updateBlocks', { templateName, mode, blocks });
    } else if (metadata.id === 'row-recommendations') {
      params.block_id = `metadata-${templateName}-${mode}-${metadata.id}`;
      params.rank = 999;

      blocks.push(new BlockRecommendations(cloneDeep(params)));
      return dispatch('updateBlocks', { templateName, mode, blocks });
    }

    let blockType = metadata.block_type === 'banner' ? 'banner' : 'metadata';
    let itemType = metadata.item_type !== 'social' ? metadata.metadata_label : 'social';

    let metadataForBlock = {
      id: metadata.id,
      metadata_id: metadata.metadataId,
      type: metadata.metadataType,
      label: metadata.metadata_label,
      title: metadata.title,
      name: metadata.title,
      cardinality: metadata.cardinality,
    };

    let block = {
      template_id: templateId,
      platform_id: platformId,
      block_type: blockType,
      item_type: itemType,
      details: metadataForBlock,
      metadatable_id: metadata.type_id ?? null,
      metadatable_type: metadata.metadataType ?? null,
      active: true,
    };

    let newBlock = new BlockMetadata(
      block,
      this.getters['platforms/defaultColors']
    );
    newBlock.setDefaultTitle(metadata.title + ' :');

    blocks.push(newBlock);
    return dispatch('updateBlocks', { templateName, mode, blocks });
  },

  /**
   * Get light template data by template id
   *
   * @return void
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  getLightTemplateById({ commit, dispatch }, templateId) {
    return templatesService.getById(templateId, true).then(
      (template) => {
        return Promise.resolve(template);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },

  /**
   * Search in templates list
   *
   * @return void
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  search({ commit, rootGetters }, payload) {
    let forceAll = payload?.all ?? false;
    let { needle = '', queryString = '' } = payload;

    if (!rootGetters['auth/can']('animation.beta') && !forceAll) {
      queryString += '&template_type_name=page';
    }

    commit('getAllRequest');
    return templatesService.search(needle, queryString).then(
      (templates) => {
        commit('getAllSuccess', templates);
        return Promise.resolve(templates);
      },
      (error) => {
        commit('getAllFailure');
        return Promise.reject(error);
      }
    );
  },

  /**
   * Delete templates
   *
   * @return void
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  deleteTemplate({ commit, dispatch, rootState }, templateId) {
    return templatesService.delete(templateId).then(
      (template) => {
        commit('cleanTemplates');
        const alert = {
          id: 'template-removed',
          icon: 'check',
          type: 'valid',
          message: 'notifications.delete.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (err) => {
        const alert = {
          id: 'template-removed-failed',
          icon: 'close',
          type: 'error',
          message: 'notifications.delete.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },

  /**
   * Update templates
   *
   * @return void
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  updateTemplate({ commit, dispatch, rootState }, payload) {
    let { params, templateId } = payload;
    params.all = true;

    return templatesService.update(params, templateId).then(
      () => {
        commit('cleanTemplates');
        const alert = {
          id: 'template-updated',
          icon: 'check',
          type: 'valid',
          message: 'notifications.update.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (err) => {
        const alert = {
          id: 'template-updated-failed',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },

  /**
   * Copy templates
   *
   * @return void
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  copyTemplate({ commit, dispatch, rootState }, payload) {
    let { params, templateId } = payload;
    params.all = true;

    return templatesService.copy(params, templateId).then(
      (response) => {
        commit('cleanTemplates');
        const alert = {
          id: 'template-copied',
          icon: 'check',
          type: 'valid',
          message: 'notifications.update.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(response);
      },
      (err) => {
        const alert = {
          id: 'template-removed',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },

  /**
   * Empty templates
   *
   * @return void
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  emptyTemplate({ commit, dispatch, rootState }, payload) {
    let { params, templateId } = payload;
    params.all = true;

    return templatesService.empty(params, templateId).then(
      (template) => {
        commit('cleanTemplates');
        const alert = {
          id: 'template-emptied',
          icon: 'check',
          type: 'valid',
          message: 'notifications.update.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (err) => {
        const alert = {
          id: 'template-removed',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },

  /**
   * Clean states
   *
   * @return void
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  cleanTemplates({ commit }) {
    commit('cleanTemplates');
  },

  /**
   * Publish
   *
   * @return void
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  publishTemplate(
    { state, rootState, commit, dispatch, rootGetters },
    templateId
  ) {
    const params = {
      all: true,
      online: true,
    };

    return templatesService.update(params, templateId).then(
      (response) => {
        commit('cleanTemplates');
        const alert = {
          id: 'template-published',
          icon: 'check',
          type: 'valid',
          message: 'notifications.publish.online.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(response);
      },
      (err) => {
        const alert = {
          id: 'template-published-failed',
          icon: 'close',
          type: 'error',
          message: 'notifications.publish.online.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },

  /**
   * Unpublish
   *
   * @return void
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  unpublishTemplate(
    { state, rootState, commit, dispatch, rootGetters },
    templateId
  ) {
    const params = {
      all: true,
      online: false,
    };

    return templatesService.update(params, templateId).then(
      () => {
        commit('cleanTemplates');
        const alert = {
          id: 'template-unpublished',
          icon: 'check',
          type: 'valid',
          message: 'notifications.publish.offline.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (err) => {
        const alert = {
          id: 'template-unpublished-failed',
          icon: 'close',
          type: 'error',
          message: 'notifications.publish.offline.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },
};

const mutations = {
  reset(state, name) {
    if (Array.isArray(name)) {
      name.forEach((item) => {
        state.savedOne[item] = cloneDeep(defaultOne[item]);
        state.currentOne[item] = cloneDeep(defaultOne[item]);
      });
    } else if (typeof name === 'string') {
      state.savedOne[name] = cloneDeep(defaultOne[name]);
      state.currentOne[name] = cloneDeep(defaultOne[name]);
    }
  },
  getAllRequest(state) {
    state.all = [];
    state.loading.all = true;
  },
  getAllSuccess(state, response) {
    state.all = response;
    state.loading.all = false;
  },
  getAllFailure(state) {
    state.all = [];
    state.loading.all = false;
  },
  // One
  getRequest(state, payload) {
    const { name, mode } = payload;
    state.savedOne[name][mode] = defaultOne[name][mode];
    state.currentOne[name][mode] = defaultOne[name][mode];
    state.loading[name] = true;
  },
  getSuccess(state, payload) {
    const { name, mode, response } = payload;
    let header =
      !isNil(response.header) && !isNil(response.header.items)
        ? response.header.items
        : [];
    let footer =
      !isNil(response.footer) && !isNil(response.footer.items)
        ? response.footer.items
        : [];
    let blocks =
      !isNil(response.blocks) && !isNil(response.blocks.items)
        ? response.blocks.items
        : [];

    let defaultItemConf = {
      align: 'left',
      movable: true,
      dropdown: { show: false, type: null },
    };

    for (let i = 0; i < header.length; i++) {
      let currentItem = new Item(header[i]);
      currentItem.configuration =
        isNil(currentItem.configuration) || isEmpty(currentItem.configuration)
          ? defaultItemConf
          : currentItem.configuration;
      currentItem.configuration.align = isNil(currentItem.configuration.align)
        ? defaultItemConf.align
        : currentItem.configuration.align;
      currentItem.configuration.movable = isNil(
        currentItem.configuration.movable
      )
        ? defaultItemConf.movable
        : currentItem.configuration.movable;

      header[i] = currentItem;
    }

    for (let i = 0; i < blocks.length; i++) {
      let currentItem = new Item(blocks[i]);
      blocks[i] = currentItem;
    }

    for (let i = 0; i < footer.length; i++) {
      let currentItem = new Item(footer[i]);
      currentItem.configuration =
        isNil(currentItem.configuration) || isEmpty(currentItem.configuration)
          ? defaultItemConf
          : currentItem.configuration;
      currentItem.configuration.align = isNil(currentItem.configuration.align)
        ? defaultItemConf.align
        : currentItem.configuration.align;
      currentItem.configuration.movable = isNil(
        currentItem.configuration.movable
      )
        ? defaultItemConf.movable
        : currentItem.configuration.movable;
      footer[i] = currentItem;
    }

    if (['program', 'serie', 'live', 'collection'].includes(name)) {
      var clonedResponse = cloneDeep(response);
      // Format blocks
      if (isNil(clonedResponse.blocks)) {
        clonedResponse = { ...clonedResponse, ...{ blocks: [] } };
      }

      for (let i = 0; i < clonedResponse.blocks.length; i++) {
        if (
          clonedResponse.blocks[i].block_type === 'banner' &&
          clonedResponse.blocks[i].item_type === 'program'
        ) {
          clonedResponse.blocks[i] = new BlockBannerText(
            clonedResponse.blocks[i],
            this.state.platforms.currentPlatform.content_config.colors.default
          );
        } else if (
          clonedResponse.blocks[i].block_type === 'banner' &&
          clonedResponse.blocks[i].item_type === 'video'
        ) {
          clonedResponse.blocks[i] = new BlockBannerImage(
            clonedResponse.blocks[i]
          );
        } else if (
          clonedResponse.blocks[i].block_type === 'banner' &&
          clonedResponse.blocks[i].item_type === 'social'
        ) {
          clonedResponse.blocks[i] = new BlockSocial(clonedResponse.blocks[i]);
        } else if (
          clonedResponse.blocks[i].block_type === 'banner' &&
          clonedResponse.blocks[i].item_type === 'episode'
        ) {
          clonedResponse.blocks[i] = new BlockEpisode(clonedResponse.blocks[i]);
        } else if (
          clonedResponse.blocks[i].block_type === 'metadata' &&
          !isNil(clonedResponse.blocks[i].item_type) &&
          !isNil(clonedResponse.blocks[i].details)
        ) {
          clonedResponse.blocks[i] = new BlockMetadata(
            clonedResponse.blocks[i],
            this.state.platforms.currentPlatform.content_config.colors.default
          );
        } else if (
          clonedResponse.blocks[i].block_type === 'row-recommendations'
        ) {
          clonedResponse.blocks[i] = new BlockRecommendations(
            clonedResponse.blocks[i],
            this.state.platforms.currentPlatform.content_config.colors.default
          );
        } else if (
          clonedResponse.blocks[i].block_type === 'grid-contents'
        ) {
          clonedResponse.blocks[i] = new BlockGridContents(clonedResponse.blocks[i]);
        } else {
          // Bad block are not meant to be used, so set to empty value
          // console.warn('bad block ' + i, clonedResponse.blocks[i]);
          clonedResponse.blocks[i] = null;
        }
      }
      // Remove empty value
      clonedResponse.blocks = clonedResponse.blocks.filter(Boolean);

      state.savedOne[name][mode] = cloneDeep(clonedResponse);
      state.currentOne[name][mode] = cloneDeep(clonedResponse);
    } else {
      state.savedOne[name] = cloneDeep(response);
      state.currentOne[name] = cloneDeep(response);
    }
    state.loading[name] = false;
  },
  getFailure(state, name) {
    state.savedOne[name] = cloneDeep(defaultOne);
    state.currentOne[name] = cloneDeep(defaultOne);
    state.loading[name] = false;
  },

  putRequest(state, name) {
    state.loading[name] = true;
  },
  putSuccess(state, payload) {
    var template = new Template(payload);
    var name = payload.name;
    state.currentOne[name] = cloneDeep(template);
    state.savedOne[name] = cloneDeep(template);
    state.loading[name] = false;
  },
  putFailure(state, name) {
    state.savedOne[name] = cloneDeep(defaultOne[name]);
    state.currentOne[name] = cloneDeep(defaultOne[name]);
    state.loading[name] = false;
  },

  changeBlocksRank: (state, payload) => {
    let { template, blocks } = payload;
    let clone = state.currentOne[template].blocks;
    let saved = state.savedOne[template].blocks;

    if (isNil(clone)) {
      return false;
    }

    for (let i = 0; i < clone.length; i++) {
      let key = findKey(clone, ['block_id', blocks[i].block_id]);
      let savedKey = findKey(saved, ['block_id', blocks[i].block_id]);
      if (!isNil(key)) {
        if (!isNil(key) && clone[key].block_type == 'metadata') {
          clone[key].rank = i;
        }
      }
    }

    state.currentOne[template].blocks = orderBy(clone, ['rank']);
  },

  /**
   * Add new block to template
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  addBlock: (state, payload) => {
    const { templateName, mode, block } = payload;
    let blocks = state.currentOne[templateName][mode].blocks;

    if (blocks.length === 0) {
      let defaultBlocks = cloneDeep(
        state.currentOne[templateName].desktop.blocks
      );
      if (utils.isDeviceTV(mode)) {
        defaultBlocks = defaultBlocks.filter((x) => x.id !== 'metadata-social');
      }
      state.currentOne[templateName][mode].blocks = defaultBlocks;
    }

    block.block_id = 10000000000 + blocks.length;
    if (blocks) {
      state.currentOne[templateName][mode].blocks.push(block);
    } else {
      state.currentOne[templateName][mode].blocks = [block];
    }
  },

  /**
   * Update all data for one block of one pair template and device
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  updateBlock: (state, payload) => {
    const { templateName, mode, block } = payload;
    let currentIndex = null;
    currentIndex = state.currentOne[templateName][mode].blocks.findIndex(
      (value, index) => {
        return value.block_id === block.block_id;
      }
    );
    if (
      !isNil(currentIndex) &&
      state.currentOne[templateName] &&
      state.currentOne[templateName][mode]
    ) {
      state.currentOne[templateName][mode].blocks.splice(
        currentIndex,
        1,
        cloneDeep(block)
      );
    }
  },

  /**
   * Delete one block from one device template
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  deleteBlock: (state, payload) => {
    const { templateName, mode, block } = payload;
    let currentIndex = null;
    currentIndex = state.currentOne[templateName][mode].blocks.findIndex(
      (value, index) => {
        return value.block_id === block.block_id;
      }
    );

    if (
      !isNil(currentIndex) &&
      state.currentOne[templateName] &&
      state.currentOne[templateName][mode]
    ) {
      state.currentOne[templateName][mode].blocks.splice(currentIndex, 1);
    }
  },

  /**
   * Update all data for all blocks for one pair template and device
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  updateBlocks(state, payload) {
    const { templateName, mode, blocks } = payload;
    const currentPlatformId = this.getters['platforms/currentPlatformId'](mode);
    const currentTemplateId = state.savedOne[templateName][mode].id;

    let retBlocks = cloneDeep(blocks);
    state.currentOne[templateName][mode].blocks = retBlocks.map((x) => {
      x.platform_id = currentPlatformId;
      x.template_id = currentTemplateId;
      return x;
    });
  },

  updateBlockMetadata: (state, payload) => {
    const { templateName, mode, block, metadata } = payload;
    state.currentOne[templateName][mode] = cloneDeep(
      state.currentOne[templateName][mode].map((x) => {
        if (x.metadatas) {
          x.metadatas = x.metadatas.map((y) =>
            y.id === metadata.id ? metadata : y
          );
        }
        return x;
      })
    );
  },

  addItemsToblock: (state, payload) => {
    // component: landing/home/...
    // nav: header/footer
    let { component, nav, ...item } = payload;
    let currentItem = new Item(item);
    let retvalIndex = -1;

    if (!state.currentOne[component][nav]?.items) {
      state.currentOne[component][nav].items = [];
      state.savedOne[component][nav].items = [];
    }

    let retval = state.currentOne[component][nav].items.find(
      (element, index) => {
        if (
          (!isNil(currentItem.id) && element.id == currentItem.id) ||
          (!isNil(currentItem.newItemID) &&
            element.newItemID == currentItem.newItemID)
        ) {
          retvalIndex = index;
          return element;
        }
      }
    );

    if (currentItem.toRemove === true) {
      retval.toRemove = true;
    } else if (!isNil(currentItem.id)) {
      retval = { ...retval, ...currentItem };
    } else if (!isNil(currentItem.newItemID)) {
      if (!isNil(retval)) {
        retval = { ...retval, ...currentItem };
      } else {
        state.currentOne[component][nav].items.push(currentItem);
      }
    }
    if (retvalIndex > -1) {
      state.currentOne[component][nav].items.splice(retvalIndex, 1, retval);
    }
  },

  cleanTemplates(state) {
    state.all = [];
  },
};

const getters = {
  loading: (state) => {
    return (
      state.loading.landing === true ||
      state.loading.home === true ||
      state.loading.program === true ||
      state.loading.serie === true ||
      state.loading.live === true ||
      state.loading.collection === true
    );
  },
  // loading: (state) => (name) => !isNil(state.loading[name]) && state.loading[name],
  shouldSaved: (state, getters) => {
    return (
      getters.hasTemplateDifference('landing') ||
      getters.hasTemplateDifference('home') ||
      getters.hasTemplateDifference('selection') ||
      getters.hasTemplateBlocksDifferences('serie') ||
      getters.hasTemplateBlocksDifferences('program') ||
      getters.hasTemplateBlocksDifferences('live') ||
      getters.hasTemplateBlocksDifferences('collection')
    );
  },
  hasTemplateDifference: (state) => (name) =>
    hasDifference(state.savedOne[name], state.currentOne[name]),
  stateBlocks: (state) => state.currentOne,
  templateModeBlocks: (state) => (templateName, mode) => {
    if (
      state.currentOne?.[templateName]?.[mode]?.blocks?.length > 0 &&
      !isNil(state.currentOne[templateName][mode].blocks)
    ) {
      return state.currentOne[templateName][mode].blocks;
    }
    if (utils.isDeviceTV(mode)) {
      return state?.currentOne[templateName].desktop?.blocks?.length ?? false
        ? state.currentOne[templateName].desktop.blocks.filter(
          (x) => x.id !== 'metadata-social'
        )
        : state.currentOne[templateName].desktop.blocks;
    } else {
      return state.currentOne[templateName].desktop.blocks;
    }
  },
  /**
   * Is there any difference between saved and updated templates based on clean data for api
   *
   * @return {boolean} Return true is there any difference else false
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   */
  hasTemplateBlocksDifferences:
    (state, getters, rootState, rootGetters) => (templateName) => {
      var isDifferent = false;
      const devices = rootGetters['platforms/devices'];
      for (let device of devices) {
        let currentBlocks = cloneDeep(
          state.currentOne[templateName][device.id].blocks ?? []
        ).map((element) =>
          typeof element.getCleanDataForApi == 'function'
            ? element.getCleanDataForApi()
            : element
        );
        let savedBlocks = cloneDeep(
          state.savedOne[templateName][device.id].blocks ?? []
        ).map((element) =>
          typeof element.getCleanDataForApi == 'function'
            ? element.getCleanDataForApi()
            : element
        );

        if (currentBlocks.length !== savedBlocks.length) {
          isDifferent = true;
        } else {
          isDifferent =
            isDifferent || hasDifference(currentBlocks, savedBlocks);
        }
      }
      return isDifferent;
    },
  templateId: (state) => (name, mode) => state.savedOne?.[name]?.[mode]?.id,
  currentPlatformId: (state, getters, rootState, rootGetters) => (mode) =>
    rootGetters['platforms/currentPlatformId'](mode),
  defaultColors: (state, getters, rootState, rootGetters) =>
    rootGetters['platforms/defaultColors'],
  episodeBlockTemplateId: (state, getters) => (name, mode) => {
    const block = getters
      .templateModeBlocks(name, mode)
      ?.find((x) => x.block_type === 'banner' && x.item_type === 'episode');
    return block?.content_episode?.aspect ?? 'none';
  },
  filters: (state) => {
    var blackList = ['text', 'video', 'date', 'integer'];
    return state.all.filter(
      (item) =>
        item.activated &&
        item.front &&
        !blackList.includes(item.type) &&
        item.filterable
    );
  },
};

export const templates = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
