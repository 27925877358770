import { DiscountCampaign } from '@/models/billing/DiscountCampaign.js';
import { Plan } from '@/models/Plan.js';
import BillingUtils from '@/utils/BillingUtils';
import moment from 'moment-timezone';

export class Voucher {
  discount_campaign_id;
  type;
  token;
  redemptions;
  redemptions_limit;
  couponnable_type;
  coupon_type;
  couponnable_id;
  status_code;
  created_at;
  updated_at;
  promo_duration;
  campaign;
  plan;
  campaign_starts_at;
  campaign_ends_at;
  redeemed_at;
  subscription_active;
  subscription_trial;
  starts_at;
  ends_at;
  status;

  constructor(
    {
      discount_campaign_id = undefined,
      type = undefined,
      token = undefined,
      redemptions = undefined,
      redemptions_limit = undefined,
      couponnable_type = undefined,
      couponnable_id = undefined,
      status_code = undefined,
      created_at = null,
      updated_at = null,
      promo_duration = {},
      campaign = {},
      plan = {},
      couponnable = {},
    } = {},
    starts_at = null,
    ends_at = null,
    subscription_active = false
  ) {
    this.discount_campaign_id = discount_campaign_id;
    this.type = type;
    this.token = token;
    this.redemptions = redemptions;
    this.redemptions_limit = redemptions_limit;
    this.couponnable_type = couponnable_type;
    this.coupon_type = (couponnable_type ?? '').replace('coupon', '');
    this.couponnable_id = couponnable_id;
    this.status_code = status_code;
    this.created_at = created_at
      ? moment.tz(created_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC')
      : null;
    this.updated_at = updated_at
      ? moment.tz(updated_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC')
      : null;
    this.campaign = new DiscountCampaign(campaign);
    this.plan = new Plan(plan);
    this.campaign_starts_at = campaign.starts_at
      ? moment.tz(campaign.starts_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC')
      : null;
    this.campaign_ends_at = campaign.ends_at
      ? moment.tz(campaign.ends_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC')
      : null;
    this.redeemed_at = null;
    this.subscription_trial = null;
    this.subscription_active = subscription_active;
    this.starts_at = starts_at
      ? moment.tz(starts_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC')
      : null;
    if (
      starts_at &&
      type === 'classic' &&
      promo_duration?.interval_count &&
      promo_duration?.cycle_count &&
      ['month', 'year'].includes(promo_duration?.interval)
    ) {
      const count = promo_duration.interval_count * promo_duration.cycle_count;
      const end_at = moment(starts_at).add(count, promo_duration.interval);
      this.ends_at = moment.tz(end_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
    } else if (ends_at && type !== 'classic') {
      this.ends_at = moment.tz(ends_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
    } else {
      this.ends_at = null;
    }

    this.promo_duration = {
      trial_in_days: promo_duration.trial_in_days,
      interval_count: promo_duration.interval_count,
      cycle_count: promo_duration.cycle_count,
      interval: promo_duration.interval,
    };

    this.couponnable = {
      id: couponnable.id,
      name: couponnable.name,
      prefix: couponnable.prefix,
      amount_off: couponnable.amount_off ?? null,
      amount_off_with_currency: couponnable.amount_off_with_currency ?? null,
      created_at:
        couponnable.created_at === null
          ? null
          : moment.tz(created_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC'),
      updated_at:
        couponnable.updated_at === null
          ? null
          : moment.tz(updated_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC'),
    };

    this.status = BillingUtils.getStatusLabel(
      this.starts_at,
      this.ends_at,
      this.subscription_active
    );
  }
}

export default Voucher;
