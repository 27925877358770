export class Style {
  constructor({
    id = null,
    group_label = null,
    label = null,
    background_color = '#000000',
    box_shadow = null,
    position = 'bottom-right',
    border_radius = 5,
    border_width = 0,
    border_color = '#336699',
    font_family = null,
    font_size = 'md',
    font_color = '#FFFFFF',
    font_style = 'normal',
    font_weight = 'normal',
    text_shadow = null,
    text_transform = 'none',
    stylable_type = null,
    stylable_id = null,
  } = {}) {
    (this.id = id),
      (this.group_label = group_label),
      (this.label = label),
      (this.background_color = background_color);
    this.box_shadow = box_shadow;
    this.position = position;
    this.border_radius = border_radius;
    this.border_width = border_width;
    this.border_color = border_color;
    this.font_family = font_family;
    this.font_size = font_size;
    this.font_color = font_color;
    this.font_style = font_style;
    this.font_weight = font_weight;
    this.text_shadow = text_shadow;
    this.text_transform = text_transform;
    this.stylable_type = stylable_type;
    this.stylable_id = stylable_id;
  }
}

export function createBadge(data) {
  return Object.freeze(new Style(data));
}

export default Style;
