<template>
  <section
    :data-cy="itemKey"
    v-auto-animate
    class="card-accordion-item expandable"
    :class="[
      ...cardAccordionItemClass,
      !toggleable && 'card-section',
      open ? 'card-accordion-item-opened' : 'card-accordion-item-closed',
    ]"
  >
    <fieldset :disabled="disabled">
      <header
        v-if="hasHeader"
        ref="header"
        class="card-accordion-item-header flex items-start gap-4 bg-skin-ui"
        :class="[
          sticky && 'md:sticky',
          {
            //tooltip: titleTooltip && titleTooltip.image,
          },
        ]"
        @click="toggleItem()"
      >
        <div class="card-accordion-item-header-infos flex flex-col gap-4">
          <h2
            v-if="title || $slots['title']"
            class="card-accordion-item-title inline-flex gap-2"
            :class="size !== 'xs' && 'uppercase'"
          >
            <Icon
              v-if="icon"
              :icon="icon"
              class="card-accordion-item-title-icon"
            />
            <slot name="title">
              {{ title }}
            </slot>
            <Tooltip
              v-if="titleTooltip"
              :icon="titleTooltip.icon"
              :color="titleTooltip.color ?? undefined"
              :position="titleTooltip.position"
              :align="titleTooltip.align"
              :image="titleTooltip.image"
              :message="titleTooltip.message"
            />
          </h2>
          <p
            v-if="showSubtitle"
            class="card-accordion-item-subtitle transition transition-opacity ease-in-out"
            :class="disabled && 'disabled'"
          >
            <slot name="subtitle">
              <span class="leading-tight">
                {{ subtitle }} 
                <a class="text-skin-accent underline text-sm"
                  v-if="link"
                  :href="link.href"
                  target="_blank"
                >
                  {{ t(link.text) }}
                </a>
              </span>
              <Tooltip
                v-if="subtitleTooltip"
                :icon="subtitleTooltip.icon"
                :color="subtitleTooltip.color ?? undefined"
                :position="subtitleTooltip.position"
                :align="subtitleTooltip.align"
                :image="subtitleTooltip.image"
                :message="subtitleTooltip.message"
              />
            </slot>
          </p>
        </div>
        <div
          v-if="toggleable"
          class="card-accordion-item-header-actions flex lg:basis-[140px] justify-end items-start"
        >
          <a
            class="card-accordion-item-header-actions-link flex gap-1 text-skin-accent"
            :class="`theme-${theme}`"
          >
            <span
              v-if="$isDesktop && !hideToggleLabel"
              class="card-accordion-item-header-actions-link-label whitespace-nowrap"
            >
              <template v-if="open">{{ hideLabel }}</template>
              <template v-else>{{ displayLabel }}</template>
            </span>
            <Icon
              :icon="open ? 'chevron-up-sm' : 'chevron-down-sm'"
              :size="$isDesktop ? 'xs' : 'md'"
              class="card-accordion-item-header-actions-link-icon"
            />
          </a>
        </div>
        <div v-if="$slots['actions']" class="card-accordion-item-header-actions">
          <slot name="actions" />
        </div>
      </header>
      <div
        v-if="open"
        class="card-accordion-item-content !z-0"
        :class="!open && '!z-0'"
      >
        <KeepAlive>
          <slot />
        </KeepAlive>
      </div>
    </fieldset>
  </section>
</template>

<script setup lang="ts">
import { computed, inject, useSlots } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import Icon from '@/components/icon/Icon.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';

const { t } = useI18n();
const slots = useSlots();
const route = useRoute();

interface Props {
  size?: string;
  title?: string;
  titleTooltip?: Object;
  subtitle?: string;
  subtitleTooltip?: Object;
  toggleDisplayLabel?: string;
  toggleHideLabel?: string;
  hideToggleLabel?: boolean;
  spacer?: string;
  icon?: string;
  sticky?: boolean;
  compact?: boolean;
  customClass?: string;
  separator?: boolean;
  disabled?: boolean;
  itemKey?: number | string;
  link?: Object;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  sticky: true,
  separator: true,
});

const $isDesktop = inject('$isDesktop');
const $activeItemKey = props.itemKey ? inject('$activeItemKey') : null;
const $multiple = props.itemKey ? inject('$multiple') : false;
const toggleItemKey = props.itemKey ? inject('toggleItemKey') : null;

const toggleable = computed(() => toggleItemKey && $activeItemKey);

const hasHeader = computed(() => {
  return (
    props.title ||
    props.subtitle ||
    slots.title ||
    slots.subtitle ||
    toggleable.value
  );
});

const open = computed(() => {
  if (!$activeItemKey || !props.itemKey) {
    return true;
  }
  return $multiple
    ? $activeItemKey.value.includes(props.itemKey)
    : $activeItemKey.value === props.itemKey;
});

const displayLabel = computed(() => 
  props.toggleDisplayLabel || t('section.toggle.show')
);
const hideLabel = computed(() => 
  props.toggleHideLabel || t('section.toggle.hide')
);

const showSubtitle = computed(() => {
  return (
    (!props.compact || (props.compact && open.value)) &&
    (props.subtitle || slots.subtitle)
  );
});

const theme = computed(() => {
  return route?.matched[0]?.meta?.theme ?? 'primary';
});

const cardAccordionItemClass = computed(() => {
  return [
    props.separator && 'border-b [&:not(:last-child)]:border-b-solid border-skin-ui',
    props.spacer && `spacer-${props.spacer}`,
    props.size && `card-accordion-item-${props.size}`,
    props.customClass && `${props.customClass}`,
  ];
});

const toggleItem = () => {
  if (!props.itemKey) return;
  toggleItemKey(props.itemKey);
};
</script>

<style lang="scss">
@import 'card_section';
</style>
