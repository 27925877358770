const sorts = [
  {
    id: 'date:desc',
    title: 'search.added',
  },
  {
    id: 'name:asc',
    title: 'A - Z',
  },
  {
    id: 'name:desc',
    title: 'Z - A',
  },
];

export default sorts;
