import durationFrequencies from '@/data/durationFrequencies';
import moment from 'moment';

export const BillingUtils = {
  getTimeUnitSimple(
    self,
    interval_count = 0,
    interval = 'month',
    defaultKey = ''
  ) {
    switch (interval_count + '_' + interval) {
      case '24_hour':
      case '1_day':
        return defaultKey + ' ' + self.$t('frequency.day.title');
      case '7_day':
      case '1_week':
        return defaultKey + ' ' + self.$t('frequency.week.title');
      case '1_month':
        return defaultKey + ' ' + self.$t('frequency.month.title');
      case '2_month':
        return defaultKey + ' ' + self.$t('frequency.bimester.title');
      case '3_month':
        return defaultKey + ' ' + self.$t('frequency.trimester.title');
      case '4_month':
        return defaultKey + ' ' + self.$t('frequency.quadrimester.title');
      case '5_month':
        return defaultKey + ' ' + self.$t('frequency.pentamester.title');
      case '6_month':
        return defaultKey + ' ' + self.$t('frequency.semester.title');
      case '12_month':
      case '1_year':
        return defaultKey + ' ' + self.$t('frequency.year.title');
      default:
        return self.$t('frequency.' + interval + '.value', interval_count, {
          n: interval_count,
        });
    }
  },
  getTimeUnitAdvanced(interval_count = 0, interval = 'month', cycle_count = 0) {
    let unitAdvanced;
    switch (interval_count + '_' + interval) {
      case '1_day':
        unitAdvanced = 'day';
        break;
      case '7_day':
      case '1_week':
        unitAdvanced = 'week';
        break;
      case '1_month':
        unitAdvanced = 'month';
        break;
      case '2_month':
        unitAdvanced = 'bimester';
        break;
      case '3_month':
        unitAdvanced = 'trimester';
        break;
      case '4_month':
        unitAdvanced = 'quadrimester';
        break;
      case '5_month':
        unitAdvanced = 'pentamester';
        break;
      case '6_month':
        unitAdvanced = 'semester';
        break;
      case '12_month':
      case '1_year':
        unitAdvanced = 'year';
        break;
      default:
        unitAdvanced = 'x ' + interval_count + ' ' + interval;
        break;
    }
    let frequency = durationFrequencies.find(
      (durationFrequency) => durationFrequency.id === unitAdvanced
    );
    let frequencyBase = durationFrequencies.find(
      (durationFrequency) => durationFrequency.id === interval
    );
    let value =
      frequency !== undefined ? frequency.value ?? 'Unknown' : frequencyBase.value;

    return {
      count: cycle_count,
      unit: unitAdvanced,
      value,
      title: frequency?.title ?? '',
    };
  },

  getStatusLabel(starts_at, ends_at, active) {
    const now = moment();
    if (active) {
      return 'active';
    } else if (ends_at && ends_at < now) {
      return 'passed';
    } else if (starts_at && starts_at > now) {
      return 'scheduled';
    } else {
      return 'passed';
    }
  }
};
export default BillingUtils;
