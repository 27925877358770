import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const PATH = '/episodes';

export const episodesService = {
  all,
  get,
  create,
  update,
  deleteEpisode,
  createMany,
  updateMany,
  deleteMany,
};

async function all(queryString = '') {
  if (queryString !== '') {
    queryString = '?' + queryString;
  }
  try {
    const response = await axios.get(
      import.meta.env.VITE_CONTENT_BASE_URL + PATH + queryString
    );
    return handleResponse(response.data);
  } catch (response_1) {
    return [];
  }
}

async function get(id) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_CONTENT_BASE_URL + PATH + '/' + id
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function update(episode, id) {
  const requestOptions = {
    method: 'PUT',
  };
  try {
    const response = await axios.put(
      import.meta.env.VITE_CONTENT_BASE_URL + PATH + '/' + id,
      episode,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function createMany(episodes) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL + PATH + '/create-many',
      episodes,
      requestOptions
    );
    return response.data.results;
  } catch (response_1) {
    return await Promise.reject();
  }
}

async function updateMany(episodes) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL + PATH + '/update-many',
      episodes,
      requestOptions
    );
    return response.data.results;
  } catch (response_1) {
    return await Promise.reject();
  }
}

async function deleteMany(episodes) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL + PATH + '/delete-many',
      episodes,
      requestOptions
    );
    return response.data.results;
  } catch (response_1) {
    return await Promise.reject();
  }
}

async function create(episode) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL + PATH,
      episode,
      requestOptions
    );
    return response.data.results;
  } catch (response_1) {
    return await Promise.reject();
  }
}

async function deleteEpisode(id) {
  const requestOptions = {
    method: 'DELETE',
  };

  try {
    const response = await axios.delete(
      import.meta.env.VITE_CONTENT_BASE_URL + PATH + '/' + id,
      requestOptions
    );
    return handleResponse(response);
  } catch (err) {
    return await Promise.reject(err.response);
  }
}
