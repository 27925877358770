import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/persons/';

export const personService = {
  getAll,
  get,
  search,
  create,
  update,
  deleteOne,
  //delete
};

function getAll(queryString = '') {
  if (queryString !== '') {
    queryString = '?' + queryString;
  }
  return axios
    .get(import.meta.env.VITE_CONTENT_BASE_URL + api_url_module + queryString)
    .then((response) => response.data.results)
    .catch(() => []);
}

async function get(id) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module + id
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function search(needle, queryString = '') {
  if (queryString !== '') {
    queryString = '?' + queryString;
  }
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL +
        api_url_module +
        'search/' +
        queryString,
      { query: needle }
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function create(person) {
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module,
      person
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function update(person, id) {
  try {
    const response = await axios.put(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module + id,
      person
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function deleteOne(id) {
  try {
    const response = await axios.delete(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module + id
    );
    if (response.status === 204) {
      response.data = { status: 'success' };
    }
    return handleResponse(response.data);
  } catch (err) {
    return await Promise.reject(err.response);
  }
}
