const selectionFacets = [
  {
    title: 'status.title',
    id: 'status',
    checked: false,
    cols: 4,
    filters: [
      {
        facet_id: 'status',
        title: 'status.online',
        id: 'online',
        checked: false,
      },
      {
        facet_id: 'status',
        title: 'status.ready',
        id: 'ready',
        checked: false,
      },
      {
        facet_id: 'status',
        title: 'status.incomplete',
        id: 'incomplete',
        checked: false,
      },
    ],
  },
];

export default selectionFacets;
