import Picture from '@/models/Picture';

export class Participant {
  roundeds_url = '';
  original_roundeds_url = '';

  constructor({
    id,
    firstname,
    lastname,
    types,
    picture = null,
    description = '',
    created_at = new Date().toISOString(),
    roles = [],
    jobs = {},
  } = {}) {
    this.id = id;
    this.firstname = firstname;
    this.lastname = lastname;
    this.types = types;
    this.description = description ?? '';
    this.created_at = created_at;
    this.jobs = jobs;
    this.roles = roles;
    this.person_types = [];
    this.setFullName();
    this.setPicture(picture);
    this.setPersonType();
    this.setPictures();
  }

  setFullName() {
    this.fullName = `${this.firstname ? this.firstname + ' ' : ''}${this.lastname || ''
      }`;
  }
  setPersonType() {
    if (!this.jobs) {
      return;
    }
    for (let index = 0; index < this.jobs.length; index++) {
      this.person_types.push(this.jobs[index].id);
    }
  }

  setPicture(picture) {
    this.picture = Picture.getDefaultPicture('participant');
    const pictureTypes = Picture.getDefaultPictureTypesList('participant');
    if (!picture) {
      this.picture = {
        roundeds: [{ url: '', collectionName: 'roundeds' }]
      }
    } else if (typeof picture === 'object') {
      pictureTypes.forEach((pictureType) => {
        this.picture[pictureType] = picture[pictureType] ?? [];
      });
    } else {
      pictureTypes.forEach((pictureType) => {
        this.picture[pictureType] = [];
      });
    }
  }

  setPictures() {
    if (
      this.picture &&
      this.picture.roundeds &&
      this.picture.roundeds[0] &&
      this.picture.roundeds[0].url
    ) {
      this.roundeds_url = this.picture.roundeds[0].url;
      this.original_roundeds_url = this.picture.roundeds[0].url;
    }
  }

  setNewPicture() {
    if (
      this.roundeds_url &&
      this.roundeds_url != '' &&
      this.original_roundeds_url != this.roundeds_url
    ) {
      this.picture = { roundeds: [{ url: this.roundeds_url }] };
    } else {
      this.picture = null;
    }
  }

  hasTypes() {
    return this.jobs && this.jobs.length > 0;
  }
}

export function createParticipant(data) {
  return Object.freeze(new Participant(data));
}

export default Participant;
