import { LegalService } from '@/services';
import { cloneDeep } from 'lodash';
import { hasDifference } from '@/utils/diffTools';
import Legal from '@/models/platform/Legal';
import kebabcase from 'lodash/kebabCase';

const legalService = new LegalService();

const state = {
  all: [],
  allSaved: [],
  newLegal: new Legal(),
  savedLegal: new Legal(),
  formattedNewLegal: null,
  loading: false,
};

const getters = {
  shouldSaveOne: (state) => {
    return hasDifference(state.newLegal, state.savedLegal);
  },
};

const actions = {
  resetAll({ commit, dispatch }) {
    commit('cleanAllStates');
  },
  resetOne({ commit, dispatch }) {
    commit('cleanOneStates');
  },
  getAll({ commit, dispatch }, all = 1) {
    commit('getAllRequest');
    return legalService.getAll(all).then(
      (legals) => {
        commit('getAllSuccess', legals);
        return Promise.resolve(legals);
      },
      (error) => {
        commit('getAllFailure', error);
        return Promise.reject(error);
      }
    );
  },
  get({ commit, dispatch }, id) {
    commit('getRequest');
    return legalService.get(id).then(
      (legal) => {
        commit('getSuccess', legal);
        return Promise.resolve(legal);
      },
      (error) => {
        commit('cleanAllStates', error);
        return Promise.reject(error);
      }
    );
  },
  create({ commit, dispatch }, legal) {
    commit('createRequest', legal);
    return legalService.create(state.formattedNewLegal).then(
      (legal) => {
        commit('createSuccess', legal);
        const alert = {
          id: 'legal-well-created',
          icon: 'check',
          type: 'valid',
          message: 'notifications.create.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(legal);
      },
      (error) => {
        commit('createFailure');
        const alert = {
          id: 'legal-not-created',
          icon: 'close',
          type: 'error',
          message: 'notifications.create.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  update({ commit, dispatch }) {
    commit('updateRequest');
    return legalService.update(state.formattedNewLegal, state.newLegal.id).then(
      (legal) => {
        commit('updateSuccess', legal);
        const alert = {
          id: 'legal-well-updated',
          icon: 'check',
          type: 'valid',
          message: 'notifications.update.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(legal);
      },
      (error) => {
        commit('updateFailure');
        const alert = {
          id: 'legal-not-updated',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  delete({ commit, dispatch }, id) {
    commit('deleteRequest');
    return legalService.delete(id).then(
      () => {
        commit('deleteSuccess', id);
        const alert = {
          id: 'legal-well-deleted',
          icon: 'check',
          type: 'valid',
          message: 'notifications.delete.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (error) => {
        commit('cleanAllStates', error);
        const alert = {
          id: 'legal-not-deleted',
          icon: 'close',
          type: 'error',
          message: 'notifications.delete.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  cleanAllStates(state) {
    state.loading = false;
    state.all = [];
    state.allSaved = [];
  },
  cleanOneStates(state) {
    state.loading = false;
    state.newLegal = new Legal();
    state.savedLegal = new Legal();
    state.formattedNewLegal = null;
  },

  getAllRequest(state) {
    state.loading = true;
    state.all = [];
    state.allSaved = [];
  },
  getAllSuccess(state, legals) {
    legals.forEach((x) => {
      return new Legal(x);
    });
    state.all = legals;
    state.allSaved = legals;
    state.loading = false;
  },
  getAllFailure(state) {
    state.all = [];
    state.allSaved = [];
    state.loading = false;
  },

  getRequest(state) {
    state.loading = true;
    state.newLegal = new Legal();
    state.savedLegal = new Legal();
  },
  getSuccess(state, legal) {
    state.newLegal = new Legal(legal);
    state.savedLegal = new Legal(legal);
    state.loading = false;
  },
  getFailure(state) {
    state.newLegal = new Legal();
    state.savedLegal = new Legal();
    state.loading = false;
  },

  createRequest(state, legal) {
    state.loading = true;
    state.formattedNewLegal = {};
    state.formattedNewLegal.display_list = legal.display_list;
    state.formattedNewLegal.name = kebabcase(legal.name);
    state.formattedNewLegal.label_title = legal.label_title;
    state.formattedNewLegal.label_menu =
      typeof legal.label_menu === 'undefined' || legal.label_menu.length === 0
        ? legal.label_title
        : legal.label_menu;
    state.formattedNewLegal.html_template =
      typeof legal.html_template === 'undefined' ||
      legal.html_template.length === 0
        ? '# todo'
        : legal.html_template;
    state.formattedNewLegal.text_template =
      typeof legal.text_template === 'undefined' ||
      legal.text_template.length === 0
        ? '# todo'
        : legal.text_template;
  },
  createSuccess(state, input) {
    state.newLegal = new Legal(input);
    state.savedLegal = new Legal(input);
    state.all = [];
    state.allSaved = [];
    state.loading = false;
  },
  createFailure(state, input) {
    state.formattedNewLegal = null;
    state.loading = false;
  },

  updateRequest(state) {
    state.loading = true;
    state.formattedNewLegal = {};
    state.formattedNewLegal.display_list = state.newLegal.display_list;
    state.formattedNewLegal.name = kebabcase(state.newLegal.name);
    state.formattedNewLegal.label_title = state.newLegal.label_title;
    state.formattedNewLegal.label_menu = state.newLegal.label_menu;
    state.formattedNewLegal.html_template =
      state.newLegal?.html_template?.length === 0
        ? '# todo'
        : state.newLegal.html_template;
    state.formattedNewLegal.text_template =
      state.newLegal?.text_template?.length === 0
        ? '# todo'
        : state.newLegal.text_template;
  },
  updateSuccess(state, input) {
    state.formattedNewLegal = null;
    state.newLegal = new Legal(input);
    state.savedLegal = new Legal(input);
    state.loading = false;
  },
  updateFailure(state) {
    state.formattedNewLegal = null;
    state.loading = false;
  },

  deleteRequest(state) {
    state.loading = true;
    state.newLegal = new Legal();
    state.savedLegal = new Legal();
  },
  deleteSuccess(state, id) {
    let all = cloneDeep(state.all);
    let index = all.findIndex((x) => x.id === id);
    all.splice(index, 1);
    state.all = cloneDeep(all);
    state.allSaved = cloneDeep(all);
    state.loading = false;
  },
  deleteFailure(state) {
    state.newLegal = new Legal();
    state.savedLegal = new Legal();
    state.loading = false;
  },
};

export const legals = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
