/**
 * Sort all by alphabetical order on keys
 */

const blockType = {
  banner: 'banner',
  metadata: 'metadata',
};

const itemType = {
  button: 'button',
  logo: 'logo',
  text: 'text',
  program: 'program',
};

const metadata = {
  category: 'category',
  title: 'title',
  casting: 'person',
  country: 'country',
  custom: 'custom',
  date: 'date',
  episode: 'episode',
  format: 'format',
  level: 'level',
  material: 'gear',
  multiple_custom: 'mutiple_custom',
  ratinglevel: 'ratinglevel',
  review: 'review',
  single_custom: 'custom',
  social: 'social',
  tag: 'tag',
  version: 'version',
  video: 'video',
  file: 'file',
};

const pictureType = {
  headerLogos: 'header_logos',
  footerLogos: 'footer_logos',
  favicons: 'favicons',
  posters: 'posters',
  xlPosters: 'xl_posters',
  thumbnails: 'thumbnails',
  xlThumbnails: 'xl_thumbnails',
  banners: 'banners',
  halfBanners: 'half_banners',
  roundeds: 'roundeds',
  pictograms: 'pictograms',
  backdrops: 'backdrops',
  cbackdrops: 'content_backdrops',
};

const colorsHexaCode = {
  light: '#FFFFFF',
  dark: '#101010',
};

const images = import.meta.glob(['/src/images/**'], { as: 'url', eager: true });

function assetUrl(name) {
  return images[Object.keys(images).find((path) => path.endsWith(name))];
}

export { blockType, itemType, metadata, pictureType, colorsHexaCode, assetUrl };
