import facets from '@/data/usersFacets';

const state = {
  sorts: [],
  facets,
};

const actions = {
  initUsersFacets({ commit, rootGetters }, plans) {
    const platformPricingTypeIsFree = rootGetters['platforms/platformPricingTypeIsFree'];
    commit('initUsersFacets', { plans, platformPricingTypeIsFree });
  },
  initUsersSort({ commit }) {
    commit('initUsersSort');
  },
};

const mutations = {
  initUsersFacets(state, settings) {
    const plansFacets = {
      id: 'plans',
      title: 'users.search.plans',
      checked: false,
      availableForFreePlatform: false,
      cols: 3,
      filters: settings.plans.map(function (plan) {
        return {
          facet_id: 'plans',
          checked: false,
          id: plan.id,
          title: plan.full_name,
          highlighted: false,
        };
      }),
    };
    if (state.facets[1]) {
      state.facets.splice(1, 1, plansFacets);
    } else {
      state.facets.push(plansFacets);
    }

    if (settings.platformPricingTypeIsFree) {
      state.facets = state.facets.filter((facet) => {
        return facet.availableForFreePlatform;
      });
    }
  },
  initUsersSort(state) {
    state.sorts = [
      {
        id: 'date:desc',
        title: 'search.modified',
      },
      {
        id: 'email:asc',
        title: 'A - Z',
      },
      {
        id: 'email:desc',
        title: 'Z - A',
      },
    ];
  },
};
export const userFilter = {
  namespaced: true,
  state,
  actions,
  mutations,
};
