import { isNil } from 'lodash';
import axios from 'axios';
import store from '@/store';

function handleResponse(res) {
  return res;
}

async function handleError(err) {
  if (typeof err?.response === 'object') {
    const error = JSON.parse(JSON.stringify(err.response));
    switch (error.status) {
      case 401:
        await store.dispatch('auth/logout', true);
        location.reload();
        break;
      // case 403:
      //   this.$store.dispatch('displayAlert', '403')
      //   break;
      case 500:
        return error;
      default:
        return error;
    }
  }
  return null;
}

export default function setup() {
  const DEFAULT_PLATFORM = 1;

  axios.interceptors.request.use(
    function (config) {
      if (localStorage.getItem('user')) {
        const api_tokens = JSON.parse(localStorage.getItem('user')).tokens;
        const url = config.url;
        let token = '';
        if (url.includes(import.meta.env.VITE_CONTENT_BASE_URL)) {
          token = api_tokens.content;
        } else if (url.includes(import.meta.env.VITE_ANALYTICS_BASE_URL)) {
          token = api_tokens.analytics;
        } else if (url.includes(import.meta.env.VITE_MEDIA_BASE_URL)) {
          token = api_tokens.media;
        } else if (url.includes(import.meta.env.VITE_USER_BASE_URL)) {
          token = api_tokens.user;
        } else if (url.includes(import.meta.env.VITE_BILLING_BASE_URL)) {
          token = api_tokens.billing;
        } else if (url.includes(import.meta.env.VITE_PLATFORM_BASE_URL)) {
          token = api_tokens.platform;
        }
        config.headers.Authorization = 'Bearer ' + token;
      }
      if (config.method === '') {
        config.method = 'GET';
      }
      if (isNil(config.headers.platform)) {
        const platformId =
          store?._modules?.root?.state?.platforms?.currentPlatform?.id ?? null;
        if (platformId) {
          config.headers.platform = platformId;
        } else if (localStorage.getItem('platform')) {
          config.headers.platform = localStorage.getItem('platform');
        } else {
          config.headers.platform = DEFAULT_PLATFORM;
        }
      }
      if (localStorage.getItem('fo_locale')) {
        config.headers.locale = '' + localStorage.getItem('fo_locale');
      }

      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (response) => handleResponse(response),
    (error) => handleError(error)
  );
}
