const videosTypes = [
  {
    id: 'main',
    type: 'main',
    title: 'videos.types.main',
    label: 'videos.types.main',
    icon: 'video-main',
    addButtonLabel: 'movie.modal.add.main.button',
    searchPlaceholder: 'movie.modal.add.main.placeholder',
  },
  {
    id: 'trailer',
    type: 'trailer',
    title: 'videos.types.trailer',
    label: 'videos.types.trailer',
    icon: 'video-trailer',
    addButtonLabel: 'movie.modal.add.trailer.button',
    searchPlaceholder: 'movie.modal.add.trailer.placeholder',
  },
  {
    id: 'bonus',
    type: 'bonus',
    title: 'videos.types.bonus',
    label: 'videos.types.bonus',
    icon: 'video-bonus',
    addButtonLabel: 'movie.modal.add.bonus.button',
    searchPlaceholder: 'movie.modal.add.bonus.placeholder',
  },
  {
    id: 'episode',
    type: 'episodes',
    title: 'videos.types.episode',
    label: 'videos.types.episode',
    icon: 'video-episode',
    addButtonLabel: 'movie.modal.add.episode.button',
    searchPlaceholder: 'movie.modal.add.episode.placeholder',
  },
  {
    id: 'live',
    type: 'live',
    title: 'videos.types.live',
    label: 'videos.types.live',
    icon: 'video-live',
    addButtonLabel: 'movie.modal.add.live.button',
    searchPlaceholder: 'movie.modal.add.live.placeholder',
  },
];

export default videosTypes;
