import { difference } from '@/utils/diffTools';
import { getField } from 'vuex-map-fields';
import { isEmpty, isNil } from 'lodash';

const getters = {
  getField,
  shouldSave(state) {
    const diff = difference(state.currentNewVideo, state.savedVideo);
    return !(isNil(diff) || isEmpty(diff));
  },
  shouldSaveTimecodes(state) {
    const diff = difference(
      state.currentNewVideoTimecodes,
      state.savedNewVideoTimecodes
    );
    return !(isNil(diff) || isEmpty(diff));
  },
};

export default getters;
