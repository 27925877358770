import { GenericContentService } from '@/services/index.js';
import { cloneDeep, union } from 'lodash';
import { metadataService } from '@/services';
import Metadata from '@/models/Metadata';

const state = {
  loading: false,
  all: [],
  formatedCurrentNewMetadata: new Metadata(),
  currentNewMetadata: new Metadata(),
  savedNewMetadata: new Metadata(),
};

const actions = {
  getAllMetadata({ commit, dispatch }) {
    commit('getAllRequest');
    return metadataService.getAll().then(
      (metadatas) => {
        commit('getAllSuccess', metadatas);
        return Promise.resolve(metadatas);
      },
      (error) => {
        commit('getAllFailure', error);
        return Promise.reject(error);
      }
    );
  },

  getMetadata({ commit, dispatch }, endpoint) {
    commit('getRequest');
    return new GenericContentService(endpoint).get().then(
      (metadatas) => {
        commit('getSuccess', metadatas);
        return Promise.resolve();
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject();
      }
    );
  },

  updateMetadata({ commit, dispatch }, endpoint) {
    commit('putRequest');
    return new GenericContentService(endpoint)
      .update(state.formatedCurrentNewMetadata, state.currentNewMetadata.id)
      .then(
        (metadatas) => {
          commit('putSuccess', metadatas);
          const alert = {
            id: 'metadata-well-updated',
            icon: 'check',
            type: 'valid',
            message: 'notifications.update.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(metadatas);
        },
        (error) => {
          commit('getFailure', error);
          const alert = {
            id: 'metadata-not-updated',
            icon: 'close',
            type: 'error',
            message: 'notifications.update.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },

  createMetadata({ commit, dispatch }, input) {
    commit('postRequest', input.element);
    return new GenericContentService(input.endpoint)
      .create(state.formatedCurrentNewMetadata)
      .then(
        (metadatas) => {
          commit('postSuccess', metadatas);
          const alert = {
            id: 'metadata-well-created',
            icon: 'check',
            type: 'valid',
            message: 'notifications.create.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(metadatas);
        },
        (error) => {
          commit('postFailure', error);
          const alert = {
            id: 'metadata-not-created',
            icon: 'close',
            type: 'error',
            message: 'notifications.create.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },

  deleteMetadata({ commit, dispatch }, endpoint) {
    commit('deleteRequest');
    return new GenericContentService(endpoint)
      .remove(state.currentNewMetadata.id)
      .then(
        (metadatas) => {
          commit('deleteSuccess', metadatas);
          const alert = {
            id: 'metadata-well-deleted',
            icon: 'check',
            type: 'valid',
            message: 'notifications.delete.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve();
        },
        (error) => {
          commit('deleteFailure', error);
          const alert = {
            id: 'metadata-not-deleted',
            icon: 'close',
            type: 'error',
            message: 'notifications.delete.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject();
        }
      );
  },
};

const mutations = {
  getAllRequest(state) {
    state.all = [];
  },
  getAllSuccess(state, metadataBruts) {
    let metadatas = [];
    metadataBruts.forEach(function (metadataBrut) {
      metadatas.push(new Metadata(metadataBrut));
    });
    state.all = metadatas;
    state.loading = false;
  },
  getAllFailure(state) {
    state.all = [];
    state.loading = false;
  },

  getRequest(state) {
    state.formatedCurrentNewMetadata = new Metadata();
  },
  getSuccess(state, payload) {
    var metadata = new Metadata(payload);
    state.currentNewMetadata = cloneDeep(metadata);
    state.savedNewMetadata = cloneDeep(metadata);
    state.formatedCurrentNewMetadata = {};
    state.loading = false;
  },
  getFailure(state) {
    state.formatedCurrentNewMetadata = new Metadata();
    state.loading = false;
  },

  putRequest(state) {
    //Do Nothing
  },
  putSuccess(state, payload) {
    state.currentNewMetadata = cloneDeep(new Metadata(payload));
    state.savedNewMetadata = cloneDeep(new Metadata(payload));
    state.loading = false;
  },
  putFailure(state) {
    state.loading = false;
  },

  postRequest(state, newElement) {
    var formated = {
      title: newElement.title,
      label: newElement.title,
      name: newElement.title,
      description: newElement.description,
    };
    if (typeof newElement.metadata_detail_id !== 'undefined') {
      formated.metadata_detail_id = newElement.metadata_detail_id;
    }
    state.formatedCurrentNewMetadata = formated;
    state.loading = true;
  },
  postSuccess(state, payload) {
    state.formatedCurrentNewMetadata = cloneDeep(new Metadata(payload));
    state.savedNewMetadata = cloneDeep(new Metadata(payload));
    state.formatedCurrentNewMetadata = {};
    state.loading = false;
  },
  postFailure(state) {
    state.formatedCurrentNewMetadata = {};
    state.loading = false;
  },

  deleteRequest(state) {
    //Do Nothing
  },
  deleteSuccess(state, payload) {
    state.formatedCurrentNewMetadata = new Metadata();
    state.loading = false;
  },
  deleteFailure(state) {
    state.loading = false;
  },
};

export const metadatas = {
  namespaced: true,
  state,
  actions,
  mutations,
};
