<template>
  <VDropdown
    :id="id"
    ref="dropdownRef"
    :show-triggers="showTriggers"
    :hide-triggers="['hover', 'click']"
    :popper-triggers="['hover']"
    :placement="placement"
    :container="dropdownPopupContainer"
    :popper-class="popperClass"
    auto-boundary-max-size
    :auto-size="autoSize"
    :disabled="disabled"
    :distance="offset"
    @show="onShow"
    @hide="onHide"
  >
    <template #default>
      <BaseButton
        v-slot="{ iconSize }"
        :nude="nude"
        :dashed="buttonType === 'dashed'"
        :disabled="disabled"
        :outline="buttonType === 'outline'"
        font-weight="normal"
        :theme="!nude ? 'none' : null"
        :size="size"
        :justify="justify"
        class="js-dropdown-button justify-between disabled:bg-skin-action-disabled"
        :class="[
          fullWidthToggle && 'w-full',
        ]"
      >
        <slot name="title" v-bind="{ iconSize }" />
        <Icon
          v-if="!noCarret && !disabled"
          :icon="dropdownIcon"
          :size="caretSize"
          class="dropdown-title-icon ml-auto"
          :class="icon === 'caret-down' && '-scale-y-100'"
        />
        <span v-if="textAfter" class="pl-2">{{ $t(textAfter) }}</span>
      </BaseButton>
      <slot v-if="$slots['content']" name="content" />
    </template>
    <template #popper="{ shown, hide }">
      <div :class="[type === 'select' && 'max-h-60 min-w-[160px]']">
        <div
          ref="trapRef"
          class="relative isolation-auto min-w-fit z-50 overflow-y-auto overflow-hidden rounded-ui-component bg-skin-ui text-base ring-opacity-5 focus:outline-none sm:text-sm min-w-full"
          :class="[
            buttonType === 'outline'
              ? 'border-skin-action-accent text-skin-accent'
              : 'border-skin-ui',
            centerContent && 'mx-auto',
          ]"
        >
          <slot v-bind="{ shown, hide }" />
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<script setup>
import { computed, onBeforeUnmount, ref } from 'vue';
import { inputCaretSize } from '@/utils/styles/styles.js';
import BaseButton from '@/components/button/BaseButton.vue';
import Icon from '@/components/icon/Icon.vue';
import useFocusTrap from '@/composables/useFocusTrap.js';

const { trapRef } = useFocusTrap();

const props = defineProps({
  id: {
    type: [String, Number],
    default: () => `id_${Math.floor(Math.random() * 10000)}`,
  },
  size: {
    type: String,
    default: 'md',
  },
  align: {
    type: String,
    default: 'left',
  },
  buttonType: {
    type: String,
    default: 'normal',
  },
  type: {
    type: String,
    default: 'dropdown',
  },
  fullWidthToggle: {
    type: Boolean,
    default: true,
  },
  centerContent: {
    type: Boolean,
    default: false,
  },
  placement: {
    type: String,
    default: 'bottom',
  },
  container: {
    type: String,
    default: '#app-container',
  },
  offset: {
    type: Number,
    default: 0,
  },
  autoSize: {
    type: [String, Boolean],
    default: true,
  },
  onHover: {
    type: Boolean,
    default: false,
  },
  justify: {
    type: String,
    default: 'space-between',
  },
  itemSelector: {
    type: String,
    default: 'li:not(:disabled)',
  },
  noCarret: {
    type: Boolean,
    default: false,
  },
  nude: {
    type: Boolean,
    default: false,
  },
  field: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  textAfter: {
    type: String,
    default: null,
    required: false,
  },
  outline: {
    type: Boolean,
    default: false,
  },
  withTheme: {
    type: Boolean,
    default: true,
  },
  link: {
    type: Boolean,
    default: false,
  },
  arrow: {
    type: Boolean,
    default: false,
  },
  dropdownIcon: {
    type: String,
    default: 'caret-down',
  },
  closeOnClick: Boolean,
});

const dropdownRef = ref(null);

const icon = ref(props.dropdownIcon);

const dropdownKeydown = (e) => {
  const dropdown = dropdownRef.value;

  function nav(next = false, items) {
    if (!Array.from(items).length) {
      return;
    }
    const activeElement = document.activeElement;
    const activeIndex = Array.from(items).indexOf(activeElement);
    const prevIndex = activeIndex - 1;
    const prevItem = items[prevIndex];
    const nextIndex = activeIndex + 1;
    const nextItem = items[nextIndex];
    const firstItem = items[0];
    const lastItem = items[items.length - 1];
    next
      ? nextItem
        ? nextItem.focus()
        : firstItem.focus()
      : prevItem
      ? prevItem.focus()
      : lastItem.focus();
  }

  if (!dropdown) {
    return;
  }

  const items = [
    ...dropdown.$refs.popperContent.$el.querySelectorAll(props.itemSelector),
  ];

  let index = items.indexOf(e.target);

  if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
    e.preventDefault();
    nav(e.key === 'ArrowDown', items);
  }

  if (e.key === 'Enter') {
    e.preventDefault();
    if (e.key === 'Enter' && items.length) {
      items[index]?.click();
    }
  }
  if (e.key === 'Escape') {
    e.preventDefault();
    dropdown.hide();
  }
};
const onShow = () => {
  icon.value = !props.dropdownIcon;
  document.addEventListener('keydown', dropdownKeydown);
};

const onHide = () => {
  icon.value = props.dropdownIcon;
  document.removeEventListener('keydown', dropdownKeydown);
  const dropdown = dropdownRef.value;
  dropdown?.$refs.popper.$_targetNodes[0].focus();
};

onBeforeUnmount(() => {
  document.removeEventListener('keydown', dropdownKeydown);
});
const caretSize = computed(() => {
  if (props.dropdownIcon === 'calendar') {
    return 'md';
  }
  return inputCaretSize[props.size];
});

const dropdownPopupContainer = computed(() => {
  return props.container === '#app-container'
    ? '#app-container'
    : `#${props.id}`;
});
const showTriggers = computed(() => {
  if (props.onHover) {
    return ['hover', 'click'];
  }
  return ['click'];
});

const popperClass = computed(() => {
  return !props.arrow ? 'popper-no-arrow' : '';
});
</script>
