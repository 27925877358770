const subtitleTypes = [
  {
    id: 1,
    label: 'normal',
    title: 'videos.subtitles.types.normal',
  },
  {
    id: 2,
    label: 'sdh',
    title: 'videos.subtitles.types.sdh',
  },
  {
    id: 3,
    label: 'forced',
    title: 'videos.subtitles.types.forced',
  }
];

export default subtitleTypes;
