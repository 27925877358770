import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/newsletters/';
const currentPath = import.meta.env.VITE_USER_BASE_URL + api_url_module;
const requestOptions = { method: 'POST' };

export const newsletterService = {
  isSubscribed,
  subscribe,
  unsubscribe,
  deleteSubscription,
};

async function isSubscribed(email) {
  try {
    const response = await axios.post(
      currentPath + 'subscribed',
      { email: email },
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function subscribe(email) {
  try {
    const response = await axios.post(
      currentPath + 'subscribe',
      { email: email },
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function unsubscribe(email) {
  try {
    const response = await axios.post(
      currentPath + 'unsubscribe',
      { email: email },
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function deleteSubscription(email) {
  try {
    const response = await axios.post(
      currentPath + 'delete',
      { email: email },
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}
