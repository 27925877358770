import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/currencies/';
const currentPath = import.meta.env.VITE_BILLING_BASE_URL + api_url_module;

export const currencyService = {
  getCurrencies,
  get,
};

async function getCurrencies(queryString = '') {
  try {
    if (queryString !== '') {
      queryString = '?' + queryString;
    }
    const response = await axios.get(currentPath + queryString);
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function get(code) {
  try {
    const response = await axios.get(currentPath + code);
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}
