/**
 * Video Utils
 * @param {*} videos
 */

const prepareProgramVideo = (videos = [], excludeId) => {
  const main = videos
    .filter((video) => video.type === 'main' && video.id !== excludeId)
    .map((video) => video.id);
  const trailer = videos
    .filter((video) => video.type === 'trailer' && video.id !== excludeId)
    .map((video) => video.id);
  const bonus = videos
    .filter((video) => video.type === 'bonus' && video.id !== excludeId)
    .map((video) => video.id);
  const episodes = videos
    .filter((video) => video.type === 'episodes' && video.id !== excludeId)
    .map((video) => video.id);

  return {
    main,
    trailer,
    bonus,
    episodes,
  };
};

export { prepareProgramVideo };
