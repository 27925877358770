import { GenericMediaService } from '@/services';
import { cloneDeep, union } from 'lodash';
import Subtitle from '@/models/Subtitle';
import utils from '@/utils/utils';

const state = {
  loading: true,
  savedAll: {},
  currentAll: {},
  formatedCurrentNewSubtitle: {},
  currentNewSubtitle: {},
  savedNewSubtitle: {},
};

const getters = {
  getCurrentSubsForVideo: (state) => {
    return state.currentAll;
  },
};

const actions = {
  getAll({ commit, dispatch }, video_id) {
    commit('getAllRequest');
    return new GenericMediaService('subtitles')
      .getAll('video_id=' + video_id)
      .then(
        (subtitles) => {
          var res = { video_id: video_id, subtitles: subtitles };
          commit('getAllSuccess', res);
          return Promise.resolve(subtitles);
        },
        (error) => {
          commit('cleanProgramStates', error);
          return Promise.reject(error);
        }
      );
  },

  create({ commit, dispatch }, subtitle) {
    commit('postRequest');
    return new GenericMediaService('subtitles').create(subtitle).then(
      (subtitles) => {
        commit('postSuccess', subtitles);
        const alert = {
          id: 'subtitle-well-created',
          icon: 'check',
          type: 'valid',
          message: 'notifications.create.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(subtitles);
      },
      (error) => {
        commit('postFailure');
        const alert = {
          id: 'subtitle-not-created',
          icon: 'close',
          type: 'error',
          message: 'notifications.create.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },

  update({ commit, dispatch }) {
    commit('putRequest');
    return new GenericMediaService('subtitles')
      .update(state.formatedCurrentNewSubtitle, state.currentNewSubtitle.id)
      .then(
        (subtitle) => {
          commit('putSuccess', subtitle);
          const alert = {
            id: 'subtitle-well-updated',
            icon: 'check',
            type: 'valid',
            message: 'notifications.update.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(subtitle);
        },
        (error) => {
          commit('putFailure', false);
          const alert = {
            id: 'subtitle-not-updated',
            icon: 'close',
            type: 'error',
            message: 'notifications.update.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },

  deleteSubtitle({ commit, dispatch }, subtitleId) {
    commit('deleteRequest');
    return new GenericMediaService('subtitles')
      .delete(state.currentNewSubtitle.id)
      .then(
        (subtitle) => {
          commit('deleteSuccess', subtitle);
          const alert = {
            id: 'subtitle-well-deleted',
            icon: 'check',
            type: 'valid',
            message: 'notifications.delete.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve();
        },
        (error) => {
          commit('deleteFailure', false);
          const alert = {
            id: 'subtitle-not-deleted',
            icon: 'close',
            type: 'error',
            message: 'notifications.delete.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },
};

const mutations = {
  getAllRequest(state) {
    state.loading = true;
  },
  getAllSuccess(state, res) {
    var newSubtitles = [];
    res.subtitles.forEach(function (subtitle) {
      newSubtitles.push(new Subtitle(subtitle));
    });
    state.savedAll[res.video_id] = cloneDeep(newSubtitles);
    state.currentAll[res.video_id] = cloneDeep(newSubtitles);
    state.loading = false;
  },
  getAllFailure(state) {
    state.savedAll = {};
    state.currentAll = {};
    state.loading = false;
  },

  postRequest() {
    state.loading = true;
  },
  postSuccess(state, payload) {
    var subtitle = new Subtitle(payload);
    state.currentNewSubtitle = cloneDeep(subtitle);
    state.savedNewSubtitle = cloneDeep(subtitle);
    state.loading = false;
  },
  postFailure(state) {
    state.currentNewSubtitle = {};
    state.savedNewSubtitle = {};
    state.formatedCurrentNewSubtitle = {};
    state.loading = false;
  },

  deleteRequest(state) {
    state.loading = true;
  },
  deleteSuccess(state) {
    state.savedAll.data.forEach(function (subtitle, key) {
      if (subtitle.id === state.currentNewSubtitle.id) {
        state.savedAll.data.splice(key, 1);
      }
    });
    state.currentNewSubtitle = {};
    state.savedNewSubtitle = {};
    state.formatedCurrentNewSubtitle = {};
    state.loading = false;
  },
  deleteFailure() {
    state.loading = false;
  },

  setCurrentNewSubtitle(state, payload) {
    state.currentNewSubtitle = cloneDeep(payload);
  },

  setAll(state, res) {
    state.savedAll[res.video_id] = cloneDeep(res.subtitles);
    state.currentAll[res.video_id] = cloneDeep(res.subtitles);
  },
  removeSubtitle(state, data) {
    var newCurrent = state.currentAll[data.videoId].filter(function (subtitle) {
      return subtitle.id !== data.subtitleId;
    });
    state.currentAll[data.videoId] = cloneDeep(newCurrent);
  },

  cleanSubtitlesStates(state) {
    state.savedAll = {};
    state.currentAll = {};
    state.currentNewSubtitle = {};
    state.savedNewSubtitle = {};
    state.formatedCurrentNewSubtitle = {};
    state.loading = false;
  },
};

export const subtitles = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
