import axios from 'axios';

export const metadataService = {
  getAll,
  get,
  //update,
  //create,
  //delete
};

function getAll() {
  return axios
    .get(import.meta.env.VITE_CONTENT_BASE_URL + '/metadatas')
    .then((response) => response.data.results)
    .catch((response) => []);
}

function get(id) {
  const requestOptions = {
    method: 'GET',
    headers: {
      platform: 1,
      transform: true,
    },
  };

  return axios
    .get(
      import.meta.env.VITE_CONTENT_BASE_URL + '/metadatas/' + id,
      requestOptions
    )
    .then((response) => response.data.results)
    .catch((response) => []);
}

function handleResponse(response) {
  console.log('in handle');
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    if (data.status == 'error') {
      return Promise.reject(data.results);
    } else {
      return data.results.data;
    }
  });
}
