<template>
  <div
    v-if="richTextEditorActivated"
    class="wrapper renderer"
  >
    <div
      :class="[truncate ? 'truncated-text' : '', className]"
      v-html="value"
    />
  </div>
  <nl2br v-else :tag="tag" :text="value" :class="className" />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  value: {
    type: String,
  },
  tag: {
    type: String,
    default: 'div',
  },
  truncate: {
    type: Boolean,
  },
  className: {
    type: String
  }
});

const richTextEditorActivated = computed(() => 
  store.state.platforms.currentPlatform.content_config.feature.rich_text_editor
);
</script>

<style>
.truncated-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.renderer b {
  font-weight: bold !important;
}
.htmlRender b {
  font-weight: bold !important;
}
.htmlRender i {
  font-style: italic !important;
}
.htmlRender ul {
  list-style-type: disc !important;
}
.htmlRender ol {
  list-style-type: decimal !important;
}
.htmlRender a {
  color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  text-decoration-line: underline !important;
  cursor: pointer !important;
  user-select: none !important;
}
</style>
