<template>
  <div v-if="hasHideFrontLinkFeature === true" />
  <div v-else-if="hasDesktopFront === false" />
  <div v-else-if="isDisplayable === true" :class="messageClass" class="text-sm">
    <div v-if="externalTooltip">
      <span class="mr-2">{{ getMessage }}</span>
      <Tooltip
        :icon="externalTooltip.icon"
        :position="externalTooltip.position"
        :align="externalTooltip.align"
        :message="getTooltipMessage"
        :color="externalTooltip.color ?? 'danger'"
        class="mr-2"
      />
      <a
        v-if="getUrl() !== ''"
        ref="codeLink"
        :href="getUrl()"
        target="_blank"
        class="example-url hidden lg:inline font-bold mr-4 underline"
      >{{ getUrl() }}</a>
      <BaseButton
        class="link-copy py-0 h-auto !px-0"
        :label="$t('link.button.copy')"
        nude
        size="sm"
        icon-after="link"
        @click="copyCodeLink"
      />
    </div>
    <Tooltip v-else :message="getTooltipMessage" position="bottom" align="left">
      <span class="mr-2">{{ getMessage }}</span>
      <a
        v-if="getUrl() !== ''"
        ref="codeLink"
        :href="getUrl()"
        target="_blank"
        class="example-url hidden lg:inline font-bold mr-4 underline"
        >{{ getUrl() }}</a
      >
      <BaseButton
        class="link-copy py-0 h-auto !px-0"
        :label="$t('link.button.copy')"
        nude
        size="sm"
        icon-after="link"
        @click="copyCodeLink"
      />
    </Tooltip>
  </div>
  <div v-else :class="messageClass">
    {{ getMessage }}
  </div>
</template>
<script>
import { mapState } from 'vuex';
import BaseButton from '@/components/button/BaseButton.vue';
import Icon from '@/components/icon/Icon.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import frontLink from '@/mixins/frontLink';

export default {
  name: 'ClipboardLink',
  components: {
    Tooltip,
    BaseButton,
    Icon,
  },
  mixins: [frontLink],
  props: {
    message: String,
    messageClass: String,
    type: String,
    slug: String,
    id: [Number, String],
    status: String,
    linkColor: {
      type: String,
      default: null,
    },
    displayLabel: {
      type: Boolean,
      default: true,
    },
    metadataOption: {
      type: Object,
      default: null,
    },
    metadataDetailId: {
      type: [Number, String],
      default: null,
    },
    metadataDetailSlug: {
      type: String,
      default: null,
    },
    externalTooltip: {
      type: Object,
      default: null,
    },
    item: Object,
  },
  methods: {
    getUrl() {
      if (this.type === 'url') {
        return this.message;
      }
      if (this.item && this.type) {
        return this.getItemUrlByObject(this.type, this.item);
      }
      if (this.metadataOption) {
        return this.getMetadataUrl(this.metadataOption, this.type);
      }
      return this.getItemPath(
        this.type,
        this.slug,
        this.id,
        this.metadataDetailSlug,
        this.metadataDetailId
      );
    },
  },
  computed: {
    getMessage() {
      if (!this.displayLabel) {
        return '';
      } else if (this.message) {
        return this.message;
      } else if (this.hasHideFrontLinkFeature === true) {
        return this.$t('link.label.hidden');
      } else if (this.isDisplayable === true) {
        return this.$t('link.label.generic');
      } else if (
        this.isDisplayable === false &&
        this.status &&
        this.status === 'incomplete'
      ) {
        return this.$t('link.label.incomplete');
      } else if (this.isDisplayable === false && this.type === 'selection') {
        return this.$t('link.label.selectionDisplayNone');
      } else if (this.isDisplayable === false) {
        return this.$t('link.label.displayNone');
      } else {
        return '';
      }
    },
    getTooltipMessage() {
      switch (this.type) {
        case 'coupon':
          return this.$t('link.tooltip.coupon');
        case 'metadata':
          return this.$t('link.tooltip.metadata');
        default:
          return this.$t('link.tooltip.generic');
      }
    },
    hasHideFrontLinkFeature() {
      return this.currentPlatform?.content_config.feature.hide_front_link;
    },
    hasDesktopFront() {
      return (
        this.currentPlatform?.platformType === 'desktop' ||
        this.childrenPlatforms?.hasOwnProperty('desktop')
      );
    },
    isDisplayable() {
      switch (this.type) {
        case 'program':
        case 'serie':
        case 'season':
        case 'collection':
        case 'live':
        case 'selection':
          return this.status === 'online';
        case 'metadata':
          return true;
        default:
          return this.status !== 'incomplete';
      }
    },
    ...mapState({
      currentPlatform: (state) => state.platforms.currentPlatform,
      childrenPlatforms: (state) => state.platforms.savedChildrenPlatforms,
    }),
  },
};
</script>

<style lang="scss">
@import 'clipboard_link.scss';
</style>
