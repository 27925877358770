<template>
  <div
    data-cy="checkbox"
    :class="[
      theme && uiElementThemeClass,
      checked && 'bg-skin-action-accent border-skin-action-accent',
      !checked && 'bg-skin-ui border-skin-action-hover',
      disabled && 'border-skin-action-disabled'
    ]"
    class="peer aspect-square relative inline-flex h-[14px] w-[14px] items-center border border-solid grid place-content-center transition-all text-skin-inverted"
  >
    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      enter-active-class="transition ease-out duration-100"
      enter-from-class="opacity-100 transform scale-50"
      enter-to-class="opacity-100 transform scale-100"
    >
      <Icon v-if="checked" icon="check" size="xs" />
    </transition>
  </div>
</template>

<script setup lang="ts">
import Icon from '@/components/icon/Icon.vue';
import { useUiElement } from '@/composables/useUiElement.ts';

interface Props {
  checked?: boolean;
  theme?: string;
  disabled?: boolean;
};

const props = defineProps<Props>();

const { uiElementThemeClass } = useUiElement(props);
</script>
