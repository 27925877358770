export default {
  loading: false,
  templateLoading: false,
  mode: 'desktop',
  template: {},
  templateName: '',
  currentBlocks: {},
  savedBlocks: {},
  templateBlocks: {},

  toCreateChildBlocks: [],
  toCreateBlocks: [],
  toUpdateBlocks: [],
  toDeleteBlocks: [],
  toUpdateBlocksRank: [],

  showDisabledBlocks: false,
  toShowDisabledBlocks: [],

  downloadingBlocks: [],

  warnings: [],
  errors: [],

};
