export class Template {
  blockTypesDefinition;
  name;
  id;
  currentPlatform;
  published;
  blocks;
  url;
  online;
  template_type_id;
  template_type_name;
  spacing_top;
  spacing_bottom;
  // footer = {}
  // header = {}

  constructor(
    {
      id,
      name,
      title,
      description,
      published,
      blocks,
      url,
      online,
      template_type_id,
      template_type_name,
      is_default,
      spacing_top = null,
      spacing_bottom = null,
      // header = {},
      // footer = {},
    },
    currentPlatform
  ) {
    this.title = title;
    this.description = description;
    this.name = name;
    this.url = url;
    this.id = id;
    this.online = online;
    this.published = published;
    this.blocks = blocks;
    this.template_type_id = template_type_id;
    this.template_type_name = template_type_name;
    this.is_default = is_default;
    // this.header = header
    // this.footer = footer
    this.currentPlatform = currentPlatform;
    this.type = 'template';
    this.spacing_top = spacing_top;
    this.spacing_bottom = spacing_bottom;
  }
}

export function createTemplate(data) {
  return Object.freeze(new Template(data));
}

export default Template;
