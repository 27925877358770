import adsConfigs from '@/data/adsConfigs';

export class AdvertisingBlocks {
  constructor({ default: myDefault, page = [] } = {}) {
    this.default = myDefault;
    this.page = page;
    adsConfigs.pages.default.forEach((defaultPage) => {
      let defaultPageId = defaultPage.id;
      if (typeof this.default[defaultPageId] === 'undefined') {
        this.default[defaultPageId] = {
          id: null,
          name: null,
          type: defaultPage,
          class: 'default',
          blocks: {
            interstitial: false,
            megaban_header: false,
            megaban_footer: false,
          },
        };
      }
      this.default[defaultPageId].id = this.default[defaultPageId].id ?? null;
      this.default[defaultPageId].name =
        this.default[defaultPageId].name ?? null;
      this.default[defaultPageId].type =
        this.default[defaultPageId].type ?? null;
      this.default[defaultPageId].class =
        this.default[defaultPageId].id ?? 'default';
      this.default[defaultPageId].blocks = this.default[defaultPageId]
        .blocks ?? {
        interstitial: false,
        megaban_header: false,
        megaban_footer: false,
      };
      this.default[defaultPageId].blocks.interstitial =
        this.default[defaultPageId].blocks.interstitial ?? false;
      this.default[defaultPageId].blocks.megaban_header =
        this.default[defaultPageId].blocks.megaban_header ?? false;
      this.default[defaultPageId].blocks.megaban_footer =
        this.default[defaultPageId].blocks.megaban_footer ?? false;
    });
  }
}
export default AdvertisingBlocks;
