export class TVODRental {
  id;
  start_interval_unit;
  start_interval_count;
  play_interval_unit;
  play_interval_count;
  quality;
  target;
  program_id;
  program_type;
  active;
  default_active;
  is_free;
  content_id;
  price_id;
  price_external_id;
  price_display;
  deletable;
  is_default;

  constructor({
    id = undefined,
    start_interval_unit = 'day',
    start_interval_count = undefined,
    play_interval_unit = 'day',
    play_interval_count = undefined,
    quality = 'SD',
    target = 'ALL',
    program_id = undefined,
    program_type = undefined,
    active = true,
    default_active = true,
    is_free = undefined,
    content_id = undefined,
    price_id = undefined,
    price_external_id = undefined,
    price_display = undefined,
    deletable = true,
    is_default = false,
  }) {
    this.id = id;
    this.start_interval_unit = start_interval_unit;
    this.start_interval_count = start_interval_count;
    this.play_interval_unit = play_interval_unit;
    this.play_interval_count = play_interval_count;
    this.quality = quality;
    this.target = target;
    this.active = active;
    this.default_active = default_active;
    this.program_id = program_id;
    this.program_type = program_type;
    this.is_free = is_free;
    this.content_id = content_id;
    this.price_id = price_id;
    this.price_external_id = price_external_id;
    this.price_display = price_display;
    this.deletable = deletable;
    this.is_default = is_default;
  }
}
