export class Language {
  constructor({
    id = 16,
    iso_639_1 = 'xx',
    iso_639_2 = 'xxx',
    iso_639_3 = 'xxxx',
    title = 'Language',
    frequently_used = 0,
    group_environment = 'otto',
  } = {}) {
    this.id = id;
    this.iso_639_1 = iso_639_1;
    this.iso_639_2 = iso_639_2;
    this.iso_639_3 = iso_639_3;
    this.title = title;
    this.frequently_used = frequently_used;
    this.group_environment = group_environment === 'default' 
      ? 'otto' 
      : group_environment;
  }
}

export function createLanguage(data) {
  return Object.freeze(new Language(data));
}

export default Language;
