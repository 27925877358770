import { Currency } from '@/models/billing/Currency';
import { currencyService } from '@/services';
import { isEmpty, isNil } from 'lodash';

const state = {
  loading: true,
  currentOne: new Currency({}),
};

const actions = {
  getAll({ commit }, queryString) {
    return currencyService.getAll(queryString).then(
      (currencies) => {
        commit('getAllSuccess', currencies);
        return Promise.resolve(currencies);
      },
      (error) => {
        return Promise.resolve(error);
      }
    );
  },

  get({ commit, dispatch }, id) {
    return currencyService.get(id).then(
      (currency) => {
        commit('getSuccess', currency);
        return Promise.resolve(currency);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },

  reset({ commit }) {
    commit('reset');
  },
};

const mutations = {
  reset(state) {
    state.loading = false;
  },

  getAllRequest(state) {
    state.loading = true;
  },
  getAllSuccess(state, users) {
    state.all = users;
    state.loading = false;
  },
  getAllFailure(state) {
    state.loading = false;
  },
  getRequest(state) {
    state.loading = true;
  },

  getSuccess(state, currency) {
    //   const newCustomer = new Customer(currency);
    if (!isNil(currency) && !isEmpty(currency)) {
      state.currentOne = currency;
    } else {
      state.currentOne = {};
    }
    state.loading = false;
  },
};

export const currencies = {
  namespaced: true,
  state,
  actions,
  mutations,
};
