import { handleResponse } from '@/services/index.js';
import axios from 'axios';

export class TemplatesService {
  _endpointName;

  constructor() {
    this._endpointName = 'templates';
  }

  async getAll(queryString = '') {
    if (queryString !== '') {
      queryString = '?' + queryString;
    }
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_CONTENT_BASE_URL}/${this._endpointName
        }${queryString}`
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async search(needle, queryString = '') {
    const requestOptions = {
      method: 'POST',
    };
    if (queryString !== '') {
      queryString = '?' + queryString;
    }
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_CONTENT_BASE_URL}/${this._endpointName
        }/search/` + queryString,
        { query: needle },
        requestOptions
      );
      let data = response.data;
      if (
        typeof data.status === 'undefined' &&
        typeof data.results === 'undefined'
      ) {
        data = {
          status: 'success',
          results: data,
        };
      }
      return handleResponse(data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async get(name, platformId = null) {
    let config;
    if (platformId !== null) {
      config = { headers: { transform: 'bo', platform: platformId } };
    } else {
      config = { headers: { transform: 'bo' } };
    }
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_CONTENT_BASE_URL}/${this._endpointName
        }/?with=no-block-details&name=${name}`,

        config
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }
  /**
   * Get template light data by id
   *
   * @param {int} id
   * @param {bool} light data
   * @returns
   *
   * @atuhor Jody JUSTINE <jjustine@vodfactory.com>
   */
  async getById(id, lightData = true) {
    let config;
    if (lightData) {
      config = { headers: { transform: 'mini' } };
    } else {
      config = { headers: { transform: 'bo' } };
    }
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_CONTENT_BASE_URL}/${this._endpointName}/${id}`,
        config
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async getTemplatePlatforms(name, platformId = null) {
    let config = { headers: { transform: 'bo' } };
    if (platformId !== null) {
      config.headers.platform = platformId;
    }
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_CONTENT_BASE_URL}/${this._endpointName
        }/platforms/?with=no-block-details&name=${name}`,
        config
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async create(data, platformId = null) {
    let config;
    if (platformId !== null) {
      config = { headers: { transform: false, platform: platformId } };
    } else {
      config = { headers: { transform: false } };
    }

    try {
      const response = await axios.post(
        import.meta.env.VITE_CONTENT_BASE_URL + '/' + this._endpointName,
        data,
        config
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async update(data, id, platformId = null) {
    let config;
    if (platformId !== null) {
      config = { headers: { transform: false, platform: platformId } };
    } else {
      config = { headers: { transform: false } };
    }
    try {
      const response = await axios.put(
        import.meta.env.VITE_CONTENT_BASE_URL +
        '/' +
        this._endpointName +
        '/' +
        id,
        data,
        config
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async delete(id) {
    const requestOptions = {
      method: 'DELETE',
    };

    try {
      const response = await axios.delete(
        import.meta.env.VITE_CONTENT_BASE_URL +
        '/' +
        this._endpointName +
        '/' +
        id,
        requestOptions
      );

      if (response.status == 204) {
        return handleResponse({ status: 204 });
      }
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async copy(data, id, platformId = null) {
    let config;
    if (platformId !== null) {
      config = { headers: { transform: false, platform: platformId } };
    } else {
      config = { headers: { transform: false } };
    }

    try {
      const response = await axios.post(
        import.meta.env.VITE_CONTENT_BASE_URL +
        '/' +
        this._endpointName +
        '/' +
        id +
        '/copy',
        data,
        config
      );
      return handleResponse(response.data);
    } catch (err) {
      return await Promise.reject(err.response);
    }
  }

  async empty(data, id, platformId = null) {
    let config;
    if (platformId !== null) {
      config = { headers: { transform: false, platform: platformId } };
    } else {
      config = { headers: { transform: false } };
    }

    try {
      const response = await axios.post(
        import.meta.env.VITE_CONTENT_BASE_URL +
        '/' +
        this._endpointName +
        '/' +
        id +
        '/empty',
        data,
        config
      );
      return handleResponse(response.data);
    } catch (err) {
      return await Promise.reject(err.response);
    }
  }

  async updateWithTransform(data, id) {
    try {
      const response = await axios.put(
        import.meta.env.VITE_CONTENT_BASE_URL +
        '/' +
        this._endpointName +
        '/' +
        id,
        data,
        { headers: { transform: true } }
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }
}
