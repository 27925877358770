import blockTypes from '@/data/animationBlockTypes';

export default {

  /**
   * Determines if any modifications are pending in the state.
   *
   * @returns {boolean}
   */
  modified: (state) => {
    return (
      state.toUpdateBlocksRank.length > 0 ||
      state.toDeleteBlocks.length > 0 ||
      state.toCreateBlocks.length > 0 ||
      state.toUpdateBlocks.length > 0 ||
      state.toCreateChildBlocks.length > 0
    );
  },

  /**
   * Retrieves the current blocks for a specific device from the state.
   *
   * @param {string} device 
   * @returns {array} 
   */
  getCurrentBlocks: (state) => (device) => {
    return state.currentBlocks?.[device] ?? [];
  },

  /**
   * Retrieves the saved blocks for a specific device from the state.
   *
   * @param {string} device 
   * @returns {array} 
   */
  getSavedBlocks: (state) => (device) => {
    return state.savedBlocks?.[device] ?? [];
  },

  /**
   * Retrieves the saved block with the specified ID for a specific device from the state.
   *
   * @param {string} device
   * @param {string} id
   * @returns {object|null} 
   */
  getSavedBlocksById: (state, getters) => (device, id) => {
    const savedBlocks = getters.getSavedBlocks(device);
    return savedBlocks.find((b) => b.id === id) ?? null;
  },

  /**
   * Retrieves the current block with the specified ID for a specific device from the state.
   *
   * @param {string} device
   * @param {string} id
   * @returns {object|null} 
   */
  getCurrentBlocksById: (state, getters) => (device, id) => {
    const currentBlocks = getters.getCurrentBlocks(device);
    return currentBlocks.find((b) => b.id === id) ?? null;
  },

  /**
   * Retrieves the parent block of a given block.
   *
   * @param {object} block
   * @returns {object} 
   */
  getParentBlock: (state, getters) => (block) => {
    const device = getters.getParentBlockDevice(block);
    return block.linked_block_id
      ? getters.getCurrentBlocks(device).find((b) => b?.id === block.linked_block_id)
      : block;
  },

  /**
   * Retrieves the platform type of the parent block.
   *
   * @param {object} block 
   * @returns {string|undefined}
   */
  getParentBlockDevice: (state, getters) => (block) => {
    const platformLinked = block.platform_linked
      ?.find((linked) => linked.is_parent_block);
    return platformLinked?.platform_type;
  },

  /**
   * Retrieves the platform linked to a specific device.
   *
   * @param {array} platformLinked
   * @param {string} device
   * @returns {object|null} 
   */
  getPlatformLinkedByDevice: (state) => (platformLinked, device) => {
    return platformLinked.find(
      (linked) => linked.platform_type === device) ?? null;
  },

  /**
   * Retrieves the parent platform linked to a block.
   *
   * @param {array} platformLinked
   * @returns {object|null}
   */
  getParentPlatformLinked: (state) => (platformLinked) => {
    return platformLinked.find(
      (linked) => linked.is_parent_block) ?? null;
  },


  /**
   * Retrieves the configuration of a specific block type.
   *
   * @param {string} type
   * @returns {object|null}
   */
  getBlockConfig: (state) => (type) => {
    return blockTypes.find((config) => config.id === type);
  },

  /**
   * Retrieves the template type name based on the current mode and template in the state.
   *
   * @returns {string}
   */
  getTemplateTypeName: (state) => {
    return state.mode
      ? (state.template?.[state.mode]?.template_type_name ?? '')
      : ''
  },


};

