import facets from '@/data/selectionFacets';
import store from "@/store";

const state = {
  sorts: [],
  facets,
};

const actions = {
  initCategoriesSort({ commit }, categories) {
    commit('initCategoriesSort', categories);
  },
};

const mutations = {
  initCategoriesSort(state) {
    state.sorts = [
      {
        id: 'date:desc',
        title: 'search.added',
      },
      {
        id: 'title:asc',
        title: 'A - Z',
      },
      {
        id: 'title:desc',
        title: 'Z - A',
      },
    ];

    if (store.getters['auth/can']('animation.beta')) {
      state.sorts.splice(1, 0, {
        id: 'generated_at:desc',
        title: 'search.indexed',
      });
    }
  },
};
export const selectionsFilter = {
  namespaced: true,
  state,
  actions,
  mutations,
};
