import { cloneDeep } from 'lodash';
import { defaultAll } from './state';
import Timecode from '@/models/Timecode';
import Video from '@/models/Video';
import utils from '@/utils/utils';

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  cleanVideoStates(state) {
    state.all = defaultAll;
    state.currentNewVideo = {};
    state.savedVideo = {};
    state.loading = false;
  },
  getAllSuccess(state, videos) {
    state.all = videos;
  },
  getAllFailure(state) {
    state.all = defaultAll;
  },
  getSuccess(state, video) {
    const videoObj = new Video(video);
    state.currentNewVideo = videoObj;
    state.savedVideo = cloneDeep(videoObj);
  },
  getFailure(state) {
    state.currentNewVideo = {};
    state.savedVideo = {};
  },
  getTimecodesSuccess(state, { id, timecodes, init }) {
    if (timecodes.length > 0) {
      timecodes.forEach((timecode) => {
        const newTimecode = new Timecode({ ...timecode, video_id: id });
        const predicate = (x) => {
          return (
            x.id === timecode.id ||
            (x.video_id === timecode.video_id && x.type === timecode.type)
          );
        };
        const existingCurrentTimecodeIndex =
          state.currentNewVideoTimecodes.findIndex(predicate);
        if (existingCurrentTimecodeIndex > -1) {
          state.currentNewVideoTimecodes.splice(
            existingCurrentTimecodeIndex,
            1,
            newTimecode
          );
        } else {
          state.currentNewVideoTimecodes.push(newTimecode);
        }
        const existingSavedTimecodeIndex =
          state.savedNewVideoTimecodes.findIndex(predicate);
        if (existingSavedTimecodeIndex > -1) {
          state.savedNewVideoTimecodes.splice(
            existingSavedTimecodeIndex,
            1,
            cloneDeep(newTimecode)
          );
        } else {
          state.savedNewVideoTimecodes.push(cloneDeep(newTimecode));
        }
      });
    } else if (init) {
      // INIT INTRO && OUTRO TIMECODES INPUT FIELDS
      state.savedNewVideoTimecodes.push(
        new Timecode({ type: 'intro', video_id: id })
      );
      state.savedNewVideoTimecodes.push(
        new Timecode({ type: 'outro', video_id: id })
      );
      state.currentNewVideoTimecodes.push(
        new Timecode({ type: 'intro', video_id: id })
      );
      state.currentNewVideoTimecodes.push(
        new Timecode({ type: 'outro', video_id: id })
      );
    }
    state.loading = false;
  },
  getTimecodesFailure(state) {
    state.currentNewVideoTimecodes = [];
    state.savedNewVideoTimecodes = [];
    state.loading = false;
  },
  createTimecodeSuccess(state, timecode) {
    const predicate = (x) => {
      return (
        x.id === timecode.id ||
        (x.video_id === timecode.video_id && x.type === timecode.type)
      );
    };
    const existingCurrentTimecodeIndex =
      state.currentNewVideoTimecodes.findIndex(predicate);
    const existingSavedTimecodeIndex =
      state.savedNewVideoTimecodes.findIndex(predicate);
    if (existingCurrentTimecodeIndex > -1) {
      state.currentNewVideoTimecodes.splice(
        existingCurrentTimecodeIndex,
        1,
        timecode
      );
    } else {
      state.currentNewVideoTimecodes.push(timecode);
    }
    if (existingSavedTimecodeIndex > -1) {
      state.savedNewVideoTimecodes.splice(
        existingSavedTimecodeIndex,
        1,
        timecode
      );
    } else {
      state.savedNewVideoTimecodes.push(timecode);
    }
    state.loading = false;
  },
  updateTimecodeField(state, data) {
    const { type, timecode } = data;
    timecode.start = utils.toSeconds(timecode.startTimecode);
    timecode.end = utils.toSeconds(timecode.endTimecode);
    const existingTimecodeIndex = state.currentNewVideoTimecodes.findIndex(
      (tc) => tc.type === type && tc.id === timecode.id
    );
    if (existingTimecodeIndex > -1) {
      state.currentNewVideoTimecodes.splice(existingTimecodeIndex, 1, timecode);
    }
  },
  updateTimecodeSuccess(state, timecode) {
    const predicate = (x) => {
      return (
        x.id === timecode.id ||
        (x.video_id === timecode.video_id && x.type === timecode.type)
      );
    };
    const existingCurrentTimecodeIndex =
      state.currentNewVideoTimecodes.findIndex(predicate);
    const existingSavedTimecodeIndex =
      state.savedNewVideoTimecodes.findIndex(predicate);
    if (existingCurrentTimecodeIndex > -1) {
      state.currentNewVideoTimecodes.splice(
        existingCurrentTimecodeIndex,
        1,
        timecode
      );
    } else {
      state.currentNewVideoTimecodes.push(timecode);
    }
    if (existingSavedTimecodeIndex > -1) {
      state.savedNewVideoTimecodes.splice(
        existingSavedTimecodeIndex,
        1,
        timecode
      );
    } else {
      state.savedNewVideoTimecodes.push(timecode);
    }
  },
  removePlaceholderTimecodes(state, videoId) {
    state.savedNewVideoTimecodes = state.savedNewVideoTimecodes.filter(
      (tc) =>
        (tc.id !== null && tc.video_id === videoId) || tc.video_id !== videoId
    );
    state.currentNewVideoTimecodes = state.currentNewVideoTimecodes.filter(
      (tc) =>
        (tc.id !== null && tc.video_id === videoId) || tc.video_id !== videoId
    );
  },
  deleteSuccess(state, id) {
    if (state.all && state.all.data && state.all.data.length) {
      state.all.data = state.all.data.filter((video) => video.id !== id);
    }
  },
  updateTitle(state, title) {
    state.currentNewVideo.name = title;
  },
  updateSuccess(state, updatedVideo) {
    const { name } = updatedVideo;
    state.currentNewVideo.name = name;
    state.savedVideo.name = name;
  },
  addVideoSubtitle(state, subtitle) {
    let subtitles = cloneDeep(state.currentNewVideo.subtitles);
    if (!subtitles || subtitles.length === 0) {
      subtitles = {};
    }

    if (!subtitles[subtitle.format]) {
      subtitles[subtitle.format] = {};
    }
    subtitles[subtitle.format][subtitle.locale] = subtitle;

    state.currentNewVideo = {
      ...state.currentNewVideo,
      subtitles,
    };
  },
  removeVideoSubtitle(state, subtitle) {
    const subtitles = cloneDeep(state.currentNewVideo.subtitles);
    delete subtitles[subtitle.format][subtitle.locale];
    state.currentNewVideo.subtitles = subtitles;
  },
  updateVideoImage(state, newImage) {
    let picture = cloneDeep(state.currentNewVideo.picture);
    if (!picture || picture.length === 0) {
      picture = {};
    }
    let image = {
      url: newImage.url,
      custom_properties: newImage.custom_properties,
      collectionName: newImage.collectionName,
    };
    if (newImage?.id) {
      image.id = newImage.id;
    }
    picture[newImage.collectionName] = [image];
    state.currentNewVideo.picture = picture;
  },
  removeVideoPicture(state, image) {
    const picture = cloneDeep(state.currentNewVideo.picture);
    picture[image.collectionName] = [];
    state.currentNewVideo.picture = picture;
  },
  resetCurrent(state) {
    state.currentNewVideo = state.savedVideo;
  },
  resetTimecodes(state) {
    state.savedNewVideoTimecodes = [];
    state.currentNewVideoTimecodes = [];
  },
  formatCurrentVideo(state) {
    state.formattedVideo = {
      id: state.currentNewVideo.id,
      name: state.currentNewVideo.name,
      picture: state.currentNewVideo?.picture?.thumbnails?.[0]?.url
        ? state.currentNewVideo.picture
        : { thumbnails: [] },
      thumbnails_url: '',
    };
  },
  unlinkSuccess(state, programRes) {
    let programs = cloneDeep(state.currentNewVideo.programs);
    programs = programs.filter((program) => program.id !== programRes.id);
    state.currentNewVideo = {
      ...state.currentNewVideo,
      programs,
    };
    state.savedVideo = {
      ...state.currentNewVideo,
      programs,
    };
  },
};

export default mutations;
