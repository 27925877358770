import { handleResponse } from '@/services/index.js';
import axios from 'axios';
import mockedPictureResponse from '@/data/__mocks__/pictures';

export class GenericUserService {
  _endpointName;

  constructor(endpointName) {
    this._endpointName = endpointName;
  }

  async getAll(queryString = '') {
    if (queryString !== '') {
      queryString = '?' + queryString;
    }
    try {
      const response = await axios.get(
        import.meta.env.VITE_USER_BASE_URL +
          '/' +
          this._endpointName +
          queryString
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async get(id, queryString = '') {
    if (queryString !== '') {
      queryString = '?' + queryString;
    }
    try {
      const response = await axios.get(
        import.meta.env.VITE_USER_BASE_URL +
          '/' +
          this._endpointName +
          '/' +
          id +
          queryString
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async update(data, id, requestOptions = {}) {
    requestOptions.method = 'PUT';
    try {
      const response = await axios.put(
        import.meta.env.VITE_USER_BASE_URL +
          '/' +
          this._endpointName +
          '/' +
          id,
        data,
        requestOptions
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async create(data, requestOptions = {}) {
    requestOptions.method = 'POST';
    try {
      const response = await axios.post(
        import.meta.env.VITE_USER_BASE_URL + '/' + this._endpointName,
        data,
        requestOptions
      );
      return handleResponse(response.data, response.status);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async delete(id) {
    try {
      const response = await axios.delete(
        import.meta.env.VITE_USER_BASE_URL + '/' + this._endpointName + '/' + id
      );
      if (response.status === 204) {
        return handleResponse(
          { status: 'success', results: { data: [] } },
          204
        );
      } else {
        return handleResponse(response.data);
      }
    } catch (err) {
      return handleResponse(err);
    }
  }

  async search(needle = '', queryString = '', requestOptions = {}) {
    requestOptions.method = 'POST';
    if (queryString !== '') {
      queryString = '?' + queryString;
    }
    try {
      var response = null;
      if (needle !== null && needle.length > 0) {
        response = await axios.post(
          import.meta.env.VITE_USER_BASE_URL +
            '/' +
            this._endpointName +
            '/search' +
            queryString,
          { query: needle },
          requestOptions
        );
      } else {
        response = await axios.post(
          import.meta.env.VITE_USER_BASE_URL +
            '/' +
            this._endpointName +
            '/search' +
            queryString,
          {},
          requestOptions
        );
      }
      return handleResponse(response.data, response.status);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async createPicture(data, requestOptions = {}) {
    let formData;
    if (typeof data.file !== 'undefined') {
      formData = new FormData();
      var myFile = data.file;
      delete data.file;
      delete data.name;
      formData.append('file', myFile);
      Object.keys(data).forEach(function (one) {
        formData.set(one, data[one]);
      });
      requestOptions['Content-Type'] = 'multipart/form-data';
      requestOptions.method = 'POST';
    } else {
      formData = data;
      requestOptions.method = 'POST';
    }

    try {
      if (this._endpointName === 'pictures') {
        const setTimeoutPromise = (timeout) => {
          return new Promise((resolve) => setTimeout(resolve, timeout));
        };
        await setTimeoutPromise(1000);
        const response = mockedPictureResponse.converted.find(
          (response) => response.collectionName === formData.collectionName
        );
        response.id = null;
        response.itemType = formData.itemType;
        return response;
      } else {
        const response = await axios.post(
          import.meta.env.VITE_USER_BASE_URL + '/' + this._endpointName,
          formData,
          requestOptions
        );
        return response.data.results;
      }
    } catch (response_1) {
      return await Promise.reject();
    }
  }

  async updatePicture(data, requestOptions = {}) {
    let formData;
    if (typeof data.file !== 'undefined') {
      formData = new FormData();
      var myFile = data.file;
      delete data.file;
      delete data.name;
      formData.append('file', myFile);
      Object.keys(data).forEach(function (one) {
        formData.set(one, data[one]);
      });
      requestOptions['Content-Type'] = 'multipart/form-data';
      requestOptions.method = 'PUT';
    } else {
      formData = data;
      requestOptions.method = 'PUT';
    }

    try {
      if (this._endpointName === 'pictures') {
        const setTimeoutPromise = (timeout) => {
          return new Promise((resolve) => setTimeout(resolve, timeout));
        };
        await setTimeoutPromise(1000);
        const response = mockedPictureResponse.converted.find(
          (response) => response.collectionName === formData.collectionName
        );
        response.id = null;
        response.itemType = formData.itemType;
        return response;
      } else {
        const response = await axios.put(
          import.meta.env.VITE_USER_BASE_URL + '/' + this._endpointName,
          formData,
          requestOptions
        );
        return response.data.results;
      }
    } catch (response_1) {
      return await Promise.reject();
    }
  }
}
