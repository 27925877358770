<template>
  <div
    v-show="showUploadBar"
    class="flex items-center px-4 bg-teal-500 h-[36px] text-white rounded-ui-component shadow-sm shadow-skin-ui text-xs ml-8 gap-4"
    @click="showModalVideosUpload()"
  >
    <span
      class="relative text-xs pr-2 :not(:first-of-type):pl-2 after:absolute after:top-1/2 after:right-0 after:-translate-y-1/2 after:w-px after:h-8 after:bg-skin-ui after:content-none"
    >
      <template v-if="activeIngestsLength > 0">
        {{ pendingCountLabel }}
      </template>
      <template v-if="finishedIngestsLength > 0">
        {{ completedCountLabel }}
      </template>
      <template v-if="stoppedIngestsLength > 0">
        {{ stoppedCountLabel }}
      </template>
    </span>
    <!--<div v-if="false">
      <p class="w-[90px]">
        {{ timeRemainingLabel }}
      </p>
      <ProgressBar :progress="uploadingVideosProgress" />
    </div>-->
    <button class="flex items-center">
      <Icon icon="enlarge" size="md" />
    </button>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import Icon from '@/components/icon/Icon.vue';
import ProgressBar from '@/components/progressBar/ProgressBar.vue';
//import filters from '@/filters/index.js';

const store = useStore();
const { t } = useI18n();

const uploadingVideos = computed(() => store.getters['uploadingVideos']);
//const uploadingVideosProgress = computed(() => store.getters['uploadingVideosProgress']);
//const uploadingVideosTimeRemaining = computed(() => store.getters['uploadingVideosTimeRemaining']);
const activeIngests = computed(() => store.getters['ingests/activeIngests']);
const finishedIngests = computed(() => store.getters['ingests/finishedIngests']);
const stoppedIngests = computed(() => store.getters['ingests/stoppedIngests']);

const uploadingVideosLength = computed(() => uploadingVideos.value?.length);
const activeIngestsLength = computed(() => activeIngests.value?.length);
const finishedIngestsLength = computed(() => finishedIngests.value?.length);
const stoppedIngestsLength = computed(() => stoppedIngests.value?.length);

const showUploadBar = computed(() => {
  return (
    uploadingVideosLength.value > 0 ||
    activeIngestsLength.value > 0 ||
    finishedIngestsLength.value > 0 ||
    stoppedIngestsLength.value > 0
  );
});

const pendingCountLabel = computed(() => {
  const pendingCount =
    uploadingVideosLength.value + activeIngestsLength.value;
  return t('videos.modal.processing.encoding.count.pending', 
    pendingCount,
    { n: pendingCount }
  );
});

const completedCountLabel = computed(() =>
  t('videos.modal.processing.encoding.count.completed', 
    finishedIngestsLength.value,
    { n: finishedIngestsLength.value }
  )
);

const stoppedCountLabel = computed(() =>
  t('videos.modal.processing.encoding.count.error', 
    stoppedIngestsLength.value,
    { n: stoppedIngestsLength.value }
  )
);

/*
const timeRemainingLabel = computed(() => {
  const timeRemaining = filters.formatSeconds(
    uploadingVideosTimeRemaining.value
  );
  return t('videos.modal.processing.count.remaining', 
    { time: timeRemaining }
  );
});
*/

const showModalVideosUpload = () => { store.commit('showModalVideosUpload') };
const getAllActiveIngests = () => { store.dispatch('ingests/getAll') };

onMounted(() => {
  getAllActiveIngests();
});

</script>