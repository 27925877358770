<template>
  <section
    class="loader-small min-h-[335px] grid place-content-center"
    :class="{ 'loader-small--with-msg': message !== '', 'w-full h-full': full }"
    :style="height && { height: height + 'px' }"
  >
    <img class="loader-small-img w-16 h-16" src="/src/images/loader/turn.gif">
    <p v-if="message !== ''" class="loader-small-msg">
      {{ message }}
    </p>
  </section>
</template>

<script>
export default {
  name: 'LoaderSmall',
  props: {
    height: {
      type: Number,
      default: null,
    },
    full: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
@import 'loader_small';
</style>
