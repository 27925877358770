import { cloneDeep, isEmpty, isNil } from 'lodash';
import { difference } from '@/utils/diffTools';
import { rentalService } from '@/services';
import Rental from '@/models/Rental';

const state = {
  loading: false,
  program_id: null,
  currentRental: null,
  savedRental: null,
  currentRentals: [],
  savedRentals: [],
  defaultRental: {
    amount: null,
    count: null,
    interval: 'day',
    quality: 'HD',
    active: 0,
    default_active: 1,
    model: null,
    program_id: null,
  },
};

const actions = {
  async get({ commit }, { programIds }) {
    commit('setLoading', true);
    return rentalService.get(programIds).then(
      async (rentals) => {
        commit('initRentals');
        // if response is an object, the rentals will be stored within an object with program_id as key
        if (programIds && typeof rentals === 'object' && rentals !== null) {
          for (let i = 0; i < programIds.length; i++) {
            const programId = programIds[i];
            if (rentals.hasOwnProperty(programId)) {
              for (let j = 0; j < rentals[programId].length; j++) {
                const rental = new Rental(rentals[programId][j]);
                rental.program_id = programId;
                commit('addToState', { rental });
              }
            }
          }
        }

        // If response is an array, it means its only default rentals
        if (Array.isArray(rentals)) {
          for (let i = 0; i < rentals.length; i++) {
            const rental = new Rental(rentals[i]);
            rental.program_id = null;
            commit('addToState', { rental });
          }
        }

        if (programIds) {
          commit('setProgramId', { programId: programIds[0] });
        }

        commit('setLoading', false);

        return Promise.resolve();
      },
      (error) => {
        commit('onGetFailure', error);
        return Promise.reject(error);
      }
    );
  },
  async update({ state, getters, dispatch, rootState }) {
    const rentalPromises = [];
    if (!state.currentRentals.length && !state.savedRentals.length) {
      return Promise.resolve();
    }
    state.currentRentals.forEach((rental) => {
      if (rental.id && Number.isInteger(rental.id)) {
        // Rental already exists, so this is an update
        const savedRental = state.savedRentals.find((r) => {
          return (
            r.id === rental.id &&
            (rental.program_id ? r.program_id === rental.program_id : true)
          );
        });

        const differenceBetweenSavedAndCurrentRental = difference(
          savedRental,
          rental
        );
        const rentalChanged =
          !isNil(differenceBetweenSavedAndCurrentRental) &&
          !isEmpty(differenceBetweenSavedAndCurrentRental);
        const priceChanged = savedRental.amount !== rental.amount;

        const r = cloneDeep(rental);

        if (rentalChanged) {
          delete r.price_details;

          if (priceChanged) {
            delete r.price_id;
            r.country_id = rootState.platforms.currentPlatform.getCountryId();
            r.currency_code =
              rootState.platforms.currentPlatform.getCurrencyCode();
          } else {
            delete r.country_id;
            delete r.currency_code;
            delete r.amount;
          }

          if (!r.model) {
            delete r.model;
          }

          if (!r.program_id) {
            delete r.program_id;
          }

          rentalPromises.push(rentalService.update(r));
        }
      } else {
        // Rental already does not exist, so this is a creation
        const newRental = cloneDeep(rental);
        if (!newRental.model) {
          delete newRental.model;
        }

        if (!newRental.program_id) {
          delete newRental.program_id;
        }

        delete newRental.id;
        delete newRental.price_id;
        delete newRental.price_details;
        newRental.country_id =
          rootState.platforms.currentPlatform.getCountryId();
        newRental.currency_code =
          rootState.platforms.currentPlatform.getCurrencyCode();

        rentalPromises.push(rentalService.create(newRental));
      }
    });

    state.savedRentals.forEach((rental) => {
      // Rental that existed but has been removed, this is a deletion
      const currentRental = state.currentRentals.find(
        (r) => r.id === rental.id
      );
      if (!currentRental) {
        rentalPromises.push(rentalService.deleteRental(rental));
      }
    });

    return Promise.all(rentalPromises).then(
      (success) => {
        const programIds = getters.getProgramIds;
        return dispatch('get', { programIds });
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },

  addNewRental({ commit, dispatch, getters }, { programId, model }) {
    // Check if a previous new rental exists and was completed before adding a new one
    const rentalsAreValid = getters.rentalsAreAllFilled(programId);
    if (rentalsAreValid) {
      commit('addNewRental', { programId, model });
    } else {
      dispatch('displayIncompleteRentalAlert');
    }
  },
  reset({ commit }) {
    commit('initRentals');
    return Promise.resolve();
  },
  displayIncompleteRentalAlert({ dispatch }) {
    const alert = {
      icon: 'close',
      type: 'error',
      message: 'error.rental.incomplete',
    };
    dispatch('displayAlert', alert, { root: true });
  },
};

const mutations = {
  initState(state) {
    state = {
      savedRentals: [],
      currentRentals: [],
    };
  },
  initRentals(state) {
    state.savedRentals = [];
    state.currentRentals = [];
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setProgramId(state, { programId }) {
    state.program_id = programId;
  },
  onGetFailure(state) {
    state = {};
  },
  updatePriceId(state, { rental, priceId }) {
    rental.price_id = priceId;
  },
  addNewRental(state, { programId, model }) {
    const newRental = cloneDeep(state.defaultRental);
    if (programId) {
      newRental.program_id = programId;
    }
    if (model) {
      newRental.model = model;
    }
    state.currentRentals.push(newRental);
  },
  removeRentalFromCurrentRentals(state, rental) {
    state.currentRentals.splice(state.currentRentals.indexOf(rental), 1);
  },
  saveThisRental(state, rental) {
    const i = state.currentRentals.findIndex((r) => r.id === rental.id);

    if (i >= 0) {
      state.currentRentals[i] = cloneDeep(rental);
    } else {
      state.currentRentals.push(cloneDeep(rental));
    }
  },
  saveCurrentRental(state) {
    const i = state.currentRentals.findIndex(
      (r) => r.id === state.currentRental.id
    );

    if (i >= 0) {
      state.currentRentals[i] = cloneDeep(state.currentRental);
    } else {
      state.currentRentals.push(cloneDeep(state.currentRental));
    }
  },
  deleteRental(state, rental) {
    const r = state.currentRentals.find((r) => {
      return (
        r.program_id === rental.program_id &&
        r.count === rental.count &&
        r.interval === rental.interval &&
        r.active === rental.active &&
        r.quality === rental.quality
      );
    });
    const index = state.currentRentals.indexOf(r);
    if (index > -1) {
      state.currentRentals.splice(index, 1);
    }
  },
  toggleRentalActive(state, rental) {
    rental.active = !rental.active;
    if (rental.default) {
      rental.default_active = rental.active;
    }
  },
  addToState(state, { rental }) {
    state.currentRentals.push(cloneDeep(rental));
    state.savedRentals.push(cloneDeep(rental));
  },

  setCurrentRental(state, { id, programId, model, seasonId }) {
    let rental = new Rental();

    if (!isNil(id)) {
      rental = state.currentRentals.find((x) => x.id === id);
    } else {
      rental.default = false;
      rental.program_id = programId;
      rental.model = null;
      rental.season_id = seasonId;
    }

    state.currentRental = cloneDeep(rental);
    state.savedRental = cloneDeep(rental);
  },
};

const getters = {
  rentalsAreAllFilled:
    (state, getters) =>
    (programId = null) => {
      let rentals = programId
        ? state.currentRentals.filter(
            (r) =>
              r.program_id === programId ||
              (r.parent_program && r.parent_program === programId)
          )
        : state.currentRentals;
      for (let i = 0; i < rentals.length; i++) {
        const rental = rentals[i];
        return getters.rentalIsValid(rental);
      }
      return true;
    },
  globalRentalsAreAllFilled: (state, getters) => {
    let rentals = state.currentRentals;
    for (let i = 0; i < rentals.length; i++) {
      const rental = rentals[i];
      const rentalIsValid = getters.rentalIsValid(rental);
      if (!rentalIsValid) {
        return false;
      }
    }
    return true;
  },
  rentalIsValid: (state) => (rental) => {
    return (
      rental !== null &&
      //rental.amount !== null &&
      //rental.amount !== '' &&
      //rental.amount >= 0.5 &&
      rental.count !== null &&
      rental.count !== '' &&
      rental.interval !== null &&
      rental.quality !== null
    );
  },
  getProgramIds(state, getters, rootState) {
    const programIds = [];

    if (
      rootState.programs.currentNewMovie &&
      rootState.programs.currentNewMovie.id
    ) {
      programIds.push(rootState.programs.currentNewMovie.id);
    }

    if (rootState.programs.currentNewMovie.seasons) {
      for (
        let i = 0;
        i < rootState.programs.currentNewMovie.seasons.length;
        i++
      ) {
        const season = rootState.programs.currentNewMovie.seasons[i];
        programIds.push(season.program_id);
      }
    }
    return programIds;
  },
  savedAndCurrentRentalsAreTheSame(state) {
    if (state.currentRentals.length !== state.savedRentals.length) {
      return false;
    }
    const differenceBetweenSavedAndCurrentRentals = difference(
      state.currentRentals,
      state.savedRentals
    );

    return (
      isNil(differenceBetweenSavedAndCurrentRentals) ||
      isEmpty(differenceBetweenSavedAndCurrentRentals)
    );
  },
  shouldSaveRentals(state, getters) {
    return !getters.savedAndCurrentRentalsAreTheSame;
  },
  shouldSaved(state, getters) {
    return getters.shouldSaveRentals;
  },
};

export const rentals = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
