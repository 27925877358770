import { isEqual } from 'lodash';

const getIds = (items = []) => {
  const ids = [];
  items.forEach((item) => {
    ids.push(item.id);
  });
  return ids;
};

const getChangedSeasons = (newSeasons = [], savedSeasons = []) => {
  const changed = [];
  newSeasons.forEach((newSeason) => {
    const savedSeason = savedSeasons.find((saved) => saved.id === newSeason.id);
    if (!isEqual(newSeason, savedSeason)) {
      changed.push({ newSeason, savedSeason });
    }
  });
  return changed;
};

export { getIds, getChangedSeasons };
