import { cloneDeep } from 'lodash';
import store from '@/store';

export class BlockBannerImage {
  id;
  block_id;
  block_type;
  rank;
  title;

  template_id;
  platform_id;
  block_type_id;
  item_type;
  item_type_id;

  description;
  items;

  constructor({
    block_id,
    template_id,
    platform_id,
    description,
    items,
    rank,
  }) {
    this.platform_id = platform_id;
    this.template_id = template_id;
    this.block_id = block_id;
    this.block_type = 'banner';
    this.block_type_id = store.getters['references/block_type_id'](
      this.block_type
    );
    this.item_type = 'video';
    this.item_type_id = store.getters['references/item_type_id'](
      this.item_type
    );

    this.title = '';
    this.name = '';
    this.description = description;

    this.items = items;
    this.rank = rank;
    this.active = 1;
  }

  /**
   * Get clean data for api
   *
   * @return object
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  getCleanDataForApi() {
    let retval = cloneDeep(this);

    retval.items = [{ item_type_id: this.item_type_id, rank: 0 }];

    // Remove unnecessary data for api
    delete retval.format;
    delete retval.stylable;
    delete retval.temp;
    delete retval.displayConfig;
    delete retval.defaultColors;
    delete retval.details;

    return retval;
  }
}

export default BlockBannerImage;
