import facets from '@/data/facets';
import store from '@/store/index.js';

const state = {
  sorts: [],
  facets,
};

const actions = {
  initAnimationFacets({ commit }, categories) {
    commit('initAnimationFacets', categories);
  },
  initAnimationSort({ commit }) {
    commit('initAnimationSort');
  },
  resetAnimationFacets({ commit }) {
    commit('resetAnimationFacets');
  },
};

const mutations = {
  initAnimationFacets(state) {
    const filters = facets
      .find((facet) => facet.id === 'status')
      .filters.filter((f) => ['online', 'offline'].includes(f.id));
    const statusFacets = {
      title: 'search.status',
      id: 'status',
      checked: false,
      cols: '1',
      unique: true,
      filters,
    };
    state.facets = [statusFacets];
  },
  initAnimationSort(state) {
    state.sorts = [
      {
        id: 'date:desc',
        title: 'search.added',
      },
      {
        id: 'title:asc',
        title: 'A - Z',
      },
      {
        id: 'title:desc',
        title: 'Z - A',
      },
    ];

    if (store.getters['auth/can']('animation.beta')) {
      state.sorts.splice(1, 0, {
        id: 'generated_at:desc',
        title: 'search.indexed',
      });
    }
  },
  resetAnimationFacets(state) {
    state.facets.forEach((facet) => {
      facet.filters.forEach((filter) => {
        filter.checked = false;
      });
    });
  },
};
export const animationFilter = {
  namespaced: true,
  state,
  actions,
  mutations,
};
