import Font from "@/models/Font"

export class FontFamily {
  constructor({
    id = null,
    label = null,
    title = '',
    available = false,
    is_main = false,
    online = false,
    status = 'incomplete',
    fonts = [],
    updated_at = null,
    generated_at = null,
  } = {}) {
    this.id = id,
    this.label = label
    this.title = title
    this.available = available
    this.is_main = is_main
    this.online = online
    this.status = status
    this.fonts = fonts.map((item) => new Font(item))
    this.updated_at = updated_at
    this.generated_at = generated_at
  }
}

export function createFont(data) {
  return Object.freeze(new FontFamily(data));
}

export default FontFamily