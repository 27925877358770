import { cloneDeep } from 'lodash';
import MetadataDetail from '@/models/MetadataDetail';
import MetadataOption from '@/models/MetadataOption';
import PersonType from '@/models/PersonType';

const initialMetadataDetail = new MetadataDetail();
const initialMetadataOption = new MetadataOption();
const initialRole = new PersonType();

export default {

  loading: false,
  searchLoading: false,
  reload: false,
  error: {},

  all: [],
  savedAll: [],
  formattedAll: [],

  currentNewMetadataDetail: cloneDeep(initialMetadataDetail),
  formatedCurrentNewMetadataDetail: cloneDeep(initialMetadataDetail),
  savedNewMetadataDetail: cloneDeep(initialMetadataDetail),

  options: {
    data: [],
    meta: {
      pagination: {
        count: 0,
        current_page: 1,
        per_page: 0,
        total: 0,
        total_pages: 0,
      },
    },
  },
  currentOption: cloneDeep(initialMetadataOption),
  formattedOption: cloneDeep(initialMetadataOption),
  savedOption: cloneDeep(initialMetadataOption),

  currentRole: cloneDeep(initialRole),
  formattedRole: cloneDeep(initialRole),
  allRoles: [],

  currentRelatedPrograms: [],
  savedRelatedPrograms: [],
  formatedRelatedPrograms: [],
};