import { cloneDeep, isNil } from 'lodash';

import DisplayConfig from '@/models/parameters/DisplayConfig';
import { picturesNames } from "@/data/pictures";
import store from '@/store';

export class BlockBannerText {
  id;
  block_id;
  block_type;
  details;
  rank;
  title;
  title_size;
  title_weight;
  title_style;
  content_banner;

  type;
  template_id;
  platform_id;
  block_type_id;

  description;
  picture;
  cta_label;
  cta_url;
  cta_target;
  background_color;
  picture_default;
  items;

  defaultColors;
  displayConfig;

  constructor(
    {
      block_id,
      type,
      template_id,
      platform_id,
      title_size,
      title_weight,
      title_style,
      description,
      picture,
      cta_label,
      cta_url,
      cta_target,
      background_color,
      picture_default,
      details,
      items,
      rank,
      content_banner,
    } = {},
    defaultColors
  ) {
    this.platform_id = platform_id;
    this.template_id = template_id;
    this.block_id = block_id;
    this.block_type = 'banner';
    this.block_type_id = store.getters['references/block_type_id'](
      this.block_type
    );
    this.item_type = 'program';
    this.item_type_id = store.getters['references/item_type_id'](
      this.item_type
    );

    if (typeof type === 'object' && 'name' in type) {
      this.type = type.name;
    } else {
      this.type = type;
    }

    this.item_type = 'program';
    this.title = '';
    this.name = '';
    this.title_size = title_size;
    this.title_weight = title_weight;
    this.title_style = title_style;
    this.description = description;
    this.picture = picture;
    this.cta_label = cta_label;
    this.cta_url = cta_url;
    this.cta_target = cta_target;
    this.background_color = background_color;
    this.picture_default = picture_default;

    this.details = details;

    this.items = items;
    this.rank = rank;

    this.content_banner = content_banner;

    this.defaultColors = defaultColors;

    this.setDefault();
    this.setDisplayConfig();
  }

  setDefault() {
    if (isNil(this.content_banner)) {
      this.content_banner = {
        aspect: 'banner-simple',
        height: 'md',
        picture: false,
        pictureType: picturesNames.PICTURE_TYPE_POSTERS,
        main_meta: null,
        common_metas: [],
        background_opacity: 70
      };
    }

    if (isNil(this.content_banner.picture)) {
      this.content_banner.picture = false;
    }

    this.format = 'multiselect';
    this.stylable = true;
    this.details = {
      type: 'category',
    };
    this.active = 1;

    this.content_banner.common_metas = this.getCleanCommonMetas();
  }

  /**
   * Set config for metadata display
   */
  setDisplayConfig() {
    let retval = {};
    const data = this;
    const defaultColors = this.defaultColors;

    retval.title = null;
    retval.style = 'badge';
    retval.titleStyles = { bold: null, fontSize: null, italic: null };

    // Set badge color
    const badgeColorId = data.content_banner?.main_meta_color ?? 'primary';
    const id = badgeColorId;
    const hex = defaultColors[id] ?? null;
    retval.badgeColor = { id, hex };

    // Set badge style : filled / bordered / ...
    retval.badgeStyle = data.content_banner?.main_meta_style ?? null;
    retval.badgeRadius = data.content_banner?.main_meta_radius ?? null;
    retval.socialNetworks = data.content_banner?.socialNetworks ?? null;

    this.displayConfig = new DisplayConfig(retval);
  }

  removeMainMeta() {
    this.content_banner.main_meta = null;
    this.content_banner.main_meta_color = null;
    this.setDisplayConfig();
  }

  setMainMeta(id) {
    if (id === null) {
      this.removeMainMeta();
      return;
    }
    this.content_banner.main_meta = id;
  }

  getCleanCommonMetas() {
    let retval = cloneDeep(this.content_banner.common_metas);
    if (retval) {
      return retval
        .filter((element) => element != null && element.id)
        .map((element) => ({
          id: element.id,
          type: element.type,
          metadata_id: element.metadata_id,
          display: element.display,
        }));
    }
  }

  /**
   * Get clean data for api
   *
   * @return object
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  getCleanDataForApi() {
    let retval = cloneDeep(this);
    const content_banner = {};

    content_banner.aspect = this.content_banner.aspect;
    content_banner.height = this.content_banner.height;
    content_banner.picture = +this.content_banner.picture; // Convert bool to int
    content_banner.picture_type = this.content_banner.picture_type;
    content_banner.background_opacity = this.content_banner.background_opacity;
    content_banner.main_meta = this.content_banner.main_meta;
    content_banner.common_metas = this.getCleanCommonMetas();

    content_banner.main_meta_type = this.displayConfig.getApiType();
    content_banner.main_meta_style = this.displayConfig.getApiStyle();
    content_banner.main_meta_color = this.displayConfig.getApiColor();
    content_banner.main_meta_radius = this.displayConfig.getApiRadius();

    if (['text', 'image'].includes(retval.content_banner.type)) {
      content_banner.main_meta_style = null;
      content_banner.main_meta_color = null;
      content_banner.main_meta_radius = 0;
    }
    retval.content_banner = content_banner;
    retval.items = [{ item_type_id: this.item_type_id, rank: 0 }];

    // Remove unnecessary data for api
    delete retval.format;
    delete retval.stylable;
    delete retval.temp;
    delete retval.displayConfig;
    delete retval.defaultColors;
    delete retval.details;

    return retval;
  }
}

export function createBlockBannerText(data) {
  return Object.freeze(new BlockBannerText(data));
}

export default BlockBannerText;
