import { GenericContentService } from '@/services';
import { cloneDeep } from 'lodash';
import Language from '@/models/Language';
import MetadataDetail from '@/models/MetadataDetail.js';

const genericContentService = new GenericContentService('languages', true);

const state = {
  loading: false,
  all: [],
  formatedCurrentNewLanguage: new Language(),
  currentNewLanguage: new Language(),
  savedNewLanguage: new Language(),
};

const getters = {
  frequentlyUsedTrue: (state) => {
    return state.all.filter((x) => 
      x.frequently_used === 1 && x.group_environment === 'otto'
    );
  },
  frequentlyUsedFalse: (state) => {
    return state.all.filter((x) => 
      x.frequently_used === 0 && x.group_environment === 'otto'
    );
  },
  allLanguages: (state) => {
    return state.all.filter((x) => x.group_environment === 'otto');
  },
  amazonFrequentlyUsedTrue: (state) => {
    return state.all.filter((x) => 
      x.frequently_used === 1 && x.group_environment === 'amazon'
    );
  },
  amazonFrequentlyUsedFalse: (state) => {
    return state.all.filter((x) => 
      x.frequently_used === 0 && x.group_environment === 'amazon'
    );
  },
  amazonAllLanguages: (state) => {
    return state.all.filter((x) => x.group_environment === 'amazon');
  },
  groupedAllLanguages: (state) => {
    return state.all;
  },
};

const actions = {
  getAll({ commit, dispatch }, params = {}) {
    commit('getAllRequest');
    if (state.all.length === 0) {
      return genericContentService.getAll().then(
        (languages) => {
          commit('getAllSuccess', languages);
          return Promise.resolve(languages);
        },
        (error) => {
          commit('getAllFailure', error);
          return Promise.reject(error);
        }
      );
    }
  },
  get({ commit, dispatch }) {
    commit('getRequest');
    genericContentService.get().then(
      (categories) => {
        commit('getSuccess', categories);
        return Promise.resolve(categories);
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  getAllRequest(state) {
    state.loading = true;
  },
  getAllSuccess(state, languages) {
    const languagesModelized = [];
    languages.forEach(function (language) {
      var languageModelized = new Language(language);
      languagesModelized.push(languageModelized);
    });
    state.all = languagesModelized;
    state.loading = false;
  },
  getAllFailure(state) {
    state.all = [];
    state.loading = false;
  },

  getRequest(state) {
    state.loading = true;
  },
  getSuccess(state, language) {
    var languageModelized = new Language(language);
    state.currentNewLanguage = cloneDeep(languageModelized);
    state.savedNewLanguage = cloneDeep(languageModelized);
    state.formatedCurrentNewLanguage = {};
    state.loading = false;
  },
  getFailure(state) {
    state.currentNewLanguage = new MetadataDetail();
    state.savedNewLanguage = new MetadataDetail();
    state.loading = false;
  },

  cleanLanguagesStates() {
    state.formatedCurrentNewLanguage = new MetadataDetail();
    state.currentNewLanguage = new MetadataDetail();
    state.savedNewLanguage = new MetadataDetail();
  },
  fullCleanLanguagesStates() {
    state.all = [];
    state.formatedCurrentNewLanguage = new MetadataDetail();
    state.currentNewLanguage = new MetadataDetail();
    state.savedNewLanguage = new MetadataDetail();
  },

  setCurrentNewLanguage(state, language) {
    state.currentNewLanguage = cloneDeep(language);
    state.savedNewLanguage = cloneDeep(language);
  },
};

export const languages = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
