import { customService } from '@/services';
import { isNil } from 'lodash';

const state = {
  all: [],
  one: [],
};

const actions = {
  getAll({ commit, state }, detailsList) {
    commit('getAllRequest');
    return customService.getByMetadataDetails(detailsList).then(
      (customs) => {
        commit('getAllSuccess', customs);
        return Promise.resolve(customs);
      },
      (error) => {
        commit('getAllFailure', error);
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  getAllRequest(state) {
    if (state.all.length > 0) {
      state.all = [];
    }
  },
  getAllSuccess(state, customs) {
    state.all = customs;
  },
  getAllFailure(state) {
    state.all = [];
  },
  getRequest(state) {
    state.one = [];
  },
  getSuccess(state, custom) {
    state.one = custom;
  },
  getFailure(state) {
    state.one = [];
  },
};

export const customs = {
  namespaced: true,
  state,
  actions,
  mutations,
};
