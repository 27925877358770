import { Coupon } from '@/models/billing/Coupon';
import { couponService } from '@/services';
import { cloneDeep } from 'lodash';
const state = {
  all: { data: [], meta: { pagination: { total_pages: 0, per_page: 0 } } },
  userId: null,
  loading: true,
};

const actions = {

  search({ commit, dispatch }, { page, keyword, sort, discount_campaign_usable_by }) {
    commit('setLoading', true);

    let queryString = 'count=10&page=' + page + '&sort=' + sort;
    if (keyword.length > 0) {
      queryString += '&query=' + keyword;
    }
    if (discount_campaign_usable_by) {
      queryString += '&discount_campaign_usable_by=' + discount_campaign_usable_by;
    }
    return couponService.search(queryString).then(
      (coupons) => {
        commit('searchSuccess', coupons);
        return Promise.resolve(coupons);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  get({ commit, dispatch }, token) {
    return couponService.get(token).then(
      (coupon) => {
        return Promise.resolve(coupon);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  setLoading(state, value) {
    state.loading = value;
  },
  searchSuccess(state, coupons) {
    const datasBrut = cloneDeep(coupons.data);
    coupons.data = datasBrut.map((dataBrut) =>
      new Coupon(dataBrut)
    );
    state.all = coupons;
    state.loading = false;
  },
};

export const coupons = {
  namespaced: true,
  state,
  actions,
  mutations,
};
