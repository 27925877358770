import { handleResponse } from '@/services/index.js';
import axios from 'axios';

export class BlockService {
  _endpointName;

  constructor() {
    this._endpointName = 'blocks';
  }

  async get(id, platformId = null) {
    let config = { headers: { transform: 'bo' } };
    if (platformId !== null) {
      config.headers.platform = platformId;
    }
    try {
      const response = await axios.get(
        import.meta.env.VITE_CONTENT_BASE_URL +
        '/' +
        this._endpointName +
        '/' +
        id,
        config
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }
  async post(data, platformId = null, userId = null) {
    let config = { headers: { transform: 'bo' } };

    if (platformId !== null) {
      config.headers.platform = platformId;
    }
    try {
      const response = await axios.post(
        import.meta.env.VITE_CONTENT_BASE_URL + '/' + this._endpointName + '/',
        data,
        config
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async createMany(data, userId = null) {
    const config = { headers: { transform: 'bo' } };
    const endpointName = this._endpointName + '/create-many';
    try {
      const response = await axios.post(
        import.meta.env.VITE_CONTENT_BASE_URL + '/' + endpointName,
        data,
        config
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async update(data, id, platformId = null, userId = null) {
    let config = { headers: { transform: 'bo' } };
    if (platformId !== null) {
      config.headers.platform = platformId;
    }
    try {
      config.method = 'PATCH';
      config.url = `${import.meta.env.VITE_CONTENT_BASE_URL}/${this._endpointName}/${id}`;
      config.data = data;
      const response = await axios(config);
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async updateRank(data, id, platformId = null, userId = null) {
    let config = { headers: { transform: 'bo' } };
    const endpointName = this._endpointName + '/rank';
    if (platformId !== null) {
      config.headers.platform = platformId;
    }
    try {
      const response = await axios.put(
        import.meta.env.VITE_CONTENT_BASE_URL + '/' + endpointName + '/' + id,
        data,
        config
      );
      if (typeof response?.data === 'object') {
        response.data.itemId = id;
      }
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async delete(id, platformId = null, userId = null) {
    let config = { headers: { transform: 'bo' } };
    if (platformId !== null) {
      config = { headers: { platform: platformId } };
    }
    try {
      const response = await axios.delete(
        import.meta.env.VITE_CONTENT_BASE_URL +
        '/' +
        this._endpointName +
        '/' +
        id,
        config
      );
      return handleResponse(response);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async updateAllRank(data, userId = null) {
    const config = { headers: { transform: 'bo' } };
    const endpointName = `${this._endpointName}/ranks`;
    try {
      const response = await axios.put(
        import.meta.env.VITE_CONTENT_BASE_URL + '/' + endpointName,
        data,
        config
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async deleteAll(data, userId = null) {
    const endpointName = `${this._endpointName}`;
    const requestConfig = {
      method: 'DELETE',
      data: data,
      headers: { transform: 'bo' }
    };
    try {
      const response = await axios.delete(
        import.meta.env.VITE_CONTENT_BASE_URL + '/' + endpointName,
        requestConfig
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }
}
