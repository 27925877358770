import { GenericBillingService } from '@/services';
import { TVODPrice } from '@/models/billing/TVODPrice';

const tvodPriceService = new GenericBillingService('tvod-prices');

const state = {
  loading: false,
  all: [],
  currentKey: null,
};

const getters = {
  loading: (state) => {
    return state.loading;
  }
};

const actions = {
  getAll({ commit, rootState }) {
    const platform = rootState.platforms.currentPlatform;
    const country = platform.billing_config.default_country ?? 'FR';
    const currency = platform.billing_config.default_currency ?? 'EUR';
    const key = `${country}-${currency}`;

    if (state.all.length === 0 || state.currentKey !== key) {
      const queryString = `country_code=${country}&currency_code=${currency}`;

      commit('getRequest');
      return tvodPriceService.getAll(queryString).then(
        (response) => {
          commit('setCurrentKey', { country, currency });
          commit('getSuccess', response);
          return Promise.resolve();
        },
        (error) => {
          commit('getFailure', error);
          return Promise.reject(error);
        }
      );
    }
  }
};

const mutations = {
  reset(state) {
    state.all = [];
    state.loading = false;
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setCurrentKey(state, { country, currency }) {
    state.currentKey = `${country}-${currency}`;
  },

  getRequest(state) {
    state.loading = true;
  },
  getSuccess(state, response) {
    state.all = response.map((tvodPrice) => new TVODPrice(tvodPrice));
    state.loading = false;
  },
  getFailure(state) {
    state.all = [];
    state.loading = false;
  }
};

export const tvodPrices = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
