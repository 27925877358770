//Permet de rÃ©cupÃ©rer une image issu d'un item de type rpogram, selection ou custom pour un bloc de la partie animation

export const DataFromMultiTypeAccessor = {
  isPerso(item) {
    return (
      (item?.type === 'custom' && item.itemable_type === null) ||
      item?.type === 'text'
    );
  },

  isEditedNotCustom(item, blockType) {

    const type = ['program', 'serie', 'season', 'episode', 'collection'].includes(item?.type)
      ? 'program'
      : item?.type
    return (
      item?.cta_label ||
      item?.cta_url ||
      (item?.title && type && item[type]?.title !== item.title) ||
      (item?.description && type && item[type]?.description !== item?.description) ||
      (item?.picture?.backdrops?.[0]?.url &&
        item?.picture?.backdrops?.[0]?.url !== '') ||
      (item?.picture?.banners?.[0]?.url &&
        item?.picture?.banners?.[0]?.url !== '') ||
      (item?.picture?.posters?.[0]?.url &&
        item?.picture?.posters?.[0]?.url !== '') ||
      (item?.picture?.thumbnails?.[0]?.url &&
        item?.picture?.thumbnails?.[0]?.url !== '') ||
      (blockType && blockType.startsWith('row-') &&
        this.isSubtitleCustom(item, type))
    )
  },

  isSubtitleCustom(item, type) {
    const subtitles =
      item?.subtitles ??
      item?.configuration?.subtitles ??
      [];
    let custom = false;
    subtitles.forEach((sub) => {
      if (sub.value && sub.value !== item[type]?.title) {
        custom = true;
      }
    });
    return custom;
  },

  isProgramLive(item) {
    return (
      item?.type === 'program' && item.program && item.program.type === 'live'
    );
  },
  getItemImage(item, collectionName = '') {
    let picture = [];
    let currentContent = {};
    const itemType = item?.type ?? null;
    if (
      item &&
      Array.isArray(item.picture) &&
      item.picture.length > 0 &&
      item.picture[0] &&
      'url' in item.picture[0] &&
      item.picture[0].url !== ''
    ) {
      picture = item.picture;
    } else if (
      collectionName !== '' &&
      item &&
      typeof item.picture !== 'undefined' &&
      collectionName in item.picture &&
      item.picture[collectionName].length > 0 &&
      'url' in item.picture[collectionName][0] &&
      item.picture[collectionName][0].url !== ''
    ) {
      currentContent = item.picture;
    } else if (
      ['program', 'serie', 'season', 'episode', 'collection'].includes(
        itemType
      ) &&
      'program' in item &&
      typeof item.program !== 'undefined'
    ) {
      currentContent = item.program.picture;
    } else if (itemType === 'selection' && 'selection' in item) {
      currentContent = item.selection.picture;
    } else if (itemType === 'plan' && 'plan' in item) {
      currentContent = item.plan.picture;
    } else if (this.isMetadata(item)) {
      currentContent = this.getMetadataItem(item).picture;
    } else if (itemType === 'custom') {
      currentContent = item.picture;
    } else if (itemType === 'banner') {
      if (Array.isArray(item.picture) && item.picture.length > 0) {
        currentContent = item.picture;
      } else if (item.items && item.items.length > 0) {
        if (item.items[0].type === 'program' && 'program' in item.items[0]) {
          currentContent = item.items[0].program.picture;
        } else if (
          item.items[0].type === 'serie' &&
          'program' in item.items[0]
        ) {
          currentContent = item.items[0].program.picture;
        } else if (
          item.items[0].type === 'selection' &&
          'selection' in item.items[0]
        ) {
          currentContent = item.items[0].selection.picture;
        }
      }
    } else if (
      itemType === 'text' &&
      'picture' in item &&
      collectionName in item.picture &&
      item.picture[collectionName].length > 0
    ) {
      currentContent = item.picture;
    } else if (itemType == 'metadata' && 'metadata' in item) {
      currentContent = item.metadata.picture;
    }
    if (currentContent) {
      if (collectionName !== '' && collectionName in currentContent) {
        picture = currentContent[collectionName];
      }
      if (
        Array.isArray(picture) &&
        picture.length > 0 &&
        picture[0] &&
        picture[0].url &&
        picture[0].url !== ''
      ) {
        return picture[0];
      }
    }

    if (collectionName === 'xl_posters') {
      return this.getItemImage(item, 'posters');
    } else if (collectionName === 'xl_thumbnails') {
      return this.getItemImage(item, 'thumbnails');
    }

    return null;
  },
  getItemTitle(item) {
    const itemType = item?.type ?? null;
    if (item?.title && typeof item.title === 'string') {
      return item.title;
    } else if (
      ['program', 'serie', 'season', 'episode', 'collection'].includes(
        itemType
      ) &&
      'program' in item &&
      typeof item.program !== 'undefined'
    ) {
      return item.program.title;
    } else if (itemType === 'selection' && 'selection' in item) {
      return item.selection.title;
    } else if (itemType === 'plan' && 'plan' in item) {
      return item.plan.title;
    } else if (itemType === 'person' && item.person) {
      return item.person.title;
    } else if (this.isMetadata(item)) {
      return this.getMetadataItem(item).title;
    } else if (itemType == 'metadata' && 'metadata' in item) {
      return item.metadata.title;
    } else if (itemType === 'banner') {
      return item?.items.length > 0
        ? DataFromMultiTypeAccessor.getItemTitle(item.items[0])
        : '';
    } else if (
      item?.subtitles &&
      item.subtitles.length > 0 &&
      item.subtitles[0].value
    ) {
      return item.subtitles[0].value;
    } else if (
      item?.configuration?.subtitles?.length > 0 &&
      item.configuration.subtitles[0].value
    ) {
      return item.configuration.subtitles[0].value;
    }
  },
  getItemDescription(item) {
    const itemType = item?.type ?? null;
    if (item?.description &&
      typeof item.description === 'string' &&
      item.description !== null
    ) {
      return item.description;
    } else if (
      ['program', 'serie', 'season', 'episode', 'collection'].includes(
        itemType
      ) &&
      'program' in item &&
      typeof item.program !== 'undefined'
    ) {
      return item.program.synopsis;
    } else if (itemType === 'selection' && 'selection' in item) {
      return item.selection.description;
    } else if (this.isMetadata(item)) {
      return this.getMetadataItem(item).description;
    } else if (itemType === 'banner') {
      return item.items && item.items.length > 0
        ? DataFromMultiTypeAccessor.getItemDescription(item.items[0])
        : '';
    }
  },
  getItemLabel(item) {
    if (this.isPerso(item)) {
      return 'animation.item.type.custom';
    }
    return this.getItemLabelByType(item);
  },
  getItemLabelByType(item) {
    const itemType = item?.type ?? null;
    if (this.isProgramLive(item)) {
      return 'movie.type.live';
    } else if (itemType === 'program') {
      return 'movie.type.generic';
    } else if (itemType === 'selection') {
      return 'animation.item.type.selection';
    } else if (itemType === 'serie') {
      return 'movie.type.serie';
    } else if (itemType === 'season') {
      return 'movie.type.season';
    } else if (itemType === 'episode') {
      return 'movie.type.episode';
    } else if (itemType === 'collection') {
      return 'movie.type.collection';
    } else if (itemType === 'banner') {
      if (item.items && item.items.length > 0) {
        return this.getItemLabelByType(item.items[0]);
      } else {
        return 'animation.item.type.custom';
      }
    } else if (this.isMetadata(item) || itemType === 'metadata') {
      return 'animation.item.type.metadata';
    }
    return 'animation.item.type.custom';
  },
  getItemStatus(item) {
    const itemType = item?.type ?? null;
    if (
      ['program', 'serie', 'season', 'episode', 'collection'].includes(
        itemType
      ) &&
      'program' in item &&
      typeof item.program !== 'undefined'
    ) {
      return item.program.status;
    } else if (itemType === 'selection' && 'selection' in item) {
      return item.selection.status;
    } else {
      return '';
    }
  },
  getItemIcon(item, isPerso = true, blockType = null) {
    if (isPerso &&
      (this.isEditedNotCustom(item, blockType) || this.isPerso(item))
    ) {
      return 'custom';
    }
    return this.getItemIconByType(item);
  },
  getItemIconByType(item) {
    const itemType = item?.type ?? null;
    if (this.isProgramLive(item)) {
      return 'live';
    } else if (
      [
        'program',
        'serie',
        'season',
        'episode',
        'selection',
        'collection',
      ].includes(itemType)
    ) {
      return itemType;
    } else if (this.isMetadata(item)) {
      return 'hierarchy';
    } else if (itemType === 'banner') {
      if (item.items && item.items.length > 0) {
        return item.items[0].type;
      } else {
        return 'custom';
      }
    }
    return 'custom';
  },
  isMetadata(item) {
    const itemType = item?.type ?? null;
    if (itemType === 'metadata') {
      return true;
    } else if (itemType === 'custom') {
      return (
        (item.itemable_type !== undefined && item.itemable_type === 'custom') ||
        (item.linkable_type !== undefined && item.linkable_type === 'custom')
      );
    } else {
      let itemableType = item?.itemable_type ? item.itemable_type : itemType;
      return [
        'metadata',
        'category',
        'person',
        'tag',
        'country',
        'gear',
        'level',
        'version',
        'custom',
      ].includes(itemableType);
    }
  },
  getMetadataItem(item) {
    let itemType = item?.itemable_type ? item.itemable_type : (item?.type ?? null);
    if (item.metadata) {
      return item.metadata;
    } else if (itemType === 'person' && item.person) {
      return item.person;
    } else if (itemType === 'category' && item.category) {
      return item.category;
    } else if (itemType === 'tag' && item.tag) {
      return item.tag;
    } else if (itemType === 'country' && item.country) {
      return item.country;
    } else if (itemType === 'gear' && item.gear) {
      return item.gear;
    } else if (itemType === 'level' && item.level) {
      return item.level;
    } else if (itemType === 'version' && item.version) {
      return item.version;
    } else if (itemType === 'custom' && item.custom) {
      return item.custom;
    }
    return {
      id: item.itemable_id,
      description: item.description,
      label: item.title,
      title: item.title,
      picture: item.picture,
    };
  },
};

export default DataFromMultiTypeAccessor;