<template>
  <span class="illustration" :class="`illustration-${illustration}`">
    <img
      :src="illustrationConfig.src"
      :width="illustrationConfig.width"
      :height="illustrationConfig.height"
    />
  </span>
</template>

<script>
import illustrations from '@/components/illustration/illustrations.js';
export default {
  name: 'Illustration',
  props: {
    illustration: String,
  },
  computed: {
    actionIllustrationConfig() {
      return this.action && illustrations[this.action.toLowerCase()];
    },
    illustrationConfig() {
      const illustration = illustrations[this.illustration.toLowerCase()];
      return {
        src: this.assetUrl(`illustrations/${illustration.path}`),
        width: illustration.width,
        height: illustration.height,
      };
    },
  },
};
</script>

<style lang="scss">
@import 'illustration.scss';
</style>
