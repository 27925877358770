const transationsFacets = [
  {
    id: 'payments',
    title: 'Paiements',
    checked: false,
    cols: 4,
    filters: [
      /*{
      facet_id: 'payments',
      id: 'INITIATED',
      title: 'Accepté',
      checked: false
    },*/ {
        facet_id: 'payments',
        id: 'FAIL',
        title: 'Échoué',
        checked: false,
      },
      {
        facet_id: 'payments',
        id: 'REFUND',
        title: 'Remboursé',
        checked: false,
      },
      /*{
      facet_id: 'payments',
      id: 'CANCEL',
      title: 'Annulé',
      checked: false
    },*/ {
        facet_id: 'payments',
        id: 'PARTIAL_REFUND',
        title: 'Remboursement partiel',
        checked: false,
      },
      {
        facet_id: 'payments',
        id: 'SUCCESS',
        title: 'Réussi',
        checked: false,
      },
    ],
  },
];

export default transationsFacets;
