import Template from '@/models/Template';
import { cloneDeep } from 'lodash';
import { isEmpty } from 'lodash';


export default {

  /**
   * Resets modified state properties to empty arrays.
   *
   * @returns {void}
   */
  resetModified(state) {
    state.toCreateChildBlocks = []
    state.toCreateBlocks = []
    state.toUpdateBlocks = []
    state.toDeleteBlocks = []
    state.toUpdateBlocksRank = []
    state.errors = []
  },

  /**
   * Cleans the current state by resetting template, currentBlocks, and savedBlocks.
   * 
   * @returns {void}
   */
  cleanTemplateState(state) {
    state.template = {};
    state.currentBlocks = {};
    state.savedBlocks = {};
  },

  /**
   * Sets the mode in the state.
   * 
   * @param {string} mode
   * @returns {void}
   */
  setMode(state, mode) {
    state.mode = mode;
  },

  /**
   * Sets the template for a specific device in the state.
   * 
   * @param {Array} payload
   * @param {Object} payload.template - The template to be set.
   * @param {string} payload.device - The device for which the template is set.
   * @returns {void}
   */
  setTemplate(state, { template, device }) {
    if (device && !isEmpty(device)) {
      state.template[device] = template
        ? Object.freeze(template)
        : { blocks: [] };
    }
  },

  /**
   * Sets the current blocks for a specific device in the state.
   *
   * @param {Object} payload
   * @param {Array} payload.blocks - The blocks to be set.
   * @param {string} payload.device - The device for which the blocks are set.
   * @returns {void}
   */
  setCurrentBlocks(state, { blocks, device }) {
    if (device && !isEmpty(device)) {
      state.currentBlocks[device] = blocks ?? [];
    }

  },

  /**
   * Sets the saved blocks to match the current blocks.
   * 
   * @returns {void}
   */
  setSavedBlocks(state) {
    state.savedBlocks = state.currentBlocks
      ? cloneDeep(state.currentBlocks)
      : {};
  },

  /**
   * Toggle the publication status of the page for all devices in the template.
   *
   * @param {boolean} status
   */
  togglePagePublication(state, status) {
    for (const device in state.template) {
      state.template[device] = new Template({
        ...state.template[device],
        online: status,
      });
    }
  },

  /**
   * Set the template name in the state.
   *
   * @param {string} value
   */
  setTemplateName: function (state, value) {
    state.templateName = value;
  },

  /**
   * Set the loading status in the state.
   *
   * @param {boolean} value
   */
  setLoading(state, value) {
    state.loading = value;
  },

  /**
   * Sets the value of the 'showDisabledBlocks' property in the state.
   *
   * @param {boolean} value
   * @returns {void}
   */
  setshowDisabledBlocks(state, value) {
    state.showDisabledBlocks = value;
  },

  /**
   * Adds or updates a disabled block to the list.
   *
   * @param {Object} block
   * @returns {void}
   */
  addToShowDisabledBlocks(state, block) {
    if (!block?.id) return;
    const index = state.toShowDisabledBlocks.findIndex(
      (b) => b.id === block?.id
    );
    if (index != -1) {
      state.toShowDisabledBlocks.splice(index, 1, block);
    } else {
      state.toShowDisabledBlocks.push(block);
    }
  },

  /**
   * Adds a block to the list of blocks to be downloaded.
   *
   * @param {string} blockId - The ID of the block to be downloaded.
   * @returns {void}
   */
  addToDownloadingBlocks(state, blockId) {
    if (blockId && !state.downloadingBlocks.includes(blockId)) {
      state.downloadingBlocks.push(blockId);
    }
  },

  /**
   * Adds a block to the list of blocks to be downloaded.
   *
   * @param {string} blockId - The ID of the block to be downloaded.
   * @returns {void}
   */
  removeToDownloadingBlocks(state, blockId) {
    const index = state.downloadingBlocks.findIndex(
      (id) => id === blockId
    );
    if (index != -1) {
      state.downloadingBlocks.splice(index, 1);
    }
  },

  /**
   * Adds the errors to the state. 
   * 
   * @param {Array} errors 
   */
  addToErrors(state, errors) {
    if (errors) {
      state.errors = state.errors.concat(errors);
    }
  },

  /**
   * Sets the warnings in the state.
   * 
   * @param {Array} warnings
   */
  addToWarnings(state, warnings) {
    if (warnings) {
      state.warnings = state.warnings.concat(warnings);
    }
  },

  /**
   * Removes all errors and warnings from the state.
   * 
   * @returns {void}
   *
  */
  removeAllErrorsAndWarnings(state) {
    state.errors = [];
    state.warnings = [];
  },

  /*********** add new block ***********/

  /**
   * Adds a new block to the list of blocks to be created.
   *
   * @param {Object} block
   * @returns {void}
   */
  addToCreateBlock(state, block) {
    if (!block) return;
    const index = state.toCreateBlocks.findIndex(
      (x) => x.id === block?.id
    );
    if (index != -1) {
      state.toCreateBlocks.splice(index, 1, block);
    } else {
      state.toCreateBlocks.push(block);
    }
  },

  /**
 * Adds a block to the list of current blocks for a specific device.
 *
 * @param {Object} payload.block - The block to be added.
 * @param {string} payload.device - The device for which the block is added.
 * @param {number} [payload.index=-1] - The optional index at which the block is inserted.
 * @returns {void}
 */
  addToCurrentBlocks(state, { block, device, index = -1 }) {
    if (!block || !device) return;
    const blocks = state.currentBlocks?.[device] ?? [];
    if (index != -1) {
      blocks.splice(index, 0, block);
    } else {
      blocks.push(block);
    }
  },

  /**
   * Removes a block from the list of blocks to be created.
   *
   * @param {string} block_id - The ID of the block to be removed.
   * @returns {void}
   */
  removeToCreateBlock(state, block_id) {
    if (!block_id) return;
    const index = state.toCreateBlocks
      .findIndex(b => b.id === block_id || b.linked_block_id === block_id);
    if (index != -1) {
      state.toCreateBlocks.splice(index, 1);
    }
  },

  /**
   * Removes a block from the list of current blocks for a specific device.
   *
   * @param {string} payload.parentId - The ID of the parent block.
   * @param {string} payload.device - The device for which the block is removed.
   * @returns {void}
   */
  removeToCurrentBlocks(state, { parentId, device }) {
    if (!parentId || !device) return;
    const index = state.currentBlocks[device]
      .findIndex((b) => b.id === parentId || b.linked_block_id === parentId);
    if (index != -1) {
      state.currentBlocks[device].splice(index, 1);
    }
  },

  /***********  add new child blocks ***********/

  /**
   * Adds a new child block to the list of child blocks to be created.
   *
   * @param {Object} block - The child block to be added.
   * @returns {void}
   */
  addToCreateChildBlocks(state, block) {
    if (!block) return;
    const index = state.toCreateChildBlocks.findIndex(
      (x) => x.id === block?.id
    );
    if (index != -1) {
      state.toCreateChildBlocks.splice(index, 1, block);
    } else {
      state.toCreateChildBlocks.push(block);
    }
  },

  /**
   * Removes a child block from the list of child blocks to be created.
   *
   * @param {string} payload.parentId - The ID of the parent block.
   * @param {string} payload.device - The device for which the child block is removed.
   * @returns {void}
   */
  removeToCreateChildBlock(state, { parentId, device }) {
    const index = state.toCreateChildBlocks
      .findIndex(b => b.linked_block_id === parentId && b.platform_type === device);
    if (index != -1) {
      state.toCreateChildBlocks.splice(index, 1);
    }
  },

  /**
   * Removes all child blocks from the list of child blocks to be created.
   *
   * @returns {void}
   */
  removeToCreateChildBlocks(state) {
    state.toCreateChildBlocks = [];
  },

  /*********** update blocks ***********/

  /**
   * Adds a block to the list of blocks to be updated.
   *
   * @param {Object} block - The block to be updated.
   * @returns {void}
   */
  addToUpdateBlocks(state, block) {
    if (!block) return;
    const index = state.toUpdateBlocks.findIndex(
      (x) => x?.id === block?.id
    );
    if (index != -1) {
      state.toUpdateBlocks.splice(index, 1, block);
    } else {
      state.toUpdateBlocks.push(block);
    }
  },

  /**
   * Removes a block from the list of blocks to be updated.
   *
   * @param {string} block_id - The ID of the block to be removed.
   * @returns {void}
   */
  removeToUpdateBlock(state, block_id) {
    const index = state.toUpdateBlocks.findIndex(
      (x) => x.id === block_id
    );
    if (index != -1) {
      state.toUpdateBlocks.splice(index, 1);
    }
  },

  /**
   * Updates a block in the list of current blocks for a specific device.
   *
   * @param {Object} block - The block to be updated.
   * @returns {void}
   */
  updateToCurrentBlocks(state, block) {
    const blocks = state.currentBlocks?.[block.device] ?? [];
    const index = blocks.findIndex((b) => b.id === block.id);
    if (index != -1) {
      blocks.splice(index, 1, block);
    }
  },

  /**
   * Updates a block in the list of current blocks and saved blocks for a specific device.
   *
   * @param {Object} block - The block to be updated.
   * @returns {void}
   */
  updateCurrentAndSavedBlock(state, block) {
    const blocks = state.currentBlocks?.[block.device] ?? [];
    const index = blocks.findIndex((b) => b.id === block.id);
    if (index != -1) {
      block.rank = blocks[index].rank;
      blocks.splice(index, 1, block);
      state.savedBlocks[block.device] = cloneDeep(blocks);
    }
  },

  /**
   * Updates a new block in the list of current blocks and saved blocks based on linked platforms.
   *
   * @param {string} payload.blockId - The ID of the block to be updated.
   * @param {Object} payload.newBlock - The new block to be added.
   * @returns {void}
   */
  updateNewBlockToCurrentAndSavedBlocks(state, { blockId, newBlock }) {
    if (!newBlock || newBlock?.platform_linked?.length == 0 || !blockId) return;
    newBlock.platform_linked.forEach((linked) => {
      const blocks = state.currentBlocks?.[linked.platform_type] ?? [];
      const index = blocks.findIndex((b) =>
        b.id === blockId || b.linked_block_id === blockId
      );
      if (index != -1) {
        if (linked.is_parent_block) {
          blocks.splice(index, 1, newBlock);
        } else {
          let block = blocks[index];
          blocks.splice(index, 1, {
            ...block,
            id: linked.block_id,
            linked_block_id: newBlock.id,
          });
        }
        state.savedBlocks[linked.platform_type] = cloneDeep(blocks);
      }
    });
  },

  /*********** delete blocks ***********/

  /**
   * Adds a block to the list of blocks to be deleted.
   *
   * @param {Object} block - The block to be deleted.
   * @returns {void}
   */
  addToDeleteBlock(state, block) {
    if (!block) return;
    const index = state.toDeleteBlocks.findIndex(
      (b) => b.block_id === block?.block_id
    );
    if (index == -1) {
      state.toDeleteBlocks.push(block);
    }
  },

  /**
   * Removes all blocks from the list of blocks to be deleted.
   *
   * @returns {void}
   */
  removeToDeleteBlocks(state) {
    state.toDeleteBlocks = [];
  },

  /**
   * Removes a specific block from the list of blocks to be deleted.
   *
   * @param {string} block_id - The ID of the block to be removed.
   * @returns {void}
   */
  removeToDeleteBlock(state, block_id) {
    const index = state.toDeleteBlocks.findIndex(
      (b) => b.block_id === block_id
    );
    if (index != -1) {
      state.toDeleteBlocks.splice(index, 1);
    }
  },

  /*********** Rank : add, update  ***********/

  /**
   * Adds a block to the list of blocks with updated ranks.
   *
   * @param {Object} block
   * @returns {void}
   */
  addToUpdateBlockRank(state, block) {
    if (!block) return;
    const index = state.toUpdateBlocksRank.findIndex(
      (x) => x.id === block?.id
    );
    if (index != -1) {
      state.toUpdateBlocksRank.splice(index, 1, block);
    } else {
      state.toUpdateBlocksRank.push(block);
    }
  },

  /**
   * Updates the rank of a block in the list of blocks to be created.
   *
   * @param {Object} payload.block - The block to be updated.
   * @param {number} payload.rank - The new rank value.
   * @param {string} payload.device - The device for which the block is updated.
   * @returns {void}
   */
  updateRanktoCreate(state, { block, rank, device }) {
    if (!block || !rank || !device) return;
    const index = state.toCreateBlocks
      .findIndex(b => b.id === block.id || b.id === block.linked_block_id);
    if (index != -1) {
      try {
        const platform = state.toCreateBlocks[index].platform_linked
          .find((platform) => platform.platform_type === device);
        if (platform) {
          // parent block
          if (platform.is_parent_block) {
            state.toCreateBlocks[index].rank = rank;
          }
          platform.rank = rank;
        }
      } catch (err) {
        console.log(err);
      }
    }
  },

  /**
   * Removes all blocks with updated ranks from the list.
   *
   * @returns {void}
   */
  removeToUpdateBlockRanks(state) {
    state.toUpdateBlocksRank = [];
  },

};