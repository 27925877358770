/**
 * @prettier
 */

export const ATTACHMENT_RESTRICTION_TYPE_FREE_WITHOUT_REGISTRATION =
  'free_without_registration';
export const ATTACHMENT_RESTRICTION_TYPE_FREE_WITH_REGISTRATION =
  'free_with_registration';
export const ATTACHMENT_RESTRICTION_TYPE_SVOD = 'svod';
export const ATTACHMENT_RESTRICTION_TYPE_SVOD_AND_TVOD = 'svod_and_tvod';
export const ATTACHMENT_RESTRICTION_TYPE_TVOD = 'tvod';

export const attachmentFilesDefaultMimeTypes = [
  'application/pdf',
  'application/zip',
  'application/x-zip-compressed',
];

export const attachmentFilesRestrictionsTypes = [
  {
    id: ATTACHMENT_RESTRICTION_TYPE_FREE_WITHOUT_REGISTRATION,
    title: 'attachments.free_without_registration',
  },
  {
    id: ATTACHMENT_RESTRICTION_TYPE_FREE_WITH_REGISTRATION,
    title: 'attachments.free_with_registration',
  },
  {
    id: ATTACHMENT_RESTRICTION_TYPE_SVOD,
    title: 'attachments.svod',
  },
  {
    id: ATTACHMENT_RESTRICTION_TYPE_TVOD,
    title: 'attachments.tvod',
  },
  {
    id: ATTACHMENT_RESTRICTION_TYPE_SVOD_AND_TVOD,
    title: 'attachments.svod_tvod',
  },
];

export const attachmentFilesRestrictionsRanking = {
  ATTACHMENT_RESTRICTION_TYPE_FREE_WITHOUT_REGISTRATION: 1,
  ATTACHMENT_RESTRICTION_TYPE_FREE_WITH_REGISTRATION: 2,
  ATTACHMENT_RESTRICTION_TYPE_SVOD: 3,
  ATTACHMENT_RESTRICTION_TYPE_TVOD: 4,
  ATTACHMENT_RESTRICTION_TYPE_SVOD_AND_TVOD: 5,
};
