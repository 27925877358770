import actions from './metadataDetail/actions.js';
import getters from './metadataDetail/getters.js';
import mutations from './metadataDetail/mutations.js';
import state from './metadataDetail/state.js';

export const metadataDetails = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};