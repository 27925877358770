export class MetadataOption {
  constructor({
    id = Math.random().toString(36).substr(2, 16),
    title = '',
    slug = 'preview',
    description = '',
    picture = {},
    linkedContents = [],
    linkedContentsCount = 0,
    firstname = '',
    lastname = '',
    jobs = [],
    external_link = null,
  } = {}) {
    this.id = id;
    this.title = title;
    this.slug = slug;
    this.description = description ?? '';
    this.picture = Array.isArray(picture) ? {} : picture;
    this.linkedContents = linkedContents;
    this.linkedContentsCount = linkedContentsCount;
    this.external_link = external_link;

    //for person type metadata
    this.firstname = firstname;
    this.lastname = lastname;
    this.setTypes(jobs);
  }

  setTypes(jobs) {
    var person_types = [];
    jobs.forEach(function (job) {
      person_types.push(job.id);
    });
    this.person_types = person_types;
  }
}

export default MetadataOption;
