<template>
  <div
    class="pie-chart chart__wrapper"
    :class="wrapperClass"
    @mousemove="moveTooltip($event)"
  >
    <div ref="chart" class="chart" :style="chartStyle" />
    <ChartLegend :data="data" />
    <transition name="zoom">
      <KpiTooltip
        v-if="tooltip"
        :item="tooltip"
        kpi-type="transactions"
        :position="tooltipPosition"
      />
    </transition>
  </div>
</template>

<script>
//import * as d3Collection from "d3-collection";
import * as d3 from 'd3';
import ChartLegend from './ChartLegend.vue';
import KpiTooltip from '@/components/statistics/KpiTooltip.vue';

//const d3 = Object.assign({}, d3Core, d3Collection);

export default {
  name: 'PieChart',
  components: { ChartLegend, KpiTooltip },
  props: {
    data: {
      type: [Array, Object],
      default: () => [],
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    legendItemsPerLine: {
      type: Number,
      default: 1,
    },
    legendPosition: {
      type: String,
      default: 'right',
    },
    overlayValues: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tooltip: null,
      tooltipPosition: {
        top: 0,
        left: 0,
      },
    };
  },
  computed: {
    keys() {
      return this.data.items.map((item) => item.key);
    },
    chartStyle() {
      return { width: this.width + 'px', height: this.height + 'px' };
    },
    wrapperClass() {
      let wrapperClass = '';
      switch (this.legendPosition) {
        case 'right':
          wrapperClass = 'flex-row';
          break;
        case 'left':
          wrapperClass = 'flex-row-reverse';
          break;
        case 'bottom':
          wrapperClass = 'flex-column';
          break;
        case 'top':
          wrapperClass = 'flex-column-reverse';
          break;
      }
      return wrapperClass;
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.render();
      },
    },
  },
  mounted() {
    this.render();
  },
  methods: {
    mouseover(event, p) {
      document.querySelector('.pie-chart').classList.add('path-hovered');
      event.target.classList.add('path-hovered');
      const detail = {
        name: p.data.key,
        color: p.data.color,
        value: p.data.value / 100,
      };
      this.tooltip = {
        detail,
      };
    },
    hideTooltip(event) {
      document
        .querySelectorAll('.chart__wrapper')
        .forEach((chart) => chart.classList.remove('path-hovered'));
      this.hoveredItem = false;
      this.tooltip = null;

      if (typeof event !== 'undefined') {
        event.target.classList.remove('path-hovered');
      }
    },
    moveTooltip(event) {
      this.tooltipPosition.left = event.pageX;
      this.tooltipPosition.top = event.pageY - window.scrollY;
    },
    getLegendPosition(type, mode, i, radius, height, width) {
      const lineCount = Math.floor(i / this.legendItemsPerLine);
      const positionInLine = i - lineCount * this.legendItemsPerLine;
      if (type === 'x') {
        if (this.legendItemsPerLine > 1) {
          if (mode === 'dot') {
            return -(width / 2) + 20 + positionInLine * 120;
          } else {
            return -(width / 2) + 35 + positionInLine * 120;
          }
        } else {
          return mode === 'dot' ? -30 : -15;
        }
      } else if (type === 'y') {
        if (mode === 'dot') {
          return radius + 15 + lineCount * 30;
        } else {
          return radius + 15 + 5 + lineCount * 30;
        }
      }
    },
    render() {
      const lineLegendHeight = 35;
      const nbLinesInLegend =
        Math.floor((this.keys.length - 1) / this.legendItemsPerLine) + 1;
      const marginBottom = 0; //lineLegendHeight * nbLinesInLegend;

      const graphWidth = this.width;
      const graphHeight = this.height - marginBottom;
      const radius = Math.min(graphHeight, graphWidth) / 2;

      // clear
      d3.select(this.$refs.chart).selectAll('*').remove();

      const svg = d3
        .select(this.$refs.chart)
        .append('svg')
        .attr('width', this.width)
        .attr('height', this.height)
        .append('g')
        .attr(
          'transform',
          'translate(' + graphWidth / 2 + ',' + graphHeight / 2 + ')'
        );

      const color = d3.scaleOrdinal(d3.schemeCategory10);

      const pie = d3
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return d.value;
        });
      const data_ready = pie(this.data.items);

      const arc = d3
        .arc()
        .innerRadius(radius * 0)
        .outerRadius(radius * 1);

      svg
        .selectAll('allSlices')
        .data(data_ready)
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('fill', function (d, i) {
          return d.data.color;
          //return color(i);
        })
        //.attr("stroke", "white")
        //.style("stroke-this.width", "1px")
        .style('opacity', 1)
        .on('mouseover', this.mouseover)
        .on('mouseout', this.hideTooltip);

      svg
        .selectAll('mySlices')
        .data(data_ready)
        .enter()
        .append('text')
        .text(function (d) {
          if (this.overlayValues && d.data.value > 15) {
            return d.data.value + '%';
          } else {
            return '';
          }
        })
        .attr('transform', function (d) {
          return 'translate(' + arc.centroid(d) + ')';
        })
        .style('text-anchor', 'middle')
        .style('font-size', 14);

      svg
        .selectAll('mydots')
        .data(data_ready)
        .enter()
        .append('circle')
        .attr('cx', (d, i) => {
          return this.getLegendPosition(
            'x',
            'dot',
            i,
            radius,
            this.height,
            this.width
          );
        })
        .attr('cy', (d, i) => {
          return this.getLegendPosition(
            'y',
            'dot',
            i,
            radius,
            this.height,
            this.width
          );
        })
        .attr('r', 8)
        .style('fill', (d, i) => {
          return d.data.color;
          //return color(i);
        });

      // Add one dot in the legend for each name.
      svg
        .selectAll('mylabels')
        .data(this.keys)
        .enter()
        .append('text')
        .attr('x', (d, i) => {
          return this.getLegendPosition(
            'x',
            'label',
            i,
            radius,
            this.height,
            this.width
          );
        })
        .attr('y', (d, i) => {
          return this.getLegendPosition(
            'y',
            'label',
            i,
            radius,
            this.height,
            this.width
          );
        })
        .style('fill', (d, i) => {
          return '#000';
          //return color(i);
        })
        .text((d) => {
          return d;
        })
        .attr('text-anchor', 'left')
        .style('alignment-baseline', 'middle')
        .style('font-size', '14px')
        .style('font-style', 'italic');
    },
  },
};
</script>
<style lang="scss">
@import 'pie_chart.scss';
</style>
