import { camelCase } from 'lodash/string.js';
import { cloneDeep } from 'lodash';
import { difference } from '@/utils/diffTools';
import { MetadataDetailTransformer } from '@/transformers';
import MetadataDetail from '@/models/MetadataDetail';
import MetadataOption from '@/models/MetadataOption';
import Movie from '@/models/Movie';
import PersonType from '@/models/PersonType';

export default {

  updateFields(state, { field, value }) {
    if (field) {
      state.currentNewMetadataDetail[field] = value;
    }
  },

  updateReload(state, value) {
    state.reload = value;
  },

  resetAll(state) {
    if (state.all.length > 0) {
      state.all = [];
    }
  },

  resetNewMetadataDetail(state) {
    state.currentNewMetadataDetail = new MetadataDetail();
    state.savedNewMetadataDetail = cloneDeep(state.currentNewMetadataDetail);
    state.formatedCurrentNewMetadataDetail = {};
  },

  setAll(state, metadatasDetails) {
    state.all = metadatasDetails?.length > 0
      ? metadatasDetails.map((metadataDetails) => {
        return new MetadataDetail(metadataDetails);
      })
      : [];
    state.savedAll = cloneDeep(state.all);
    state.loading = false;
  },

  updateAll(state, metadatasDetails) {
    if (metadatasDetails) {
      state.all = metadatasDetails;
      state.savedAll = cloneDeep(state.all);
    }
    state.loading = false;
  },

  setLoading(state, value) {
    state.loading = value;
  },

  getSuccess(state, metadataDetail) {
    if (metadataDetail) {
      state.currentNewMetadataDetail = new MetadataDetail(metadataDetail);
      state.savedNewMetadataDetail = cloneDeep(state.currentNewMetadataDetail);
      state.formatedCurrentNewMetadataDetail = {};
    }
    state.loading = false;
  },

  getFailure(state) {
    state.currentNewMetadataDetail = new MetadataDetail();
    state.savedNewMetadataDetail = cloneDeep(state.currentNewMetadataDetail);
    state.loading = false;
  },

  createRequest(state, { allMetadatas, params }) {
    if (allMetadatas?.length > 0 && params?.type && params?.name) {
      state.loading = true;
      let newMetadataDetail = new MetadataDetail();
      newMetadataDetail.name = params.name;
      newMetadataDetail.title = params.name;
      newMetadataDetail.label = camelCase(params.name);
      newMetadataDetail.type = params.type;
      delete newMetadataDetail.id;
      newMetadataDetail.setMetadataId(allMetadatas, params.type, params.subId);
      state.formatedCurrentNewMetadataDetail = cloneDeep(newMetadataDetail);
    }
  },

  createSuccess(state, payload) {
    if (payload) {
      const newModel = new MetadataDetail(payload);
      state.currentNewMetadataDetail = newModel;
      state.currentNewMetadataDetail = cloneDeep(newModel);
      state.all.push(newModel);
    }
    state.loading = false;
  },

  putAllRequest(state, values) {
    if (values?.length > 0) {
      state.loading = true;
      let rank = 1;
      values.forEach((value) => {
        state.formattedAll.push({
          id: value.id,
          rank: rank++,
        });
      });
    }
  },

  putAllFailure(state) {
    state.all = [];
    state.savedAll = [];
    state.formattedAll = [];
    state.loading = false;
  },

  putRequest(state) {
    if (state.currentNewMetadataDetail) {
      state.loading = true;
      const metadataDetailTransformer =
        new MetadataDetailTransformer(state.currentNewMetadataDetail);
      state.formatedCurrentNewMetadataDetail =
        metadataDetailTransformer.transform();
      state.loading = false;
    }
  },

  putSuccess(state, metadataDetail) {
    if (!metadataDetail) return;
    const metadata = new MetadataDetail(metadataDetail);
    let index = state.all.findIndex((x) => x.id === metadata.id);
    if (index != -1) {
      let copy = cloneDeep(state.all);
      copy.splice(index, 1, metadata);
      state.all = cloneDeep(copy);
      state.savedAll = cloneDeep(copy);
    }
    state.currentNewMetadataDetail = metadata;
    state.savedNewMetadataDetail = cloneDeep(metadata);
    state.formatedCurrentNewMetadataDetail = {};
    state.loading = false;
  },

  removeSuccess(state) {
    const index = state.all.findIndex(
      (x) => x.id === state.currentNewMetadataDetail?.id
    );
    if (index != -1) {
      state.all.splice(index, 1);
    }
    state.loading = false;
  },

  /******************************************************************************************** OPTIONS ********************************************************************************************/

  setSearchLoading(state, value) {
    state.searchLoading = value;
  },

  searchOptionsSuccess(state, payload) {
    state.options = cloneDeep(payload);
    state.searchLoading = false;
  },
  resetOptions(state) {
    state.options = {
      data: [],
      meta: {
        pagination: {
          count: 0,
          current_page: 1,
          per_page: 0,
          total: 0,
          total_pages: 0,
        },
      },
    };
    state.searchLoading = false;
  },

  setOption(state, payload = {}) {
    state.currentOption = new MetadataOption(payload);
    state.savedOption = cloneDeep(state.currentOption);
  },

  setOptionsLinkedContentCount(state, value) {
    state.currentOption.linkedContentsCount = value;
    state.savedOption.linkedContentsCount = value;
  },

  updateOptionRequest(state) {
    state.formattedOption = cloneDeep(state.currentOption);
    state.formattedOption.label = state.formattedOption.title;
    for (const [key, _] of Object.entries(state.formattedOption.picture)) {
      delete state.formattedOption.picture[key][0].url;
    }
    state.loading = true;
  },

  updateOptionSuccess(state, payload) {
    const metadataOption = new MetadataOption(payload);
    state.currentOption = metadataOption;
    state.savedOption = cloneDeep(metadataOption);
    state.formattedOption = new MetadataOption({});
    state.loading = false;
    state.reload = true;
  },

  updateOptionFailure(state) {
    state.formattedOption = new MetadataOption({});
    state.loading = false;
  },

  createOptionRequest(state) {
    state.formattedOption = cloneDeep(state.currentOption);
    state.formattedOption.label = state.formattedOption.title;
    state.formattedOption.metadata_detail_id =
      state.currentNewMetadataDetail.id;
    delete state.formattedOption.id;
    state.loading = true;
  },

  createOptionSuccess(state, payload) {
    state.currentOption = new MetadataOption(payload);
    state.savedOption = cloneDeep(state.currentOption);
    state.formattedOption = new MetadataOption({});
    state.loading = false;
    state.reload = true;
  },

  createOptionFailure(state) {
    state.formattedOption = new MetadataOption();
    state.loading = false;
  },

  removeOptionRequest(state) {
    state.loading = true;
    state.options = [];
  },
  removeOptionSuccess(state) {
    state.currentOption = new MetadataOption({});
    state.savedOption = cloneDeep(state.currentOption);
    state.loading = false;
    state.reload = true;
  },
  removeOptionFailure(state) {
    state.currentOption = new MetadataOption({});
    state.savedOption = cloneDeep(state.currentOption);
    state.loading = false;
  },

  /***************************************************************************************** PERSON TYPES ******************************************************************************************/

  searchPersonTypeSuccess(state, payload) {
    let personTypes = payload.data.map((x) => new PersonType(x));
    state.allRoles = cloneDeep(personTypes);
    state.currentRole = new PersonType({});
    state.searchLoading = false;
  },
  searchPersonTypeFailure(state) {
    state.allRoles = [];
    state.currentRole = new PersonType({});
    state.searchLoading = false;
  },

  getPersonTypeRequest(state) {
    state.currentRole = new PersonType({});
    state.loading = true;
  },
  getPersonTypeSuccess(state, payload) {
    state.currentRole = new PersonType(payload);
    state.loading = false;
  },
  getPersonTypeFailure(state) {
    state.currentRole = new PersonType({});
    state.loading = false;
  },

  updatePersonTypeRequest(state) {
    state.formattedRole = cloneDeep(state.currentRole);
    state.formattedRole.name = state.formattedRole.title;
  },
  updatePersonTypeSuccess(state, payload) {
    let index = state.allRoles.findIndex((x) => x.id === payload.id);
    state.allRoles.splice(index, 1, new PersonType(payload));
    state.currentRole = new PersonType({});
    state.formattedRole = cloneDeep(state.currentRole);
  },
  updatePersonTypeFailure(state) {
    state.formattedRole = new PersonType();
  },

  createPersonTypeRequest(state) {
    state.formattedRole = cloneDeep(state.currentRole);
    state.formattedRole.label = state.formattedRole.title;
    state.formattedRole.metadata_detail_id = state.currentNewMetadataDetail.id;
    state.loading = true;
  },
  createPersonTypeSuccess(state, payload) {
    state.allRoles.push(new PersonType(payload));
    state.currentRole = new PersonType({});
    state.formattedRole = cloneDeep(state.currentRole);
    state.loading = false;
  },
  createPersonTypeFailure(state) {
    state.formattedRole = new PersonType({});
    state.loading = false;
  },

  removePersonTypeRequest(state) {
    state.loading = true;
  },
  removePersonTypeSuccess(state, id) {
    let index = state.allRoles.findIndex((x) => x.id === id);
    state.allRoles.splice(index, 1);
    state.currentRole = new PersonType({});
    state.formattedRole = cloneDeep(state.currentRole);
    state.loading = false;
    state.reload = true;
  },
  removePersonTypeFailure(state) {
    state.currentRole = new PersonType({});
    state.formattedRole = cloneDeep(state.currentRole);
    state.loading = false;
  },

  /**************************************************************************************** RELATED PROGRAMS ***************************************************************************************/

  searchProgramsSuccess(state, payload) {
    state.currentRelatedPrograms = payload.data.map((x) => new Movie(x));
    state.savedRelatedPrograms = cloneDeep(state.currentRelatedPrograms);
    state.searchLoading = false;
  },

  resetSearchPrograms(state) {
    state.currentRelatedPrograms = [];
    state.savedRelatedPrograms = [];
    state.searchLoading = false;
  },

  updateRelatedProgramsRequest(state) {
    state.searchLoading = true;
    let currentRelatedProgramIds =
      state.currentRelatedPrograms.map((x) => x.id);
    let savedRelatedProgramsProgramIds =
      state.savedRelatedPrograms.map((x) => x.id);
    let toCreate = difference(
      currentRelatedProgramIds,
      savedRelatedProgramsProgramIds
    );
    let toRemove = difference(
      savedRelatedProgramsProgramIds,
      currentRelatedProgramIds
    );

    toCreate = toCreate.filter((x) => x !== null);
    toRemove = toRemove.filter((x) => x !== null);

    state.formatedRelatedPrograms = [];
    if (toCreate.length > 0) {
      state.formatedRelatedPrograms.push({
        metadata_detail_id: state.currentNewMetadataDetail.id,
        metadatable_id: state.currentOption.id,
        action: 'add',
        program_ids: toCreate,
      });
    }
    if (toRemove.length > 0) {
      state.formatedRelatedPrograms.push({
        metadata_detail_id: state.currentNewMetadataDetail.id,
        metadatable_id: state.currentOption.id,
        action: 'delete',
        program_ids: toRemove,
      });
    }
  },
  updateRelatedProgramsSuccess(state) {
    state.searchLoading = false;
    state.savedRelatedPrograms = cloneDeep(state.currentRelatedPrograms);
  },

  /******************************************************************************************** OTHERS ********************************************************************************************/
  addProgram(state, programs) {
    let current = cloneDeep(state.currentRelatedPrograms);
    current = current.concat(programs);
    state.currentRelatedPrograms = current;
  },

  removeProgram(state, programId) {
    let programs = cloneDeep(state.currentRelatedPrograms);
    let index = programs.findIndex((x) => x.id === programId);
    if (!isNaN(index)) {
      programs.splice(index, 1);
    }
    state.currentRelatedPrograms = programs;
  },

  updateExternalLinkUrl(state, value) {
    state.currentOption.external_link.url = value;
  },

  updateExternalLinkTarget(state, value) {
    state.currentOption.external_link.target = value ? 'blank' : 'self';
  },

  updateOptionImage(state, payload) {
    const image = {
      ...payload,
    };
    if (payload.collectionName in state.currentOption.picture) {
      state.currentOption.picture[payload.collectionName].splice(0, 1);
    }
    state.currentOption.picture = Object.assign(
      {},
      state.currentOption.picture,
      { [payload.collectionName]: [image] }
    );
  },

  cleanMetadataDetailStates(state) {
    state.currentNewMetadataDetail = new MetadataDetail();
    state.formatedCurrentNewMetadataDetail =
      cloneDeep(state.currentNewMetadataDetail);
    state.savedNewMetadataDetail =
      cloneDeep(state.currentNewMetadataDetail);
    state.error = {};
  },

  cleanOptionStates(state) {
    state.currentOption = new MetadataOption({});
    state.savedOption = cloneDeep(state.currentOption);
    state.loading = false;
  },

  fullCleanMetadataDetailStates(state) {
    state.all = [];
    state.savedAll = [];
    state.formattedAll = [];
    state.currentRelatedPrograms = [];
    state.savedRelatedPrograms = [];
    state.formatedCurrentNewMetadataDetail = new MetadataDetail();
    state.currentNewMetadataDetail = cloneDeep(
      state.formatedCurrentNewMetadataDetail
    );
    state.savedNewMetadataDetail = cloneDeep(
      state.formatedCurrentNewMetadataDetail
    );
    state.currentOption = new MetadataOption();
    state.formattedOption = cloneDeep(state.currentOption);
    state.savedOption = cloneDeep(state.currentOption);
    state.loading = false;
    state.error = {};
  },

  setCurrentPersonTypeById(state, id) {
    if (isNaN(id)) {
      state.currentRole = cloneDeep(new PersonType());
    } else {
      let all = cloneDeep(state.allRoles);
      let currentRole = all.find((x) => x.id === parseInt(id));
      state.currentRole = cloneDeep(currentRole);
    }
  },

  setCurrentNewMetadataDetailsById(state, id) {
    let all = cloneDeep(state.all);
    state.currentNewMetadataDetail = all.find((x) => x.id === parseInt(id));
    state.savedNewMetadataDetail = cloneDeep(state.currentNewMetadataDetail);
  },

  setCurrentNewMetadataDetails(state, metadata) {
    state.currentNewMetadataDetail = cloneDeep(metadata);
    state.savedNewMetadataDetail = cloneDeep(metadata);
  },

  setError(state, error) {
    state.error[error.location] = error.message;
  },

  unsetError(state, location) {
    delete state.error[location];
  },
};