import store from '@/store/index.js';

export const AdminsUtil = {
  adminRolesByPermission(array) {
    //super admins - tech
    if (store.getters['auth/can']('admins.tech.access')) {
      return array;
    }
    //super admins - otto
    else if (store.getters['auth/can']('admins.otto.access')) {
      return array.filter((role) =>
        !['tech', 'admins'].includes(role.id)
      );
    }
    //super admins - client
    else {
      return array.filter((role) =>
        !['advanced-settings', 'tech', 'admins'].includes(role.id)
      );
    }
  }
};
export default AdminsUtil;