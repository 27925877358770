import {
  GenericContentService,
  GenericMediaService,
  GenericPlatformService,
  GenericUserService,
} from '@/services';
import { cloneDeep } from 'lodash';
import Picture from '@/models/Picture';

const state = {
  loading: false,
  savedAll: {},
  currentAll: {},
  currentNewPicture: {},
  savedNewPicture: {},
};

const getters = {
  loading: (state) => state.loading,
};

const actions = {
  create({ commit, dispatch }, picture) {
    commit('postRequest');
    let pictureService;
    picture.modelType = picture.itemType;
    delete picture.itemType;
    switch (picture.modelType) {
      case 'block':
      case 'item':
      case 'movie':
      case 'episode':
      case 'selection':
      case 'participant':
      case 'metadata':
        pictureService = new GenericContentService('medias/pictures');
        break;
      case 'user':
        pictureService = new GenericUserService('medias/pictures');
        break;
      case 'platform':
        pictureService = new GenericPlatformService('medias/pictures');
        break;
      case 'video':
        pictureService = new GenericMediaService('medias/pictures');
        break;
    }
    return pictureService.createPicture(picture).then(
      (picture) => {
        commit('postSuccess', picture);
        const alert = {
          id: 'picture-well-created',
          icon: 'check',
          type: 'valid',
          message: 'notifications.create.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(picture);
      },
      (error) => {
        commit('postFailure');
        const alert = {
          id: 'picture-not-created',
          icon: 'close',
          type: 'error',
          message: 'notifications.create.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  postRequest(state) {
    state.loading = true;
  },
  postSuccess(state, payload) {
    var picture = new Picture(payload);
    state.currentNewPicture = cloneDeep(picture);
    state.savedNewPicture = cloneDeep(picture);
    state.loading = false;
  },
  postFailure(state) {
    state.currentNewPicture = {};
    state.savedNewPicture = {};
    state.loading = false;
  },

  cleanPicturesStates(state) {
    state.savedAll = {};
    state.currentAll = {};
    state.currentNewPicture = {};
    state.savedNewPicture = {};
    state.loading = false;
  },
};

export const pictures = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
