import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/rentals';
const currentPath = import.meta.env.VITE_USER_BASE_URL + api_url_module;

async function get(list) {
  let queryString = '?with=details,defaults';
  if (list && list.length) {
    queryString += '&program_id=in:' + list.join();
  } else {
    queryString += '&model=all';
  }
  const headers = { headers: { transform: true } };

  try {
    const response = await axios.get(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module + queryString,
      headers
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function update(rental) {
  try {
    const response = await axios.put(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module + '/' + rental.id,
      rental
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function create(rental) {
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module,
      rental
    );
    return handleResponse(response.data);
  } catch (e) {
    return handleResponse(e);
  }
}

async function deleteRental(rental) {
  try {
    const response = await axios.delete(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module + '/' + rental.id,
      rental
    );
    if (response.status === 204) {
      return handleResponse({ status: 'success', results: { data: [] } }, 204);
    } else {
      return handleResponse(response.data);
    }
  } catch (e) {
    return handleResponse(e);
  }
}

export const rentalService = {
  get,
  create,
  update,
  deleteRental,
};
