<template>
  <div>
    <header id="header" class="main-header">
      <div class="main-header-logo p-4">
        <router-link to="/">
          <img alt="Otto logo" :src="getEnvLogo" class="main-header-logo-img" />
        </router-link>
      </div>
    </header>
    <div class="page login-page login-page--login min-h-screen">
      <div class="login-page-form">
        <div v-if="!password_created" class="login-page-form-container">
          <header class="page-header pb-6">
            <h1 class="page-title">
              <span v-html="$t('admin.resetPassword.title', {storeName: getPlatformNameToReset})" />
            </h1>
          </header>

          <form @keydown="errors.clear($event.target.name)" @submit.prevent>
            <input v-model="auth.token" type="hidden" name="token" />
            <input v-model="auth.platformId" type="hidden" name="platformId" />
            <FormControl
              label="admin.resetPassword.email"
              weight="medium"
              :error="errors.get('email')"
            >
              <FormInput
                id="email"
                v-model="auth.email"
                name="email"
                autofocus
                @reset="resetInput('email')"
              />
            </FormControl>
            <legend>{{ $t('admin.resetPassword.passwordSection')}}</legend>
            <FormControl
              label="admin.resetPassword.password"
              weight="medium"
              class="pb-6"
              :error="errors.get('password')"
              :hint="passwordSpecMessage"
            >
              <FormInput
                id="password"
                v-model="auth.password"
                type="password"
                name="password"
                disclosable
                @reset="resetInput('password')"
              />
            </FormControl>
            <FormControl
              label="admin.resetPassword.passwordConfirmation"
              weight="medium"
              class="pb-6"
              :error="errors.get('password_confirmation')"
            >
              <FormInput
                id="password_confirmation"
                v-model="auth.password_confirmation"
                type="password"
                name="password_confirmation"
                disclosable
                @reset="resetInput('password_confirmation')"
              />
            </FormControl>
            <div class="form-input">
              <div class="form-input-container">
                <BaseButton
                  label="admin.resetPassword.reset"
                  size="lg"
                  class="w-full"
                  :disabled="isFormInvalid"
                  :loading="isLoading"
                  @click.prevent="handleSubmit()"
                />
              </div>
            </div>
          </form>
        </div>
        <div v-else>
          <header class="page-header">
            <h1 class="page-title">Félicitations !</h1>
          </header>
          <p class="page-description">Votre mot de passe à bien été modifié.</p>
          <BaseButton
            label="Retour à l'écran de connexion"
            size="lg"
            @click.prevent="goToLogin()"
          />
        </div>
      </div>
      <div
        class="login-page-illustration"
        :class="{
          'login-page-illustration--logging': !password_created,
          'login-page-illustration--success': password_created,
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import BaseButton from '@/components/button/BaseButton.vue';
import Errors from '@/utils/Errors';
import FormControl from '@/components/form/FormControl.vue';
import FormInput from '@/components/form/FormInput.vue';

export default {
  name: 'PasswordReset',
  components: {
    BaseButton,
    FormControl,
    FormInput,
  },
  data() {
    return {
      passwordConfig: {
        min_length: 10,
        must_have_number: true,
        must_have_letter: true,
        must_have_mixed_case: true,
        must_have_symbol: true,
      },
      minAdminPasswordLength: 10,
      auth: {
        email: '',
        password: '',
        password_confirmation: '',
        token: this.$route.params.token,
        platformId: this.$route.params.platformId,
      },
      password_created: false,
      errors: new Errors(),
    };
  },
  computed: {
    isFormInvalid() {
      return (
        !this.auth.email ||
        !this.isPasswordValid ||
        this.errors.any()
      );
    },
    isPasswordValid() {
      return !(
        (this.auth.password.length < this.passwordConfig.min_length) ||
        (this.passwordConfig.must_have_number && ! /[0-9]/.test(this.auth.password)) ||
        (this.passwordConfig.must_have_letter && ! /[A-Za-zÀ-ÖØ-öø-ÿ]/.test(this.auth.password)) ||
        (this.passwordConfig.must_have_mixed_case && ! /[a-z].*[A-Z]|[A-Z].*[a-z]/.test(this.auth.password)) ||
        (this.passwordConfig.must_have_symbol && ! /[\s!"#$%&\'()*+,-.\/:;<=>?@\[\]^_`{|}~]/.test(this.auth.password)) ||
        (this.passwordConfig.must_not_have_repetition && /^(.)\1+$/.test(this.auth.password)) ||
        this.auth.password !== this.auth.password_confirmation
      );
    },
    getEnvLogo() {
      return `/logos/otto.${import.meta.env.VITE_ENV}.png`;
    },
    passwordSpecMessage() {
      let sentence = this.$t('users.pages.user.modal.password.input.hint.length', this.passwordConfig.min_length, { n: this.passwordConfig.min_length });
      sentence += this.passwordConfig.must_have_number ? ', ' + this.$t('users.pages.user.modal.password.input.hint.number') : '';
      sentence += this.passwordConfig.must_have_letter ? ', ' + this.$t('users.pages.user.modal.password.input.hint.letter') : '';
      sentence += this.passwordConfig.must_have_mixed_case ? ', ' + this.$t('users.pages.user.modal.password.input.hint.case') : '';
      sentence += this.passwordConfig.must_have_symbol ? ', ' + this.$t('users.pages.user.modal.password.input.hint.symbole') : '';

      sentence = sentence.replace(', ', ' ' + this.$t('users.pages.user.modal.password.input.hint.link') + ' ')
      let lastIndexOfTerm = sentence.lastIndexOf(', ');
      if (lastIndexOfTerm !== -1) {
        sentence = sentence.substring(0, lastIndexOfTerm) + sentence.substring(lastIndexOfTerm).replace(', ', ' & ');
      }

      return sentence;
    },
    ...mapFields(['platforms.selectedPlatformID']),
    ...mapGetters({
      isLoading: 'auth/isLoading',
      getCurrentPlatformName: 'platforms/getCurrentPlatformName',
      getPlatformNameToReset: 'platforms/getPlatformNameToReset',
    }),
  },
  methods: {
    handleSubmit() {
      this.resetPassword(this.auth)
        .then(() => (this.password_created = true))
        .catch((error) => {
          this.errors.format(error);
        });
    },
    resetInput(field) {
      this.auth[field] = '';
      this.errors.clear(field);
    },
    goToLogin() {
      this.$router.push('/').catch((e) => console.log(e));
    },
    ...mapMutations('platforms', ['setSelectedPlatformID']),
    ...mapActions('auth', ['resetPassword']),
  },
  watch: {
    selectedPlatformID() {
      this.setSelectedPlatformID(this.selectedPlatformID);
    },
  },
  created() {
    this.selectedPlatformID = this.$route.params.platformId;
  },
};
</script>