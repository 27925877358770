<template>
  <header
    id="page-header"
    ref="pageHeader"
    class="page-header min-h-10 flex flex-col items-start py-4 lg:py-6 z-40 bg-skin-background bg-opacity-100 backdrop-blur-md"
  >
    <slot />
  </header>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { useResizeObserver } from '@vueuse/core';

const pageHeader = ref(null);
let pageContent;
const pageHeaderHeight = ref(0);
onMounted(() => {
  pageContent = document.querySelector('.page-content');
  useResizeObserver(pageHeader, (entries) => {
    const entry = entries[0] ?? null;
    pageHeaderHeight.value = entry?.borderBoxSize?.[0]?.blockSize ?? 0;
    if (pageContent) {
      pageContent.style = `--page-header-height: ${
        pageHeaderHeight.value + 60
      }px`;
      pageContent.setAttribute(
        'data-page-header-height',
        `${pageHeaderHeight.value + 59}px`
      );
    }
  });
});
</script>
