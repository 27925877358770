import { GenericContentService } from '@/services';
import { LightModel } from '@/models/content/LightModel';
import { cloneDeep, isEmpty } from 'lodash';
import { hasDifference } from '@/utils/diffTools';

const groupPlanService = new GenericContentService('group-plans');

const state = {
  loading: false,

  allFormated: {},
  allCurrent: {
    data: [],
    meta: {
      pagination: {
        total_pages: 0,
        per_page: 0,
        total: 0,
        count: 0,
        current_page: 1,
      },
    },
  },
  allSaved: {
    data: [],
    meta: {
      pagination: {
        total_pages: 0,
        per_page: 0,
        total: 0,
        count: 0,
        current_page: 1,
      },
    },
  },
};

const getters = {
  loading: (state) => {
    return state.loading;
  },
  shouldSaveAll: (state) => {
    let currentIds = state.allCurrent.data
      .map((x) => x.id)
      .sort()
      .join('-');
    let savedIds = state.allSaved.data
      .map((x) => x.id)
      .sort()
      .join('-');
    return currentIds !== savedIds;
  },
};

const actions = {
  search(
    { commit },
    {
      id,
      page = 1,
      model = 'program',
      keyword = '',
      facets = [],
      sort = 'id:desc',
    }
  ) {
    commit('searchRequest');
    let queryString = 'count=10&page=' + page + '&sort=' + sort;
    if (keyword.length > 0) {
      queryString += '&query=' + keyword;
    }
    if (facets.length > 0) {
      let filters = [];
      facets.forEach((facet) => {
        if (typeof filters[facet.facet_id] === 'undefined') {
          filters[facet.facet_id] = [];
        }
        filters[facet.facet_id].push(facet.id);
      });
      Object.keys(filters).forEach(function (key) {
        queryString +=
          '&' +
          key +
          '=' +
          (filters[key].length > 1
            ? 'in:' + filters[key].join(',')
            : filters[key][0]);
      });
    }
    return groupPlanService.get(id + '/' + model, queryString).then(
      (groupPlans) => {
        commit('searchSuccess', groupPlans);
        return Promise.resolve(groupPlans);
      },
      (error) => {
        commit('searchFailure', error);
        return Promise.reject(error);
      }
    );
  },
  update({ state, commit }, id) {
    commit('updateRequest');
    if (state.allFormated.actions.length === 0) {
      return Promise.resolve();
    }
    return groupPlanService.update(state.allFormated, id).then(
      (groupPlanContent) => {
        commit('setLoading', false);
        return Promise.resolve(groupPlanContent);
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  reset(state) {
    state.allCurrent = {
      data: [],
      meta: {
        pagination: {
          total_pages: 0,
          per_page: 0,
          total: 0,
          count: 0,
          current_page: 1,
        },
      },
    };
    state.allSaved = {
      data: [],
      meta: {
        pagination: {
          total_pages: 0,
          per_page: 0,
          total: 0,
          count: 0,
          current_page: 1,
        },
      },
    };
    state.allFormated = {};
    state.loading = false;
  },
  setLoading(state, value) {
    state.loading = value;
  },

  searchRequest(state) {
    state.loading = true;
    state.allCurrent = {
      data: [],
      meta: {
        pagination: {
          total_pages: 0,
          per_page: 0,
          total: 0,
          count: 0,
          current_page: 1,
        },
      },
    };
    state.allSaved = {
      data: [],
      meta: {
        pagination: {
          total_pages: 0,
          per_page: 0,
          total: 0,
          count: 0,
          current_page: 1,
        },
      },
    };
  },
  searchSuccess(state, groupPlans) {
    let datasBrut = cloneDeep(groupPlans);
    let datasClean = [];

    datasBrut.data.forEach(function (dataBrut) {
      datasClean.push(new LightModel(dataBrut));
    });

    let all = {
      data: cloneDeep(datasClean),
      meta: { pagination: {} },
    };

    all.meta.pagination.total_pages =
      datasBrut.meta.pagination.total_pages ?? 1;
    all.meta.pagination.per_page = datasBrut.meta.pagination.per_page ?? 10;
    all.meta.pagination.total = datasBrut.meta.pagination.total ?? 0;
    all.meta.pagination.count = datasBrut.meta.pagination.count ?? 0;
    all.meta.pagination.current_page =
      datasBrut.meta.pagination.current_page ?? 0;
    state.allCurrent = cloneDeep(all);
    state.allSaved = cloneDeep(all);
    state.loading = false;
  },
  searchFailure(state) {
    state.allCurrent = {
      data: [],
      meta: {
        pagination: {
          total_pages: 0,
          per_page: 0,
          total: 0,
          count: 0,
          current_page: 1,
        },
      },
    };
    state.allSaved = {
      data: [],
      meta: {
        pagination: {
          total_pages: 0,
          per_page: 0,
          total: 0,
          count: 0,
          current_page: 1,
        },
      },
    };
    state.loading = false;
  },

  updateRequest(state) {
    state.loading = true;
    let currentIds = state.allCurrent.data.map((x) => x.id);
    let savedIds = state.allSaved.data.map((x) => x.id);
    let toAdd = [];
    let toRemove = [];

    currentIds.forEach(function (o) {
      if (!savedIds.includes(o)) {
        toAdd.push(o);
      }
    });

    savedIds.forEach(function (o) {
      if (!currentIds.includes(o)) {
        toRemove.push(o);
      }
    });

    let payload = { actions: [] };

    if (toAdd.length > 0) {
      payload.actions.push({
        type: 'add',
        model_type: 'program',
        ids: toAdd,
      });
    }
    console.log(payload);

    if (toRemove.length > 0) {
      payload.actions.push({
        type: 'remove',
        model_type: 'program',
        ids: toRemove,
      });
    }

    state.allFormated = payload;
  },

  add(state, list) {
    const contents = cloneDeep(state.allCurrent.data);
    list.forEach(function (one) {
      if (contents.findIndex((x) => x.id === one.id) < 0) {
        contents.push(new LightModel(one));
      }
    });
    state.allCurrent.data = contents;
  },
  remove(state, id) {
    const plans = cloneDeep(state.allCurrent.data);
    state.allCurrent.data = plans.filter((x) => x.id !== id);
  },
};

export const groupPlansContent = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
