import { Buffer } from 'buffer';
import { cloneDeep } from 'lodash';
import S3Uploader from '@/services/aws/s3uploader.service';

const state = {
  loading: false,
  s3uploader: null,
};

const actions = {
  uploadFile({ commit, dispatch, rootState, state }, { file, path = null }) {
    commit('setLoading', true);
    commit('initS3Uploader');
    let s3uploader = cloneDeep(state.s3uploader);

    const mimeType = file?.url
      ? file.url.substring(0, file.url.indexOf(';base64,')).replace('data:', '')
      : file.type;

    const extension = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
        'application/vnd.ms-excel'
      ].includes(mimeType)
        ? 'xlsx' 
        : mimeType.split('/')[1];

    let _path = null;
    if (path) {
      const random = Math.floor(Math.random() * Date.now());
      const userID = rootState.auth.authUser.id;
      const fileName = file?.name?.split('.')?.[0].replaceAll(' ', '_') ?? '';
      _path = `${path}/${fileName}_${random}_${userID}.${extension}`;
    }
    
    const decodedFile = file?.url?.includes('base64') 
      ? Buffer.from(file.url.replace('data:' + mimeType + ';base64,', ''), 'base64')
      : file
      
    return s3uploader
      .uploadFile({
          file: decodedFile,
          mimeType: mimeType,
          platformId: rootState.platforms.currentPlatform.id,
          extension: extension,
        }, 
        _path
      ).then(
        (upload) => {
          const alert = {
            id: 'upload-success',
            icon: 'check',
            type: 'valid',
            message: file.alerts
              ? file.alerts.success
              : 'notifications.s3.success',
          };
          dispatch('displayAlert', alert, { root: true });
          commit('setLoading', false);
          return Promise.resolve(upload);
        },
        (error) => {
          const alert = {
            id: 'upload-failed',
            icon: 'close',
            type: 'error',
            message: file.alerts ? file.alerts.error : 'notifications.s3.error',
          };
          commit('setLoading', false);
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },
  removeUpload({ commit }, key) {
    commit('removeUpload', key);
  },
};

const mutations = {
  setLoading(state, value) {
    state.loading = value;
  },
  initS3Uploader(state) {
    if (state.s3uploader === null) {
      state.s3uploader = new S3Uploader();
    }
  },
};

export const s3Uploader = {
  namespaced: true,
  state,
  actions,
  mutations,
};
