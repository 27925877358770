<template>
  <div
    :data-cy="`form-checkbox-${id}`"
    class="flex gap-2"
    :class="[
      display === 'row' && 'justify-center',
      display === 'column' && 'items-center',
    ]"
  >
    <MouseHover
      :id="id"
      :message="itemMouseHover.message"
      :cursor="itemMouseHover.cursor"
      :active="itemMouseHover.active"
      :disable="itemMouseHover.disable"
      full
    >
      <label
        :for="id"
        class="flex gap-2 items-center w-full"
        :class="checkboxClass"
      >
        <div
          v-if="$slots['header']"
          class="peer-disabled:text-skin-muted text-sm w-full"
          :class="[disabled && 'text-skin-muted']"
        >
          <slot name="header" v-bind="$props" />
        </div>
        <div
          class="flex flex-row gap-2 items-center peer-disabled:text-skin-muted text-sm cursor-pointer"
        >
          <input
            :id="id"
            :value="checked"
            :readonly="readonly"
            type="checkbox"
            :disabled="disabled"
            class="hidden"
            @click="onCheckboxClick"
          />
          <Checkbox :checked="checked" :theme="theme" :disabled="disabled"/>
          <FormLabel
            v-if="label && !hideLabel"
            :weight="labelWeight"
            :size="labelSize"
            :disabled="disabled"
            :auto-colons="autoColons"
            class="cursor-pointer"
            @click="onCheckboxClick"
          >
            {{ $t(labelFormatted) }}
          </FormLabel>
          <Tooltip 
            v-if="tooltip"
            :id="id"
            :icon="tooltip.icon"
            :message="tooltip.message"
            :position="tooltip.position"
          />
          <template v-if="$slots['suffix']">
            <slot name="suffix" v-bind="$props" />
          </template>
        </div>
        <div
          v-if="$slots['footer']"
          class="peer-disabled:text-skin-muted text-sm"
        >
          <slot name="footer" v-bind="$props" />
        </div>
      </label>
    </MouseHover>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import FormLabel from '@/components/form/FormLabel.vue';
import MouseHover from '@/components/mouse/MouseHover.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import { useUiElement } from '@/composables/useUiElement.ts';

type ItemMouseHover = {
  message: string;
  cursor: string;
  active: boolean;
  disable: boolean;
};

type Tooltip = {
  icon: string;
  message: string;
  position: string;
};

interface Props {
  id?: string;
  labelSize?: string;
  label?: string;
  hideLabel?: boolean;
  display?: string;
  align?: string;
  modelValue?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  maxCharacters?: number;
  theme?: string;
  tooltip?: Tooltip;
  mouseHover?: ItemMouseHover;
  labelWeight?: string;
  autoColons?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  id: () => `id_${Math.floor(Math.random() * 10000)}`,
  display: 'row',
  align: 'left',
  labelSize: 'sm',
  labelWeight: 'normal',
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const { uiElementThemeClass } = useUiElement(props);

const checked = computed<boolean>({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const checkboxClass = computed<string[]>(() => {
  return [
    props.display === 'row' && 'flex-row',
    props.display === 'row' && props.align === 'right' && 'justify-end',
    props.display === 'column' && 'flex-col',
    props.labelSize && `text-${props.labelSize}`,
    uiElementThemeClass,
    props.disabled && `text-skin-muted`,
  ];
});

const labelFormatted = computed<string>(() => {
  if (props.maxCharacters && props.label.length > props.maxCharacters) {
    return props.label.substring(0, props.maxCharacters) + '&#8230;';
  }
  return props.label;
});

const itemMouseHover = computed<ItemMouseHover>(() => {
  return props.mouseHover
    ? props.mouseHover
    : {
        message: '',
        cursor: 'pointer',
        active: false,
        disable: false,
      };
});

function onCheckboxClick() {
  if (props.disabled) return;
  checked.value = !checked.value;
}
</script>
