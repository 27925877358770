const templateNameList = ['program', 'serie', 'live', 'collection'];

const actions = {
  save({ dispatch, rootGetters }) {
    let allPromises = [];
    // Template Items
    allPromises.push(dispatch('templates/updateItems', null, { root: true }));

    // Platform
    allPromises.push(dispatch('platforms/update', null, { root: true }));

    // Children platforms
    const childrenPlatforms = rootGetters['platforms/childrenPlatforms'];
    const platforms = rootGetters['platforms/devices'];
    const mainDevice = rootGetters['platforms/mainDevice'];
    childrenPlatforms.forEach((platform) => {
      allPromises.push(
        dispatch('platforms/updateChildren', platform, { root: true })
      );
    });

    // MetadataDetails
    allPromises.push(
      dispatch('metadataDetails/updateAll', ['filterable', 'rank'], {
        root: true,
      })
    );
    // Template name / Device
    templateNameList.forEach((templateName) => {
      platforms.forEach((device) => {
        allPromises.push(
          dispatch(
            'templates/saveBlocksData',
            { device: device.id, name: templateName },
            { root: true }
          )
        );
      });
    });
    // Pictures
    allPromises.push(
      dispatch('templates/updateBlocksPicture', 'home', { root: true })
    );
    //Badge
    allPromises.push(dispatch('badges/replace', null, { root: true }));

    // Rentals
    allPromises.push(dispatch('tvodRentals/saveAll', null, { root: true }));

    return Promise.allSettled(allPromises)
      .then(function (values) {
        let alert = {
          id: 'platform-update-success',
          icon: 'check',
          type: 'valid',
          message: 'notifications.update.success',
        };
        dispatch('displayAlert', alert, { root: true });

        let templatePromises = [];
        templatePromises.push(
          dispatch(
            'templates/touchTemplate',
            { name: 'home', mode: mainDevice },
            { root: true }
          )
        );
        templatePromises.push(
          dispatch(
            'templates/touchTemplate',
            { name: 'landing', mode: mainDevice },
            { root: true }
          )
        );

        return Promise.allSettled(templatePromises)
          .then(async (values) => {
            await dispatch('refresh');
            return Promise.resolve();
          })
          .catch((error) => {
            console.debug(error);
            let alert = {
              id: 'refresh-data',
              icon: 'close',
              type: 'error',
              message: 'error.refresh',
            };
            dispatch('displayAlert', alert, { root: true });
            return Promise.reject(error);
          });
      })
      .catch((err) => {
        console.debug(err);
        let alert = {
          id: 'platform-update-error',
          icon: 'close',
          type: 'error',
          message: 'error.refresh',
        };
        dispatch('displayAlert', alert, { root: true });
      });
  },
  reset({ dispatch }) {
    const promises = [];
    promises.push(
      dispatch(
        'templates/reset',
        ['landing', 'home', 'program', 'serie', 'live', 'collection'],
        { root: true }
      )
    );
    promises.push(dispatch('tvodRentals/reset', null, { root: true }));
    promises.push(dispatch('platforms/get', { force: true }, { root: true }));
    promises.push(dispatch('metadataDetails/resetAll', null, { root: true }));
    return Promise.all(promises).then(
      () => {
        return Promise.resolve();
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  refresh({ dispatch, rootGetters }) {
    const platforms = rootGetters['platforms/devices'];
    //Badge
    const promises = [];
    promises.push(
      dispatch('badges/get', { type: 'live', refresh: true }, { root: true })
    );
    // landing
    promises.push(
      dispatch(
        'templates/get',
        { name: 'landing', refresh: 'after-first-load' },
        { root: true }
      )
    );
    // home
    promises.push(
      dispatch(
        'templates/get',
        { name: 'home', refresh: 'after-first-load' },
        { root: true }
      )
    );
    // Template name / Device
    templateNameList.forEach((templateName) => {
      platforms.forEach((device) => {
        promises.push(
          dispatch(
            'templates/get',
            {
              name: templateName,
              mode: device.id,
              refresh: 'after-first-load',
            },
            { root: true }
          )
        );
      });
    });
    // Platform
    promises.push(dispatch('platforms/get', { force: true }, { root: true }));
    promises.push(dispatch('metadataDetails/refreshAll', null, { root: true }));
    return Promise.all(promises).then(
      () => {
        return Promise.resolve();
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
};

const getters = {
  shouldSaved: (state, getters, rootState, rootGetters) => {
    let retval =
      rootGetters['platforms/shouldSaved'] ||
      rootGetters['templates/shouldSaved'] ||
      (rootGetters['tvodRentals/shouldSaveAll'] &&
        rootGetters['tvodRentals/couldSaveAll']) ||
      rootGetters['metadataDetails/shouldSaved'] ||
      rootGetters['badges/shouldSaved'];
    return retval;
  },
  loading: (state, getters, rootState, rootGetters) => {
    let retval =
      rootGetters['platforms/loading'] ||
      rootGetters['templates/loading'] ||
      rootGetters['metadataDetails/loading'] ||
      rootGetters['references/loading'];
    return retval;
  },
};

export const frontProfiles = {
  namespaced: true,
  actions,
  getters,
};
