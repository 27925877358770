import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/customers/';
const currentPath = import.meta.env.VITE_BILLING_BASE_URL + api_url_module;

export const customerService = {
  getAll,
  get,
  create,
  getBankInfos
};

async function getAll(queryString = '') {
  if (queryString !== '') {
    queryString = '?' + queryString;
  }

  try {
    const response = await axios.get(
      import.meta.env.VITE_BILLING_BASE_URL + api_url_module + queryString
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function get(id) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_BILLING_BASE_URL + api_url_module + id
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function create(data) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      currentPath,
      data,
      requestOptions
    );
    return response.data.results;
  } catch (response_1) {
    return await Promise.reject();
  }
}


async function getBankInfos(customerId, paymentMethod) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_BILLING_BASE_URL + '/' + paymentMethod + '?customer_id=' + customerId
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}
