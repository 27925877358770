<template>
  <div class="text-danger-500 font-light text-xs leading-tight tracking-normal">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormError',
};
</script>
