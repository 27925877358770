import { GenericPlatformService } from '@/services';
import { cloneDeep } from 'lodash';
import { hasDifference } from '@/utils/diffTools';
import PictureConfiguration from '@/models/PictureConfiguration';
import picturesConfigurationsDefaults from '@/data/picturesConfigurationsDefaults';

const pictureConfigurationService = new GenericPlatformService(
  'pictures-configurations'
);

const state = {
  currentAll: [],
  savedAll: [],
  formattedToCreate: [],
  formattedToUpdate: [],
  formattedToDelete: [],
  loading: true,
};

const actions = {
  getAll({ commit, dispatch }) {
    commit('getAllRequest', true);
    return pictureConfigurationService.getAll().then(
      (picturesConfigurations) => {
        commit('getAllSuccess', picturesConfigurations);
        let alert = {
          id: 'pictures-configuration-recovery-success',
          icon: 'check',
          type: 'valid',
          message:
            'La récupération des configurations des images est un succès',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(picturesConfigurations);
      },
      (error) => {
        commit('getAllFailure');
        let alert = {
          id: 'pictures-configuration-recovery-failure',
          icon: 'close',
          type: 'error',
          message:
            'Erreur lors de la récupération des configurations des images',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  saveAll({ commit, dispatch }) {
    commit('saveAllRequest', true);
    var promiseList = [];

    if (state.formattedToCreate.length > 0) {
      promiseList.push(
        pictureConfigurationService.createMany(state.formattedToCreate).then(
          () => {
            console.log('Picture Configuration Created : SUCCESS');
          },
          () => {
            console.log('Picture Configuration Created : FAILURE');
          }
        )
      );
    }

    if (state.formattedToUpdate.length > 0) {
      promiseList.push(
        pictureConfigurationService.updateMany(state.formattedToUpdate).then(
          () => {
            console.log('Picture Configuration Updated : SUCCESS');
          },
          () => {
            console.log('Picture Configuration Updated : FAILURE');
          }
        )
      );
    }

    if (state.formattedToDelete.length > 0) {
      promiseList.push(
        pictureConfigurationService
          .deleteMany({ ids: state.formattedToDelete })
          .then(
            () => {
              console.log('Picture Configuration Deleted : SUCCESS');
            },
            () => {
              console.log('Picture Configuration Deleted : FAILURE');
            }
          )
      );
    }

    return Promise.all(promiseList).then(
      () => {
        commit('saveAllSuccess');
        let alert = {
          id: 'pictures-configuration-save-success',
          icon: 'check',
          type: 'valid',
          message:
            'La récupération des configurations des images est un succès',
        };
        dispatch('displayAlert', alert, { root: true });
        dispatch('getAll');
        return Promise.resolve();
      },
      (error) => {
        commit('saveAllFailure');
        let alert = {
          id: 'pictures-configuration-save-failure',
          icon: 'close',
          type: 'error',
          message: 'Erreur lors de la sauvegarde des configurations des images',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  getAllRequest(state) {
    state.loading = true;
  },
  getAllSuccess(state, picturesConfigurations) {
    let picturesConfigurationsModelized = [];
    picturesConfigurations.forEach(function (pictureConfigurations) {
      let pictureConfigurationsModelized = new PictureConfiguration(
        pictureConfigurations
      );
      picturesConfigurationsModelized.push(pictureConfigurationsModelized);
    });
    state.currentAll = cloneDeep(picturesConfigurationsModelized);
    state.savedAll = cloneDeep(picturesConfigurationsModelized);
    state.loading = false;
  },
  getAllFailure(state) {
    state.currentAll = [];
    state.savedAll = [];
    state.loading = false;
  },

  saveAllRequest(state) {
    let currentAll = cloneDeep(state.currentAll);
    state.formattedToCreate = [];
    state.formattedToUpdate = [];
    state.formattedToDelete = [];
    currentAll.forEach(function (currentOne) {
      if (currentOne.id === null) {
        state.formattedToCreate.push(currentOne);
      } else {
        let savedOne = state.savedAll.find((x) => x.id === currentOne.id);
        if (typeof savedOne === 'undefined') {
          state.formattedToCreate.push(currentOne);
        } else if (hasDifference(currentOne, savedOne)) {
          state.formattedToUpdate.push(currentOne);
        } else {
          //nothing
        }
      }
    });
    let currentIds = state.currentAll.map(({ id }) => id);
    let savedIds = state.savedAll.map(({ id }) => id);
    savedIds.forEach(function (savedId) {
      if (!currentIds.includes(savedId)) {
        state.formattedToDelete.push(savedId);
      }
    });
  },
  saveAllSuccess(state) {
    state.formattedToCreate = [];
    state.formattedToUpdate = [];
    state.formattedToDelete = [];
  },
  saveAllFailure(state) {
    state.formattedToCreate = [];
    state.formattedToUpdate = [];
    state.formattedToDelete = [];
  },

  add(state, pictureConfigurations) {
    let pictureConfigurationsModelized = new PictureConfiguration(
      pictureConfigurations
    );
    let indexSaved = state.savedAll.findIndex(
      (x) => x.local_id === pictureConfigurationsModelized.local_id
    );
    if (indexSaved >= 0) {
      pictureConfigurationsModelized.id = state.savedAll[indexSaved].id;
    }
    let copy = cloneDeep(state.currentAll);
    let indexCurrent = copy.findIndex(
      (x) => x.id === pictureConfigurationsModelized.id && x.id !== null
    );
    if (indexCurrent >= 0) {
      copy.splice(indexCurrent, 1, pictureConfigurationsModelized);
    } else {
      copy.push(pictureConfigurationsModelized);
    }
    state.currentAll = copy;
  },
  remove(state, local_id) {
    let copy = cloneDeep(state.currentAll);
    let index = copy.findIndex((x) => x.local_id === local_id);
    if (index >= 0) {
      copy.splice(index, 1);
      state.currentAll = copy;
    }
  },
  reset(state) {
    state.currentAll = [];
    state.savedAll = [];
  },
  set(state, { local_id, field, value }) {
    let copy = cloneDeep(state.currentAll);
    let index = copy.findIndex((x) => x.local_id === local_id);

    if (index >= 0) {
      let conf = copy[index];
      if (field === 'fit') {
        conf[field] = value.id;
        conf.fit_object = picturesConfigurationsDefaults.fits.find(
          (x) => x.id === value.id
        );
      } else {
        conf[field] = parseInt(value);
      }
      copy.splice(index, 1, conf);
      state.currentAll = copy;
    }
  },
};

const getters = {
  shouldSave: (state) => {
    let current = cloneDeep(state.currentAll);
    let saved = cloneDeep(state.savedAll);
    let currentIds = current.map(({ id }) => id);
    let savedIds = saved.map(({ id }) => id);
    return (
      current.length !== saved.length ||
      hasDifference(current, saved) ||
      hasDifference(currentIds, savedIds)
    );
  },
};

export const picturesConfigurations = {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
