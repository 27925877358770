<template>
  <div class="fixed right-8 bottom-8 w-[270px] z-[1000] translate-x">
    <transition-group
      tag="div"
      enter-from-class="opacity-0 -translate-x-full"
      enter-active-class="transition transform ease-out duration-300"
      enter-to-class="opacity-100 translate-x-0"
      leave-from-class="opacity-100 translate-x-0"
      leave-active-class="transition transform ease-in duration-300"
      leave-to-class="opacity-0 -translate-x-full"
      class="flex flex-col gap-3"
    >
      <div
        v-for="alert in alerts"
        :key="alert.id"
        class="flex items-center p-4 bg-skin-action-accent text-white gap-3 rounded-ui-component shadow shadow-skin-ui"
        :class="[
          alert.type === 'valid' && 'theme-success',
          alert.type === 'error' && 'theme-danger',
          alert.type === 'warning' && 'theme-warning',
        ]"
      >
        <Icon v-if="alert.icon" :icon="alert.icon" size="md" />
        <p class="text-sm">
          {{ $t(alert.message) }}
        </p>
      </div>
    </transition-group>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Icon from '@/components/icon/Icon.vue';

const store = useStore();

const alerts = computed(() => store.state.alerts);
</script>
