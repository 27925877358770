<template>
  <button
    :data-cy="`btn-${id}`"
    :id="id"
    ref="button"
    class="btn otto-base-ui-component transition-all ease-in-out select-none inline-flex items-center border gap-2 group relative"
    :class="[
      uiElementSizeClass,
      ...uiElementButtonClass,
      disabled && loading && 'pointer-events-none',
      size === 'xxl' && 'flex-col',
      rounded ? 'rounded-full' : 'rounded-ui-component group-last:not(:first-child):rounded-l-none',
    ]"
    :disabled="disabled && !loading"
    tabindex="0"
  >
    <slot v-bind="{ iconSize: uiElementIconSize }">
      <span v-if="loading" class="btn-loader mt-2">
        <Icon icon="spinner" class="btn-icon animate-spin" />
      </span>
      <Icon v-if="iconBefore" :icon="iconBefore" :size="uiElementIconSize" />
      <span
        v-if="props.label && !loading"
        class="btn-label truncate whitespace-nowrap"
      >
        {{ $t(label) }}
      </span>
      <Icon v-if="iconAfter" :icon="iconAfter" :size="uiElementIconSize" />
    </slot>
    <RemoveButton
      v-if="removable"
      :id="id"
      :icon-size="uiElementIconSize"
      class="btn-remove absolute right-0 top-0 translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity"
      @click.prevent.stop="emit('remove')"
    />
  </button>
</template>

<script setup lang="ts">
import { computed, onMounted,  ref, watch } from 'vue';
import Icon from '@/components/icon/Icon.vue';
import RemoveButton from '@/components/button/RemoveButton.vue';
import { useUiElement } from '@/composables/useUiElement.ts';

// Define props types
interface Props {
  id?: string;
  theme?: string;
  label?: string;
  dashed?: boolean;
  outline?: boolean;
  nude?: boolean;
  disabled?: boolean;
  size?: string;
  loading?: boolean;
  autofocus?: boolean;
  removable?: boolean;
  iconBefore?: string;
  iconAfter?: string;
  justify?: string;
  rounded?: boolean;
  fontSize?: string;
  fontWeight?: string;
  textAlign?: string;
}

// Define emits types
const emit = defineEmits<{
  (e: 'remove'): void;
}>();

// Define props
const props = withDefaults(defineProps<Props>(), {
  id: () => `id_${Math.floor(Math.random() * 10000)}`,
  fontWeight: 'medium',
  textAlign: 'center',
  size: 'md',
  loading: false,
  justify: 'center',
  removable:false,
  rounded: false,
})

const button = ref<HTMLElement | null>(null);
const focused = computed(() => props.autofocus);

watch(focused, () => {
  if (focused.value) {
    setTimeout(() => {
      button.value?.focus();
    }, 500);
  }
});

onMounted(() => {
  if (props.autofocus) {
    setTimeout(() => {
      button.value?.focus();
    }, 200);
  }
});

const { uiElementSizeClass, uiElementIconSize, uiElementButtonClass } =
  useUiElement(props);
</script>
