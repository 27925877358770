export const devicesType = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
  ORANGE: 'orange',
  FREE: 'free',
  APPLE_TV: 'apple_tv',
  ANDROID_TV: 'android_tv',
  SAMSUNG_TV: 'samsung_tv'
};

export const devicesConfig = [
  {
    id: devicesType.DESKTOP,
    icon: 'computer',
    color: '#000',
    label: 'devices.desktop',
    platform_type_id: 1,
    isTV: false,
    billing: ['stripe', 'purchasely'],
    liveAvailable: true, // Parameters template Live,
    isOtt: true,
  },
  {
    id: devicesType.TABLET,
    icon: 'tablet',
    color: '#000',
    label: 'devices.tablet',
    sublabel: '(web + app)',
    platform_type_id: 2,
    isTV: false,
    billing: ['stripe', 'purchasely'],
    liveAvailable: true,
    isOtt: true,
  },
  {
    id: devicesType.MOBILE,
    icon: 'smartphone',
    color: '#000',
    label: 'devices.mobile',
    sublabel: '(web + app)',
    platform_type_id: 3,
    isTV: false,
    billing: ['stripe', 'purchasely'],
    liveAvailable: true,
    isOtt: true,
  },
  {
    id: devicesType.ORANGE,
    icon: 'orange-logo-square',
    color: '#FF6600',
    label: 'Orange',
    platform_type_id: 4,
    isTV: true,
    billing: ['wha'],
    liveAvailable: true,
    isOtt: false,
  },
  {
    id: devicesType.FREE,
    icon: 'logo-free-square',
    color: '#cd1e25',
    label: 'Free',
    platform_type_id: 5,
    isTV: true,
    billing: ['free'],
    liveAvailable: false,
    isOtt: false,
  },
  {
    id: devicesType.APPLE_TV,
    icon: 'logo-apple-square',
    color: '#000',
    label: 'Apple TV',
    platform_type_id: 6,
    isTV: true,
    billing: ['purchasely'],
    liveAvailable: false,
    isOtt: true,
  },
  {
    id: devicesType.ANDROID_TV,
    icon: 'logo-android-square',
    color: '#3ddc84',
    label: 'Android TV',
    platform_type_id: 7,
    isTV: true,
    billing: ['purchasely'],
    liveAvailable: false,
    isOtt: true,
  },
  {
    id: devicesType.SAMSUNG_TV,
    icon: 'logo-samsung-square',
    color: null,
    label: 'Samsung TV',
    platform_type_id: 8,
    isTV: true,
    billing: ['xxx'],
    liveAvailable: false,
    isOtt: true,
  },
];
