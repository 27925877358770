<template>
  <nav
    id="nav"
    class="group fixed top-[60px] md:pointer-events-auto w-full left-0 bottom-0 bg-skin-ui overflow-hidden md:translate-x-0 md:peer-checked:w-[213px] md:peer-disabled:w-[213px] md:hover:w-[213px] hover:shadow-lg hover:shadow-skin-ui md:group-focus-visible:w-[213px] transition-all duration-300 hover:delay-0 ease-in-out z-50 shadow-md shadow-skin-ui z-[1000]"
    :class="{
      'hovered md:w-[213px]': isNavHovered,
      'md:w-[213px] translate-x-0 opacity-100': expanded,
      'opacity-0 pointer-events-none pointer md:opacity-100': !expanded,
      'md:w-18': !isNavHovered && !$isMobile && !expanded,
    }"
  >
    <ul
      class="h-full flex flex-col items-stretch md:items-start main-nav-list border-solid border-t border-t-skin-ui relative pb-[84px]"
    >
      <NavItem
        v-for="navItem in filteredNavItems"
        :key="navItem.index"
        v-can="navItem.permission"
        :title="$t(navItem.title)"
        :icon="$t(navItem.icon)"
        :size="$isMobile ? 'xlg' : 'lg'"
        :url="navItem.url"
        :color="$t(navItem.color)"
        :visible="expanded"
      />
      <li
        v-if="false"
        class="absolute bottom-6 left-1/2 -translate-x-1/2 flex items-center w-full lg:w-auto group-hover:w-full justify-center"
      >
        <Icon
          icon="sea-scape"
          size="xl"
          class="group-hover:transition-all md:scale-0 md:opacity-0 duration-400 group-hover:opacity-100 group-hover:scale-100 group-hover:delay-150 absolute left-8"
        />
        <FormSwitch
          :checked="isDark"
          :label-as-tooltip="{
            position: 'bottom',
            align: 'right',
          }"
          label-true="Mode Clair"
          label-false="Mode Sombre"
          class="flex items-center justify-center flex-1"
          @update:model-value="toggleDark"
        />
        <Icon
          icon="scuba-diver"
          size="xl"
          class="group-hover:transition-all md:scale-0 md:opacity-0 duration-400 group-hover:opacity-100 group-hover:scale-100 group-hover:delay-150 absolute right-8"
        />
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';
import FormSwitch from '@/components/form/FormSwitch.vue';
import Icon from '@/components/icon/Icon.vue';
import NavItem from '@/components/nav/NavItem.vue';
import navItems from '@/data/navItems';

const store = useStore();

const props = defineProps({
  expanded: Boolean,
});

const $isMobile  = inject('$isMobile');

const isNavHovered = ref(false);

const pricingType = computed(() =>
  store.state.platforms.currentPlatform?.content_config?.pricing_type ?? null
);

const filteredNavItems = computed(() => {
  return navItems.filter(
    (item) =>
      item.visible &&
      can(item.permission) && 
      (
        (
          !item?.pricing ||
          (pricingType.value && item.pricing.includes(pricingType.value))
        ) || 
        featureEnabled('import_gar_esidoc')
      ) 
  );
});

const can = (role) => {
  const can = computed(() => store.getters['auth/can'](role));
  return can.value;
};

const featureEnabled = (featureName) => {
  const featureActivated = computed(() => store.getters['platforms/featureEnabled'](featureName));
  return featureActivated.value;
};

</script>
