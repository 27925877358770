export class PersonType {
  constructor({
    id = Math.random().toString(36).substr(2, 16),
    title = '',
    display = false,
    linkedContents = 0,
    label = '',
  } = {}) {
    this.id = id;
    this.title = title;
    this.display = display;
    this.linkedContents = linkedContents;
    this.label = label;
  }
}

export default PersonType;
