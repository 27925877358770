import { Plan } from '@/models/Plan';

export class GroupPlan {
  id;
  name;
  simultaneous_watch;
  simultaneous_watch_class;
  access_type;
  plans;
  deletable;

  constructor({
    id = undefined,
    name = 'Default Title',
    simultaneous_watch = null,
    access_type = 'complete',
    plans = [],
  }) {
    this.id = id;

    this.name = name;

    this.title = name; // used for rows

    this.simultaneous_watch = simultaneous_watch;

    this.simultaneous_watch_class = simultaneous_watch === null ? 'unlimited' : 'limited';

    this.access_type = access_type;

    let cleanPlans = [];

    plans = plans.filter((x) => x.type !== 'prepaid');
  
    plans.forEach(function (plan) {
      cleanPlans.push(new Plan(plan));
    });

    this.plans = cleanPlans;

    this.count = cleanPlans.length;

    this.devices = {
      computer: plans.filter((x) => x.type === 'stripe' || x.type === 'prepaid').length > 0,
      mobile: plans.filter((x) => x.type === 'app').length > 0,
      tv: plans.filter((x) => x.type === 'app').length > 0,
      free: plans.filter((x) => x.type === 'free').length > 0,
      orange: plans.filter((x) => x.type === 'orange').length > 0
    };

    this.status = plans.length === 0 ? 'incomplete' : (plans.filter((x) => x.active === true).length > 0 ? 'online' : 'ready');
  
    this.activated = plans.length === 0 ? 'incomplete' : (plans.filter((x) => x.active === true).length > 0 ? 'online' : 'ready');
  
    this.deletable = plans.length === 0;
  }
}
