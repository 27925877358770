export class Legal {
  constructor({
    id = -1,
    display_list = true,
    name = null,
    label_title = null,
    label_menu = null,
    html_template = '',
    text_template = '',
  } = {}) {
    this.id = id;
    this.display_list = !!display_list;
    this.name = name;
    this.label_title = label_title;
    this.label_menu = label_menu;
    this.html_template = html_template;
    this.text_template = text_template;
  }
}
export default Legal;
