import { handleResponse } from '../index';
import axios from 'axios';

export class GenericStatisticsService {
  _endpointName;

  constructor(endpointName, transformBo = false) {
    this._endpointName = endpointName;
    this._transformBo = transformBo;
  }

  async search(queryString = '') {
    if (queryString !== '') {
      queryString = '?' + queryString;
    }
    try {
      const response = await axios.get(
        import.meta.env.VITE_ANALYTICS_BASE_URL +
        '/kpis/' +
        this._endpointName +
        queryString
      );
      return handleResponse(response.data, response.status);
    } catch (err) {
      return handleResponse(err);
    }
  }
}
