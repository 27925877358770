import picturesConfigurationsDefaults from '@/data/picturesConfigurationsDefaults';
export class PictureConfiguration {
  constructor({
    id = null,
    model = null,
    collection_name = null,
    device_type = null,
    width = null,
    height = null,
    quality = null,
    fit = null,
  } = {}) {
    this.id = id;
    this.local_id = model + '@' + collection_name + '@' + device_type;
    this.model = model;
    this.collection_name = collection_name;
    this.device_type = device_type;
    this.width = width;
    this.height = height;
    this.quality = quality;
    this.fit = fit;
    this.fit_object = picturesConfigurationsDefaults.fits.find(
      (x) => x.id === fit
    );
    this.removable = id === null || (model !== 'default' && device_type !== '*');
  }
}
export default PictureConfiguration;
