<template>
  <td
    class="text-xs leading-none align-middle"
    :class="tableCellClass"
    :colspan="colspan"
  >
    <router-link
      v-if="!stopClick && routerLink" 
      :to="routerLink"
      class="block h-full w-full"
      :class="itemClass"
    >
      <slot />
    </router-link>
    <div v-else :class="itemClass">
      <slot  />
    </div>
    
  </td>
</template>

<script>
export default {
  name: 'TableCell',
  inject: ['isSelectOption', 'routerLink', 'compact'],
  props: {
    auto: Boolean,
    shrink: {
      type: Boolean,
      default: false,
    },
    colspan: {
      type: Number,
      default: 1,
    },
    stopClick: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'start'
    }
  },
  computed: {
    tableCellClass() {
      return [
        this.auto && 'w-full',
        this.shrink && 'w-px',
        this.compact ? 'h-[32px]' : 'h-[67px]',
        !this.isSelectOption && 'first-of-type:pl-0 first:pl-0 last:pr-0',
      ];
    },
    itemClass() {
      return [
        'flex items-center pr-2 xl:pr-4',
        this.justifyClass
      ];
    },
    justifyClass() {
      switch(this.position) {
        case 'start': return  'justify-start'
        case 'end': return  'justify-end'
        case 'center': return  'justify-center'
        default: return 'justify-start'
      }
    }
    
  },
};
</script>