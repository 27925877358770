export class FtpUser {
  constructor({
    id = -1,
    created_by = null,
    type = 'aws_transfer_family',
    title = null,
    user_name = null,
    description = null,
    right_type = 'read_and_write',
    client_path = null,
    full_path = null,
    host = null,
    keys = [{ value: '' }],
    created_at = null,
    updated_at = null,
  } = {}) {
    this.id = id;
    this.created_by = created_by;
    this.type = type;
    this.title = title;
    this.user_name = user_name;
    this.description = description;
    this.right_type = right_type;
    this.client_path = client_path;
    this.full_path = full_path;
    this.host = host;
    this.keys = keys;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }
}
export default FtpUser;
