import { GenericContentService } from '@/services';
const gearService = new GenericContentService('gears');

const state = {
  all: [],
  one: [],
};

const actions = {
  getAll({ commit, state }, refresh) {
    if (state.all.length === 0 || refresh === true) {
      commit('getAllRequest');
      return gearService.getAll().then(
        (gears) => {
          commit('getAllSuccess', gears);
          return Promise.resolve(gears);
        },
        (error) => {
          commit('getAllFailure', error);
          return Promise.reject(error);
        }
      );
    }
    return Promise.resolve();
  },
  get({ commit }) {
    commit('getRequest');
    return gearService.get().then(
      (gear) => {
        commit('getSuccess', gear);
        return Promise.resolve(gear);
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  getAllRequest(state) {
    if (state.all.length > 0) {
      state.all = [];
    }
  },
  getAllSuccess(state, versions) {
    state.all = versions;
  },
  getAllFailure(state) {
    state.all = [];
  },
  getRequest(state) {
    state.one = [];
  },
  getSuccess(state, version) {
    state.one = version;
  },
  getFailure(state) {
    state.one = [];
  },
};

export const gears = {
  namespaced: true,
  state,
  actions,
  mutations,
};
