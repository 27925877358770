import { handleResponse } from '@/services/index.js';
import axios from 'axios';

export class LegalService {
  _endpointName;

  constructor() {
    this._endpointName = 'legals';
  }

  async getAll(all = 1) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_PLATFORM_BASE_URL +
          '/' +
          this._endpointName +
          '?all=' + parseInt(all)
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async get(id) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_PLATFORM_BASE_URL +
          '/' +
          this._endpointName +
          '/' +
          id
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async create(data) {
    try {
      const response = await axios.post(
        import.meta.env.VITE_PLATFORM_BASE_URL + '/' + this._endpointName,
        data
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async update(data, id) {
    try {
      const response = await axios.put(
        import.meta.env.VITE_PLATFORM_BASE_URL +
          '/' +
          this._endpointName +
          '/' +
          id,
        data
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async delete(id) {
    try {
      const response = await axios.delete(
        import.meta.env.VITE_PLATFORM_BASE_URL +
          '/' +
          this._endpointName +
          '/' +
          id
      );
      return handleResponse(response);
    } catch (err) {
      return handleResponse(err);
    }
  }
}
