export class Sepa {
  id;
  bank_code;
  branch_code;
  country;
  fingerprint;
  last4;
  name;

  constructor({
    id = undefined,
    bank_code = null,
    branch_code = null,
    country = null,
    fingerprint = null,
    last4 = null,
    name = null,
  }) {
    this.id = id;
    this.bank_code = bank_code;
    this.branch_code = branch_code;
    this.country = country;
    this.fingerprint = fingerprint;
    this.last4 = last4;
    this.name = name;
  }
}
