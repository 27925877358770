import { GenericContentService } from '@/services';
const tagService = new GenericContentService('tags');

const state = {
  all: [],
  one: [],
};

const actions = {
  getAll({ commit, state }, refresh = false) {
    if (state.all.length === 0 || refresh === true) {
      commit('getAllRequest');
      return tagService.getAll().then(
        (tags) => {
          commit('getAllSuccess', tags);
          return Promise.resolve(tags);
        },
        (error) => {
          commit('getAllFailure', error);
          return Promise.reject(error);
        }
      );
    }
  },

  get({ commit }) {
    commit('getRequest');
    return tagService.get().then(
      (tag) => {
        commit('getSuccess', tag);
        return Promise.resolve(tag);
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  getAllRequest(state) {
    if (state.all.length > 0) {
      state.all = [];
    }
  },
  getAllSuccess(state, versions) {
    state.all = versions;
  },
  getAllFailure(state) {
    state.all = [];
  },
  getRequest(state) {
    state.one = [];
  },
  getSuccess(state, version) {
    state.one = version;
  },
  getFailure(state) {
    state.one = [];
  },
};

export const tags = {
  namespaced: true,
  state,
  actions,
  mutations,
};
