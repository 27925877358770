import { cloneDeep, isEqual } from 'lodash';
import SiteParameters from '@/models/SiteParameters';

const state = {
  currentSiteParameters: new SiteParameters(),
  savedSiteParameters: new SiteParameters(),
};

const getters = {
  isSiteParametersSaved: (state) => {
    return isEqual(state.currentSiteParameters, state.savedSiteParameters);
  },
  currentFormat: (state) => {
    return state.currentSiteParameters.formats.find((x) => x.checked);
  },
  contentsTypeFormat: (state) => (contentsType, mode) => {
    const rootFormat = state.currentSiteParameters.formats.find(
      (x) => x.checked
    );
    const contentsFormats =
      state.currentSiteParameters.animationBlocks.contents.formats[mode];
    const contentFormat = contentsFormats.find((x) => x.id === contentsType.id);
    return contentFormat ? { ...contentFormat } : { ...rootFormat };
  },
};

const mutations = {
  updateNav: (state, item) => {
    console.log(' -- updateNav --');
    let nav;
    let navRef;
    if (item.component === 'header') {
      if (item.nav === 'primaryNav') {
        nav = state.currentSiteParameters.header.primaryNav;
        navRef = 'headerPrimaryNav';
      }
    }
    if (item.component === 'landingHeader') {
      if (item.nav === 'primaryNav') {
        nav = state.currentSiteParameters.landingHeader.primaryNav;
        navRef = 'landingHeaderPrimaryNav';
      }
      if (item.nav === 'secondaryNav') {
        nav = state.currentSiteParameters.landingHeader.secondaryNav;
      }
      navRef = 'landingHeaderSecondaryNav';
    }
    if (item.component === 'footer') {
      if (item.nav === 'primaryNav') {
        nav = state.currentSiteParameters.footer.primaryNav;
        navRef = 'footerPrimaryNav';
      }
      if (item.nav === 'appsNav') {
        nav = state.currentSiteParameters.footer.appsNav;
        navRef = 'footerAppsNav';
      }
      if (item.nav === 'socialNav') {
        nav = state.currentSiteParameters.footer.socialNav;
        navRef = 'footerSocialNav';
      }
    }

    if (nav && navRef) {
      if (item.toRemove) {
        if (navRef === 'footerAppsNav' || navRef === 'footerSocialNav') {
          const navItem = nav[item.index];
          if (navItem) {
            navItem.hidden = true;
            nav.splice(item.index, 1, navItem);
          }
        } else {
          nav.splice(item.index, 1);
        }
      } else if (item.index > -1) {
        const navItem = nav[item.index];
        if (navItem) {
          if (navRef === 'footerAppsNav' || navRef === 'footerSocialNav') {
            navItem.hidden = false;
          } else {
            navItem.label = item.label;
            navItem.url = item.url;
            navItem.type = item.type ? item.type : 'link';
            navItem.categoriesDropdown = item.categoriesDropdown;
          }
        }
      } else {
        nav.push({
          label: item.label,
          url: item.url,
          type: item.type ? item.type : 'link',
          categoriesDropdown: item.categoriesDropdown,
        });
      }
    }
  },
  updatePagesBlockPosition: (state, payload) => {
    let { blocks, block, oldIndex, newIndex } = payload;
    if (blocks === 'contentPagesBlock') {
      blocks = state.currentSiteParameters.contentPages.blocks;
    } else if (blocks === 'seriePagesBlock') {
      blocks = state.currentSiteParameters.seriePages.blocks;
    } else {
      blocks = null;
    }
    if (blocks) {
      blocks.splice(oldIndex, 1);
      if (newIndex > blocks.length) {
        blocks.push(block);
      } else {
        blocks.splice(newIndex, 0, block);
      }
    }
  },
  setEpisodesTemplate: (state, template) => {
    let episodesTemplate =
      state.currentSiteParameters.seriePages.episodesTemplate;
    let blocks = state.currentSiteParameters.seriePages.blocks;
    if (!episodesTemplate) {
      blocks.unshift('episodes');
    }
    state.currentSiteParameters.seriePages.episodesTemplate = template;
  },
  saveSiteParameters: (state) => {
    state.savedSiteParameters = cloneDeep(state.currentSiteParameters);
  },
  resetSiteParameters: (state) => {
    state.currentSiteParameters = cloneDeep(state.savedSiteParameters);
  },
  updateDefaultVisuals: (state, visual) => {
    switch (visual.type) {
      case 'poster':
        state.currentSiteParameters.defaultVisuals.poster.url = visual.url;
        break;
      case 'thumbnail':
        state.currentSiteParameters.defaultVisuals.thumbnail.url = visual.url;
        break;
      case 'meta':
        state.currentSiteParameters.defaultVisuals.meta.url = visual.url;
        break;
      case 'cast':
        state.currentSiteParameters.defaultVisuals.cast.url = visual.url;
        break;
      case 'cover':
        state.currentSiteParameters.defaultVisuals.cover.url = visual.url;
        break;
      case 'banner':
        state.currentSiteParameters.defaultVisuals.banner.url = visual.url;
        break;
    }
  },
  updateAnimationBlockCarouselTextMetadata: (state, { value, index, mode }) => {
    const carouselSettings =
      state.currentSiteParameters.animationBlocks.carousel;
    let textMetadatas = carouselSettings.textMetadatas[mode];
    textMetadatas[index].checked = value;
    if (mode === 'desktop' && carouselSettings.tabletAsDesktop) {
      carouselSettings.textMetadatas.tablet[index].checked = value;
    }
    if (mode === 'tablet') {
      carouselSettings.tabletAsDesktop = false;
    }
  },
  updateAnimationBlockCarouselTextMetadatas: (
    state,
    { metadatas, type, mode }
  ) => {
    const carouselSettings =
      state.currentSiteParameters.animationBlocks.carousel;
    let typeMetadatas;
    if (type === 'others') {
      typeMetadatas = carouselSettings.textMetadatas[mode].filter((x) =>
        ['text', 'category'].includes(x.type)
      );
    } else {
      typeMetadatas = carouselSettings.textMetadatas[mode].filter(
        (x) => x.type !== type
      );
    }
    typeMetadatas = [...typeMetadatas, ...metadatas];
    carouselSettings.textMetadatas[mode] = typeMetadatas;
    if (mode === 'tablet') {
      carouselSettings.tabletAsDesktop = false;
    }
  },
  updateAnimationBlockCarouselTextButtons: (state, { buttons, mode }) => {
    state.currentSiteParameters.animationBlocks.carousel.textButtons[mode] =
      buttons;
  },
  /**
   * @deprecated
   * @see platform.module.js mutation updateSiteParametersFormat
   * @author Jody JUSTINE <jjustine@vodfacoy.com>
   **/
  // updateSiteParametersFormat: (state, format) => {
  //   state.currentSiteParameters.formats = state.currentSiteParameters.formats.map(
  //     x => {
  //       x.checked = false
  //       return x.id === format.id ? format : x
  //     }
  //   )
  // },
  updateSiteParametersAnimationBlocksContentsFormat: (
    state,
    { format, mode }
  ) => {
    let contentsFormat =
      state.currentSiteParameters.animationBlocks.contents.formats[mode];
    const contentsFormatIndex = contentsFormat.findIndex(
      (x) => x.id === format.id
    );
    if (contentsFormatIndex > -1) {
      contentsFormat.splice(contentsFormatIndex, 1, format);
    } else {
      contentsFormat.push(format);
    }
  },
  updateSiteParametersCataloguePage: (state, { infos, mode }) => {
    let cataloguePage = state.currentSiteParameters.cataloguePage[mode];
    const newCataloguePage = {
      ...cataloguePage,
      ...infos,
    };
    state.currentSiteParameters.cataloguePage[mode] = newCataloguePage;
  },
};

const actions = {
  saveSiteParametersAction: ({ commit, dispatch }) => {
    const alert = {
      id: 'movie-not-found',
      icon: 'check',
      type: 'valid',
      message: 'notifications.update.success',
    };
    commit('saveSiteParameters');
    dispatch('displayAlert', alert, { root: true });
  },
  resetSiteParametersAction: ({ commit }) => {
    commit('saveSiteParameters');
  },
};

export const siteParameters = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
