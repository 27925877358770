import { getField } from 'vuex-map-fields';
import { recommendationService } from '@/services';

const state = () => ({
  loadingIndexAll: false,
  loadingUpdateCategoryWeights: false,
});

const getters = {
  getField,
  loadingIndexAll: () => {
    return state.loadingIndexAll;
  },
  loadingUpdateCategoryWeights: () => {
    return state.loadingUpdateCategoryWeights;
  },
};

const actions = {
  indexAll({ commit, dispatch }, type) {
    commit('toggleLoadingIndexAll', true);
    return recommendationService.indexAll(type).then(
      () => {
        commit('toggleLoadingIndexAll', false);
        const alert = {
          id: 'recommendations-indexed-all',
          icon: 'check',
          type: 'valid',
          message: 'recommendations.indexAll.alert.accepted',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (error) => {
        console.log(error);
        commit('toggleLoadingIndexAll', false);
        const alert = {
          id: 'recommendations-indexed-all',
          icon: 'close',
          type: 'error',
          message: 'recommendations.indexAll.alert.rejected',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  updateCategoryWeights({ commit, dispatch }, type) {
    commit('toggleLoadingUpdateCategoryWeights', true);
    return recommendationService.updateCategoryWeights(type).then(
      () => {
        commit('toggleLoadingUpdateCategoryWeights', false);
        const alert = {
          id: 'recommendations-indexed-all',
          icon: 'check',
          type: 'valid',
          message: 'recommendations.updateCategoryWeights.alert.accepted',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (error) => {
        console.log(error);
        commit('toggleLoadingUpdateCategoryWeights', false);
        const alert = {
          id: 'recommendations-update-category-weights',
          icon: 'close',
          type: 'error',
          message: 'recommendations.updateCategoryWeights.alert.rejected',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  toggleLoadingIndexAll(state, status) {
    state.loadingIndexAll = status;
  },
  toggleLoadingUpdateCategoryWeights(state, status) {
    state.loadingUpdateCategoryWeights = status;
  },
};

export const recommendations = {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
