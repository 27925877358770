const scrollArrowTypes = [
  {
    id: 'controls-nude',
    label:
      'settings.pages.appearance.sections.global.navigation.arrows.options.simple',
  },
  {
    id: 'controls-button',
    label:
      'settings.pages.appearance.sections.global.navigation.arrows.options.full',
  },
];
export default scrollArrowTypes;
