import { cloneDeep } from 'lodash';
import DisplayConfig from '@/models/parameters/DisplayConfig';
import store from '@/store';

/**
 * Block Recommendation for program/serie/live template
 *
 * @author Timothée PLANCHAIS <t.planchais@vodfactory.com>
 */
export class BlockRecommendations {
  id;
  block_id;
  block_type;
  details;
  rank;
  title;
  title_size;
  title_weight;
  title_style;

  format;
  stylable;

  type;
  template_id;
  platform_id;
  block_type_id;
  items;
  picture_default;

  displayConfig;

  constructor({
    platform_id,
    template_id,
    block_id,
    title,
    title_size,
    title_weight,
    title_style,
    rank,
    picture_default,
  }) {
    this.platform_id = platform_id;
    this.template_id = template_id;
    this.block_id = block_id ?? 'row-recommendations';
    this.block_type = 'row-recommendations';
    this.block_type_id = store.getters['references/block_type_id'](
      this.block_type
    );
    this.item_type = 'program';
    this.item_type_id = store.getters['references/item_type_id'](
      this.item_type
    );

    this.title = title;
    this.title_size = title_size;
    this.title_weight = title_weight;
    this.title_style = title_style;

    this.rank = rank;

    this.picture_default = picture_default;

    this.setDefault();
    this.setDisplayConfig();
    this.setFormatAndStylable();
  }

  setDefault() {
    this.id = 'row-recommendations';
    this.type = 'row-recommendations';
    this.format = 'row-recommendations';
    this.details = {
      name: 'row-recommendations',
      type: 'row-recommendations',
    };
    this.active = 1;
  }

  /**
   * Set config for metadata display
   */
  setDisplayConfig() {
    let retval = {};
    retval.title = this.title ?? '';
    retval.default_title = null;
    retval.style = 'image';
    retval.badgeStyle = 'videos';

    if (this.picture_default) {
      retval.pictureDefault =
        this.picture_default == 'poster' ? { id: 1 } : { id: 2 };
    } else {
      retval.pictureDefault = { id: 1 };
    }

    // Set Title style : bold / italic / font-size
    retval.titleStyles = {};
    retval.titleStyles.bold =
      (this.title_weight ?? null) == 'bold' ? true : false;
    retval.titleStyles.fontSize =
      this.title_size ?? null ? 'font-size-' + this.title_size : 'font-size-md';
    retval.titleStyles.italic =
      (this.title_style ?? null) == 'italic' ? true : false;

    this.displayConfig = new DisplayConfig(retval);
  }

  /**
   * Set format and stylable
   */
  setFormatAndStylable() {
    this.format = 'multiselect';
    this.stylable = false;
  }

  /**
   * Get clean data for api
   *
   * @return object
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  getCleanDataForApi() {
    let retval = cloneDeep(this);

    retval.title = this.displayConfig.getApiTitle();

    retval.title_size = this.displayConfig.getApiFontSize();
    retval.title_style = this.displayConfig.getApiFontStyle();
    retval.title_weight = this.displayConfig.getApiFontWeight();
    const pictureDefault = this.displayConfig.getApiPictureDefault();
    if (pictureDefault) {
      retval.picture_default = pictureDefault;
    }

    delete retval.items;
    delete retval.format;
    delete retval.stylable;
    delete retval.temp;
    delete retval.displayConfig;
    delete retval.defaultColors;
    delete retval.details;

    return retval;
  }
}

export default BlockRecommendations;
