const isProdEnv =
  'VITE_ENV' in import.meta &&
  ['integ', 'production'].includes(import.meta.env.VITE_ENV);
const navItems = [
  {
    title: 'nav.catalogue.title',
    icon: 'nav.catalogue.icon',
    url: '/catalogue',
    color: 'nav.catalogue.color',
    visible: true,
    permission: 'catalogue.access',
  },
  {
    title: 'nav.animation.title',
    icon: 'nav.animation.icon',
    url: '/animation',
    color: 'nav.animation.color',
    visible: true,
    permission: 'animation.access',
  },
  {
    title: 'nav.users.title',
    icon: 'nav.users.icon',
    url: '/utilisateurs',
    color: 'nav.users.color',
    visible: true,
    permission: 'users.access',
  },
  {
    id: 'marketing',
    title: 'nav.marketing.title',
    icon: 'nav.marketing.icon',
    url: '/marketing',
    color: 'nav.marketing.color',
    visible: true,
    pricing: ['svod', 'svod_and_tvod'],
    permission: 'marketing.access',
  },
  {
    title: 'nav.stats.title',
    icon: 'nav.stats.icon',
    url: '/statistiques',
    color: 'nav.stats.color',
    visible: !isProdEnv,
    permission: 'stats.access',
  },
  {
    title: 'nav.settings.title',
    icon: 'nav.settings.icon',
    url: '/parametres',
    color: 'nav.settings.color',
    visible: true,
    permission: 'settings.access',
  },
  {
    title: 'nav.advanced-settings.title',
    icon: 'nav.advanced-settings.icon',
    url: '/advanced-settings',
    color: 'nav.advanced-settings.color',
    visible: true,
    permission: 'advanced-settings.access',
  },
  {
    title: 'nav.tech.title',
    icon: 'nav.tech.icon',
    url: '/tech',
    color: 'nav.tech.color',
    visible: true,
    permission: 'tech.index.access',
  },
];

export default navItems;