import { GenericBillingService } from '@/services';
import { TVODRental } from '@/models/billing/TVODRental';
import { cloneDeep, isEmpty } from 'lodash';

const tvodRentalService = new GenericBillingService('tvod-rentals', true);

const state = {
  loading: false,
  all: {},
  allSaved: {},
};

const getters = {
  loading: (state) => {
    return state.loading;
  },
  shouldSaveAll: (state, getters) => {
    return getters.toCreate.length > 0 || getters.toUpdate.length > 0 || getters.toDelete.length > 0;
  },
  couldSaveAll: (state, getters) => {
    const list = cloneDeep(getters.toCreate);

    let couldSave = true;

    list.forEach((tvodRental) => {
      couldSave = couldSave
        && tvodRental.price_id !== undefined
        && ! isNaN(tvodRental.start_interval_count)
        && Number.isInteger(Number(tvodRental.start_interval_count))
        && Number(tvodRental.start_interval_count) > 0
        && ! isNaN(tvodRental.play_interval_count)
        && Number.isInteger(Number(tvodRental.play_interval_count))
        && Number(tvodRental.play_interval_count) > 0
      ;
    });

    return couldSave;
  },
  toCreate: (state) => {
    const toCreate = [];

    Object.keys(state.all).forEach((key) => {
      state.all[key].forEach((tvodRental) => {
        if (tvodRental.id === undefined) {
          toCreate.push(tvodRental);
        }
      });
    });

    return toCreate;
  },
  toUpdate: (state) => {
    let allState = {};
    let allSavedState = {};
    let flat = {};

    Object.keys(state.all).forEach((key) => {
      state.all[key].forEach((tvodRental) => {
        if (tvodRental.id !== undefined) {
          allState[tvodRental.id] = tvodRental.active;
        }
      });
    });

    Object.keys(state.allSaved).forEach((key) => {
      state.allSaved[key].forEach((tvodRental) => {
        flat[tvodRental.id] = tvodRental;
        if (tvodRental.id !== undefined) {
          allSavedState[tvodRental.id] = tvodRental.active;
        }
      });
    });

    const toUpdate = [];

    Object.keys(allSavedState).forEach((key) => {
      if (typeof allState[key] !== 'undefined' && allState[key] !== allSavedState[key]) {
        toUpdate.push({
          program_id: flat[key].program_id,
          tvod_rental_id: flat[key].id,
          active: allState[key],
        });
      }
    });

    return toUpdate;
  },
  toDelete: (state) => {
    const toDelete = [];
    let remainingIds = [];

    Object.keys(state.all).forEach((key) => {
      state.all[key].forEach((tvodRental) => {
        if (tvodRental.id !== undefined) {
          remainingIds.push(tvodRental.id);
        }
      });
    });

    Object.keys(state.allSaved).forEach((key) => {
      state.allSaved[key].forEach((tvodRental) => {
        if (!remainingIds.includes(tvodRental.id)) {
          toDelete.push(tvodRental.id);
        }
      });
    });

    return toDelete;
  }
};

const actions = {
  getAllByType({ commit }, { programId, programType }) {
    const queryString = `programs[0][id]=${programId}&programs[0][type]=${programType}&active_only=0&distinct=1`;

    commit('getRequest');
    return tvodRentalService.getAll(queryString).then(
      (response) => {
        commit('getSuccessByType', { response, programId, programType });
        return Promise.resolve();
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject(error);
      }
    );
  },
  getAllTypes({ commit }, { types } = { types: ['program', 'serie', 'season', 'episode', 'live', 'collection'] }) {
    const promises = [];
  
    types.forEach((programType) => {
      const queryString = `programs[0][id]=0&programs[0][type]=${programType}&active_only=0&distinct=1`;
      promises.push(
        tvodRentalService.getAll(queryString).then(
          (response) => {
            commit('getSuccessByType', { programType, programId: 0, response });
            return Promise.resolve();
          },
          (error) => {
            commit('getFailure', error);
            return Promise.reject(error);
          }
        )
      );
    })
  
    commit('getRequest');
  
    return Promise.all(promises).then(
      () => {
        return Promise.resolve();
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  saveAll({ commit, getters }) {
    let promises = [];

    getters.toDelete.forEach((id) => {
      promises.push(tvodRentalService.delete(id));
    });

    if (getters.toCreate.length > 0) {
      let list = [];
      getters.toCreate.forEach((tvodRental) => {
        list.push({
          tvod_price_id: tvodRental.price_id,
          program_id: tvodRental.program_id ?? null,
          start_interval_count: Number(tvodRental.start_interval_count),
          start_interval_unit: tvodRental.start_interval_unit,
          play_interval_count: Number(tvodRental.play_interval_count),
          play_interval_unit: tvodRental.play_interval_unit,
          program_id: tvodRental.program_id,
          program_type: tvodRental.program_type,
          quality: tvodRental.quality,
          target: tvodRental.target,
          active: tvodRental.active,
        });
      });
      promises.push(tvodRentalService.createMany(list).then(
        (response) => {
          commit('createSuccess', response);
          return Promise.resolve();
        },
        (error) => {
          return Promise.reject(error);}
      ));
    }

    if (getters.toUpdate.length > 0) {
      promises.push(tvodRentalService.toggleManyStatus({ actions: getters.toUpdate }));
    }

    commit('saveRequest');

    return Promise.all(promises).then(
      () => {
        commit('saveSuccess');
        return Promise.resolve();
      },
      (error) => {
        commit('saveFailure');
        return Promise.reject(error);
      }
    );
  },
  reset({ commit }) {
    commit('reset');
  }
};

const mutations = {
  reset(state) {
    state.all = {};
    state.allSaved = {};
    state.loading = false;
  },
  setLoading(state, value) {
    state.loading = value;
  },

  add(state, { key, programId, programType }) {
    state.all[key].push(new TVODRental({program_id: programId, program_type: programType}));
  },
  update(state, { key, index, field, value }) {
    state.all[key][index][field] = value;
  },
  remove(state, { key, index }) {
    state.all[key].splice(index, 1);
  },

  getRequest(state) {
    state.loading = true;
  },
  getSuccessByType(state, { response, programId, programType }) {
    const tvodRentals = Object.keys(response).map(function (key) {
      return response[key].map((tvodRental) => new TVODRental(tvodRental))
    })[0];
    const fullKey = `id_${programId}_${programType}`;
    state.all[fullKey] = tvodRentals
    state.allSaved[fullKey] = cloneDeep(tvodRentals);
    state.loading = false;
  },
  createSuccess(state, response) {
    let copy = cloneDeep(state.all);

    Object.keys(copy).forEach(function (key) {
      copy[key] = copy[key].filter((tvodRental) => tvodRental.id !== undefined);
    })

    response.forEach(function (one) {
      let tvodRental = new TVODRental(one);
      let key = 'id_' + (tvodRental.program_id ?? 0) + '_' + (tvodRental.program_type ?? 'program');
      if (typeof state.all[key] !== 'undefined') {
        copy[key].push(tvodRental);
      }
    });
    state.all = cloneDeep(copy);
  },
  getFailure(state) {
    state.all = {};
    state.allSaved = {};
    state.loading = false;
  },
  saveRequest(state) {
    state.loading = true;
  },
  saveSuccess(state) {
    state.allSaved = cloneDeep(state.all);
    state.loading = false;
  },
  saveFailure(state) {
    state.loading = false;
  },
};

export const tvodRentals = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
