import { join, values } from 'lodash';
import { transactionService } from '@/services';
import Transaction from '@/models/Transaction';
import utils from '@/utils/utils';

const state = {
  allForUser: null,
  one: {},
  params: {},
  totalAmount: 0,
  loading: true,
};

const actions = {
  refresh({ dispatch }) {
    return dispatch('getByUser', state.params);
  },

  getByUser({ commit, dispatch }, params) {
    commit('getAllRequest');
    commit('setParams', params);

    let page = 'page' in params ? params.page : null;
    let sort = 'sort' in params ? params.sort : '';
    let userId = 'userId' in params ? params.userId : '';
    let facets = 'facets' in params ? params.facets : null;
    // let query = isNil(page) ? state.currentSearch : ('query' in params ? '&query=' + params.query : '');

    // page  = isNil(page) ? state.currentPage : page;

    let queryString = utils.makeQueryString(facets, sort, page);

    return transactionService.getUserTransactions(userId, queryString).then(
      (response) => {
        commit('getAllSuccess', response);
        return Promise.resolve(response);
      },
      (error) => {
        commit('getAllFailure', error);
        return Promise.reject(error);
      }
    );
  },

  getById({ commit }, id) {
    commit('getRequest');
    return transactionService.getTransaction(id).then(
      (response) => {
        commit('getTransactionSuccess', response);
        return Promise.resolve(response);
      },
      (error) => {
        commit('getTransactionFailure', error);
        return Promise.reject(error);
      }
    );
  },

  refund({ commit, dispatch }, params) {
    let chargeId = 'charge_id' in params ? params.charge_id : null;
    let amount = 'amount' in params ? params.amount : '';
    let reason = 'reason' in params ? params.reason : 'requested_by_customer';
    let note = 'note' in params ? params.note : null;

    // 'charge_id' => 'required|string|starts_with:ch_',
    // 'amount' => 'required|numeric',
    // 'reason' => 'in:duplicate,fraudulent,requested_by_customer’,
    // 'note' => $request->get('note'),

    commit('getRequest');
    return transactionService.refund(chargeId, amount, reason, note).then(
      (response) => {
        commit('getTransactionSuccess', response);
        let alert = {
          id: 'item-not-found',
          icon: 'check',
          type: 'valid',
          message: 'notifications.update.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(response);
      },
      (error) => {
        commit('getTransactionFailure', error);
        console.log('getTransactionFailure  then error');

        let alert = {
          id: 'item-not-found',
          icon: 'close',
          type: 'error',
          message: join(values(error)),
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },

  reset({ commit }) {
    commit('resetAll');
    commit('reset');
    commit('setParams', {});
  },
};

const mutations = {
  setParams(state, params) {
    state.params = params;
  },

  resetAll(state) {
    state.allForUser = [];
    state.loading = false;
  },

  getAllRequest(state) {
    // state.allForUser = []
    state.loading = true;
  },

  getAllSuccess(state, items) {
    items = items.map(function (element) {
      let retval = new Transaction(element);
      return retval;
    });
    state.allForUser = items;

    state.totalAmount = state.allForUser.reduce(function (
      accumulateur,
      valeurCourante
    ) {
      if (!['initiated', 'failed', 'cancelled'].includes(valeurCourante.status)) {
        return accumulateur + valeurCourante.getBalance();
      }
      return accumulateur;
    }, 0);

    state.totalAmount = Math.round(state.totalAmount * 100) / 100;

    state.loading = false;
  },

  getAllFailure(state, error) {
    console.log('transactions - getFailure');
    state.loading = false;
  },

  //------ ONE ------
  reset(state) {
    state.one = {};
    state.loading = false;
  },

  getRequest(state) {
    state.one = {};
    state.loading = true;
  },

  getTransactionSuccess(state, item) {
    state.one = new Transaction(item);
    state.loading = false;
  },

  getTransactionFailure(state, error) {
    console.log('transaction - getFailure');
    state.loading = false;
  },
};

export const transactions = {
  namespaced: true,
  state,
  actions,
  mutations,
};
