import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/coupons/';
const currentPath = import.meta.env.VITE_BILLING_BASE_URL + api_url_module;

export const couponService = {
  get,
  create,
  update,
  exportData,
  search
};

async function get(token) {
  try {
    const response = await axios.get(currentPath + token);
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function create(coupon) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(currentPath, coupon, requestOptions);
    return response.data;
  } catch (response_1) {
    return await Promise.reject();
  }
}

async function update(coupon, id) {
  const requestOptions = {
    method: 'PUT',
  };
  try {
    const response = await axios.put(currentPath + id, coupon, requestOptions);
    return response.data.results;
  } catch (response_1) {
    return await Promise.reject();
  }
}

async function exportData(data) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      currentPath + 'export',
      data,
      requestOptions
    );
    return response.data.results;
  } catch (response_1) {
    return await Promise.reject();
  }
}

async function search(queryString = '') {
  const requestOptions = {
    method: 'GET',
  };
  if (queryString !== '') {
    queryString = '?' + queryString;
  }
  try {
    const response = await axios.get(
      currentPath + queryString,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

