import { EpisodeTransformer } from '@/transformers';
import { cloneDeep } from 'lodash';
import { episodesService } from '@/services';
import { getField } from 'vuex-map-fields';
import router from '@/router';

const state = {
  episodePerPage: 20, //TODO: 50 ITEMS FOR EPISODES
  epLoading: false,
};

const getters = {
  getField,
  metadata: (state, getters, rootState, rootGetters) => {
    let retval = rootGetters['metadataDetails/allActivated'];
    return retval;
  },
};

const actions = {
  getAll(
    { commit, getters, dispatch },
    { serieId, seasonId, page, parent, reset = false }
  ) {
    commit('setLoading', true);
    let queryString = `serie_id=${serieId}&with=video-details&cache=nocache&count=${state.episodePerPage}&page=${page}`;
    if (seasonId) {
      queryString = `serie_id=${serieId}&season_id=${seasonId}&with=video-details&cache=nocache&count=${state.episodePerPage}&page=${page}`;
    }
    return episodesService.all(queryString).then(
      (episodes) => {
        if (reset) {
          dispatch('programs/resetSerieEpisodes', null, { root: true });
        }
        dispatch(
          'programs/setSerieEpisodes',
          { episodes: cloneDeep(episodes), parent: parent },
          {
            root: true,
          }
        );
        commit('setLoading', false);
        return Promise.resolve(episodes);
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  checkOne({ commit, getters, dispatch }, { serieId, seasonId }) {
    commit('setLoading', true);
    let queryString = `serie_id=${serieId}&cache=nocache&count=1&page=1`;
    if (seasonId) {
      queryString = `serie_id=${serieId}&season_id=${seasonId}&cache=nocache&count=1&page=1`;
    }
    return episodesService.all(queryString).then(
      (episodes) => {
        commit('setLoading', false);
        return Promise.resolve(episodes.data);
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  //search({ commit, getters, dispatch }, { word, serieId, page, parent }){
  //  let queryString = `serie_id=${serieId}&with=video-details&cache=nocache&count=${state.episodePerPage}&page=${page}`;
  //},
  get({ commit, dispatch }, id) {
    commit('setLoading', true);
    return episodesService.get(id).then(
      (response) => {
        commit('setLoading', false);
        return Promise.resolve(response);
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  create({ commit, dispatch }, episode) {
    commit('setLoading', true);
    episodesService.create(episode).then((response) => {
      console.log('NEW EPISODE', response);
      router.push(`/catalogue/${response.program_id}/episodes/${response.id}`);
      commit('setLoading', false);
    });
  },
  delete({ commit, dispatch }, episode) {
    commit('setLoading', true);
    episodesService.deleteEpisode(episode).then(() => {
      commit('setLoading', false);
    });
  },
  processEpisodes(
    { commit, getters, dispatch, rootState },
    { serieId, seasonId, newSeasonEpisodes = [], savedSeasonEpisodes = [] }
  ) {
    commit('setLoading', true);
    const listPromises = [];

    savedSeasonEpisodes.forEach(function (savedSeasonEpisode) {
      const index = newSeasonEpisodes.findIndex(
        (x) => (x.programId = savedSeasonEpisode.programId)
      );
      if (index < 0) {
        listPromises.push(
          episodesService.deleteEpisode(savedSeasonEpisode.episodeProgramId)
        );
      }
    });
    const countryCode = rootState.platforms.currentPlatform.getCountryCode();
    newSeasonEpisodes.forEach(function (newSeasonEpisode) {
      const index = savedSeasonEpisodes.findIndex(
        (x) => (x.programId = newSeasonEpisode.episodeProgramId)
      );
      let program;
      if (index < 0) {
        //var program = (new ProgramTransformer(newSeasonEpisode, metadata)).transform()
        program = EpisodeTransformer.transform(
          {},
          newSeasonEpisode,
          'episodes',
          countryCode
        );
        program.title = program.name;
        program.synopsis = program.description;
        program.serie_id = serieId;
        program.season_id = seasonId;
        program.rank = 0;
        listPromises.push(episodesService.create(program));
      } else {
        //var program = (new ProgramTransformer(newSeasonEpisode, metadata)).transform()
        program = EpisodeTransformer.transform(
          {},
          newSeasonEpisode,
          'episodes',
          countryCode
        );
        program.title = program.name;
        program.synopsis = program.description;
        program.serie_id = serieId;
        program.season_id = seasonId;
        program.rank = 0;
        listPromises.push(
          episodesService.update(program, newSeasonEpisode.episodeProgramId)
        );
      }
    });
    return Promise.all(listPromises).then(
      () => {
        commit('setLoading', false);
        return Promise.resolve();
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  updatePublication({ commit }, { pubPromises }) {
    if (pubPromises.length) {
      commit('setLoading', true);
      return Promise.all(pubPromises).then(
        (response) => {
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (err) => {
          commit('setLoading', false);
          return Promise.reject(err);
        }
      );
    }
    return Promise.resolve();
  },
};

const mutations = {
  setLoading(state, value) {
    state.epLoading = value;
  },
};
export const episodes = {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
