const emailConfigs = [
  {
    id: 1,
    title: 'emails.types.register',
    name: 'register',
    mailable: 'App\\Mails\\RegisterMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.online_link',
        label: 'online_link',
        type: 'string',
      },
      {
        title: 'emails.params.account_link',
        label: 'account_link',
        type: 'string',
      },
      {
        title: 'emails.params.subscription_link',
        label: 'subscription_link',
        type: 'string',
      },
    ],
  },
  {
    id: 2,
    title: 'emails.types.subscription',
    name: 'subscription',
    mailable: 'App\\Mails\\SubscriptionMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.online_link',
        label: 'online_link',
        type: 'string',
      },
      {
        title: 'emails.params.account_link',
        label: 'account_link',
        type: 'string',
      },
      {
        title: 'emails.params.offer_name',
        label: 'offer_name',
        type: 'string',
      },
      {
        title: 'emails.params.grace_period',
        label: 'grace_period',
        type: 'string',
      },
      {
        title: 'emails.params.standard_price',
        label: 'standard_price',
        type: 'string',
      },
      {
        title: 'emails.params.is_standard',
        label: 'is_standard',
        type: 'boolean',
      },
      {
        title: 'emails.params.promo_price',
        label: 'promo_price',
        type: 'string',
      },
      {
        title: 'emails.params.promo_duration',
        label: 'promo_duration',
        type: 'string',
      },
      {
        title: 'emails.params.is_promo',
        label: 'is_promo',
        type: 'boolean',
      },
      {
        title: 'emails.params.prepaid_duration',
        label: 'prepaid_duration',
        type: 'string',
      },
      {
        title: 'emails.params.prepaid_price',
        label: 'prepaid_price',
        type: 'string',
      },
      {
        title: 'emails.params.is_prepaid',
        label: 'is_prepaid',
        type: 'boolean',
      },
      {
        title: 'emails.params.trial_duration',
        label: 'trial_duration',
        type: 'string',
      },
      {
        title: 'emails.params.is_trial',
        label: 'is_trial',
        type: 'boolean',
      },
      {
        title: 'emails.params.subscribe_newsletter',
        label: 'subscribe_newsletter',
        type: 'string',
      },
    ],
  },
  {
    id: 3,
    title: 'emails.types.unsubscription',
    name: 'unsubscription',
    mailable: 'App\\Mails\\UnsubscriptionMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.online_link',
        label: 'online_link',
        type: 'string',
      },
      {
        title: 'emails.params.account_link',
        label: 'account_link',
        type: 'string',
      },
    ],
  },
  {
    id: 4,
    title: 'emails.types.reset_password',
    name: 'reset-password',
    mailable: 'App\\Mails\\ResetPasswordMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.online_link',
        label: 'online_link',
        type: 'string',
      },
      {
        title: 'emails.params.reset_link',
        label: 'reset_link',
        type: 'string',
      },
    ],
  },
  {
    id: 5,
    title: 'emails.types.payment_rejected',
    name: 'payment-rejected',
    mailable: 'App\\Mails\\PaymentRejectedMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.account_link',
        label: 'account_link',
        type: 'string',
      },
    ],
  },
  {
    id: 6,
    title: 'emails.types.credit_card_expire_1d',
    name: 'credit-card-expire-1d',
    mailable: 'App\\Mails\\CreditCardExpire1dMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.account_link',
        label: 'account_link',
        type: 'string',
      },
    ],
  },
  {
    id: 7,
    title: 'emails.types.credit_card_expire_1w',
    name: 'credit-card-expire-1w',
    mailable: 'App\\Mails\\CreditCardExpire1wMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.account_link',
        label: 'account_link',
        type: 'string',
      },
    ],
  },
  {
    id: 8,
    title: 'emails.types.credit_card_expire_1m',
    name: 'credit-card-expire-1m',
    mailable: 'App\\Mails\\CreditCardExpire1mMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.account_link',
        label: 'account_link',
        type: 'string',
      },
    ],
  },
  {
    id: 9,
    title: 'emails.types.credit_card_expired',
    name: 'credit-card-expired',
    mailable: 'App\\Mails\\CreditCardExpiredMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.account_link',
        label: 'account_link',
        type: 'string',
      },
    ],
  },
  {
    id: 10,
    title: 'emails.types.admin_mail_modification',
    name: 'admin-mail-modification',
    mailable: 'App\\Mails\\AdminMailModificationMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.online_link',
        label: 'online_link',
        type: 'string',
      },
      {
        title: 'emails.params.account_link',
        label: 'account_link',
        type: 'string',
      },
    ],
  },
  {
    id: 11,
    title: 'emails.types.admin_password_modification',
    name: 'admin-password-modification',
    mailable: 'App\\Mails\\AdminPasswordModificationMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.online_link',
        label: 'online_link',
        type: 'string',
      },
      {
        title: 'emails.params.account_link',
        label: 'account_link',
        type: 'string',
      },
      {
        title: 'emails.params.reset_link',
        label: 'reset_link',
        type: 'string',
      },
    ],
  },
  {
    id: 12,
    title: 'emails.types.admin_subscription_modification',
    name: 'admin-subscription-modification',
    mailable: 'App\\Mails\\AdminSubscriptionModificationMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.online_link',
        label: 'online_link',
        type: 'string',
      },
      {
        title: 'emails.params.account_link',
        label: 'account_link',
        type: 'string',
      },
      {
        title: 'emails.params.offer_name',
        label: 'offer_name',
        type: 'string',
      },
      {
        title: 'emails.params.periodicity',
        label: 'periodicity',
        type: 'string',
      },
      {
        title: 'emails.params.price',
        label: 'price',
        type: 'string',
      },
      {
        title: 'emails.params.next_payment_date',
        label: 'next_payment_date',
        type: 'string',
      },
    ],
  },
  {
    id: 13,
    title: 'emails.types.credit_card_expiring',
    name: 'credit-card-expiring',
    mailable: 'App\\Mails\\CreditCardExpiringMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.account_link',
        label: 'account_link',
        type: 'string',
      },
      {
        title: 'emails.params.expiring_delay',
        label: 'expiringDelay',
        type: 'string',
      },
    ],
  },
  {
    id: 14,
    title: 'emails.types.init_password',
    name: 'init-password',
    mailable: 'App\\Mails\\InitPasswordMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.reset_link',
        label: 'reset_link',
        type: 'string',
      },
      {
        title: 'emails.params.discover_link',
        label: 'discover_link',
        type: 'string',
      },
      {
        title: 'emails.params.catalog_link',
        label: 'catalog_link',
        type: 'string',
      },
      {
        title: 'emails.params.apps_link',
        label: 'app_links',
        type: 'string',
      },
    ],
  },
  {
    id: 15,
    title: 'emails.types.subscription_suspended',
    name: 'subscription-suspended',
    mailable: 'App\\Mails\\SubscriptionSuspendedMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.account_link',
        label: 'account_link',
        type: 'string',
      },
    ],
  },
  {
    id: 16,
    title: 'emails.types.admin_account_access_granted',
    name: 'admin-account-access-granted',
    mailable: 'App\\Mails\\AdminAccountAccessGrantedMail',
    params: [
      {
        title: 'emails.params.admin_email',
        label: 'adminEmail',
        type: 'string',
      },
      {
        title: 'emails.params.access_email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.online_link',
        label: 'online_link',
        type: 'string',
      },
      {
        title: 'emails.params.platform_logo',
        label: 'platform_logo',
        type: 'string',
      },
      {
        title: 'emails.params.cta_button_url',
        label: 'ctaButtonUrl',
        type: 'string',
      },
    ],
  },
  {
    id: 17,
    title: 'emails.types.admin_account_register',
    name: 'admin-account-register',
    mailable: 'App\\Mails\\AdminAccountRegisterMail',
    params: [
      {
        title: 'emails.params.admin_email',
        label: 'adminEmail',
        type: 'string',
      },
      {
        title: 'emails.params.access_email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.online_link',
        label: 'online_link',
        type: 'string',
      },
      {
        title: 'emails.params.platform_logo',
        label: 'platform_logo',
        type: 'string',
      },
      {
        title: 'emails.params.cta_button_url',
        label: 'ctaButtonUrl',
        type: 'string',
      },
    ],
  },
  {
    id: 18,
    title: 'emails.types.admin_account_reset_password',
    name: 'admin-account-reset-password',
    mailable: 'App\\Mails\\AdminAccountResetPasswordMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.online_link',
        label: 'online_link',
        type: 'string',
      },
      {
        title: 'emails.params.cta_button_url',
        label: 'cta_button_url',
        type: 'string',
      },
    ],
  },
  {
    id: 19,
    title: 'emails.types.invitation_fr',
    name: 'invitation-fr',
    mailable: 'App\\Mails\\InvitationFrenchMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.lastname',
        label: 'lastname',
        type: 'string',
      },
      {
        title: 'emails.params.password',
        label: 'password',
        type: 'string',
      },
    ],
  },
  {
    id: 20,
    title: 'emails.types.invitation_en',
    name: 'invitation-en',
    mailable: 'App\\Mails\\InvitationEnglishMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.lastname',
        label: 'lastname',
        type: 'string',
      },
      {
        title: 'emails.params.password',
        label: 'password',
        type: 'string',
      },
    ],
  },
  {
    id: 21,
    title: 'emails.types.contact_form',
    name: 'contact-form',
    mailable: 'App\\Mails\\ContactFormMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.lastname',
        label: 'lastname',
        type: 'string',
      },
      {
        title: 'emails.params.reply_email',
        label: 'replyemail',
        type: 'string',
      },
      {
        title: 'emails.params.company',
        label: 'company',
        type: 'string',
      },
      {
        title: 'emails.params.message',
        label: 'message',
        type: 'string',
      },
    ],
  },
  {
    id: 22,
    title: 'emails.types.transaction',
    name: 'transaction',
    mailable: 'App\\Mails\\TransactionMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.online_link',
        label: 'online_link',
        type: 'string',
      },
      {
        title: 'emails.params.account_link',
        label: 'account_link',
        type: 'string',
      },
      {
        title: 'emails.params.transaction_link',
        label: 'transaction_link',
        type: 'string',
      },
      {
        title: 'emails.params.product_name',
        label: 'product_name',
        type: 'string',
      },
      {
        title: 'emails.params.product_price',
        label: 'product_price',
        type: 'string',
      },
      {
        title: 'emails.params.product_quality',
        label: 'product_quality',
        type: 'string',
      },
      {
        title: 'emails.params.rental_start_duration',
        label: 'rental_start_duration',
        type: 'string',
      },
      {
        title: 'emails.params.rental_duration',
        label: 'rental_duration',
        type: 'string',
      },
    ],
  },
  {
    id: 23,
    title: 'emails.types.payment_rejected_first_attempt',
    name: 'payment-rejected-first-attempt',
    mailable: 'App\\Mails\\PaymentRejectedFirstAttemptMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.account_link',
        label: 'account_link',
        type: 'string',
      },
    ],
  },
  {
    id: 24,
    title: 'emails.types.account_deleted',
    name: 'account-deleted',
    mailable: 'App\\Mails\\AccountDeletedMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.online_link',
        label: 'online_link',
        type: 'string',
      },
    ],
  },
  {
    id: 25,
    title: 'emails.types.prepaid_expire_1d',
    name: 'prepaid-expire-1d',
    mailable: 'App\\Mails\\PrepaidExpire1dMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.online_link',
        label: 'online_link',
        type: 'string',
      },
    ],
  },
  {
    id: 26,
    title: 'emails.types.block_form',
    name: 'block-form',
    mailable: 'App\\Mails\\BlockFormMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.block_title',
        label: 'blockTitle',
        type: 'string',
      },
      {
        title: 'emails.params.form_fields',
        label: 'formFields',
        type: 'string'
      }
    ],
  },
  {
    id: 27,
    title: 'emails.types.subscription_reminder',
    name: 'subscription-reminder',
    mailable: 'App\\Mails\\SubscriptionReminderMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.expire_date',
        label: 'expire_date',
        type: 'string'
      },
    ],
  },
  {
    id: 28,
    title: 'emails.types.admin_subscription',
    name: 'admin-subscription',
    mailable: 'App\\Mails\\AdminSubscriptionMail',
    params: [
      {
        title: 'emails.params.email',
        label: 'email',
        type: 'string',
      },
      {
        title: 'emails.params.firstname',
        label: 'firstname',
        type: 'string',
      },
      {
        title: 'emails.params.discount_campaign_name',
        label: 'discount_campaign_name',
        type: 'string'
      },
    ],
  },
];
export default emailConfigs;
