import { handleResponse } from '@/services/index.js';
import { isNil } from 'lodash';
import axios from 'axios';

const api_url_module = '/admins/';

export const adminService = {
  getAll,
  get,
  create,
  update,
  deleteOne,
  //delete
};

async function getAll(queryString = '', personalToken) {
  if (queryString !== '') {
    queryString = '?' + queryString;
  }
  try {
      let response =  await axios.get(
        import.meta.env.VITE_USER_BASE_URL + '/admins' + queryString,
          { headers: { 'personal-token': personalToken } }
        );
        response = response.data;

        // TODO update this when user service will be updated in production
        if (
          typeof response.results === 'undefined' &&
          typeof response.data !== 'undefined'
        ) {
          response = {
            status: 'success',
            results: response,
          };
        }
      return handleResponse(response);
    } catch (err) {
      return handleResponse(err); 
    }
}

async function get(id, personalToken) {
  try {
    /*[GET]/admins/{id} requires transform because this header is not set by default in USER service as there is two different
    transformers availables: "transformer" and "transformer BO"*/
    const response = await axios.get(
      import.meta.env.VITE_USER_BASE_URL + api_url_module + id,
      { headers: { transform: 1 , 'personal-token': personalToken } }
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function create(item, personalToken) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'personal-token': personalToken
    },
  };

  item.source = 'bo';

  try {
    const response = await axios.post(
      import.meta.env.VITE_USER_BASE_URL + api_url_module,
      item,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function update(item, id, personalToken) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'personal-token': personalToken
    },
  };

  if (!isNil(item.password)) {
    item.password_confirmation = item.password;
  }

  item.source = 'bo';

  try {
    const response = await axios.put(
      import.meta.env.VITE_USER_BASE_URL + api_url_module + id,
      item,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function deleteOne(id, personalToken) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'personal-token': personalToken
    },
  };

  try {
    const response = await axios.delete(
      import.meta.env.VITE_USER_BASE_URL + api_url_module + id,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}
