const state = {
  loading: false,
};

const actions = {
  async save({ commit, dispatch }) {
    console.log('personProfiles save');
    commit('setLoading', true);
    var promisePersons = dispatch('persons/update', null, { root: true });
    var promiseDelete = dispatch('programMetadatas/deleteFromList', null, {
      root: true,
    });
    var promiseAdd = dispatch('programMetadatas/addFromList', null, {
      root: true,
    });

    return Promise.all([promisePersons, promiseDelete, promiseAdd]).then(
      (values) => {
        commit('setLoading', false);
        return Promise.resolve(values);
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  refresh({ dispatch }) {
    console.log(' --> personProfiles refresh');
    const promises = [];
    promises.push(dispatch('persons/refresh', null, { root: true }));
    promises.push(dispatch('programMetadatas/resetList', null, { root: true }));
    return Promise.all(promises).then(
      () => {
        return Promise.resolve();
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  setLoading(state, value) {
    state.loading = value;
  },
};

const getters = {
  shouldSaved: (state, getters, rootState, rootGetters) => {
    let retval =
      rootGetters['persons/shouldSaved'] ||
      rootGetters['programMetadatas/shouldSaved'];
    return retval;
  },
};

export const personProfiles = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
