import { Subscription } from '@/models/billing/Subscription.js';
import moment from 'moment-timezone';

export class Customer {
  id;
  user_id;
  email;
  status;
  customer_type;
  subscribed_at;
  unsubscribed_at;
  subscriptions;

  constructor({
    id = undefined,
    user_id = undefined,
    email = undefined,
    status = undefined,
    customer_type = undefined,
    subscribed_at = null,
    unsubscribed_at = null,
    stripe_customer_id = null,
    subscriptions = [],
  }) {
    this.id = id;
    this.user_id = user_id;
    this.email = email;
    this.status = status;
    this.customer_type = customer_type;
    this.subscribed_at = subscribed_at === null ? null : moment.tz(subscribed_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
    this.unsubscribed_at = unsubscribed_at === null ? null : moment.tz(unsubscribed_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
    this.stripe_customer_id = stripe_customer_id;
    this.subscriptions = subscriptions.map((item) => new Subscription(item));
  }
}

export function createCustomer(data) {
  return Object.freeze(new Customer(data));
}

export default Customer;
