import { GenericBillingService } from '@/services';
import { GroupPlan } from '../../models/billing/GroupPlan';
import { cloneDeep, isEmpty } from 'lodash';
import { hasDifference } from '@/utils/diffTools';

const groupPlanService = new GenericBillingService('group-plans');
const planService = new GenericBillingService('plans');

const state = {
  loading: false,
  reload: 0,

  allSaved: {
    data: [],
    meta: {
      pagination: {
        total_pages: 0,
        per_page: 0,
        total: 0,
        count: 0,
        current_page: 1,
      },
    },
  },

  formattedOne: null,
  currentOne: new GroupPlan({}),
  savedOne: new GroupPlan({}),
};

const getters = {
  loading: (state) => {
    return state.loading;
  },
  shouldSaveOne: (state) => {
    return hasDifference(state.currentOne, state.savedOne);
  },
  couldSaveOne: (state) => {
    return (
      state.currentOne.name.length > 3 &&
      ['unlimited', 'limited'].includes(
        state.currentOne.simultaneous_watch_class
      ) &&
      (state.currentOne.simultaneous_watch > 0 ||
        state.currentOne.simultaneous_watch_class === 'unlimited') &&
      ['partial', 'complete'].includes(state.currentOne.access_type)
    );
  },
};

const actions = {
  search({ commit }, { count = 10, page, keyword, sort, facets }) {
    commit('searchRequest');
    let queryString = 'count=' + count + '&page=' + page + '&sort=' + sort;
    if (keyword.length > 0) {
      queryString += '&query=' + keyword;
    }
    if (facets.length > 0) {
      let filters = [];
      facets.forEach((facet) => {
        if (typeof filters[facet.facet_id] === 'undefined') {
          filters[facet.facet_id] = [];
        }
        filters[facet.facet_id].push(facet.id);
      });
      Object.keys(filters).forEach(function (key) {
        queryString +=
          '&' +
          key +
          '=' +
          (filters[key].length > 1
            ? 'in:' + filters[key].join(',')
            : filters[key][0]);
      });
    }
    return groupPlanService.getAll(queryString).then(
      (groupPlans) => {
        commit('searchSuccess', groupPlans);
        return Promise.resolve(groupPlans);
      },
      (error) => {
        commit('searchFailure', error);
        return Promise.reject(error);
      }
    );
  },
  get({ commit }, id) {
    commit('getRequest');
    return groupPlanService.get(id).then(
      (groupPlan) => {
        commit('getSuccess', groupPlan);
        return Promise.resolve(groupPlan);
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject(error);
      }
    );
  },
  create({ commit, dispatch }) {
    commit('createRequest');
    return groupPlanService.create(state.formattedOne).then(
      (groupPlan) => {
        commit('createSuccess', groupPlan);
        return Promise.resolve(groupPlan);
      },
      (error) => {
        const alert = {
          id: 'group-plan-creation-request-rejected',
          icon: 'close',
          type: 'error',
          message:
            error?.errors?.name?.[0] ?? 'marketing.pages.groupPlan.alert',
        };
        dispatch('displayAlert', alert, { root: true });
        commit('createFailure', error);
        return Promise.reject(error);
      }
    );
  },
  delete({ commit }, id) {
    commit('deleteRequest');
    return groupPlanService.delete(id).then(
      (groupPlan) => {
        commit('deleteSuccess', groupPlan);
        return Promise.resolve(groupPlan);
      },
      (error) => {
        commit('deleteFailure', error);
        return Promise.reject(error);
      }
    );
  },
  export({ commit, rootState, dispatch }, id) {
    let payload = {
      user_id: rootState.auth.authUser.id,
    };
    if (id !== null && !isNaN(id)) {
      payload.group_plan_id = id;
    }
    commit('exportRequest');
    return planService.export(payload).then(
      () => {
        const alert = {
          id: 'group-plan-export-request-accepted',
          icon: 'check',
          type: 'valid',
          message: 'marketing.pages.plans.search.export.alert.accepted',
        };
        dispatch('displayAlert', alert, { root: true });
        commit('exportSuccess');
        return Promise.resolve();
      },
      (error) => {
        const alert = {
          id: 'group-plan-export-request-rejected',
          icon: 'close',
          type: 'error',
          message: 'marketing.pages.plans.search.export.alert.rejected',
        };
        dispatch('displayAlert', alert, { root: true });
        commit('exportFailure', error);
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  reset(state) {
    state.allSaved = {
      data: [],
      meta: {
        pagination: {
          total_pages: 0,
          per_page: 0,
          total: 0,
          count: 0,
          current_page: 1,
        },
      },
    };
    state.reload = 0;
    state.loading = false;
    state.formattedOne = null;
    state.currentOne = new GroupPlan({});
    state.savedOne = new GroupPlan({});
  },
  setLoading(state, value) {
    state.loading = value;
  },

  searchRequest(state) {
    state.loading = true;
    state.allSaved = {
      data: [],
      meta: {
        pagination: {
          total_pages: 0,
          per_page: 0,
          total: 0,
          count: 0,
          current_page: 1,
        },
      },
    };
  },
  searchSuccess(state, groupPlans) {
    let datasBrut = cloneDeep(groupPlans);
    let datasClean = [];

    datasBrut.data.forEach(function (dataBrut) {
      datasClean.push(new GroupPlan(dataBrut));
    });

    state.allSaved.data = cloneDeep(datasClean);
    state.allSaved.meta.pagination.total_pages =
      datasBrut.meta.pagination.total_pages ?? 1;
    state.allSaved.meta.pagination.per_page =
      datasBrut.meta.pagination.per_page ?? 10;
    state.allSaved.meta.pagination.total = datasBrut.meta.pagination.total ?? 0;
    state.allSaved.meta.pagination.count = datasBrut.meta.pagination.count ?? 0;
    state.allSaved.meta.pagination.current_page =
      datasBrut.meta.pagination.current_page ?? 0;
    state.loading = false;
  },
  searchFailure(state) {
    state.allSaved = {
      data: [],
      meta: {
        pagination: {
          total_pages: 0,
          per_page: 0,
          total: 0,
          count: 0,
          current_page: 1,
        },
      },
    };
    state.loading = false;
  },

  deleteRequest(state) {
    state.loading = true;
  },
  deleteSuccess(state) {
    state.currentOne = new GroupPlan({});
    state.savedOne = new GroupPlan({});
    state.reload++;
  },
  deleteFailure(state) {
    state.loading = false;
  },

  getRequest(state) {
    state.loading = true;
  },
  getSuccess(state, groupPlan) {
    state.loading = false;
    state.currentOne = new GroupPlan(groupPlan);
    state.savedOne = new GroupPlan(groupPlan);
    state.reload++;
  },
  getFailure(state) {
    state.currentOne = new GroupPlan({});
    state.savedOne = new GroupPlan({});
    state.loading = false;
  },

  createRequest(state) {
    state.loading = true;
    state.formattedOne = {
      name: state.currentOne.name,
      simultaneous_watch:
        state.currentOne.simultaneous_watch_class === 'limited'
          ? state.currentOne.simultaneous_watch
          : null,
      access_type: state.currentOne.access_type,
    };
  },
  createSuccess(state, groupPlan) {
    state.loading = false;
    state.currentOne = new GroupPlan(groupPlan);
    state.savedOne = new GroupPlan(groupPlan);
    state.reload++;
  },
  createFailure(state) {
    const newGroupPlan = new GroupPlan({});
    state.currentOne = cloneDeep(newGroupPlan);
    state.savedOne = cloneDeep(newGroupPlan);
    state.loading = false;
  },

  exportRequest(state) {
    state.loading = true;
  },
  exportSuccess(state) {
    state.loading = false;
  },
  exportFailure(state) {
    state.loading = false;
  },

  setCurrentGroupOfferById(state, groupPlanId) {
    let groupPlans = cloneDeep(state.all.data);
    let groupPlan = groupPlans.find((x) => x.id === groupPlanId);
    state.currentOne = groupPlan;
    state.savedOne = cloneDeep(groupPlan);
  },
};

export const groupPlans = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
