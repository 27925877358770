<template>
  <vue-final-modal
    data-cy="modal"
    v-slot="{ params, close }"
    v-bind="$attrs"
    classes="modal-container !left-2 !md:left-20 !right-2"
    :esc-to-close="false"
    :click-to-close="false"
    :transition="{
      'enter-active-class': 'transition-all duration-75 ease-in-out transform',
      'enter-from-class': 'scale-90 translate-y-2 opacity-0',
      'enter-to-class': 'scale-100 opacity-100',
      'leave-active-class': 'transition-all duration-300 ease-in-out transform',
      'leave-to-class': 'scale-90 translate-y-2 opacity-0',
      'leave-from-class': 'scale-100 opacity-100',
    }"
    :fit-parent="true"
    drag-selector=".modal-title"
    :content-class="contentClasses"
    overlay-class="backdrop-blur-[2px] !bg-skin-overlay !bg-opacity-50"
    :max-height="100"
    :focus-trap="false"
    :focus-retain="false"
  >
    <div class="modal" :class="modalClasses" style="--page-header-height: 78px">
      <header data-cy="modal-header" 
        id="modal-header" class="modal-header bg-skin-ui z-50">
        <slot name="header" v-bind="{ params, close }" />
        <BaseButton
          v-if="showCloseButton && size !== 'fs' && size !== 'lgfs'"
          class="modal-close-btn focus:ring-skin-ui-hover focus:ring-4 p-0 rounded-full content-center !absolute"
          nude
          size="xs"
          @click="hideModal(close)"
        >
          <Icon icon="close" />
        </BaseButton>
      </header>
      <div
        v-if="$slots['content']"
        id="modal-content"
        class="modal-content"
        :class="[!$slots['header'] && '!pt-10', !$slots['footer'] && 'pb-10']"
      >
        <div
          v-if="$slots['picto']"
          class="modal-picto flex place-content-center mx-auto mb-4 text-center"
        >
          <slot name="picto" />
        </div>
        <slot name="content" v-bind="{ params, close }" />
      </div>
      <footer
        v-if="$slots['footer']"
        id="modal-footer"
        class="modal-footer bg-skin-ui"
      >
        <div
          id="modal-footer-actions"
          class="btn-container"
          :class="
            size === 'sm' ? 'btn-container--column' : 'btn-container--row'
          "
        >
          <slot name="footer" v-bind="{ params, close }" />
        </div>
      </footer>
    </div>
  </vue-final-modal>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { themeClasses } from '@/utils/styles/styles.js';
import BaseButton from '@/components/button/BaseButton.vue';
import Icon from '@/components/icon/Icon.vue';

const route = useRoute();

const props = defineProps({
  overflow: {
    type: String,
    default: 'auto',
  },
  size: {
    type: String,
    default: 'md',
  },
  config: {
    type: Object,
  },
  theme: {
    type: String,
  },
  sticky: {
    type: Boolean,
    default: false,
  },
  showCloseButton: {
    type: Boolean,
    default: true,
  },
  params: {
    type: Object,
  },
  heightAuto: {
    type: Boolean,
    default: false,
  },
  close: {
    type: Function,
  },
});

const modalTheme = computed(() => {
  return props.theme ?? route?.matched[0]?.meta?.theme ?? 'primary';
});

const modalClasses = computed(() => {
  return [
    themeClasses[modalTheme.value],
    props.size && `modal-${props.size}`,
    props.sticky && `modal-sticky`,
    props.overflow && `modal-overflow-${props.overflow}`,
  ];
});
function hideModal(close) {
  if (typeof props.close === 'function') 
    props.close();
  close();
};

const contentClasses = computed(() => {
  return [
  'shadow-lg shadow-skin-background rounded bg-skin-ui',
    props.heightAuto ? 'h-auto' : 'lg:max-h-[90vh]',
  ];
});

</script>

<style lang="scss">
@import 'modal.scss';
</style>
