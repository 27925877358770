<template>
  <input
    :data-cy="`input-${id}`"
    ref="refs"
    :disabled="disabled"
    :readonly="readonly"
    class="otto-base-ui-component peer leading-[22px] transition-all grow read-only:bg-gray-100 read-only:hover:border-gray-300"
    :class="[
      {
        'tabular-nums': inputType === 'number',
      },
      ...uiElementFieldClass,
      ...uiElementTextStylesClass,
      uiElementSizeClass,
      uiElementThemeClass,
      square && 'max-w-[2rem] max-h-[2rem] !px-0',
      !square && 'max-w-full',
    ]"
    :placeholder="placeholder"
    :value="modelValue"
    :required="required"
    :minlength="minlength > 0 ? minlength : 0"
    :maxlength="maxlength > 0 ? maxlength : 1e6"
    :min="min"
    :max="max"
    :step="step"
    v-bind="$attrs"
    :type="inputType"
    @input="update($event)"
    @keyup="handleKeyup"
    @focus="focus($event)"
    @blur="update($event, true)"
  />

  <div
    v-if="displayCounter && maxlength > 0"
    id="display-counter"
    class="px-4 inline-flex items-center min-w-fit absolute right-0"
    :class="uiElementCharacterCounterClass"
  >
    {{ uiElementCharacterCounter }}
  </div>
  <div
    v-if="valid"
    id="valid-icon"
    class="px-4 inline-flex items-center min-w-fit absolute right-0"
    :class="[
      {
        'rounded-r': !($slots['footer'] && $slots['button']),
      },
    ]"
  >
    <Icon icon="check" color="success" />
  </div>
  <div
    v-if="error"
    id="error-icon"
    class="px-4 inline-flex items-center min-w-fit absolute right-0"
    :class="[
      {
        'rounded-r': !($slots['footer'] && $slots['button']),
      },
    ]"
  >
    <Icon icon="close" color="danger" />
  </div>

  <div
    v-if="passwordInputIconAfter && disclosable"
    id="password-disclose-icon"
    class="px-4 inline-flex items-center min-w-fit border absolute right-0"
    @click="toggleReveal()"
  >
    <Icon :icon="passwordInputIconAfter" color="black" size="md" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch} from 'vue';
import { useUiElement } from '@/composables/useUiElement.js';
import Icon from '@/components/icon/Icon.vue';

interface Props {
  id?: string;
  type?: 'text' | 'password' | 'email' | 'number';
  disabled?: boolean;
  readonly?: boolean;
  modelValue?: string | number;
  required?: boolean;
  size?: string;
  square?: boolean;
  autofocus?: boolean;
  minlength?: number | null;
  maxlength?: number | null;
  displayCounter?: boolean;
  disclosable?: boolean;
  step?: number;
  valid?: boolean;
  error?: boolean;
  placeholder?: string;
  styles?: Record<string, unknown>;
  theme?: string;
  min?: number | null;
  max?: number | null;
};

const emit = defineEmits<{
  (e: 'focus', event: FocusEvent): void;
  (e: 'blur', value?: string | number): void;
  (e: 'reset'): void;
  (e: 'update:modelValue', value: string | number): void;
  (e: 'keyup', value: string | number): void;
  (e: 'enter', value: string | number): void;
}>();

const props = withDefaults(defineProps<Props>(), {
  id: () => `id-${Math.floor(Math.random() * 10000)}`,
  size: 'md',
  type: 'text',
  valid: false,
  square: false,
  disabled: false,
  readonly: false,
  required: false,
  displayCounter: false,
  disclosable: false,
  minlength: null,
  maxlength: null,
  min: null,
  max: null,
});

const refs = ref<HTMLInputElement | null>(null);
const disclosedPassword = ref<boolean>(false);

const inputType = computed<string>(() => {
  return disclosedPassword.value 
    ? 'text' 
    : props.type ?? 'text';
});

const focused = computed<boolean>(() => props.autofocus ?? false);

const focus = ($event: FocusEvent) => {
  emit('focus', $event);
};

const handleKeyup = ($event: KeyboardEvent) => {
  const target = $event.target as HTMLInputElement;
  if ($event.key === 'Enter') {
    emit('enter', target.value);
  }
  emit('keyup', target.value);
};

const update = ($event: Event, emitBlur = false) => {
  const target = $event.target as HTMLInputElement;
  emit('update:modelValue', target.value);
  if (emitBlur) {
    emit('blur', target.value);
  }
};

const passwordInputIconAfter = computed<string>(() => {
  return disclosedPassword.value 
    ? 'password-eye-slash' 
    : 'password-eye';
});

const toggleReveal = () => {
  disclosedPassword.value = !disclosedPassword.value;
};

onMounted(() => {
  if (props.autofocus) {
    setTimeout(() => {
      refs.value?.focus();
    }, 200);
  }
});

watch(focused, () => {
  if (focused.value) {
    setTimeout(() => {
      refs.value?.focus();
    });
  }
});

const {
  uiElementSizeClass,
  uiElementFieldClass,
  uiElementThemeClass,
  uiElementCharacterCounter,
  uiElementCharacterCounterClass,
  uiElementTextStylesClass,
} = useUiElement(props);

</script>

<style lang="scss">
input:-internal-autofill-selected {
  background: transparent;
}
</style>
