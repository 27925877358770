import { cloneDeep } from 'lodash';


export class MetadataDetailTransformer {
  _metadataDetail;

  constructor(metadataDetail) {
    this._metadataDetail = cloneDeep(metadataDetail);
  }

  transform() {
    this._metadataDetail.clickable = this._metadataDetail.visible_front
      ? this._metadataDetail.clickable
      : false;

    this._metadataDetail.filterable =
      this._metadataDetail.visible_front &&
      this._metadataDetail.filterable;

    this._metadataDetail.title = this._metadataDetail.frontTitle;

    this._metadataDetail.type = this._metadataDetail.metadataType;
    this._metadataDetail.visible_back = this._metadataDetail.activated;
    if (this._metadataDetail.metadataType === 'text') {
      this._metadataDetail.settings.size_max =
        this._metadataDetail.settings.size_max
          ? parseInt(this._metadataDetail.settings.size_max)
          : 5000;
      this._metadataDetail.settings.size_recommended =
        this._metadataDetail.settings.size_recommended
          ? parseInt(this._metadataDetail.settings.size_recommended)
          : null;
    }

    this._metadataDetail.group_environments = 
      this._metadataDetail.group_environments.map(env => env.id);

    delete this._metadataDetail.metadataType;
    delete this._metadataDetail.activated;
    delete this._metadataDetail.frontTitle;
    
    return this._metadataDetail;
  }
}