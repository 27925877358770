<template>
  <Card
    v-if="item"
    :wrapper="wrapper"
    class="flex-1 text-center select-none"
    content-class="flex flex-1 self-stretch"
  >
    <MouseHover
      :disable="false"
      :active="!!item.message"
      :message="item.message"
      :with-background="true"
      message-type="info"
      icon=""
      class="flex-1 flex flex-col justify-between self-stretch"
    >
      <div class="flex flex-1 flex-col justify-between">
        <h3
          v-if="header && item.title"
          class="flex items-center text-xl font-medium leading-normal self-start mb-10 text-skin-accent"
        >
          <template
            v-if="item.id === 'transactions' && !wrapper && item.label"
          >
            {{ $t(item.label) }}
          </template>
          <template v-else>
            {{ $t(item.title) }}
          </template>
          <Tooltip
            v-if="item.tooltip"
            :message="item.tooltip"
            position="right"
            icon="info-circle"
            class="ml-2"
          />
        </h3>
        <div
          v-if="item.type === 'pie'"
          class="flex flex-col items-center text-center w-full gap-2"
        >
          <PieChart
            :width="200"
            :height="200"
            :data="item.data"
            :legend="legend"
          />
        </div>
        <div v-if="loadingKpiType && header">
          <LoaderSmall class="flex-1" />
        </div>
        <div
          v-else-if="item.type === 'summary'"
          class="flex flex-col items-center text-center w-full gap-2"
        >
          <template v-if="item.icon">
            <div v-if="item.icon === 'video-flat'" class="mb2">
              <Icon size="xxl" :icon="item.icon" />
            </div>
            <div
              v-else
              class="flex items-center justify-center mt-2 mb-4 rounded-full bg-skin-action-muted h-14 w-14"
              :style="badgeStyle"
            >
              <Icon
                size="lg"
                :icon="item.icon"
                :color="item.iconColor"
                :action-color="item.actionColor"
                :action="item.action"
                action-size="md"
              />
            </div>
          </template>
          <div v-if="item.illustration" class="mb-2">
            <Illustration :illustration="item.illustration" />
          </div>
          <div v-if="cumulative" class="text-[32px] font-black">
            {{ cumulative }}<span v-if="item.unit">{{ item.unit }}</span>
          </div>
          <div v-else ref="refValue" class="text-[32px] font-black">
            {{ summaryValue }}
          </div>
          <div
            v-if="item.label && item.id !== 'transactions'"
            class="text-sm mb-6"
          >
            {{ $t(item.label) }}
          </div>
        </div>
        <footer
          class="text-center flex flex-col items-center text-xs leading-tight text-skin-muted"
        >
          <div
            v-if="!item.footer && (!header || item.id === 'transactions')"
            class="text-base font-bold flex items-center text-skin-muted"
            :class="evolutionClass"
          >
            <Icon
              v-if="hasEvolutionIcon"
              size="md"
              :icon="evolutionIcon"
              class="mr-1"
            />{{ evolutionSign }}{{ evolutionValue }}%
            <sup class="text-xxs">*</sup>
          </div>
          <div v-if="header" class="italic">
            <template v-if="item.footer">
            {{ $t('kpis.card.range.title') }} : <br />
            {{ formatDate(item.start) }} - {{ formatDate(item.end) }}
              </template>
            <template v-else>
            {{ formatEndDate(item.end) }}
            </template>
          </div>
        </footer>
      </div>
    </MouseHover>
  </Card>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import * as d3 from 'd3';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import Card from '@/components/card/Card.vue';
import Icon from '@/components/icon/Icon.vue';
import Illustration from '@/components/illustration/Illustration.vue';
import LoaderSmall from '@/components/loader/LoaderSmall.vue';
import MouseHover from '@/components/mouse/MouseHover.vue';
import PieChart from '@/components/charts/PieChart.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import anime from 'animejs';

const store = useStore();
const { t, n } = useI18n();

const props = defineProps({
  item: {
    type: Object,
  },
  wrapper: {
    type: Boolean,
    default: false,
  },
  kpiType: {
    type: String,
    required: true,
  },
  path: {
    type: String,
    default: '',
  },
  header: {
    type: Boolean,
    default: true,
  },
  legend: {
    type: Boolean,
    default: true,
  },
  cumulative: {
    type: Number,
    default: null,
  },
  footer: {
    type: Boolean,
    default: false,
  },
});

const refValue = ref('');

const locale = computed(() => store.state.kpis.locale);
const loadingKpiType = computed(() => store.state[props.kpiType + 'Kpis']?.loading);
const errorKpiType = computed(() => store.state[props.kpiType + 'Kpis']?.error);

function formatDate(date) {
  d3.timeFormatDefaultLocale(locale.value.fr);
  const parseTime = d3.timeParse(timeParseVal.value)
  return d3.timeFormat(timeFormatValFormatted.value)(parseTime(date));
}
function formatEndDate(date) {
  d3.timeFormatDefaultLocale(locale.value.fr);
  let prefix = 'date.at';
  switch (timeFormatValFormatted.value) {
    case '%B %Y':
      prefix = 'date.in';
      break;
    case '%d/%m/%Y':
      prefix = 'date.at';
      break;
  }
  const parseTime = d3.timeParse(timeParseVal.value)
  const formattedDate = d3.timeFormat(timeFormatValFormatted.value)(parseTime(date));
  return t(prefix, {date:formattedDate});
};
function animateKpis(newVal, oldVal) {
  const $el = refValue.value;
  if (oldVal == undefined) {
    oldVal = 0;
  }
  anime({
    targets: $el,
    innerText: [oldVal, newVal],
    easing: 'linear',
    round: true,
    duration: 4000,
  });
};
function invertEvolution(evolution) {
  switch(evolution) {
    case 'positive': return 'negative';
    case 'negative': return 'positive';
    default: return 'equal';
  } 
};

const timeParseVal = computed(
  () => store.getters['subscribedKpis/timeParseVal']
);
const timeFormatVal = computed(
  () => store.getters['subscribedKpis/timeFormatVal']
);
    
const timeFormatValFormatted = computed(() => {
  if(['registered', 'subscribed'].includes(props.item?.id)) {
    return '%d/%m/%Y';
  }
  let _timeFormatVal = timeFormatVal.value; 
  switch (_timeFormatVal) {
    case '%b %y':
      _timeFormatVal = '%B %Y';
      break;
    case '%d/%m':
      _timeFormatVal = '%d/%m/%Y';
      break;
    case 'S%W':
      _timeFormatVal = '%d/%m/%Y';
      break;
  }
  return _timeFormatVal
});

const badgeStyle = computed(() => {
  return {
    backgroundColor: props.item.color ? `${props.item.color}1a` : `#eee`,
  };
});
const summaryValue = computed(() => {
  if (
    props.item?.value && 
    (props.item?.show || !props.header) &&
    !errorKpiType.value
  ) {
    /*const value = props.item.value;
    const currency = t('price.currency');
    return props.item?.valueType === 'currency'
      ? n(value, 'currency', currency)
      : n(props.item.value);
    */
    return n(props.item.value);
  } else {
    return 0;
  }
});

const evolutionValue = computed(() => {
  let value = +(props.item?.evolution_value ?? 0);
  return value.toFixed(2).replace(/[.,]00$/, "");
});
const evolutionIcon = computed(() => {
  return props.item.evolution === 'positive'
    ? 'arrow-up-rounded'
    : 'arrow-down-rounded';
});
const hasEvolutionIcon = computed(() => {
  return props.item.evolution !== 'equal';
});
const evolutionSign = computed(() => {
  let sign = '';
  switch (props.item.evolution) {
    case 'equal':
      sign = '';
      break;
    case 'negative':
      sign = '';
      break;
    case 'positive':
      sign = '+';
      break;
  }
  return sign;
});
const evolutionClass = computed(() => {
  let evolution = props.item.evolution;
  if (props.item?.id === 'unsubscribed') {
    evolution = invertEvolution(evolution)
  }
  return [
    evolution === 'positive' && '!text-success-500',
    evolution === 'negative' && '!text-danger-500',
    evolution === 'equal' && '!text-skin-base',
  ];
});



watch(loadingKpiType, (value) => {
  if (!value) {
    animateKpis(summaryValue.value, 0);
  }
});
</script>
