import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/ingests/';

export const ingestService = {
  getAll,
  update,
  create,
  archive,
  launch,
  //delete
};

async function getAll(path) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_MEDIA_BASE_URL + api_url_module + path
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function create(ingestData) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_MEDIA_BASE_URL + api_url_module,
      ingestData,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function update(ingestUpdateData, ingestId) {
  const requestOptions = {
    method: 'PUT',
  };
  try {
    const response = await axios.put(
      import.meta.env.VITE_MEDIA_BASE_URL + api_url_module + ingestId,
      ingestUpdateData,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function launch(ingestProcessData) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_MEDIA_BASE_URL + api_url_module + 'process',
      ingestProcessData,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function archive(ingestId) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_MEDIA_BASE_URL +
        api_url_module +
        ingestId +
        '/archive',
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}
