<template>
  <button
    :data-cy="`remove-btn-${props.id}`"
    class="flex items-center disabled:opacity-50 disabled:pointer-events-none"
    :disabled="props.disabled"
  >
    <Icon icon="close-circle" :size="props.iconSize" />
  </button>
</template>

<script setup lang="ts">
import Icon from '@/components/icon/Icon.vue';

// Define the props interface
interface Props {
  id?: string;
  disabled?: boolean;
  iconSize?: string;
};

// Define props
const props = withDefaults(defineProps<Props>(), {
  id: () => `id_${Math.floor(Math.random() * 10000)}`,
  disabled: false,
  iconSize: 'mlg'
});
</script>
