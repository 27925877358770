<template>
  <div
    class="error-wrapper"
    :class="{
      'position-left': error.tooltipPosition === 'left',
      'position-right': error.tooltipPosition === 'right',
      'error-wrapper--active': show && !valid,
    }"
    @click="handleClick"
  >
    <Tooltip
      v-if="show && !valid"
      :message="message"
      :align="error.align"
      :icon="error.icon"
      :position="error.position"
      class="error-wrapper-tooltip"
    />
    <slot />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router'
import Tooltip from '@/components/tooltip/Tooltip.vue';

const props = defineProps({
  error: {
    type: Object,
    default: null,
  },
  valid: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['click']);

const route = useRoute()

const show = computed(() => {
  return (
    route?.matched[0].path.indexOf('/animation/') > -1 &&
    route?.matched[0].path.indexOf('/animation/Pages') === -1
  );
});

const message = computed(() => {
  return (
    props.error?.message ??
    `Ce champ a présenté une erreur lors de l'enregistrement de votre page`
  );
});

const handleClick = () => {
  emit('click');
};
</script>
<style lang="scss">
@import 'error_wrapper.scss';
</style>
