import { GenericStatisticsService } from '@/services';
import { cloneDeep } from 'lodash';
import { defaultKpisState, kpisTypes } from '@/data/kpis';
import { getField, updateField } from 'vuex-map-fields';
import genericKpisGetters from '@/store/statistics/genericKpisGetters';
import genericKpisMutations from '@/store/statistics/genericKpisMutations';

const service = new GenericStatisticsService('views');

const {
  id,
  color,
  title,
  tooltip,
  label,
  icon = '',
  iconColor = '',
  action = '',
  actionColor = '',
  illustration = '',
  valueType,
  queryString = '',
} = kpisTypes.find((type) => type.id === 'topViews');

const filters = cloneDeep(defaultKpisState.filters)
  .filter((f) =>
    ['device', 'video_type', 'category_id', 'genre_id'].includes(f.id)
  );

const state = {
  ...cloneDeep(defaultKpisState),
  id,
  filters,
  title,
  tooltip,
  label,
  color,
  icon,
  action,
  iconColor,
  actionColor,
  illustration,
  valueType,
  queryString
};
const actions = {
  async search({ commit, dispatch, rootState }, payload) {
    const lastQueryString = state.queryString;
    const start = rootState.kpis.startDateId;
    const end = rootState.kpis.endDateId;
    let queryString = `start_date=${start}&end_date=${end}&per_page=10`;
    if (payload?.keyword && payload?.keyword != '') {
      queryString += `&keyword=${payload.keyword}`;
    }
    if (payload?.page && payload?.page !== 1) {
      queryString += `&page=${payload.page}`;
    }
    commit('setQueryString', queryString);

    if (lastQueryString !== state.queryString || payload?.refresh) {
      commit('setLoading', true);
      commit('resetSelectedDate');
      return service.search(state.queryString).then(
        (views) => {
          commit('getAllSuccess', views);
          commit('setLoading', false);
          commit('setError', false);
          return Promise.resolve(views);
        },
        (error) => {
          commit('setError', true);
          commit('setLoading', false);
          commit('getAllSuccess', []);
          const alert = {
            id: 'top-views-search-failure',
            icon: 'close',
            type: 'error',
            message: 'notifications.search.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
    } else {
      return Promise.resolve();
    }
  },

  async export({ commit, dispatch, rootState }) {
    const start = rootState.kpis.startDateId;
    const end = rootState.kpis.endDateId;
    const email = rootState.auth.authUser.email;
    const queryString = `start_date=${start}&end_date=${end}&export=1&email=${email}`;
    commit('setQueryString', queryString);

    return service.search(state.queryString).then(
      (_) => {
        const alert = {
          id: 'stats-exported',
          icon: 'check',
          type: 'valid',
          message: 'kpis.export.notifications.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (error) => {
        const alert = {
          id: 'export-failure',
          icon: 'close',
          type: 'error',
          message: 'kpis.export.notifications.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  updateField,
  ...genericKpisMutations,

  setQueryString(state, queryString) {
    state.queryString = queryString;
    state.filters.forEach((filter) => {
      const values = filter?.value ?? [];
      if (values.length > 0 && !values.includes('all')) {
        let id = filter.id;
        if (filter?.name === 'custom_id') {
          id = filter.name;
        }
        const ids = `&${id}[]=`;
        state.queryString += (ids + values.join(ids));
      }
    })
  },
};

const getters = {
  getField,
  ...cloneDeep(genericKpisGetters),
};

export const topViewsKpis = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
