import { cloneDeep } from 'lodash';
import { itemsSendToPartners } from '@/data/partners.js';

export class PartnersTransformer {
  _partnersPackages;

  constructor(partnersPackages) {
    this._partnersPackages = cloneDeep(partnersPackages);
  }

  transformPartnersPackages() {
    return itemsSendToPartners.map((partner) => {
      let _partner = cloneDeep(partner);
      if (this._partnersPackages?.[partner.id]) {
        const partnerPackages = this._partnersPackages?.[partner.id]
        partnerPackages.actions = partner.actions.map((action) => {
          const _action = this._partnersPackages?.[partner.id]?.actions?.[action.id];
          return { 
            ...action, 
            ..._action,
          };
        });
        _partner = { 
          ...partner,
          ...partnerPackages, 
        };
      } 
      return _partner;
    });
  }
}
