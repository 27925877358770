<template>
  <fieldset
    data-cy="form-control"
    v-auto-animate
    class="appearance-none flex gap-2 otto-form-control"
    :disabled="disabled"
    :readonly="readonly"
    :class="[
      inline ? 'flex-row items-center' : 'flex-col',
      hasError && displayError && 'form-control--error theme-danger',
      valid && 'form-control--valid',
      alignContent === 'center' && 'align-center',
    ]"
  >
    <div v-if="label || description">
      <slot v-if="label || $slots.label" name="label">
        <FormLabel
          :icon-before="labelIconBefore"
          :size="labelSize"
          :optional="optional"
          :optional-text="optionalText"
          :custom="custom"
          :required="required"
          :ghost="ghostLabel"
          :disabled="disabled"
          :readonly="readonly"
          :label-for="attrs.id"
          :weight="weight"
          :auto-colons="labelAutoColons"
          :tooltip="
            hasTooltip
              ? {
                  align: 'right',
                  position: 'top',
                  icon: 'info-circle',
                  ...labelTooltip,
                }
              : null
          "
          :class="alignContent === 'center' && 'justify-center'"
        >
          {{ $t(label) }} 
        </FormLabel>
      </slot>
      <FormHint v-if="description" :italic="italicHint">
        {{ $t(description) }}
      </FormHint>
    </div>
    <ErrorWrapper
      class="form-input-field--error-wrapper group relative grid-col-1 gap-2 items-center max-w-full"
      :class="layout"
      :valid="!error"
      :error="{
        icon: 'warning-flat',
        position: 'bottom',
        align: 'right',
        tooltipPosition: 'right',
      }"
    >
      <slot />
    </ErrorWrapper>
    <FormError v-if="errorMessage && displayError">
      {{ $t(errorMessage) }}
    </FormError>
    <FormHint v-if="hint" :italic="italicHint">
      {{ hint }}
    </FormHint>
  </fieldset>
</template>
<script setup>
import { computed, ref } from 'vue';
import { useAttrs } from 'vue';
import { useI18n } from 'vue-i18n';
import ErrorWrapper from '@/components/error/ErrorWrapper.vue';
import FormError from '@/components/form/FormError.vue';
import FormHint from '@/components/form/FormHint.vue';
import FormLabel from '@/components/form/FormLabel.vue';

const attrs = useAttrs();
const emit = defineEmits(['update:modelValue']);
const { t } = useI18n();

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  modelValue: [String, Number],
  label: String,
  labelIconBefore: String,
  labelTooltip: {
    type: Object,
    default: null,
  },
  labelAutoColons: {
    type: Boolean,
    default: true,
  },
  alignContent: {
    type: String,
    default: 'left',
  },
  description: String,
  required: Boolean,
  optional: Boolean,
  optionalText: String,
  custom: Boolean,
  size: String,
  hint: String,
  italicHint: {
    type: Boolean,
    default: true
  },
  inline: {
    type: Boolean,
    default: false,
  },
  autofocus: Boolean,
  ghostLabel: Boolean,
  error: [String, Function, Boolean],
  displayError: { type: Boolean, default: true, required: false },
  valid: Boolean,
  loading: Boolean,
  textOptions: Object,
  mask: { type: [String, Array], default: null },
  hideCrossOnError: {
    type: Boolean,
    default: false,
  },
  tooltipMessage: {
    type: String,
    default: null,
    required: false,
  },
  theme: {
    type: String,
    required: false,
  },
  weight: {
    type: String,
    default: 'normal',
  },
  layout: {
    type: String,
    default: 'grid'
  },
});

const hasTooltip = computed(() => {
  return props.labelTooltip !== null;
});
const errorMessage = computed(() => {
  if (props.error && typeof props.error === 'string') {
    return props.error;
  }
  return '';
});

const hasError = computed(() => {
  return !!props.error;
});

const labelSize = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'xs';
    case 'md':
      return 'sm';
    case 'lg':
      return 'lg';
    case 'xl':
      return 'xl';
    default:
      return 'sm';
  }
});
</script>
