import * as d3 from 'd3';
import { GenericStatisticsService } from '@/services';
import { cloneDeep } from 'lodash';
import { defaultKpisState, kpisTypes } from '@/data/kpis';
import { getField, updateField } from 'vuex-map-fields';
import genericKpisGetters from '@/store/statistics/genericKpisGetters';
import genericKpisMutations from '@/store/statistics/genericKpisMutations';

const service = new GenericStatisticsService('subscriptions');

const formatToObject = function ([name, children]) {
  const obj = {
    name,
  };
  if (Array.isArray(children)) {
    obj.value = d3.sum(children.map((c) => c.total));
  } else {
    obj.children = Array.from(children, formatToObject);
    obj.total = d3.sum(
      obj.children.map((c) => {
        return c.children ? c.total : c.value;
      })
    );
  }
  return obj;
};

const {
  id,
  color,
  title,
  tooltip,
  label,
  icon = '',
  iconColor = '',
  action = '',
  actionColor = 'success',
  illustration = '',
  valueType,
  queryString = '',
} = kpisTypes.find((type) => type.id === 'subscribed');

const state = {
  ...cloneDeep(defaultKpisState),
  filters: cloneDeep(defaultKpisState.filters).filter((f) =>
    ['source', 'status', 'type'].includes(f.id)
  ),
  id,
  color,
  title,
  tooltip,
  label,
  icon,
  action,
  iconColor,
  actionColor,
  illustration,
  valueType,
  queryString
};

const actions = {

  async search({ commit, dispatch, state, rootState }) {
    commit('setLoading', true);
    commit('resetSelectedDate');
    const start = rootState.kpis.startDateId;
    const end = rootState.kpis.endDateId;
    const unit = rootState.kpis.granularity;
    const queryString = `start_date=${start}&end_date=${end}&interval=${unit}`;
    commit('addFiltersToQueryString', queryString);
    return service.search(state.queryString).then(
      (subscribed) => {
        commit('getAllSuccess', subscribed);
        commit('setLoading', false);
        return Promise.resolve(subscribed);
      },
      (error) => {
        const alert = {
          id: 'subscribed-search-failure',
          icon: 'close',
          type: 'error',
          message: 'notifications.search.error',
        };
        commit('setLoading', false);
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  updateField,
  ...genericKpisMutations,
};

const getters = {
  getField,
  ...cloneDeep(genericKpisGetters),
  filteredAll: (state, getters) => {
    if (!getters.formattedAll.length) {
      return [];
    }
    return getters.formattedAll.map(({ name, children }) => {
      const groups =
        getters.groups.length > 0 ? getters.groups : [(d) => d.date];
      children = children.filter((d) => {
        const filterObj = getters.filters.reduce((prevVal, currVal) => {
          const key = Object.keys(currVal)[0];
          return (
            prevVal &&
            (currVal[key]?.length === 0 ||
              currVal[key].includes('all') ||
              currVal[key].includes(d[key]))
          );
        }, true);
        return filterObj;
      });
      children =
        children.length > 0
          ? Array.from(d3.group(children, ...groups), formatToObject)
          : [];
      return {
        date: name,
        name,
        children,
      };
    });
  },
  summaryValue: (state, getters) => {
    return getters.toDate;
  },
  summaryHasFooter: () => {
    return false;
  },
};

export const subscribedKpis = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
