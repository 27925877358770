<template>
  <header
    id="header"
    class="main-header z-[1000] bg-skin-ui w-full shadow-sm shadow-skin-ui px-4 md:pr-10"
    :class="headerThemeClass"
  >
    <MenuBurger :checked="menuToggled" @toggle-menu="$emit('toggle-menu')" />
    <div class="main-header-logo flex-1 mx-12 md:mx-0">
      <router-link
        to="/"
        class="flex items-center justify-center md:justify-start"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xml:space="preserve"
          style="enable-background: new 0 0 247.2 82.4"
          viewBox="0 0 247.2 82.4"
          class="h-[32px] ml-1"
        >
          <path
            d="M65.7 61.2c-10.5-6.4-11.5-12.4-8-17 .1-.1.3-.4.4-.4 3.1-4 5.1-8.8 5.7-13.8.6-5.1-.3-10.2-2.5-14.7-2.2-4.6-5.6-8.4-9.9-11.1C47 1.4 42.1 0 37.1 0s-10 1.4-14.2 4.1c-4.3 2.7-7.7 6.6-9.9 11.1-2.2 4.7-3.1 9.8-2.6 14.8.6 5.1 2.5 9.8 5.7 13.8 0 0 .2.3.4.4 3.5 4.5 2.5 10.5-8 17-10.9 6.6-10.1 9.4-4.1 9.4 6 .1 13.6-.2 19.6-7.7 0 0-2.8 8.6-8.4 12.4-6.4 4.4-3.6 7.5.3 6.9 3.6-.6 15.7-2.8 21.4-14.1C43 79.4 55.1 81.6 58.7 82.2c3.9.7 6.7-2.5.3-6.9-5.9-3.7-8.7-12.3-8.7-12.3 6 7.6 13.6 7.8 19.6 7.7 5.9-.1 6.7-2.8-4.2-9.5z"
            class="fill-skin-accent"
          />
          <path
            d="M87.9 41.1c-.1-8.8 2.1-16.1 6.7-21.7s11.1-8.4 19.7-8.4c8 0 14.3 2.8 18.7 8.4 4.4 5.6 6.7 12.8 6.9 21.7.1 8.8-2.1 16.1-6.8 21.7-4.6 5.6-11 8.4-19.2 8.4-8.7 0-15.1-2.7-19.4-8.2-4.4-5.4-6.6-12.7-6.6-21.9zm4.9-.1c-.1 7.6 1.6 13.8 5.1 18.7 3.4 4.9 8.9 7.3 16.2 7.3 4.3.1 8.5-1.2 11.9-3.7 3.2-2.4 5.6-5.7 6.9-9.4 1.4-4.1 2.1-8.5 2.1-12.8 0-3.4-.4-6.7-1.2-10-.7-2.9-2-5.7-3.7-8.2-1.7-2.4-4-4.3-6.6-5.6-2.9-1.4-6.1-2.1-9.4-2.1-7.3 0-12.6 2.4-16.1 7.2-3.5 4.9-5.2 11.1-5.2 18.6zM150.7 58.2c0-1.8.1-5.2.2-10.2.1-5 .2-9.8.3-14.1.1-4.4.2-6.7.2-7.1h-6.8c-.3 0-.6 0-1-.1-.2 0-.3-.1-.4-.3 0-.1-.1-.2-.1-.3v-1.7c0-.7.4-1 1.1-1 3.4 0 5.6-.5 6.5-1.5.9-1 1.4-3.1 1.4-6.3 0-1.2.8-1.8 2.2-1.8 1.2 0 1.7.6 1.7 1.9 0 .8 0 2.1-.1 3.8-.1 1.7-.1 2.9-.1 3.6.8.1 2.9.1 6.4.1 3.5 0 18.8 0 21-.1 1 0 1.6.4 1.6 1.3v1.2c0 .9-.5 1.2-1.6 1.2h-27.7c-.3 5.8-.4 16.3-.4 31.5 0 3.2.5 5.5 1.4 6.9.5.7 1.2 1.3 2 1.6.8.4 1.7.5 2.6.4 2.8.1 5.6-.7 8-2.2 1.1-.7 1.9-.5 2.3.5l.3.8c.2.4.2.8.1 1.1-.1.4-.4.7-.7.9-1.3.9-2.8 1.6-4.4 2-1.8.6-3.7.9-5.6.9-3.8 0-6.5-1-8.2-2.9-1.4-2-2.3-5.3-2.2-10.1z"
            class="fill-current"
          />
          <path
            d="M182.1 58.2c0-1.8 0-5.2.2-10.2.2-5 .2-9.8.2-14.1 0-4.3.2-6.7.2-7.1h-6.8c-.3 0-.6 0-.9-.1-.2 0-.3-.1-.4-.3 0-.1-.1-.2-.1-.3v-1.7c0-.7.4-1 1.1-1 3.4 0 5.6-.5 6.5-1.5.9-1 1.4-3.2 1.4-6.3 0-1.2.7-1.8 2.2-1.8 1.1 0 1.7.6 1.7 1.9 0 .8 0 2.1-.1 3.8-.1 1.7-.1 2.9-.1 3.6.7.1 2.9.1 6.4.1 3.5 0 6.5 0 8.8-.1 1 0 1.5.4 1.5 1.3v1.2c0 .9-.5 1.2-1.5 1.2H187c-.3 5.8-.4 16.3-.4 31.5 0 3.2.5 5.5 1.4 6.9.5.7 1.2 1.3 2.1 1.6.8.4 1.7.5 2.6.4 2.8 0 5.5-.8 7.9-2.3 1.1-.7 1.9-.5 2.3.5l.3.8c.2.4.2.8.1 1.1-.1.4-.4.7-.7.9-1.3.9-2.8 1.6-4.4 2-1.8.6-3.7.9-5.6.9-3.8 0-6.5-1-8.2-2.9-1.6-1.9-2.4-5.2-2.3-10zM206.1 46.3c.1-7.1 1.9-12.8 5.4-17.3 3.5-4.5 8.5-6.8 15-6.8 6.6 0 11.7 2.3 15.3 6.8 3.6 4.5 5.4 10.3 5.3 17.3-.1 7.4-1.9 13.5-5.5 18.2-3.6 4.7-8.6 7-15 6.9-6.7 0-11.8-2.3-15.3-6.9-3.5-4.6-5.3-10.6-5.2-18.2zm4.6 0c0 6.2 1.2 11.2 3.7 15.1s6.5 5.9 12.1 5.9 9.7-2 12.3-5.9c2.6-3.9 3.9-8.9 3.9-15.1 0-5.9-1.4-10.7-4.1-14.5-2.8-3.8-6.7-5.7-12-5.8-5.6 0-9.7 1.9-12.2 5.7-2.5 3.8-3.7 8.6-3.7 14.6z"
            class="fill-current"
          />
        </svg>
        <div class="ml-1 p-1 bg-red-500 rounded-md text-xxs leading-none font-bold align-top relative -top-2 text-white uppercase" style="background-color: #DF3F3F">
          {{
            $t('logo_version')
          }}
        </div>
      </router-link>
    </div>
    <div class="main-header-inner">
      <div class="main-header-notifications hidden md:flex cursor-pointer">
        <UploadBar v-if="!modalVideosUploadVisible" />
      </div>
      <div class="main-header-search hidden md:flex">
        <form
          v-hidden.prod
          class="main-header-search-form"
          :class="{ 'has-focus': searchHasFocus }"
        >
          <div v-if="false" class="form-group">
            <div class="form-control-wrapper with-icon">
              <span class="form-control-icon">
                <Icon icon="search" />
              </span>
              <input
                type="text"
                class="form-control main-header-search-form-input"
                placeholder="Rechercher dans mon espace"
                @focus="searchHasFocus = true"
                @blur="searchHasFocus = false"
              />
            </div>
          </div>
        </form>
      </div>
      <nav v-can="'settings.beta'" class="main-header-user-nav hidden md:flex">
        <Dropdown v-if="true" class="user-nav-dropdown" nude size="lg">
          <template #title>
            🌐&nbsp;<span style="text-transform: uppercase">{{
              $i18n.locale
            }}</span
            >&nbsp;
          </template>
          <nav class="spaces-nav">
            <ul class="spaces-nav-list">
              <li
                v-for="(locale, i) in locales"
                :key="i"
                class="spaces-nav-item"
              >
                <a class="spaces-nav-item-link" @click="setLocale(locale.id)">
                  {{ locale.name }}
                </a>
              </li>
            </ul>
          </nav>
        </Dropdown>
      </nav>
      <nav
        class="main-header-user-nav absolute right-4 md:flex md:relative md:right-auto"
      >
        <Dropdown
          link
          class="user-nav-dropdown -mx-4"
          placement="bottom-end"
          nude
          on-hover
          size="lg"
        >
          <template #title>
            <div v-if="currentPlatform" class="user-nav-dropdown-toggler">
              <div
                class="user-nav-dropdown-toggler-avatar w-8 h-8 md:w-10 md:h-10 rounded-full bg-skin-background"
                :style="
                  getPlatformLogo &&
                  `background-image: url(${getPlatformLogo()})`
                "
              />
              <h4 class="user-nav-dropdown-toggler-title hidden md:inline-flex">
                {{ currentPlatform.name }}
              </h4>
            </div>
          </template>
          <nav
            v-if="platforms && platforms.length > 0"
            class="spaces-nav border-b border-skin-ui border-solid pt-2"
          >
            <h5 class="spaces-nav-title text-skin-muted">
              {{ $t('generic.platform.dropdown.title') }} :
            </h5>
            <ul class="spaces-nav-list">
              <li
                v-for="(platform, i) in platforms"
                :key="i"
                class="spaces-nav-item"
              >
                <a
                  class="spaces-nav-item-link hover:bg-skin-ui-hover"
                  @click="upSelectedPlatformID(platform.id)"
                >
                  <span
                    v-if="isTestEnv"
                    class="spaces-nav-item-logo bg-skin-ui-hover"
                    :style="
                      getPlatformLogo &&
                      `background-image: url(${getPlatformLogo(platform)})`
                    "
                  />
                  <span
                    v-else
                    class="spaces-nav-item-logo bg-skin-ui-hover"
                    :style="`background-image: url(${platform.logo})`"
                  />
                  <span class="spaces-nav-item-title">
                    {{ platform.name }}
                  </span>
                </a>
              </li>
            </ul>
          </nav>
          <ul class="user-nav-list">
            <li class="user-nav-item">
              <a @click="goToAccess" class="user-nav-item-link hover:bg-skin-ui-hover">
                {{ titleAccessOrProfile }}
              </a>
            </li>
            <li class="user-nav-item">
              <a
                href="#"
                class="user-nav-item-link hover:bg-skin-ui-hover"
                @click="logout"
              >
                <Icon icon="logout" class="user-nav-item-link-icon" />
                {{ $t('button.logout') }}
              </a>
            </li>
          </ul>
        </Dropdown>
      </nav>
    </div>
  </header>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Dropdown from '@/components/dropdown/Dropdown.vue';
import Icon from '@/components/icon/Icon.vue';
import MenuBurger from '@/components/molecules/MenuBurger.vue';
import moment from 'moment';
import UploadBar from '@/components/uploadBar/UploadBar.vue';

const store = useStore();
const route = useRoute();
const router = useRouter();
const { locale, t } = useI18n();

const props = defineProps({
  menuToggled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['toggle-menu']);
  
const searchHasFocus =  ref(false);
const locales = [
  {
    id: 'en',
    name: 'EN',
  },
  {
    id: 'es',
    name: 'ES',
  },
  {
    id: 'fr',
    name: 'FR',
  },
  {
    id: 'it',
    name: 'IT',
  },
  {
    id: 'pt',
    name: 'PT',
  },
];

const selectedPlatformID = computed(() => store.state.platforms.selectedPlatformID);
const currentPlatform = computed(() => store.state.platforms.currentPlatform);
const userPlatforms = computed(() => store.state.platforms.userPlatforms);
const authUser = computed(() => store.state.auth.authUser);
const modalVideosUploadVisible = computed(() => store.state.modalVideosUploadVisible);

const isTestEnv = computed(() => {
  return (
    'VITE_ENV' in import.meta &&
    ['staging', 'local'].includes(import.meta.env.VITE_ENV)
  );
});

const headerThemeClass = computed(() => {
  let theme = '';
  switch (import.meta.env.VITE_ENV) {
    case 'local':
      theme = 'theme-yellow';
      break;
    case 'prod':
    case 'production':
      theme = 'theme-secondary';
      break;
    case 'preproduction':
      theme = 'theme-green';
      break;
    case 'staging':
      theme = 'theme-purple';
      break;
    default:
      theme = 'theme-secondary';
      break;
  }
  return theme;
});

const platforms = computed(() => {
  if (!currentPlatform.value) {
    return userPlatforms.value;
  }
  return userPlatforms.value?.filter(
    (platform) => platform.id !== currentPlatform.value.id
  );
});

const titleAccessOrProfile = computed(() => {
  if (canAdminAccess) {
    return t('generic.platform.dropdown.access')
  } else {
    return t('generic.platform.dropdown.profile')
  }
});

const canAdminAccess = computed(() => store.getters['auth/can']('admins.access'));

const setSelectedPlatformID = (id) => 
  store.commit('platforms/setSelectedPlatformID', id);

function setLocale(localeValue) {
  locale.value = localeValue;
  localStorage.setItem('locale', localeValue);
  moment.locale(localeValue);
};

function goToAccess() {
  if (canAdminAccess) {
    router.push('/admins/access');
  } else {
    router.push('/admins/profile');
  }
};

function upSelectedPlatformID(id) {
  if (isTestEnv.value) {
    if (id !== selectedPlatformID.value) {
      if (route.name !== 'home') {
        router.push({ path: '/', query: { platformID: id } });
      } else {
        setSelectedPlatformID(id);
      }
    }
  } else {
    const email = authUser.value?.email;
    store.dispatch('auth/logout').then(async () => {
      await router.push({
        path: '/login',
        query: { userEmail: email, platformID: id },
      });
      store.dispatch('platforms/logoutPlatform');
      store.dispatch('references/resetAll');
    });
  }
};

function getPlatformLogo(platform = null) {
  let _platform = null;
  if (platform !== null) {
    _platform = platform;
  } else if (typeof currentPlatform.value === 'object') {
    _platform = currentPlatform.value
  }

  // Get square_logos, fallback header_logos
  if (_platform?.picture?.favicons?.url) {
    return _platform.picture.favicons.url;
  } else if (_platform?.logo) {
    return _platform.logo;
  } else if (_platform?.picture?.square_logos?.url) {
    return _platform.picture.square_logos.url;
  } else if (_platform?.picture?.header_logos?.length > 0) {
    return _platform.picture.header_logos[0].url;
  } else if (_platform?.picture?.header_logos?.url) {
    return _platform.picture.header_logos.url;
  }
  return false;
};


async function logout() {
  await store.dispatch('auth/logout')
  router.push('/login');
  store.dispatch('platforms/logoutPlatform');
  store.dispatch('references/resetAll');
};
</script>

<style lang="scss" scoped>
@import 'header.scss';
</style>
