import { isEqual } from 'lodash';
import { newsletterService } from '@/services';

const state = {
  email: '',
  newStatus: false,
  savedStatus: false,
  error: false,
  loading: true,
};

const actions = {
  isSubscribed({ commit }, userEmail) {
    commit('reset');
    commit('setLoading', true);
    commit('setEmail', userEmail);
    return newsletterService.isSubscribed(state.email).then(
      (response) => {
        commit('getRequestSuccess', response);
        return Promise.resolve(response);
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject(error);
      }
    );
  },

  save({ commit }) {
    if (isEqual(state.newStatus, state.savedStatus)) {
      console.log(' --> Do not save: newsletter');
      return Promise.resolve();
    }

    commit('setLoading', true);
    if (state.savedStatus === true) {
      return newsletterService.subscribe(state.email).then(
        (response) => {
          commit('getSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('getFailure', error);
          return Promise.reject(error);
        }
      );
    } else {
      return newsletterService.unsubscribe(state.email).then(
        (response) => {
          commit('getSuccess', response);
          return Promise.resolve(response);
        },
        (error) => {
          commit('getFailure', error);
          return Promise.reject(error);
        }
      );
    }
  },

  reset({ commit }) {
    commit('reset');
  },

  // shouldSaved() {
  //   console.log('newsletterService shouldSaved')
  //   console.log(isEqual(state.newStatus, state.savedStatus))
  //   return !isEqual(state.newStatus, state.savedStatus)
  // },
};

const mutations = {
  reset(state) {
    state.newStatus = false;
    state.savedStatus = false;
    state.error = false;
    state.loading = false;
  },

  setLoading(state, value) {
    state.loading = value;
  },

  getRequestSuccess(state, result) {
    if (result === 'subscribed') {
      state.newStatus = true;
      state.savedStatus = true;
    } else {
      state.newStatus = false;
      state.savedStatus = false;
    }
    state.error = false;
    state.loading = false;
  },

  getSuccess(state, result) {
    if (result?.status === 'subscribed') {
      state.newStatus = true;
      state.savedStatus = true;
    } else {
      state.newStatus = false;
      state.savedStatus = false;
    }
    state.error = false;
    state.loading = false;
  },

  getFailure(state, error) {
    state.error = true;
    state.newStatus = false;
    state.savedStatus = false;
    state.loading = false;
  },

  setEmail(state, email) {
    state.email = email;
  },
};

const getters = {
  shouldSaved: (state) => {
    return !isEqual(state.newStatus, state.savedStatus);
  },
};

export const newsletters = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
