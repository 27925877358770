import { cloneDeep } from 'lodash';

export class EpisodeTransformer {
  static create(serieId, episodeData) {
    const main = [episodeData.id];
    const thumbnails = EpisodeTransformer.getPictureData(episodeData.picture);

    return {
      serie_id: serieId,
      title: episodeData.name,
      rank: episodeData.rank || 0,
      synopsis: episodeData.description,
      video: { main },
      picture: { thumbnails },
    };
  }

  static updateEpProgram({
    programId,
    name,
    description,
    programTypeId,
    video,
    picture,
  }) {
    const main = EpisodeTransformer.getVideoData(video);
    const thumbnails = EpisodeTransformer.getPictureData(picture);

    return {
      id: programId,
      title: name,
      synopsis: description,
      program_type_id: programTypeId,
      video: { main },
      picture: { thumbnails },
    };
  }

  static transform(episodeMedia, episodeContent, type, countryCode) {
    let episode = cloneDeep(episodeContent);
    let video = episode.video;
    if (typeof video === 'undefined') {
      video = {};
    } else {
      video.episodes = video.main;
      delete video.main;
      video.episodes[0].name = episode.title;
      video.episodes[0].description = episode.description;
      video.episodes[0].type = 'episodes';
    }

    if (type) {
      episodeMedia.type = type;
    }
    return {
      ...episodeMedia,
      activated: episode.status === 'online' ? true : false,
      is_free: EpisodeTransformer.getGratuitData(
        episode.publication,
        countryCode
      ),
      episodeProgramId: episode.id,
      name: episode.title,
      description: episode.synopsis,
      title: episode.title,
      synopsis: episode.synopsis,
      type: 'episodes',
      programId: episode.program_id,
      programTypeId: episode.program_type_id,
      picture: episode.picture,
      publication: episode.publication,
      video,
      // For test only
      // is_drmised: true
    };
  }

  static updateSeasonEp(episode, serieId, seasonId) {
    const {
      id,
      title: epTitle,
      name,
      synopsis: epSyn,
      description,
      video,
      picture,
    } = episode;

    let title = epTitle ? epTitle : name;
    let synopsis = epSyn ? epSyn : description;
    const main = video ? EpisodeTransformer.getVideoData(video) : [id];
    const thumbnails = EpisodeTransformer.getPictureData(picture);

    return {
      serie_id: serieId,
      season_id: seasonId,
      rank: 0,
      title,
      synopsis,
      video: { main },
      picture: { thumbnails },
    };
  }

  static getVideoData(video) {
    const main = [];
    if (video && video.main && video.main.length) {
      main.push(video.main[0]);
    } else if (video && video.length) {
      main.push(video[0].id);
    }
    return main;
  }

  static getPictureData(picture) {
    let thumbnails = [];
    if (picture && picture.thumbnails && picture.thumbnails.length) {
      delete picture.thumbnails[0].id;
      thumbnails = picture.thumbnails;
    }
    return thumbnails;
  }

  static getGratuitData(publication, countryCode) {
    let isFree = false;
    //TODO:Dynamic country code
    if (publication && publication[countryCode]) {
      isFree = !!publication[countryCode].is_free || false;
    }
    return isFree;
  }

  static getPubStatus(publication, countryCode) {
    let isOnline = false;
    //TODO:Dynamic country code
    if (publication && publication[countryCode]) {
      isOnline = publication[countryCode].unpublish_at ? false : true;
    }
    return isOnline;
  }
}
