import router from '@/router.js';
import store from '@/store/index.js';

// Directive to hide ui items depending on environment
export const hidden = {
  bind(el, binding, vnode) {
    return;
    /*
    if (
      Object.getOwnPropertyNames(binding.modifiers).length === 0 ||
      (binding.modifiers.dev && import.meta.env.VITE_ENV === 'local') ||
      (binding.modifiers.prod && import.meta.env.VITE_ENV === 'production') ||
      (binding.modifiers.staging && import.meta.env.VITE_ENV === 'staging')
    ) {
      const comment = document.createComment(' ');
      Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined,
      });
      vnode.elm = comment;
      vnode.text = ' ';
      vnode.isComment = true;
      vnode.context = undefined;
      vnode.tag = undefined;
      vnode.data.directives = undefined;

      if (vnode.componentInstance) {
        vnode.componentInstance.$el = comment;
      }

      if (el.parentNode) {
        el.parentNode.replaceChild(comment, el);
      }
    }

     */
  },
};

/**
 * Permission directive
 *
 * @beta
 * @returns bool
 * @author  Jody JUSTINE <jjustine@vodfactory.com>
 * @see     https://vodfactory.atlassian.net/wiki/spaces/FAC/pages/2794618895/Droits
 **/
export const can = (el, binding, vnode) => {
  if (!binding.value) {
    return;
  }
  const ret = store.getters['auth/can'](binding.value);
  if (!ret) {
    if (binding.modifiers?.hide) {
      el.classList.add('hide');
    } else {
      el.style.display = 'none';
      el.disabled = true;
      el.remove();
    }

    if (binding.modifiers?.redirect) {
      router.push('home');
    }
  } else {
    el.classList.remove('permission-hide');
  }
};
