import { cloneDeep } from 'lodash';

const state = {
  loading: true,
  all: [],
};

const mutations = {
  setNewFilesList(state, list) {
    state.all = cloneDeep(list);
  },
  cleanNewFilesList(state) {
    state.all = [];
  },
};
export const files = {
  namespaced: true,
  mutations,
  state,
};
