// all data related to partners
export const partners = [
  /*{
    id: 'free',
    title: 'Free',
    featureName: 'environment_free',
    icon: 'free-simple-logo',
    pulication: {
      canDelete: false,
    },
    video: {
      availableFormats: [
        'main',
        'trailer',
      ]
    }
  },*/
  {
    id: 'amazon',
    title: 'Amazon',
    featureName: 'environment_amazon',
    icon: 'prime-video-logo',
    video: {
      description: 'section.videos.partner.amazon.description',
      link: {
        href: 'https://videocentral.amazon.com/home/help?topicId=G9L49ZRPJS4HTUVF&ref_=avd_sup_G9L49ZRPJS4HTUVF',
        text: 'section.videos.partner.amazon.descriptionLink'
      },
      availableFormats: [
        'main',
        'trailer',
      ]
    },
    picture: {
      link: {
        href: 'https://videocentral.amazon.com/home/help?topicId=G6J8LYCF9YEQ98N2&ref_=avd_sup_G6J8LYCF9YEQ98N2',
        text: 'section.pictures.partner.amazon.descriptionLink'
      }
    },
    pulication: {
      description: 'section.partners.availabilityDates.scheduled.amazon.description',
      link: {
        href: 'https://videocentral.amazon.com/home/help?topicId=G85YLT2W6734GGPA&ref_=avd_sup_G85YLT2W6734GGPA#G9Q3E9LGBUX3WRVH',
        text: 'section.partners.availabilityDates.scheduled.amazon.descriptionLink'
      },
      canDelete: true,
    }
  },
  {
    id: 'betv',
    title: 'VOO',
    featureName: 'environment_betv',
    icon: "betv-logo",
    pulication: {
      canDelete: false,
    },
    video: {
      availableFormats: [
        'main',
      ]
    }
  },
  {
    id: 'molotov',
    title: 'Molotov',
    featureName: 'environment_molotov',
    icon: "molotov-logo",
    pulication: {
      canDelete: false,
    },
    video: {
      availableFormats: [
        'main',
      ]
    }
  },
];

/*
  * Metadata details for partners
*/
// metadata labels for program and serie
export  const partnerMetadataLabelsForProgramAndSerie = [
  //'partner_original_title',
  'partner_title',
  'partner_video_quality',
  'partner_synopsis',
  'partner_short_synopsis',
  'partner_realisator',
  'partner_casting',
  'partner_country',
  'partner_duration',
  'partner_owner',
  //'partner_version', OTTO-13605 : hide version, not used
  'partner_original_language',
  'imdb_id',
  'partner_release_date'
];
// metadata labels for season and episode
export const partnerMetadataLabelsForSeasonAndEpisode = [
  'partner_title',
  'partner_synopsis',
  'partner_short_synopsis',
  'imdb_id',
  'partner_duration',
  'partner_release_date',
];
// metadata labels for amazon
export const amazonMetadataLabels = [
  'amazon_genre',
  'amazon_maturity_rating',
];
// metadata labels for betv
export const betvMetadataLabels = [
  'betv_showtype',
  'betv_genre',
  'betv_category',
  'betv_rating_level',
];
// metadata labels for molotov
export const molotovMetadataLabels = [
  'molotov_genre',
  'molotov_rating_level',
];

export const amazonPictures = [
  'box_art',
  'season_box_art',
  'title_art',
  'poster_art'
];

/*
  * Items to send to partners
*/
export const itemsSendToPartners = [
  {
    id:'amazon',
    label: 'Amazon',
    icon: 'prime-video-logo',
    checked: false,
    sent_at: null,
    status: 'incomplete',
    actions: [
      {
        id: 'amazon_avails',
        label: 'Avails (dates de disponibilité)',
        checked: false,
        sent_at: null,
        status: 'incomplete',
      },
      {
        id: 'amazon_data',
        label: 'Infos',
        checked: false,
        sent_at: null,
        status: 'incomplete',
      },
      {
        id: 'amazon_pictures',
        label: 'Images',
        checked: false,
        sent_at: null,
        status: 'incomplete',
      },
      {
        id: 'amazon_videos',
        label: 'Vidéos',
        checked: false,
        sent_at: null,
        status: 'incomplete',
      },
    ]
  },
  {
    id:'betv',
    label: 'VOO',
    icon: "betv-logo",
    checked: false,
    sent_at: null,
    status: 'incomplete',
    actions: [
      {
        id: 'betv_update',
        label: 'Update Infos',
        checked: false,
        sent_at: null,
        status: 'incomplete',
      },
    ]
  },
  {
    id:'molotov',
    label: 'Molotov',
    icon: "molotov-logo",
    checked: false,
    sent_at: null,
    status: 'incomplete',
    actions: [
      {
        id: 'molotov_update',
        label: 'Update Infos',
        checked: false,
        sent_at: null,
        status: 'incomplete',
      },
    ]
  },
]

/*
  * Amazon territories
*/
export const amazonTerritories = [
  { id: 'FR', title: 'France' },
  { id: 'BE', title: 'Belgique' },
  { id: 'ES', title: 'Espagne' },
  { id: 'IT', title: 'Italie' },
  { id: 'DE', title: 'Allemagne' },
  { id: 'NL', title: 'Pays-Bas' },
  { id: 'PT', title: 'Portugal' },
  { id: 'GR', title: 'Grèce' },
  { id: 'FI', title: 'Finlande' },
  { id: 'LU', title: 'Luxembourg' },
  { id: 'DK', title: 'Danemark' },
  { id: 'SE', title: 'Suède' },
  { id: 'NO', title: 'Norvège' },
  { id: 'CH', title: 'Suisse' },
  { id: 'GB', title: 'Royaume-Uni' },
  { id: 'CA', title: 'Canada' },
  { id: 'US', title: 'États-Unis' },
];

// list of genres for betv showtypes
export const betvGenresRelatedToShowType = {
  "Film": [
    "Comédie",
    "Drame",
    "Thriller",
    "Policier",
    "Action/Aventures",
    "Science-Fiction",
    "Erotique",
    "Fantastique",
    "Horreur",
    "Animation",
    "Documentaire",
    "Guerre",
    "Histoire",
    "Western",
  ],
  "Sport": [
    "Football",
    "Basket",
    "Cyclisme",
    "Tennis",
    "Autre sport Raquette",
    "Sport mécanique",
    "Gymnastique",
    "Rugby",
    "Football US",
    "Autre sport Ballon",
    "Natation",
    "Golf",
    "Sport de combat",
    "Sport de Glisse",
    "Sport nautique",
  ],
  "Série": [
    "Série Comédie",
    "Série Dramatique",
    "Série Thriller",
    "Série Aventures",
    "Série Policière",
    "Série Aventures",
    "Série Science-Fiction",
    "Série Erotique",
    "Série Fantastique",
    "Série Horreur",
    "Série Animation",
    "Série Guerre",
    "Série Histoire",
    "Série Western",
    "Série Action",
  ],
  "Documentaire": [
    "Doc Cinéma",
    "Docu-fiction",
    "Doc Sport",
    "Doc Animalier",
    "Doc Nature",
    "Doc Découverte",
    "Doc Sciences",
    "Doc Société",
    "Doc Musique",
    "Portrait",
    "Doc Histoire",
  ],
  "Enfants": [
    "Dessin Animé",
    "Magazine Jeunesse",
    "Série Enfants",
    "Film Enfants",
    "Emission Enfants",
  ],
  "Magazine": [
    "Mag Cinéma",
    "Mag Sport",
    "Mag Culture",
    "Mag Musique",
    "Mag Sciences",
    "Mag Societé",
    "Mag Cuisine",
    "Mag Art de Vivre",
    "Mag Consommation",
    "Mag Nature/Envir",
    "Mag Reportage",
    "Mag Politique",
    "Mag Religieux",
    "Mag Animalier",
  ],
  "Divertissement": [
    "Divertissement",
    "Jeu",
    "Spectacle",
    "Theâtre",
    "One Man Show",
    "Talk Show",
  ],
  "Musique": [
    "Concert",
    "Emission",
    "Clips",
    "Festival",
    "Opéra",
    "Danse",
  ],
  "Information": [
    "Journal Télévisé",
    "Météo",
    "Magazine d'information",
    "Infos sportives",
    "Emission d'information",
    "Débat",
  ],
  "Télé-Réalité": [
    "Télé-Réalité",
    "Magazine Télé-Réalité",
    "Sérié Télé-Réalité",
  ],
  "Télé-Achat": [
    "Emission Télé-Achat",
    "Magazine Télé-Achat",
  ],
  "Adulte": [
    "Film",
    "Série",
    "Magazine",
    "Divertissement",
    "Emissions",
  ],
  "Autres": [
    "Evénement",
    "Retransmission",
    "Cérémonie",
    "Loterie",
    "Tiercé",
  ]
}
