import { isEmpty, isEqual, isNil, isObject, transform } from 'lodash';

/** TODO
 * Is there any difference between two object
 *
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {boolean}       Return true is there any difference else false
 */
export function hasDifference(object, base) {
  let retDiff = difference(object, base);
  return isNil(retDiff) || isEmpty(retDiff) ? false : true;
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
  return changes(object, base);
}

export function changes(object, base) {
  return transform(object, function (result, value, key) {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? changes(value, base[key])
          : value;
    }
  });
}

//FROM https://jsfiddle.net/sbgoran/kySNu/
export class DeepDiffMapper {
  VALUE_CREATED = 'created';
  VALUE_UPDATED = 'updated';
  VALUE_DELETED = 'deleted';
  VALUE_UNCHANGED = 'unchanged';

  getDiff(object, base) {
    return this.map(object, base);
  }
  map(obj1, obj2) {
    if (this.isFunction(obj1) || this.isFunction(obj2)) {
      throw 'Invalid argument. Function given, object expected.';
    }
    if (this.isValue(obj1) || this.isValue(obj2)) {
      let comp = this.compareValues(obj1, obj2);
      if (comp === this.VALUE_UNCHANGED) {
        return obj1 === undefined ? obj2 : obj1;
      } else {
        return {
          type: comp,
          data: obj1 === undefined ? obj2 : obj1,
        };
      }
    }
    let diff = {};
    for (let key in obj1) {
      if (this.isFunction(obj1[key])) {
        continue;
      }
      var value2 = undefined;
      if ('undefined' != typeof obj2[key]) {
        value2 = obj2[key];
      }
      diff[key] = this.map(obj1[key], value2);
    }
    for (var key in obj2) {
      if (this.isFunction(obj2[key]) || 'undefined' != typeof diff[key]) {
        continue;
      }
      diff[key] = this.map(undefined, obj2[key]);
    }
    return diff;
  }

  compareValues(value1, value2) {
    if (value1 === value2) {
      return this.VALUE_UNCHANGED;
    }
    if (
      this.isDate(value1) &&
      this.isDate(value2) &&
      value1.getTime() === value2.getTime()
    ) {
      return this.VALUE_UNCHANGED;
    }
    if ('undefined' == typeof value1) {
      return this.VALUE_CREATED;
    }
    if ('undefined' == typeof value2) {
      return this.VALUE_DELETED;
    }
    return this.VALUE_UPDATED;
  }

  isFunction(obj) {
    return {}.toString.apply(obj) === '[object Function]';
  }

  isArray(obj) {
    return {}.toString.apply(obj) === '[object Array]';
  }

  isObject(obj) {
    return {}.toString.apply(obj) === '[object Object]';
  }

  isDate(obj) {
    return {}.toString.apply(obj) === '[object Date]';
  }

  isValue(obj) {
    return !this.isObject(obj) && !this.isArray(obj);
  }
}
