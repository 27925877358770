import { cloneDeep, isNil } from 'lodash';

const state = {
  all: [],
  savedAll: [],
};

const getters = {
  allActivated: (state) => {
    return state.all.filter((x) => x.activated);
  },
  allDisabled: (state) => {
    return state.all.filter((x) => !x.activated);
  },
};

const mutations = {
  getAllSuccess(state, metadatas) {
    state.all = metadatas;
    state.savedAll = cloneDeep(state.all);
  },
  updateMetadatas(state, metadatas) {
    state.all = metadatas;
  },
  createMetadata(state, metadata) {
    state.all.push(metadata);
  },
  updateMetadata(state, metadata) {
    const metadataIndex = state.all.findIndex((x) => x.id === metadata.id);
    state.all.splice(metadataIndex, 1, metadata);
  },
  deleteMetadata(state, metadata) {
    const metadataIndex = state.all.findIndex((x) => x.id === metadata.id);
    state.all.splice(metadataIndex, 1);
  },
  createMetadataOption(state, payload) {
    if (payload.metadata && payload.metadataOption) {
      let metadata = state.all.find(
        (x) => x.metadataId === payload.metadata.metadataId
      )[0];
      const metadataOption = payload.metadataOption;
      metadata.options.push(metadataOption);
    }
  },
  updateMetadataOption(state, payload) {
    if (payload.metadata && payload.metadataOption) {
      let metadata = state.all.find((x) => x.id === payload.metadata.id);
      const metadataOptionIndex = metadata.options.findIndex(
        (x) => x.id === payload.metadataOption.id
      );
      metadata.options.splice(metadataOptionIndex, 1, payload.metadataOption);
    }
  },
  deleteMetadataOption(state, payload) {
    if (payload.metadata && payload.metadataOption) {
      let metadata = state.all.find((x) => x.id === payload.metadata.id);
      const metadataOptionIndex = metadata.options.findIndex(
        (x) => x.id === payload.metadataOption.id
      );
      metadata.options.splice(metadataOptionIndex, 1);
    }
  },
};

const actions = {
  getAll({ commit }) {
    commit('getAllSuccess', metadatas);
  },
  get({ state }, metadataId) {
    return state.all.find((x) => x.id === metadataId);
  },
  updateMetadatasAction({ commit, dispatch }, metadatas) {
    const alert = {
      icon: 'check',
      type: 'valid',
      message: 'notifications.update.success',
    };
    commit('updateMetadatas', metadatas);
    dispatch('displayAlert', alert, { root: true });
  },
  createMetadataAction({ commit, dispatch }, metadata) {
    const alert = {
      icon: 'check',
      type: 'valid',
      message: 'notifications.create.success',
    };
    commit('createMetadata', metadata);
    dispatch('displayAlert', alert, { root: true });
  },
  updateMetadataAction({ commit, dispatch }, metadata) {
    const alert = {
      icon: 'check',
      type: 'valid',
      message: 'notifications.update.success',
    };
    commit('updateMetadata', metadata);
    dispatch('displayAlert', alert, { root: true });
  },
  deleteMetadataAction({ commit, dispatch }, metadata) {
    const alert = {
      icon: 'check',
      type: 'valid',
      message: 'notifications.delete.success',
    };
    commit('deleteMetadata', metadata);
    dispatch('displayAlert', alert, { root: true });
  },
  createMetadataOptionAction({ commit, dispatch }, payload) {
    const alert = {
      icon: 'check',
      type: 'valid',
      message: 'notifications.create.success',
    };
    commit('createMetadataOption', payload);
    dispatch('displayAlert', alert, { root: true });
  },
  updateMetadataOptionAction({ commit, dispatch }, payload) {
    const alert = {
      icon: 'check',
      type: 'valid',
      message: 'notifications.update.success',
    };
    commit('updateMetadataOption', payload);
    dispatch('displayAlert', alert, { root: true });
  },
  deleteMetadataOptionAction({ commit, dispatch }, payload) {
    const alert = {
      icon: 'check',
      type: 'valid',
      message: 'notifications.delete.success',
    };
    commit('deleteMetadataOption', payload);
    dispatch('displayAlert', alert, { root: true });
  },
};

export const metadatasParameters = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
