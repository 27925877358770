import { authService } from '@/services/user/auth.service';

const state = {
  authUser: JSON.parse(localStorage.getItem('user')) || null,
  token: localStorage.getItem('token') || null,
  loading: false,
  registered: false,
  permissions: [],
  error: 0,
};

const getters = {
  isAuthenticated: (state) => !!state.authUser,
  isRegistered: (state) => state.registered,
  isLoading: (state) => state.loading,
  user: (state) => state.authUser,
  can: (state, getters) => (role) =>
    getters.permissions.findIndex((x) => x == 'bo.' + role) != -1,
  cannot: (state, getters) => (role) => !getters.can(role),
  hasAccess: (state, getters) => (roles) => {
    for (let role of roles) {
      if (getters.can(role)) return true;
    };
    return false;
  },
  permissions: (state) =>
    state.permissions.length
      ? state.permissions
      : JSON.parse(localStorage.getItem('permissions')) ?? [],
  hasPermissions: (state, getters) => getters.permissions.length,
};

const actions = {
  login({ commit }, user) {
    commit('setLoading', true);
    return authService.login(user).then(
      (response) => {
        localStorage.setItem('user', JSON.stringify(response));
        localStorage.setItem('token', response.tokens.user);
        commit('setAuthUser', response);
        commit('setLoading', false);
        return Promise.resolve(response);
      },
      (error) => {
        commit('setLoading', false);
        localStorage.removeItem('user');
        return Promise.reject(error);
      }
    );
  },
  register({ commit }, user) {
    commit('setLoading', true);
    return authService.register(user).then(
      (response) => {
        commit('register_success');
        commit('setLoading', false);
        return Promise.resolve(response);
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  checkEmailPlatforms({ commit }, user) {
    commit('setLoading', true);
    return authService.checkEmailPlatforms(user).then(
      (response) => {
        if (response.length === 0) {
          commit('setLoading', false);
          return Promise.reject(response);
        } else {
          commit('setLoading', false);
          return Promise.resolve(response);
        }
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  sendResetLinkEmail({ commit }, user) {
    commit('setLoading', true);
    return authService.sendResetLinkEmail(user).then(
      (response) => {
        commit('setLoading', false);
        return Promise.resolve(response);
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  resetPassword({ commit }, user) {
    commit('setLoading', true);
    return authService.resetPassword(user).then(
      (response) => {
        if (response.length === 0) {
          commit('setLoading', false);
          return Promise.reject(response);
        } else {
          commit('setLoading', false);
          return Promise.resolve(response);
        }
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  logout({ commit, dispatch }) {
    return new Promise((resolve) => {
      commit('logout');
      commit('setLoading', false);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('user-platforms');
      localStorage.removeItem('permissions');
      localStorage.removeItem('platform');
      localStorage.removeItem('locale');
      localStorage.removeItem('fo_locale');
      resolve();
    });
  },

  getUserPermissions({ commit, state }, refresh = false) {
    if (!state.authUser?.id) {
      console.debug(`Do no refresh permissions: user id not set`);
      return Promise.resolve();
    }

    if (refresh === false && state.permissions.length) {
      console.debug(`Do no refresh permissions: ask by user`);
      return Promise.resolve();
    }

    return authService
      .getPermissions(state.authUser.id)
      .then((response) => {
        commit('setPermissions', response);
        if (response.length == 0) {
          state.error = 403;
        }
        return Promise.resolve(response);
      })
      .catch((error) => {
        commit('setPermissions', []);
        state.error = 403;
        return Promise.resolve(error);
      });
  },

  setError({ commit }, errorNumber) {
    commit('setError', errorNumber);
  },
};

const mutations = {
  setError(state, errorNumber) {
    state.error = errorNumber;
  },
  setLoading(state, status) {
    state.loading = status;
  },
  setAuthUser(state, user) {
    state.authUser = user;
    state.token = user.tokens.user;
  },

  resetUserPermissions(state) {
    state.permissions = [];
    localStorage.setItem('permissions', JSON.stringify([]));
  },

  setPermissions(state, permissionList) {
    state.loading = false;
    state.permissions = permissionList;
    localStorage.setItem('permissions', JSON.stringify(permissionList));
  },
  register_success(state) {
    state.registered = true;
  },
  logout(state) {
    state.loading = false;
    state.authUser = null;
    state.token = null;
  },
};

export const auth = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
