export const AdTypes = Object.freeze({
  MEGABANHEADER: 'megaban_header',
  MEGABANFOOTER: 'megaban_footer',
  INTERSTITIAL: 'interstitial',
  PAVES: 'block',
  PREROLL: 'preroll',
});

export const AdTargets = Object.freeze({
  ALL: 'all',
  PROSPECT: 'prospect',
  NONE: 'none',
});

export const Pages = Object.freeze({
  HOME: 'home',
  LANDING: 'landing',
  PROGRAM: 'program',
  SERIE: 'serie',
  LIVE: 'live',
  CATALOG: 'catalog',
  SELECTIONS: 'selection',
  SELECTION: 'one_selection',
  METADATAS: 'all_metadata',
  METADATA: 'one_metadata',
  ACCOUNT: 'account',
  STATICPAGES: 'legals',
  PAGE: 'page',
});

const adsConfigs = {
  types: [
    {
      id: AdTypes.MEGABANHEADER,
      title: 'section.avod.types.topbanner',
    },
    {
      id: AdTypes.MEGABANFOOTER,
      title: 'section.avod.types.banner',
    },
    {
      id: AdTypes.INTERSTITIAL,
      title: 'section.avod.types.interstitial',
    },
    {
      id: AdTypes.PAVES,
      title: 'section.avod.types.block',
    },
    {
      id: AdTypes.PREROLL,
      title: 'section.avod.types.preroll',
    },
  ],
  target: [
    {
      id: AdTargets.ALL,
      title: 'marketing.pages.ads.sections.target.options.all',
    },
    {
      id: AdTargets.PROSPECT,
      title: 'marketing.pages.ads.sections.target.options.prospect',
    },
    {
      id: AdTargets.NONE,
      title: 'marketing.pages.ads.sections.target.options.none',
    },
  ],
  pages: {
    default: [
      {
        id: Pages.HOME,
        label: 'pages.home',
      },
      {
        id: Pages.LANDING,
        label: 'pages.landing',
      },
      {
        id: Pages.PROGRAM,
        label: 'pages.program',
      },
      {
        id: Pages.SERIE,
        label: 'pages.serie',
      },
      {
        id: Pages.LIVE,
        label: 'pages.live',
      },
      {
        id: Pages.CATALOG,
        label: 'pages.catalog',
      },
      {
        id: Pages.SELECTIONS,
        label: 'pages.selections',
      },
      {
        id: Pages.SELECTION,
        label: 'pages.selection',
      },
      {
        id: Pages.METADATAS,
        label: 'pages.metadatas',
      },
      {
        id: Pages.METADATA,
        label: 'pages.metadata',
      },
      {
        id: Pages.ACCOUNT,
        label: 'pages.account',
      },
      {
        id: Pages.STATICPAGES,
        label: 'pages.staticpages',
      },
    ],
  },
};

export default adsConfigs;
