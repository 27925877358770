<template>
  <footer class="mt-6">
    <div class="page-footer-actions text-center mb-6">
      <slot />
    </div>
  </footer>
</template>
<script>
export default {
  name: 'PageFooter',
};
</script>
