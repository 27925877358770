import { Plan } from '@/models/Plan';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { customerService } from '@/services';

const state = {
  currentNewCustomer: {},
  savedCustomer: {},
  savedCards: {},
  savedSepas: {},
  loading: true,
};

const actions = {
  refresh({ state, dispatch }) {
    console.log('refresh');
    console.log(state.savedCustomer.user_id);
    if (isNil(state.savedCustomer.user_id)) {
      return Promise.resolve();
    }
    return dispatch('getByUser', state.savedCustomer.user_id);
  },

  getAll({ commit }, queryString) {
    return customerService.getAll(queryString).then(
      (customers) => {
        commit('getAllSuccess', customers);
        return Promise.resolve(customers);
      },
      (error) => {
        commit('cleanProgramStates', error);
        return Promise.resolve(error);
      }
    );
  },

  get({ commit, dispatch }, id) {
    return customerService.get(id).then(
      (customer) => {
        commit('getSuccess', customer);
        return Promise.resolve(customer);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },

  create({ commit, dispatch }, data) {
    return customerService.create(data).then(
      (customer) => {
        return Promise.resolve(customer);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },

  getCards({ commit, dispatch }, customerId) {
    return customerService.getBankInfos(customerId, "cards").then(
      (cards) => {
        commit('getSuccessCards', cards);
        return Promise.resolve(cards);
      },
      (error) => {
        commit('getFailureBankInfos', error, 'card');
        return Promise.reject(error);
      }
    );
  },
  getSepas({ commit, dispatch }, customerId) {
    return customerService.getBankInfos(customerId, "sepas").then(
      (sepas) => {
        commit('getSuccessSepas', sepas);
        return Promise.resolve(sepas);
      },
      (error) => {
        commit('getFailureBankInfos', error, 'sepa');
        return Promise.reject(error);
      }
    );
  },

  reset({ commit }) {
    commit('reset');
  },
};

const mutations = {
  reset(state) {
    state.currentNewCustomer = {};
    state.savedCustomer = {};
    state.savedCards = {};
    state.savedSepas = {};
    state.loading = false;
  },

  getAllRequest(state) {
    state.loading = true;
  },
  getAllSuccess(state, users) {
    state.all = users;
    state.loading = false;
  },
  getAllFailure(state) {
    state.loading = false;
  },
  getRequest(state) {
    state.loading = true;
    state.currentNewCustomer = {};
    state.savedCustomer = {};
  },

  getSuccess(state, customer) {
    //   const newCustomer = new Customer(customer);
    const newCustomer = customer;
    if (!isNil(customer) && !isEmpty(customer)) {
      customer.subscriptions.map(function (s) {
        s.plan = new Plan(s.plan);
        return s;
      });
      state.currentNewCustomer = customer;
      state.savedCustomer = cloneDeep(newCustomer);
    } else {
      state.currentNewCustomer = {};
      state.savedCustomer = {};
    }
    state.loading = false;
  },

  getSuccessCards(state, cards) {
    if (isNil(cards)) {
      state.savedCards = {};
    } else {
      state.savedCards = cards;
    }
    state.loading = false;
  },
  getSuccessSepas(state, sepas) {
    if (isNil(sepas)) {
      state.savedSepas = {};
    } else {
      state.savedSepas = sepas;
    }
    state.loading = false;
  },

  getFailureBankInfos(state, paymentMethod) {
    if (paymentMethod === 'card') {
      state.savedCards = {};
    } else if (paymentMethod === 'sepa') {
      state.savedSepas = {};
    }
    state.loading = false;
  },

  getFailure(state, error) {
    console.log('Customer - getFailure');
    state.currentNewCustomer = {};
    state.savedCustomer = {};
    state.loading = false;
  },
};

export const customers = {
  namespaced: true,
  state,
  actions,
  mutations,
};
