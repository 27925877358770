import { BlockService } from '@/services/content/blocks.service';

const blocksService = new BlockService();

const state = {
  currentBlocks: {},
  savedBlocks: {},
  loading: true,
};

const actions = {
  add({ commit, dispatch }, payload) {
    let { block, platformId = null } = payload;
    return blocksService.post(block, platformId).then(
      (response) => {
        // commit('getAddSuccess', response)
        return Promise.resolve();
      },
      (error) => {
        const alert = {
          id: 'block-add-failed',
          icon: 'close',
          type: 'error',
          message: 'notifications.create.error',
        };
        dispatch('displayAlert', alert, { root: true });
        // commit('getUpdateFailure', error)
        return Promise.reject();
      }
    );

    //
  },

  update({ commit, dispatch }, payload) {
    let { blockId, block, platformId = null } = payload;

    return blocksService.update(block, blockId, platformId).then(
      (response) => {
        // commit('getUpdateSuccess', response)
        return Promise.resolve();
      },
      (error) => {
        const alert = {
          id: 'block-update-failed',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        // commit('getUpdateFailure', error)
        return Promise.reject();
      }
    );

    //
  },

  updateRank({ commit, dispatch }, payload) {
    let { blockId, rank, picture } = payload;
    return blocksService.update({ rank: rank, picture: picture }, blockId).then(
      (response) => {
        // commit('getUpdateSuccess', response)
        return Promise.resolve();
      },
      (error) => {
        const alert = {
          id: 'block-rank-update-failed',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        // commit('getUpdateFailure', error)
        return Promise.reject();
      }
    );

    //
  },

  delete({ commit, dispatch }, payload) {
    let { blockId, platformId = null } = payload;
    return blocksService.delete(blockId, platformId).then(
      (response) => {
        return Promise.resolve();
      },
      (error) => {
        const alert = {
          id: 'block-delete-failed',
          icon: 'close',
          type: 'error',
          message: 'notifications.delete.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject();
      }
    );
  },
};

const mutations = {
  getSuccess(state, response) {
    state.savedBlocks = response;
    state.loading = false;
  },
};

export const blocks = {
  namespaced: true,
  state,
  actions,
  mutations,
};
