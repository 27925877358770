import { handleResponse } from '../index';
import axios from 'axios';

export class AdvertisingBlocksService {
  _endpointName;

  constructor() {
    this._endpointName = 'advertising-blocks';
  }

  async get(platformId) {
    let config = {
      headers: { transform: 'bo', platform: platformId },
      timeout: 30000,
    };

    try {
      const response = await axios.get(
        import.meta.env.VITE_CONTENT_BASE_URL + '/' + this._endpointName,
        config
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async post({ data, platformId }) {
    let config = {
      headers: { transform: 'bo', platform: platformId },
      timeout: 30000,
    };

    try {
      const response = await axios.post(
        import.meta.env.VITE_CONTENT_BASE_URL + '/' + this._endpointName,
        data,
        config
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }
}
