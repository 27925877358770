import { sortBy } from 'lodash';
import Picture from '@/models/Picture';
import blockTypes from '@/data/animationBlockTypes';
import store from '@/store';
import Item from '@/models/Item.js';



export class Block {
  id;
  type;
  template_id;
  platform_id;
  block_type_id;
  title;
  description;
  picture;
  cta_label;
  cta_url;
  cta_target;
  background_color;
  picture_default;
  spacing_top;
  spacing_bottom;
  items;
  rank;
  itemIDs;

  // Attributes for previewBlocks
  previewComponent;
  template;

  //Atribut permettant de gérer l'état du bloc
  status;

  placeholder;

  animationSettings = null;
  errors = null;

  // Custom form
  form;

  device;
  textLengthValid;
  _experimental;

  constructor(
    {
      id,
      type,
      template_id,
      platform_id,
      block_type_id,
      title = '',
      description,
      picture = [],
      cta_label = '',
      cta_url = '',
      cta_target = '',
      background_color = null,
      picture_default = null,
      spacing_top = null,
      spacing_bottom = null,
      items = [],
      rank,
      active = 1,
      is_linked,
      linked_block_id,
      platform_linked = [],
      carousel,
      block_video,
      details = [],
      buttons = [],
      modes = [],
      template = null,
      placeholder = false,
      errors = null,
      form = {},
      device = null,
      _experimental = {}
    }
  ) {
    this.id = id;
    if (typeof type === 'object' && type?.name) {
      this.type = type.name;
    } else {
      this.type = type;
    }
    this.template_id = template_id;
    this.platform_id = platform_id;
    this.block_type_id = block_type_id;
    this.title = title;
    this.description = description;
    this.picture = picture;
    this.cta_label = cta_label;
    this.cta_url = cta_url;
    this.cta_target = cta_target;
    this.background_color = background_color;
    this.picture_default = picture_default ?? 'poster';
    this.items = items;
    this.rank = rank;
    this.active = active;
    this.is_linked = is_linked;
    this.linked_block_id = linked_block_id;
    this.platform_linked = platform_linked;
    this.carousel = carousel;
    this.block_video = block_video;
    this.details = details;
    this.buttons = buttons;
    this.modes = modes;
    this.template = template;
    this.placeholder = placeholder;
    this.spacing_top = spacing_top;
    this.spacing_bottom = spacing_bottom;

    this.errors = errors;
    this.form = form;

    this.textLengthValid = true;
    this._experimental = _experimental;

    if (this.id && !this.placeholder) {
      this.loadBlockSettings();
      this.loadBlockButtons();
      this.loadItemIDs(items);
      this.loadBlockModes(platform_linked);
    }
    this.setPicture(picture);

    this.device = device
      ? device
      : store.getters['platforms/getPlatformTypeByPlatformId'](platform_id);

    if (this.type === 'multiscreen') {
      this.addItemToMultiscreen();
    } else if (this.type && this.type.includes('ad-')) {
      this.addItemToAd();
    }
  }

  getAnimationSettings() {
    if (this.animationSettings === null && this.type) {
      this.animationSettings = blockTypes.find(
        (element) => this.type === element.id
      );
    }
    return this.animationSettings;
  }

  loadItemIDs(items) {
    this.itemIDs = items.map((element) => element.id);
  }

  loadBlockSettings() {
    const blockType = blockTypes.find((element) =>
      this.type.startsWith(element.id)
    );
    if (blockType !== undefined) {
      this.previewComponent = blockType.components.preview;
      if (this.isRow()) {
        this.loadRowBlockSettings();
      } else if (this.type === 'video') {
        this.loadVideoBlockSettings();
      } else if (
        this.type.startsWith('carousel') ||
        this.type === 'fullscreen'
      ) {
        this.loadCarrouselBlockSettings();
      } else if (this.type === 'banner') {
        this.loadBannerBlockSettings();
      }
    }
  }

  loadBlockButtons() {
    if (this.details && this.details.button && this.details.button.length > 0) {
      this.buttons = this.details.button;
    }
  }

  loadBlockModes(platform_linked) {
    if (platform_linked && platform_linked.length > 0) {
      let platforms = sortBy(platform_linked, ['platform_id']);
      platforms = platforms.map((platform) => {
        platform.platform_type =
          platform.platform_type === 'main'
            ? 'desktop'
            : platform.platform_type;
        return platform;
      });
      this.platform_linked = platforms;
      this.modes = this.platform_linked.map((element) => {
        return element.platform_type;
      });
    } else {
      this.modes = ['desktop'];
    }
  }

  loadBannerBlockSettings() {
    this.template = '';
  }

  loadRowBlockSettings() {
    if (this.picture_default === 'poster') {
      this.template = { id: 1 };
    } else if (this.template === null) {
      this.template = { id: 2 };
    }
  }

  loadCarrouselBlockSettings() {
    if (this.type === 'carousel-1') {
      this.template = 1;
    } else if (this.type === 'carousel-2') {
      this.template = 2;
    }
    if (typeof this.carousel === 'object') {
      for (let prop in this.carousel) {
        if (
          typeof this.carousel[prop] === 'object' &&
          this.carousel[prop] !== null
        ) {
          for (let subprop in this.carousel[prop]) {
            this.carousel[prop][subprop] =
              this.carousel[prop][subprop] !== null
                ? this.carousel[prop][subprop]
                : '';
          }
        } else {
          this.carousel[prop] =
            this.carousel[prop] !== null ? this.carousel[prop] : '';
        }
      }
    }
  }

  loadVideoBlockSettings() {
    if (typeof this.block_video === 'object') {
      for (let prop in this.block_video) {
        if (
          typeof this.block_video[prop] === 'object' &&
          this.block_video[prop] !== null
        ) {
          for (let subprop in this.block_video[prop]) {
            this.block_video[prop][subprop] =
              this.block_video[prop][subprop] !== null
                ? this.block_video[prop][subprop]
                : '';
          }
        } else {
          this.block_video[prop] =
            this.block_video[prop] !== null ? this.block_video[prop] : '';
        }
      }
    }
  }



  setDefaultBackgroundColor(currentPlatform) {
    let configColor = this.background_color;
    const content_config = currentPlatform.content_config;
    const custom_background_color = content_config?.feature?.custom_background_color;
    const layout = content_config?.colors?.layout;
    if (!configColor) {
      this.background_color = (
        custom_background_color &&
        layout?.default?.background?.id === 'custom'
      )
        ? layout.default.background
        : (layout?.['layouts.default'] === 'dark'
          ? { hex: '#101010', id: 'black' }
          : { hex: '#ffffff', id: 'white' });
    } else if (typeof this.background_color !== 'object') {
      if (configColor.startsWith('#')) {
        this.background_color = {
          id: 'custom_hex',
          title: 'Couleur personnalisée',
          hex: configColor,
        };
      } else if (configColor !== '') {
        if (['white', 'black', 'custom'].includes(configColor)) {
          if (custom_background_color
            && layout?.default?.background?.id === 'custom'
          ) {
            configColor = layout.default.background.id;
          } else {
            configColor =
              (layout?.['layouts.default'] === 'dark'
                ? 'black' : 'white');
          }
        }
        let defaultColors = currentPlatform
          ? currentPlatform?.getDefaultColors([
              'black',
              'white',
              'custom',
              'primary',
              'secondary',
              'tertiary',
              'custom_hex'
            ]) ?? []
          : [];
        this.background_color = defaultColors.find(
          (element) => element.id === configColor
        );
      }
    }
  }

  setDefaultSpacing(currentPlatform, template) {
    const spacingTop = currentPlatform.content_config.animation.spacing_top;
    const spacingBottom = currentPlatform.content_config.animation.spacing_bottom;
    // spacing Top
    if (!this.spacing_top) {
      this.spacing_top =
        template?.spacing_top ?? spacingTop;
    }
    //spacing Bottom 
    if (!this.spacing_bottom) {
      this.spacing_bottom =
        template?.spacing_bottom ?? spacingBottom;
    }
  }

  setPicture(picture) {
    this.picture = Picture.getDefaultPicture('block');
    const pictureTypes = Picture.getDefaultPictureTypesList('block');
    if (typeof picture === 'object') {
      pictureTypes.forEach((pictureType) => {
        this.picture[pictureType] = picture[pictureType] ?? [];
      });
    } else {
      pictureTypes.forEach((pictureType) => {
        this.picture[pictureType] = [];
      });
    }
  }

  isRow() {
    return this.type.startsWith('row');
  }

  getModalSettingsTitle() {
    return this.getAnimationSettings().modalSettingsTitle;
  }

  getAnimationBlockTitle() {
    return this.getAnimationSettings().title;
  }

  getBlockable() {
    if (this.block_video) {
      return this.block_video;
    } else if (this.carousel) {
      return this.carousel;
    }
    return null;
  }

  addItemToMultiscreen() {
    if (this.items.length == 0) {
      const item = new Item({
        configuration: { devices: [], position: 'left' },
        picture: { thumbnails: [{ url: '' }] },
        type: 'custom',
      })
      this.items.push(item);
    }
  }

  addItemToAd() {
    if (this.items.length == 0) {
      this.items.push(new Item({ type: 'ad' }));
    }
  }
}

export function createBlock(data) {
  return Object.freeze(new Block(data));
}

export default Block;
