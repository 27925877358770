import actions from './animation/actions.js';
import getters from './animation/getters.js';
import mutations from './animation/mutations.js';
import state from './animation/state.js';

export const animation = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};