export class PlaybackConfiguration {
  constructor({
    completion_percentage = 95,
    simultaneous_playbacks_allowed = 4,
  } = {}) {
    this.completion_percentage = completion_percentage;
    this.simultaneous_playbacks_allowed = simultaneous_playbacks_allowed;
  }
}
export default PlaybackConfiguration;
