import { cloneDeep } from 'lodash';
import { episodesService, programService, seasonsService } from '@/services';
import { getField } from 'vuex-map-fields';
import { getIds } from '@/utils/SeasonUtil';
import router from '@/router';

const state = {
  seasonLoading: false,
};

const getters = {
  getField,
};

const actions = {
  get({ commit, dispatch }, id) {
    commit('setLoading', true);
    const query = `mini=1&with=video-details&cache=nocache`;
    return seasonsService.get(id).then(
      (season) => {
        commit('setLoading', false);
        return Promise.resolve(season);
      },
      (err) => {
        commit('setLoading', false);
        return Promise.reject(err);
      }
    );
  },
  getAll({ commit, dispatch }, { serieId }) {
    commit('setLoading', true);
    const queryString = 'serie_id=' + serieId + '&mini=1&cache=nocache';
    return seasonsService.all(queryString).then(
      (seasons) => {
        commit('setLoading', false);
        return Promise.resolve(seasons);
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  checkOne({ commit, dispatch }, { serieId }) {
    commit('setLoading', true);
    const queryString =
      'serie_id=' + serieId + '&mini=1&cache=nocache&count=1&page=1';
    return seasonsService.all(queryString).then(
      (seasons) => {
        commit('setLoading', false);
        return Promise.resolve(seasons);
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  create({ commit, dispatch }, season) {
    commit('setLoading', true);

    return seasonsService.create(season).then(
      async (createdSeason) => {
        await dispatch('programs/createSeasonSuccess', createdSeason, {
          root: true,
        });

        if (
          createdSeason.serie_id &&
          createdSeason.video &&
          createdSeason.video.length
        ) {
          commit('setLoading', true);
          const query = `serie_id=${createdSeason.serie_id}&cache=nocache`;
          await episodesService.all(query).then(
            (episodes) => {
              dispatch(
                'episodes/processEpisodes',
                {
                  programId: createdSeason.program_id,
                  serieId: createdSeason.serie_id,
                  seasonId: createdSeason.id,
                  newSeasonEpisodes: episodes,
                },
                { root: true }
              );
              commit('setLoading', false);
              return Promise.resolve();
            },
            (err) => {
              commit('setLoading', false);
              return Promise.reject(err);
            }
          );
        }

        const alert = {
          id: 'season-created',
          icon: 'check',
          type: 'valid',
          message: 'notifications.create.success',
        };
        commit('setLoading', false);
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(createdSeason);
      },
      (err) => {
        commit('setLoading', false);
        return Promise.reject(err);
      }
    );
  },
  updateAll({ commit, dispatch }, { serieId, seasons }) {
    commit('setLoading', true);
    console.log(seasons);
    const promises = [];
    seasons.forEach(({ newSeason, savedSeason }) => {
      const newEps = cloneDeep(newSeason.video.episodes);
      const savedEps = cloneDeep(savedSeason.video.episodes);

      // Update Video: Trailer && Bonuses
      console.log(newSeason.video);
      const programData = {
        id: newSeason.program_id,
        program_type_id: 3,
        title: newSeason.title,
        video: {
          bonus: getIds(newSeason.video.bonus),
          trailer: getIds(newSeason.video.trailer),
        },
      };

      promises.push(
        programService.update(programData, programData.id).then(
          async (data) => {
            // Update Season Episodes
            await dispatch(
              'episodes/processEpisodes',
              {
                serieId,
                seasonId: newSeason.id,
                newSeasonEpisodes: newEps,
                savedSeasonEpisodes: savedEps,
              },
              { root: true }
            );
            return Promise.resolve(data);
          },
          (err) => {
            return Promise.reject(err);
          }
        )
      );
    });
    return Promise.all(promises).then(
      () => {
        commit('setLoading', false);
        return Promise.resolve();
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  deleteSeason({ dispatch }, seasonId) {
    return seasonsService.deleteSeason(seasonId).then(
      () => {
        const alert = {
          id: 'program-removed',
          icon: 'check',
          type: 'valid',
          message: 'notifications.delete.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (err) => {
        const alert = {
          id: 'program-removed',
          icon: 'close',
          type: 'error',
          message: 'notifications.delete.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },
};

const mutations = {
  setLoading(state, value) {
    state.seasonLoading = value;
  },
};

export const seasons = {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
