<template>
  <div class="flex flex-col gap-2 w-full">
    <div class="flex flex-row lg:items-center gap-4 flex-wrap">
      <div v-if="$slots['title']" class="flex items-center grow">
        <div class="flex gap-2 items-center">
          <div
            ref="pagHeaderTitle"
            class="page-header-title font-medium flex items-center gap-2 group leading-none"
            :class="[
              !editableTitle && 'text-skin-accent text-2xl',
              editableTitle && 'text-xl page-header-title--editable',
              pagHeaderTitleIsHovered &&
                editableTitle &&
                'page-header-title--hovered',
            ]"
          >
            <slot name="title" />
            <IconButton v-if="editableTitle" @click="$emit('edit:title')">
              <Icon
                size="md"
                icon="edit"
                class="page-title-edit-hint cursor-pointer opacity-0 -rotate-12 w-0 transform -translate-x-4 group-hover:w-4 group-hover:inline-block group-hover:opacity-100 group-hover:rotate-0 group-hover:translate-x-0 group-focus-within:w-4 group-focus-within:inline-block group-focus-within:opacity-100 group-focus-within:rotate-0 group-focus-within:translate-x-0 transition-all duration-300 ease-in-out"
              />
            </IconButton>
          </div>
          <slot name="title-metas" />
        </div>
      </div>
      <div
        v-if="hasActionSlots"
        class="flex items-center lg:ml-auto divide-x divide-solid divide-skin-action-disabled"
      >
        <template v-for="index in actionsSlots">
          <div
            v-if="$slots[`action-${index}`]"
            :key="index"
            class="flex gap-3 items-center first:pl-0 last:pr-0 px-6"
          >
            <slot :name="`action-${index}`" />
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="hasInfosSlots"
      class="flex items-center divide-x divide-solid divide-skin-action-disabled"
    >
      <slot>
        <template v-for="index in infosSlots" :key="index">
          <div
            v-if="$slots[`info-${index}`]"
            class="flex items-center first:pl-0 px-3 last:pr-0"
          >
            <slot :name="`info-${index}`" />
          </div>
        </template>
      </slot>
    </div>
  </div>
</template>
<script>
import { inject, ref } from 'vue';
import { useElementHover } from '@vueuse/core';
import Icon from '@/components/icon/Icon.vue';
import IconButton from '@/components/icon/IconButton.vue';

export default {
  name: 'PageHeaderRow',
  components: { IconButton, Icon },
  props: {
    actions: {
      type: Number,
      default: 2,
    },
    infos: {
      type: Number,
      default: 4,
    },
    editableTitle: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['edit', 'edit:title'],
  setup() {
    const pagHeaderTitle = ref();
    const pagHeaderTitleIsHovered = useElementHover(pagHeaderTitle);
    const actionSlotsNb = inject('actionSlotsNb');
    const infosSlotsNb = inject('infosSlotsNb');
    const themedTitle = inject('themedTitle');

    return {
      pagHeaderTitle,
      pagHeaderTitleIsHovered,
      actionSlotsNb,
      infosSlotsNb,
      themedTitle
    };
  },

  computed: {
    actionsSlots() {
      return this.actionSlotsNb || this.actions;
    },
    infosSlots() {
      return this.infosSlotsNb || this.infos;
    },
    hasInfosSlots() {
      return Object.keys(this.$slots).some((slot) => slot.indexOf('info') > -1);
    },
    hasActionSlots() {
      return Object.keys(this.$slots).some(
        (slot) => slot.indexOf('action') > -1
      );
    },
  },
};
</script>
