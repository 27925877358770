import { computed, ref } from 'vue';

const useAccordion = (props = {}) => {
  const activeItemKey = ref(
    props.multiple
      ? Array.isArray(props.initialItemKey)
        ? [...props.initialItemKey]
        : [props.initialItemKey]
      : props.initialItemKey
  );
  const $activeKey = computed(() => activeItemKey.value);
  
  const toggleItemKey = (key) => {
    if (props.multiple) {
      if (activeItemKey.value.includes(key)) {
        const keyIndex = activeItemKey.value.indexOf(key);
        activeItemKey.value.splice(keyIndex, 1);
      } else {
        activeItemKey.value.push(key);
      }
    } else {
      activeItemKey.value = activeItemKey.value === key ? '' : key;
    }
  };

  return {
    $activeKey,
    toggleItemKey,
  };
};

export default useAccordion;
