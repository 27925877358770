import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/playback-configuration/';

export const playbackConfigurationService = {
  get,
  update,
};

async function get() {
  try {
    const response = await axios.get(
      import.meta.env.VITE_MEDIA_BASE_URL + api_url_module
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function update(data) {
  try {
    const response = await axios.put(
      import.meta.env.VITE_MEDIA_BASE_URL + api_url_module,
      data
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}
