import Style from './Style';
import store from '@/store';

export class Badge {
  constructor({
    id = null,
    platform_id = null,
    related_id = null,
    text_aspect = null,
    name = 'Voir',
    label = null,
    language = 'fr',
    badge_type = null,
    position = 'bottom-right',
    style = null,
    online = true,
  } = {}) {
    (this.id = id),
      (this.platform_id = platform_id),
      (this.related_id = related_id),
      (this.name = name);
    this.language = language;
    this.badge_type = badge_type ?? 'default';
    this.label = label ?? badge_type;
    this.badge_type_id = store.getters['references/badge_type_id'](badge_type);
    this.position = position;

    this.style = style ? style : new Style();

    this.online = online;

    this.setTextAspect(text_aspect);
    this.setStyleLabel();
  }

  setTextAspect(text_aspect) {
    switch (this.badge_type) {
      case 'duration':
        this.text_aspect = text_aspect ?? 'min';
        break;
      case 'live':
        this.text_aspect = text_aspect ?? 'badge-timer';
        break;
      default:
        this.text_aspect = text_aspect ?? 'default';
        break;
    }
  }

  setStyleLabel() {
    switch (this.badge_type) {
      case 'duration':
      case 'live':
        this.style.group_label = this.style?.group_label ?? this.badge_type;
        this.style.label = 'default';
        break;
      default:
        this.style.group_label = 'default';
        this.style.label = 'default';
        break;
    }
  }
}

export function createBadge(data) {
  return Object.freeze(new Badge(data));
}

export default Badge;
