export class Email {
  constructor({
    id = -1,
    name = null,
    mailable = null,
    subject = null,
    html_template = '',
    text_template = '',
  } = {}) {
    this.id = id;
    this.name = name;
    this.mailable = mailable;
    this.subject = subject;
    this.html_template = html_template;
    this.text_template = text_template;
  }
}
export default Email;
