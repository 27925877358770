const state = {
  loading: false,
};
const actions = {
  save({ commit, dispatch }) {
    commit('setLoading', true);
    const promiseUsers = dispatch('users/update', null, { root: true });
    const promiseNewsletters = dispatch('newsletters/save', null, {
      root: true,
    });
    const promiseSubscriptions = dispatch('subscriptions/cancel', null, {
      root: true,
    });

    return Promise.all([
      promiseUsers,
      promiseNewsletters,
      promiseSubscriptions,
    ]).then(
      async (values) => {
        await dispatch('refresh');
        return Promise.resolve();
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  reset({ commit, dispatch }) {
    console.log(' --> userProfiles reset');
    commit('setLoading', true);
    const promiseUsers = dispatch('users/resetOne', null, { root: true });
    const promiseNewsletters = dispatch('newsletters/reset', null, {
      root: true,
    });
    const promiseSubscriptions = dispatch(
      'subscriptions/resetToBeCanceledList',
      null,
      { root: true }
    );

    return Promise.all([
      promiseUsers,
      promiseNewsletters,
      promiseSubscriptions,
    ]).then(
      (values) => {
        commit('setLoading', false);
        return Promise.resolve(values);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  async refresh({ dispatch, commit }) {
    console.log(' --> userProfiles refresh');

    return Promise.all([
      await dispatch('users/refresh', null, { root: true }),
      await dispatch('customers/refresh', null, { root: true }),
      await dispatch('coupons/refresh', null, { root: true }),
    ]).then(
      (values) => {
        commit('setLoading', false);
        return Promise.resolve(values);
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  setLoading: (state, value) => {
    state.loading = value;
  },
};

const getters = {
  shouldSaved: (state, getters, rootState, rootGetters) => {
    let retval =
      rootGetters['newsletters/shouldSaved'] ||
      rootGetters['users/shouldSaved'];
    return retval;
  },
};

export const userProfiles = {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
};
