<template>
  <div
    class="relative h-[14px] border border-solid border-skin-action-muted bg-skin-ui p-[2px] rounded-full"
  >
    <div
      class="w-0 h-full bg-success-500 rounded-full transition-all"
      :style="getWidth()"
    />
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progress: [String, Number],
  },
  methods: {
    getWidth() {
      return `width: ${this.progress}%`;
    },
  },
};
</script>
