import { cloneDeep, isEmpty, isNil } from 'lodash';

import DisplayConfig from '@/models/parameters/DisplayConfig';
import store from '@/store';

/**
 * Block episode for program/serie/live template
 *
 * @author Jody JUSTINE <jjustine@vodfactory.com>
 */
export class BlockEpisode {
  id;
  block_id;
  block_type;
  details;
  rank;
  title;
  title_size;
  title_weight;
  title_style;
  content_episode;

  format;
  stylable;

  type;
  template_id;
  platform_id;
  block_type_id;
  items;

  displayConfig;

  constructor({ platform_id, template_id, block_id, rank, content_episode }) {
    this.platform_id = platform_id;
    this.template_id = template_id;
    this.block_id = block_id ?? 'episode';

    this.block_type = 'banner';
    this.block_type_id = store.getters['references/block_type_id'](
      this.block_type
    );
    this.item_type = 'episode';
    this.item_type_id = store.getters['references/item_type_id'](
      this.item_type
    );

    this.rank = rank;
    this.content_episode = content_episode;

    this.setDefault();
    this.setDisplayConfig();
  }

  setDefault() {
    (this.id = 'episode'),
      (this.type = 'episode'),
      (this.format = 'episode'),
      (this.stylable = false);
    this.active = 1;

    this.details = {
      name: 'episode',
      title: null,
      type: 'episode',
    };
    if (isNil(this.content_episode) || isEmpty(this.content_episode)) {
      this.content_episode = {};
    }
    if (
      isNil(this.content_episode.aspect) ||
      isEmpty(this.content_episode.aspect)
    ) {
      this.content_episode.aspect = '1';
    }
    if (isNil(this.content_episode.show_duration)) {
      this.content_episode.show_duration = false;
    }
  }

  /**
   * Get template format id
   * @returns {String} template format id
   */
  getAspect() {
    if (isNil(this.content_episode.aspect)) {
      return 0;
    }
    return this.content_episode.aspect.toString();
  }

  /**
   * Set template format id
   * @returns {Void}
   */
  setAspect(value) {
    this.content_episode.aspect = value.toString();
  }

  setShowDuration(value) {
    this.content_episode.show_duration = value;
  }

  getShowDuration() {
    return this.content_episode?.show_duration ?? false;
  }

  /**
   * Set config for metadata display
   */
  setDisplayConfig() {
    let retval = {};
    retval.title = null;
    retval.default_title = null;

    // Set Title style : bold / italic / font-size
    retval.titleStyles = {};
    retval.titleStyles.bold =
      (this?.title_weight ?? null) == 'bold' ? true : false;
    retval.titleStyles.fontSize =
      this?.title_size ?? null
        ? 'font-size-' + this.title_size
        : 'font-size-md';
    retval.titleStyles.italic =
      (this?.title_style ?? null) == 'italic' ? true : false;

    this.displayConfig = new DisplayConfig(retval);
  }

  /**
   * Get clean data for api
   *
   * @return object
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  getCleanDataForApi() {
    let retval = cloneDeep(this);

    retval.items = [{ item_type_id: this.item_type_id, rank: 0 }];
    this.content_episode.aspect = this.content_episode.aspect.toString();

    // Remove unnecessary data for api
    delete retval.format;
    delete retval.stylable;
    delete retval.temp;
    delete retval.displayConfig;
    delete retval.defaultColors;
    delete retval.details;

    return retval;
  }
}

export default BlockEpisode;
