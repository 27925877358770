import { EmailService } from '@/services';
import { cloneDeep } from 'lodash';
import { hasDifference } from '@/utils/diffTools';
import Email from '@/models/platform/Email';
import emailConfigs from '@/data/emailConfigs';
import kebabcase from 'lodash/kebabCase';

const emailService = new EmailService();

const state = {
  all: [],
  allSaved: [],
  newEmail: new Email(),
  savedEmail: new Email(),
  formattedNewEmail: null,
  formSendEmail: {},
  loading: false,
};

const getters = {
  shouldSaveOne: (state) => {
    return hasDifference(state.newEmail, state.savedEmail);
  },
  isFormSendable: (state) => {
    if (hasDifference(state.newEmail, state.savedEmail)) {
      return false;
    } else if (state.savedEmail.name) {
      let emailConfig = emailConfigs.find(
        (x) => x.name === state.savedEmail.name
      );
      let validity = true;
      emailConfig.params.forEach((param) => {
        switch (param.type) {
          case 'string':
            validity =
              validity &&
              typeof state.formSendEmail[param.label] === 'string' &&
              state.formSendEmail[param.label] !== null &&
              state.formSendEmail[param.label] !== '';
            break;
          case 'boolean':
            validity =
              validity && typeof state.formSendEmail[param.label] === 'boolean';
            break;
          default: // Case not managed
            validity = false;
            break;
        }
      });
      return validity;
    } else {
      return false;
    }
  },
};

const actions = {
  resetAll({ commit, dispatch }) {
    commit('cleanAllStates');
  },
  resetOne({ commit, dispatch }) {
    commit('cleanOneStates');
  },
  getAll({ commit, dispatch }) {
    commit('getAllRequest');
    return emailService.getAll().then(
      (emails) => {
        commit('getAllSuccess', emails);
        return Promise.resolve(emails);
      },
      (error) => {
        commit('cleanAllStates', error);
        return Promise.reject(error);
      }
    );
  },
  get({ commit, dispatch }, id) {
    commit('getRequest');
    return emailService.get(id).then(
      (email) => {
        commit('getSuccess', email);
        return Promise.resolve(email);
      },
      (error) => {
        commit('cleanAllStates', error);
        return Promise.reject(error);
      }
    );
  },
  create({ commit, dispatch }, email) {
    commit('createRequest', email);
    return emailService.create(state.formattedNewEmail).then(
      (email) => {
        commit('createSuccess', email);
        const alert = {
          id: 'email-well-created',
          icon: 'check',
          type: 'valid',
          message: 'notifications.create.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(email);
      },
      (error) => {
        commit('createFailure');
        const alert = {
          id: 'email-not-created',
          icon: 'close',
          type: 'error',
          message: 'notifications.create.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  update({ commit, dispatch }) {
    commit('updateRequest');
    return emailService.update(state.formattedNewEmail, state.newEmail.id).then(
      (email) => {
        commit('updateSuccess', email);
        const alert = {
          id: 'email-well-updated',
          icon: 'check',
          type: 'valid',
          message: 'notifications.update.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(email);
      },
      (error) => {
        commit('updateFailure');
        const alert = {
          id: 'email-not-updated',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  delete({ commit, dispatch }, id) {
    commit('deleteRequest');
    return emailService.delete(id).then(
      () => {
        commit('deleteSuccess', id);
        const alert = {
          id: 'email-well-deleted',
          icon: 'check',
          type: 'valid',
          message: 'notifications.delete.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (error) => {
        commit('cleanAllStates', error);
        const alert = {
          id: 'email-not-deleted',
          icon: 'close',
          type: 'error',
          message: 'notifications.delete.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  sendForm({ commit, dispatch }) {
    commit('sendFormRequest', Email);
    return emailService.send(state.newEmail.name, state.formSendEmail).then(
      (testEmail) => {
        commit('sendFormSuccess');
        const alert = {
          id: 'email-well-sent',
          icon: 'check',
          type: 'valid',
          message: 'notifications.email.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(testEmail);
      },
      (error) => {
        commit('sendFormFailure');
        const alert = {
          id: 'email-not-sent',
          icon: 'close',
          type: 'error',
          message: 'notifications.email.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  resetForm({ commit, rootState }) {
    let user = rootState.auth.authUser;
    let platform = rootState.platforms.currentPlatform;
    commit('resetForm', { user, platform });
  },
};

const mutations = {
  cleanAllStates(state) {
    state.loading = false;
    state.all = [];
    state.allSaved = [];
  },
  cleanOneStates(state) {
    state.loading = false;
    state.newEmail = new Email();
    state.savedEmail = new Email();
    state.formattedNewEmail = null;
  },

  getAllRequest(state) {
    state.loading = true;
    state.all = [];
    state.allSaved = [];
  },
  getAllSuccess(state, emails) {
    emails.forEach((x) => {
      return new Email(x);
    });
    state.all = emails;
    state.allSaved = emails;
    state.loading = false;
  },
  getAllFailure(state) {
    state.all = [];
    state.allSaved = [];
    state.loading = false;
  },

  getRequest(state) {
    state.loading = true;
    state.newEmail = new Email();
    state.savedEmail = new Email();
  },
  getSuccess(state, email) {
    state.newEmail = new Email(email);
    state.savedEmail = new Email(email);
    state.loading = false;
  },
  getFailure(state) {
    state.newEmail = new Email();
    state.savedEmail = new Email();
    state.loading = false;
  },

  createRequest(state, email) {
    state.loading = true;
    state.formattedNewEmail = {};
    state.formattedNewEmail.name = kebabcase(email.name)
      .replace('1-d', '1d')
      .replace('1-w', '1w')
      .replace('1-m', '1m');
    state.formattedNewEmail.mailable = email.mailable;
    state.formattedNewEmail.subject = email.subject;
    state.formattedNewEmail.html_template =
      typeof email.html_template === 'undefined' ||
      email.html_template.length === 0
        ? '# todo'
        : email.html_template;
    state.formattedNewEmail.text_template =
      typeof email.text_template === 'undefined' ||
      email.text_template.length === 0
        ? '# todo'
        : email.text_template;
  },
  createSuccess(state, input) {
    state.newEmail = new Email(input);
    state.savedEmail = new Email(input);
    state.all = [];
    state.allSaved = [];
    state.loading = false;
  },
  createFailure(state, input) {
    state.formattedNewEmail = null;
    state.loading = false;
  },

  updateRequest(state) {
    state.loading = true;
    state.formattedNewEmail = {};
    state.formattedNewEmail.subject = state.newEmail.subject;
    state.formattedNewEmail.html_template =
      typeof state.newEmail.html_template === 'undefined' ||
      state.newEmail.html_template === null ||
      state.newEmail.html_template.length === 0
        ? '# todo'
        : state.newEmail.html_template;
    state.formattedNewEmail.text_template =
      typeof state.newEmail.text_template === 'undefined' ||
      state.newEmail.text_template === null ||
      state.newEmail.text_template.length === 0
        ? '# todo'
        : state.newEmail.text_template;
  },
  updateSuccess(state, input) {
    state.formattedNewEmail = null;
    state.newEmail = new Email(input);
    state.savedEmail = new Email(input);
    state.loading = false;
  },
  updateFailure(state) {
    state.formattedNewEmail = null;
    state.loading = false;
  },

  deleteRequest(state) {
    state.loading = true;
    state.newEmail = new Email();
    state.savedEmail = new Email();
  },
  deleteSuccess(state, id) {
    let all = cloneDeep(state.all);
    let index = all.findIndex((x) => x.id === id);
    all.splice(index, 1);
    state.all = cloneDeep(all);
    state.allSaved = cloneDeep(all);
    state.loading = false;
  },
  deleteFailure(state) {
    state.newEmail = new Email();
    state.savedEmail = new Email();
    state.loading = false;
  },

  sendFormRequest(state) {
    state.loading = true;
    //no specific preparation
  },
  sendFormSuccess(state) {
    state.loading = false;
  },
  sendFormFailure(state) {
    state.loading = false;
  },

  resetForm(state, { user, platform }) {
    if (state.savedEmail.name ?? null) {
      let emailConfig = emailConfigs.find(
        (x) => x.name === state.savedEmail.name
      );
      let config = {};
      emailConfig.params.forEach((param) => {
        switch (param.type) {
          case 'string':
            switch (param.label) {
              case 'firstname':
                config[param.label] = user.firstname ?? 'John';
                break;
              case 'lastname':
                config[param.label] = user.lastname ?? 'Doe';
                break;
              case 'email':
                config[param.label] = user.email ?? 'vodfactory75010@gmail.com';
                break;
              case 'online_link':
                config[param.label] =
                  platform.front_url ?? 'https://www.monsite.fr';
                break;
              case 'account_link':
                config[param.label] =
                  (platform.front_url ?? 'https://www.monsite.fr') + '/account';
                break;
              case 'discover_link':
                config[param.label] =
                  (platform.front_url ?? 'https://www.monsite.fr') +
                  '/discover';
                break;
              case 'subscription_link':
                config[param.label] =
                  (platform.front_url ?? 'https://www.monsite.fr') +
                  '/subscribe';
                break;
              case 'reset_link':
                config[param.label] =
                  (platform.front_url ?? 'https://www.monsite.fr') +
                  '#fake-reset-link';
                break;
              default:
                config[param.label] = null;
                break;
            }
            break;
          case 'boolean':
            state.formSendEmail[param.label] = true;
            break;
          default:
            //ignore
            break;
        }
      });
      state.formSendEmail = config;
    } else {
      state.formSendEmail = {};
    }
  },
  updateForm(state, { label, value, type = 'string' }) {
    if (type === 'boolean') {
      value = value === '1' ? true : false;
    }
    let copy = cloneDeep(state.formSendEmail);
    copy[label] = value;
    state.formSendEmail = copy;
  },
};

export const emails = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
