import axios from 'axios';
import { handleResponse } from '@/services/index.js';
import mockedPictureResponse from '@/data/__mocks__/pictures';

export class GenericPlatformService {
  constructor(endpointName) {
    this.endpointName = endpointName;
  }

  async getAll() {
    try {
      const response = await axios.get(
        import.meta.env.VITE_PLATFORM_BASE_URL + '/' + this.endpointName
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async get(selectedPlatformID, light = false) {
    try {
      const endpointSuffix = light ? 'name-logo/' : '';
      const response = await axios.get(
        import.meta.env.VITE_PLATFORM_BASE_URL +
        '/' +
        this.endpointName +
        '/' +
        endpointSuffix +
        selectedPlatformID
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async getMany(ids) {
    try {
      const endpointSuffix = 'name-logo';
      const response = await axios.get(
        import.meta.env.VITE_PLATFORM_BASE_URL +
        '/' +
        this.endpointName +
        '/' +
        endpointSuffix +
        '?id=in:' +
        ids.join(',')
      );
      return handleResponse(response.data);
    } catch (err) {
      console.log(err);
      return handleResponse(err);
    }
  }

  async create(data, requestOptions = {}) {
    requestOptions.method = 'POST';
    try {
      const response = await axios.post(
        import.meta.env.VITE_PLATFORM_BASE_URL + '/' + this.endpointName,
        data,
        requestOptions
      );
      return handleResponse(response.data, response.status);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async createMany(data) {
    try {
      const response = await axios.post(
        import.meta.env.VITE_PLATFORM_BASE_URL +
        '/' +
        this.endpointName +
        '/create-many',
        data
      );
      return handleResponse(response.data, response.status);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async update(selectedPlatformID, data) {
    try {
      const response = await axios.put(
        import.meta.env.VITE_PLATFORM_BASE_URL +
        '/' +
        this.endpointName +
        '/' +
        selectedPlatformID,
        data
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async updateMany(data) {
    try {
      const response = await axios.post(
        import.meta.env.VITE_PLATFORM_BASE_URL +
        '/' +
        this.endpointName +
        '/update-many',
        data
      );
      return handleResponse(response.data, response.status);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async delete(id) {
    try {
      const response = await axios.delete(
        import.meta.env.VITE_PLATFORM_BASE_URL +
        '/' +
        this.endpointName +
        '/' +
        id
      );
      if (response.status === 204) {
        return handleResponse(
          { status: 'success', results: { data: [] } },
          204
        );
      } else {
        return handleResponse(response.data);
      }
    } catch (err) {
      return handleResponse(err);
    }
  }

  async deleteMany(data) {
    try {
      const response = await axios.post(
        import.meta.env.VITE_PLATFORM_BASE_URL +
        '/' +
        this.endpointName +
        '/delete-many',
        data
      );
      return handleResponse(response.data, response.status);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async createPicture(data, requestOptions = {}) {
    let formData;
    if (typeof data.file !== 'undefined') {
      formData = new FormData();
      var myFile = data.file;
      delete data.file;
      delete data.name;
      formData.append('file', myFile);
      Object.keys(data).forEach(function (one) {
        formData.set(one, data[one]);
      });
      requestOptions['Content-Type'] = 'multipart/form-data';
      requestOptions.method = 'POST';
    } else {
      formData = data;
      requestOptions.method = 'POST';
    }
    try {
      const response = await axios.post(
        import.meta.env.VITE_PLATFORM_BASE_URL + '/' + this.endpointName,
        formData,
        requestOptions
      );
      response.id = null;
      //response.itemType = formData.itemType
      return response.data.results;
    } catch (response_1) {
      return await Promise.reject();
    }
  }

  async updatePicture(data, requestOptions = {}) {
    let formData;
    if (typeof data.file !== 'undefined') {
      formData = new FormData();
      var myFile = data.file;
      delete data.file;
      delete data.name;
      formData.append('file', myFile);
      Object.keys(data).forEach(function (one) {
        formData.set(one, data[one]);
      });
      requestOptions['Content-Type'] = 'multipart/form-data';
      requestOptions.method = 'PUT';
    } else {
      formData = data;
      requestOptions.method = 'PUT';
    }

    try {
      const response = await axios.put(
        import.meta.env.VITE_PLATFORM_BASE_URL + '/' + this.endpointName,
        formData,
        requestOptions
      );
      return response?.data?.results;
    } catch (response_1) {
      return await Promise.reject();
    }
  }

  async updateBOMaintenanceMode(data, personalToken) {
    const config = { headers: { 'personal-token': personalToken } };
    try {
      const response = await axios.put(
        import.meta.env.VITE_PLATFORM_BASE_URL +
        '/' +
        this.endpointName +
        '/bo-maintenance',
        data,
        config
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

}
