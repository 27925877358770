<template>
  <div class="theme-teal">
    <header id="header" class="main-header fixed top-0 left-0 flex items-center h-[3.75rem] w-1/2 p-4 border-transparent">
      <div class="main-header-logo">
        <router-link to="/">
          <img alt="Otto logo" :src="getEnvLogo" class="main-header-logo-img h-[38px]"/>
        </router-link>
      </div>
    </header>
    <div
      class="page login-page flex flex-row h-screen"
      :class="{
        'login-page--login': !auth.hasForgottenPassword,
        'login-page--forgot': auth.hasForgottenPassword,
      }"
    >
      <div class="login-page-form flex flex-1 flex-col items-center justify-center bg-white">
        <div
          v-if="!auth.hasForgottenPassword"
          class="login-page-form-container max-w-[360px]"
        >
          <header class="page-header mb-4">
            <h1 class="page-title font-size-xl leading-snug whitespace-pre-line">
              {{ t('login.title') }}
            </h1>
          </header>
          <p class="page-description text-lg font-weight-light leading-snug" :class="auth.hasForgottenPassword ? 'mb-8' : 'mb-12'">
            {{ t('login.description') }}
          </p>
          <form
            class="flex flex-col gap-6 w-80"
            @keydown="errors.clear($event.target.name)"
            @submit.prevent
          >
            <FormControl
              id="email"
              :label="$t('login.input.email')"
              :error="errors.get('email')"
              :labelAutoColons="false"
            >
              <FormInput
                id="email"
                v-model="auth.email"
                type="email"
                name="email"
                autofocus
                @reset="resetInput('email')"
              >
                <template #header>
                  <Icon icon="mail" size="md" />
                </template>
              </FormInput>
            </FormControl>
            <transition data-cy="platforms" name="slide-fade">
              <div
                v-if="showCompleteForm && userPlatforms.length > 1"
                class="form-input"
              >
                <FormControl :label="$t('login.input.platforms.title')" :labelAutoColons="false">
                  <FormSelect
                    v-model="selectedPlatform"
                    with-search
                    :options="userPlatformsSorted"
                  >
                    <template #title="{ selectedOption }">
                      <div
                        v-if="selectedOption.length"
                        class="flex flex-row gap-2 items-center"
                      >
                        <Image
                          :src="selectedOption[0].logo"
                          :width="30"
                          :height="30"
                          rounded
                          with-background
                          :alt="selectedOption[0].name"
                        />
                        <span class="inline-flex items-center text-center">{{
                          selectedOption[0].name
                        }}</span>
                      </div>
                      <span v-else class="flex flex-auto">{{
                        $t('generic.select')
                      }}</span>
                    </template>
                    <template #option="option">
                      <div class="flex flex-row gap-2 items-center">
                        <Image
                          :src="option.image"
                          :width="30"
                          :height="30"
                          rounded
                          with-background
                          :alt="option.title"
                        />
                        <span class="inline-flex items-center text-center">{{
                          option.title
                        }}</span>
                      </div>
                    </template>
                  </FormSelect>
                </FormControl>
              </div>
            </transition>
            <transition data-cy="password" name="slide-fade">
              <div v-if="showCompleteForm" class="relative">
                <FormControl
                  id="password"
                  :label="t('login.input.password')"
                  :error="errors.get('password')"
                  :labelAutoColons="false"
                >
                  <div class="absolute -top-7 right-0">
                    <a
                      class="text-xs underline"
                      @click.prevent="handleForgetPassword()"
                    >
                      Mot de passe oublié ?
                    </a>
                  </div>
                  <FormInput
                    id="password"
                    v-model="auth.password"
                    type="password"
                    name="password"
                    disclosable
                    autofocus
                    @enter="!isFormInvalid && handleSubmit()"
                    @reset="resetInput('password')"
                  />
                </FormControl>
              </div>
            </transition>
            <div class="form-input">
              <div class="form-input-container">
                <BaseButton
                  :label="submitLabel"
                  :loading="isLoading"
                  class="w-full"
                  @click="handleSubmit()"
                />
              </div>
            </div>
          </form>
        </div>
        <div
          v-if="auth.hasForgottenPassword"
          class="login-page-form-container max-w-[360px] login-page-form-container--forgot"
        >
          <header class="page-header mb-4">
            <h1 class="page-title font-size-xl leading-snug">
              {{ t('login.forgot.title') }}
            </h1>
          </header>
          <p class="page-description text-lg font-weight-light leading-snug mb-8">
            {{ t('login.forgot.description.text1') }} {{ auth.email }}
          </p>
          <p class="page-description text-lg font-weight-light leading-snug mb-8">
            {{ t('login.forgot.description.text2') }}
          </p>
          <form>
            <div class="form-input">
              <div class="form-input-container">
                <BaseButton
                  :label="t('login.forgot.back')"
                  class="w-full"
                  @click.prevent="auth.hasForgottenPassword = false"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="login-page-illustration login-page-illustration--logging hidden md:block flex-1 bg-no-repeat bg-center bg-scroll" style="background-image: url('/src/images/illustrations/poulpi_laptop.gif');background-size: 400px;"/>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import BaseButton from '@/components/button/BaseButton.vue';
import Errors from '@/utils/Errors';
import FormControl from '@/components/form/FormControl.vue';
import FormInput from '@/components/form/FormInput.vue';
import FormSelect from '@/components/form/FormSelect.vue';
import Icon from '@/components/icon/Icon.vue';
import Image from '@/components/image/Image.vue';
const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const store = useStore();
const emit = defineEmits(['login']);

const hasPlatforms = computed(() => store.getters['platforms/hasPlatforms']);
const isLoading = computed(() => store.getters['auth/isLoading']);
const selectedPlatformID = computed(
  () => store.state.platforms.selectedPlatformID
);
let userPlatforms = computed({
  get() {
    return store.state.platforms.userPlatforms;
  },
  set(value) {
    store.commit('platforms/storeUserPlatforms', { platforms: value });
  },
});

const auth = reactive({
  email: '',
  password: '',
  hasForgottenPassword: false,
});
const errors = reactive(new Errors());
const isFormInvalid = computed(() => {
  if (showCompleteForm.value) {
    return !auth.password || !selectedPlatformID.value;
  }
  return !auth.email || errors.any();
});

const submitLabel = computed(() => {
  return hasPlatforms.value
    ? t('login.input.buttons.connect')
    : t('login.input.buttons.validate');
});
const showCompleteForm = computed(() => {
  return hasPlatforms.value && userPlatforms && !!userPlatforms.value.length;
});
const getEnvLogo = computed(() => {
  return `/logos/otto.${import.meta.env.VITE_ENV}.png`;
});

const userPlatformsSorted = computed(() => {
  return (
    userPlatforms?.value?.map((platform) => {
      return {
        id: platform.id,
        title: platform.name,
        image: platform.logo ?? '',
      };
    }) ?? []
  );
});

const handleSubmit = () => {
  let { email, password } = auth;
  if (hasPlatforms.value) {
    if (email === '' || password === '') {
      return;
    }
    login({ email, password })
      .then((response) => {
        emit('login');
        getUserPermissions(true).then((response) => {
          updateSelectedPlatform();
          router.push('/').catch((e) => console.log(e));
        });
      })
      .catch((error) => {
        errors.format(error.errors);
      });
  } else {
    if (email === '') {
      return;
    }
    checkEmail(email);
  }
};
const checkEmail = (email) => {
  return checkEmailPlatforms({ email })
    .then((response) => {
      return getUserPlatforms(response, routePlatformIDInfo.value);
    })
    .catch((error) => {
      errors.clear();
      if (error && error.length === 0) {
        errors.set('email', t('error.login.unauthorized'));
      }
    });
};
const switchPlatform = (platformId) => {
  setSelectedPlatformID(platformId);
};
const handleForgetPassword = () => {
  let { email } = auth;
  if (email === '') {
    errors.clear();
    errors.set('email', t('error.login.email.empty'));
  } else {
    sendResetLinkEmail({ email, source: 'bo' })
      .then((response) => {
        auth.hasForgottenPassword = true;
      })
      .catch((error) => {
        errors.format(error);
      });
  }
};
const resetInput = (field) => {
  auth[field] = '';
  errors.clear(field);
};

const setSelectedPlatformID = (platformId) =>
  store.commit('platforms/setSelectedPlatformID', platformId);
const getUserPlatforms = (platforms, id = null) =>
  store.dispatch('platforms/getUserPlatforms', { platforms, id });
const updateSelectedPlatform = () =>
  store.dispatch('platforms/updateSelectedPlatform');
const login = (user) => store.dispatch('auth/login', user);
const checkEmailPlatforms = (user) =>
  store.dispatch('auth/checkEmailPlatforms', user);
const getUserPermissions = () => store.dispatch('auth/getUserPermissions');
const sendResetLinkEmail = (user) =>
  store.dispatch('auth/sendResetLinkEmail', user);

const selectedPlatform = computed({
  get() {
    if (selectedPlatformID.value) {
      return userPlatforms.value.filter(
        (x) => x.id === selectedPlatformID.value
      );
    }
    return [{ id: '', title: '', name: '' }];
  },
  set(value) {
    switchPlatform(value.id);
  },
});

const routeEmailInfo = computed(() => {
  return route?.query?.userEmail ?? '';
});

const routePlatformIDInfo = computed(() => {
  return route?.query?.platformID ?? null;
});

if (routeEmailInfo.value && routePlatformIDInfo.value) {
  auth.email = routeEmailInfo.value;
  if (routePlatformIDInfo.value) {
    switchPlatform(routePlatformIDInfo.value);
  }
  checkEmail(routeEmailInfo.value);
}
</script>