export class Metadata {
  constructor({
    id = Math.random().toString(36).substr(2, 16),
    label = '',
    type = 'custom',
    title = '',
    activated = '',
    format = '',
    withPage = false,
    template = '',
    slug = '',
    settings = {
      display_bo: true,
    },
    options = [],
  } = {}) {
    this.id = id;
    this.label = label;
    this.type = type;
    this.title = title;
    this.activated = activated;
    this.format = format;
    this.withPage = withPage;
    this.template = template;
    this.slug = slug;
    this.options = options;
    this.settings = settings;
  }
}

export default Metadata;
