export class MetadataDetail {
  constructor({
    id = Math.random().toString(36).substr(2, 16),
    platform_id = null,
    metadata_id = null,
    program_type_id = 1,
    label = '',
    name = '',
    title = '',
    description = '',
    metadata_label = '',
    mandatory = false,
    visible_front = false,
    visible_back = true,
    filterable = false,
    clickable = true,
    rank = null,
    type = null,
    cardinality = null,
    endpoint = null,
    editable = true,
    settings = this.defaultSettings(),
    styles = this.defaultStyles(),
    rules = {},
    linkedContents = [],
    options = [],
    total = 0,
    partners = [],
    group_environments = [],
  } = {}) {
    this.id = id;
    this.platform_id = platform_id;
    this.metadata_id = metadata_id;
    this.program_type_id = program_type_id;
    this.label = label;
    this.name = name;
    this.description = description;
    this.metadata_label = metadata_label;
    this.mandatory = mandatory;
    this.title = name;
    this.frontTitle = title;
    this.visible_front = visible_front;
    this.visible_back = visible_back;
    this.activated = visible_back;
    this.disabled = !this.activated;
    this.filterable = filterable;
    this.clickable = clickable;
    this.rank = rank ?? null;
    this.metadataType = type;
    this.editable = editable;
    // Set cardinality, used to choose between single/multi select form
    this.cardinality = cardinality;
    this.endpoint = endpoint;
    this.hasListValues = false;
    this.settings = settings;
    this.styles = styles;
    this.options = options;
    this.linkedContents = linkedContents;
    this.display = rules.display_bo;
    this.total = total;
    this.typeDescription = '';
    this.setStyles(this.styles);
    this.rules = rules;
    this.dateFormat = null;
    this.partners = partners;
    this.group_environments = group_environments;

    switch (this.metadata_label) {
      case 'title':
      case 'synopsis':
      case 'review':
      case 'note':
        this.type = 'text';
        this.icon = 'metadata-text';
        this.typeDescription = 'metadatas.type.text';
        break;
      case 'duration':
        this.type = 'text';
        this.icon = 'metadata-duration';
        this.typeDescription = 'metadatas.type.duration';
        break;
      case 'date':
      case 'date_jj_mm_aaaa':
      case 'date_mm_aaaa':
      case 'date_aaaa':
      case 'datetime':
        this.type = 'date';
        this.icon = 'metadata-date';
        this.typeDescription = 'metadatas.type.date';
        break;
      case 'ratinglevel':
      case 'format':
      case 'version':
      case 'level':
      case 'single_custom':
        this.type = 'singleselect';
        this.cardinality = '1';
        this.icon = 'metadata-unique';
        this.typeDescription = 'metadatas.type.singleselect';
        this.hasListValues = true;
        break;
      case 'category':
      case 'country':
      case 'tag':
      case 'gear':
      case 'multiple_custom':
        this.type = 'multiselect';
        this.cardinality = 'N';
        this.icon = 'metadata-list';
        this.typeDescription = 'metadatas.type.multiselect';
        this.hasListValues = true;
        break;
      case 'person':
      case 'director':
      case 'cast':
        this.type = 'multiselect';
        this.cardinality = 'N';
        this.icon = 'metadata-person';
        this.typeDescription = 'metadatas.type.cast';
        this.hasListValues = true;
        break;
      case 'file':
        this.type = 'file';
        this.icon = 'metadata-file';
        this.typeDescription = 'metadatas.type.file';
        break;
      case 'video':
        this.type = 'video';
        this.icon = 'metadata-video';
        this.typeDescription = 'metadatas.type.video';
        break;
      default:
        this.type = 'text';
        this.typeDescription = 'metadatas.type.text';
        this.icon = 'metadata-text';
        break;
    }

    if (this.type === 'date') {
      switch (this.metadata_label) {
        case 'date_jj_mm_aaaa':
          this.dateFormat = 'DD-MM-YYYY';
          break;
        case 'date_mm_aaaa':
          this.dateFormat = 'MM-YYYY';
          break;
        case 'date_aaaa':
          this.dateFormat = 'YYYY';
          break;
        case 'datetime':
          this.dateFormat = 'DD-MM-YYYY HH:mm';
          break;
        default:
          this.dateFormat = 'DD-MM-YYYY';
          break;
      }
    }
  };

  addOptions(allOptions, fields) {
    const thisId = this.metadata_id;
    const optionsBrut = allOptions[thisId];
    const myFields = fields;
    if (typeof optionsBrut === 'object') {
      let list = [];
      optionsBrut.forEach(function (optionBrut) {
        let title = '';
        myFields.forEach(function (field) {
          title += optionBrut[field] + ' ';
        });
        title = title.substring(0, title.length - 1);
        list.push({ title: title });
      });
      this.options = list;
      this.total = this.options.length;
    }
  };

  setMetadataId(metadatas, type = null, subId = null) {
    const _type = type ?? this.type;
    switch (_type) {
      case 'text':
        this.metadata_id = metadatas.find(
          (m) => m.label === 'review'
        ).id;
        break;
      case 'date':
        this.metadata_id = metadatas.find(
          (m) => m.label === subId
        ).id;
        break;
      case 'date_jj_mm_aaaa':
        this.metadata_id = metadatas.find(
          (m) => m.label === 'date_jj_mm_aaaa'
        ).id;
        break;
      case 'date_mm_aaaa':
        this.metadata_id = metadatas.find(
          (m) => m.label === 'date_mm_aaaa'
        ).id;
        break;
      case 'date_aaaa':
        this.metadata_id = metadatas.find(
          (m) => m.label === 'date_aaaa'
        ).id;
        break;
      case 'datetime':
        this.metadata_id = metadatas.find(
          (m) => m.label === 'datetime'
        ).id;
        break;
      case 'multiselect':
        this.metadata_id = metadatas.find(
          (m) => m.label === 'multiple_custom'
        ).id;
        break;
      case 'singleselect':
        this.metadata_id = metadatas.find(
          (m) => m.label === 'single_custom'
        ).id;
        break;
      case 'participant':
        this.metadata_id = metadatas.find(
          (m) => m.label === 'director'
        ).id;
        break;
      case 'file':
        this.label = camelCase(brut.label);
        this.metadata_id = metadatas.find(
          (m) => m.label === 'file'
        ).id;
        break;
      default:
        this.metadata_id = metadatas.find(
          (m) => m.label === 'review'
        ).id;
        break;
    }
  };

  defaultStyles() {
    return {
      all_format: 'roundeds',
      all_display_count: false,
      all_display_title: false,
      one_format: 'roundeds',
      one_display_title: false,
      one_filters: []
    };
  };

  defaultSettings() {
    return {
      size_max: this.metadataType === 'text' ? 5000 : null,
      size_recommended: null,
      action_add: true,
      action_update: true,
      action_delete: true,
      editable_status: true,
      editable_delete: true,
      has_rich_text: true,
      editable_settings: true,
      fields: []
    };
  };

  setStyles() {
    if (this.styles) {
      this.styles.one_format =
        this.styles.one_format
          ? this.styles.one_format === 'backdrops'
            ? 'banners'
            : this.styles.one_format
          : 'roundeds';

      this.styles.one_filters = Array.isArray(this.styles?.one_filters)
        ? this.styles?.one_filters.map((filter) => filter.id)
        : [];
    } else {
      this.styles = this.defaultStyles();
    }
  };

}

export default MetadataDetail;
