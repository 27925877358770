import { isEmpty, isNil } from 'lodash';
import { subscriptionService } from '@/services';

/**
 * User subscription management
 * @author Jody JUSTINE
 */

const alertSaved = {
  id: 'saved',
  icon: 'check',
  type: 'valid',
  message: 'notifications.update.success',
};
const alertFailed = {
  id: 'failed',
  icon: 'close',
  type: 'error',
  message: 'notifications.update.error',
};

const state = {
  toBeCanceledList: [], // Array of subscriptions to be canceled
  loading: true,
};

const actions = {
  update({ commit }) {
    //
  },

  cancel({ commit, dispatch }) {
    console.log('subscription cancel');
    if (isNil(state.toBeCanceledList) || isEmpty(state.toBeCanceledList)) {
      return Promise.resolve();
    }

    commit('getRequest');
    let options = { at_period_end: true };
    return subscriptionService.destroy(state.toBeCanceledList[0], options).then(
      (response) => {
        dispatch('displayAlert', alertSaved, { root: true });
        console.log('subscriptionService.destroy done');
        commit('getSuccess', response);
        return Promise.resolve(response);
      },
      (error) => {
        dispatch('displayAlert', alertFailed, { root: true });
        commit('getFailure', error);
        return Promise.reject(error);
      }
    );
  },
  async create({ commit, dispatch }, coupon) {
    return subscriptionService.create(coupon).then(
      (response) => {
        dispatch('displayAlert', alertSaved, { root: true });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch('displayAlert', alertFailed, { root: true });
        return Promise.reject(error);
      });
  },
  async upsell({ commit, dispatch }, { id, data }) {
    return subscriptionService.upsell(id, data).then(
      (response) => {
        dispatch('displayAlert', alertSaved, { root: true });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch('displayAlert', alertFailed, { root: true });
        return Promise.reject(error);
      });
  }
};

const mutations = {
  reset(state) {
    console.log('Subscriptions Module reset');
    state.loading = false;
  },

  getRequest(state) {
    state.loading = true;
  },

  getSuccess(state, result) {
    state.loading = false;
  },

  getFailure(state, error) {
    state.loading = false;
  },

  resetToBeCanceledList(state) {
    state.toBeCanceledList = [];
  },

  addToBeCanceledList(state, id) {
    state.toBeCanceledList.push(id);
  },

  getToBeCanceledList(state) {
    return state.toBeCanceledList;
  },
};

export const subscriptions = {
  namespaced: true,
  state,
  actions,
  mutations,
};
