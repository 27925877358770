export class TVODPrice {
  id;
  name;

  constructor({
    id = undefined,
    external_id = undefined,
    currency_code = undefined,
    amount = undefined,
    display_price = undefined,
  }) {
    this.id = id;
    this.external_id = external_id;
    this.currency_code = currency_code;
    this.amount = amount;
    this.display_price = display_price;
    this.title = display_price;
  }
}
