import { cloneDeep, isNil } from 'lodash';

import DisplayConfig from '@/models/parameters/DisplayConfig';
import store from '@/store';

/**
 * Block Metadata for program/serie/live template
 *
 * @author Jody JUSTINE <jjustine@vodfactory.com>
 */
export class BlockMetadata {
  id;
  block_id;
  block_type;
  details;
  rank;
  title;
  title_size;
  title_weight;
  title_style;
  content_metadata;

  type;
  template_id;
  platform_id;
  block_type_id;

  description;
  picture;
  cta_label;
  cta_url;
  cta_target;
  background_color;
  picture_default;
  items;

  defaultColors;
  displayConfig;

  metadatable_id;
  metadatable_type;

  constructor(
    {
      block_id,
      type,
      template_id,
      platform_id,
      block_type,
      item_type,
      name,
      title,
      title_size,
      title_weight,
      title_style,
      description,
      picture,
      cta_label,
      cta_url,
      cta_target,
      background_color,
      picture_default,
      details,
      items,
      rank,
      content_metadata,
      metadatable_id,
      metadatable_type,
      active,
    },
    defaultColors
  ) {
    this.platform_id = platform_id;
    this.template_id = template_id;
    this.block_id = block_id;
    this.block_type = block_type;
    this.block_type_id = store.getters['references/block_type_id'](
      this.block_type
    );

    if (typeof type === 'object' && 'name' in type) {
      this.type = type.name;
    } else {
      this.type = type;
    }

    this.item_type = item_type;
    this.title = title;
    this.name = name;
    this.title_size = title_size;
    this.title_weight = title_weight;
    this.title_style = title_style;
    this.description = description;
    this.picture = picture;
    this.cta_label = cta_label;
    this.cta_url = cta_url;
    this.cta_target = cta_target;
    this.background_color = background_color;
    this.picture_default = picture_default;

    this.details = details;
    this.metadata = details;

    if (!isNil(this.details)) {
      this.details.title = this.details.name;
    }

    this.items = items;
    this.rank = rank ?? 999;
    this.active = active;

    this.content_metadata = content_metadata;

    this.defaultColors = defaultColors;

    this.setFormatAndStylable();
    this.initBlockId();
    this.initItemTypeId();
    this.setDefault();
    this.setDisplayConfig();
    this.initMetadataconfigurationFormat();

    if (metadatable_id != null && metadatable_type != null) {
      this.content_metadata.metadatable_id = metadatable_id;
      this.content_metadata.metadatable_type = 'person_type';
    }
  }

  setDefault() {
    this.content_metadata = isNil(this.content_metadata)
      ? {}
      : this.content_metadata;

    switch (this.item_type) {
      case 'multiselect':
        this.content_metadata.type = this.content_metadata?.type ?? 'badge';
        this.content_metadata.style = this.content_metadata?.style ?? 'filled';
        this.content_metadata.color = this.content_metadata?.color ?? 'primary';
        this.content_metadata.radius = this.content_metadata?.radius ?? 5;
        break;

      // case 'date':
      //   this.content_metadata.type  = 'date'
      //   this.content_metadata.style = null
      //   this.content_metadata.color = null
      //   this.content_metadata.radius = 0
      //   break;

      // case 'duration':
      //   this.content_metadata.type  = 'duration'
      //   this.content_metadata.style = null
      //   this.content_metadata.color = null
      //   this.content_metadata.radius = 0
      //   break;

      case 'ratinglevel':
      case 'rating_level':
        this.content_metadata.type = 'image';
        this.content_metadata.style = 'pictograms';
        this.content_metadata.color = null;
        this.content_metadata.radius = 0;
        break;

      case 'cast':
      case 'person':
        this.content_metadata.type = this.content_metadata?.type ?? 'image';
        this.content_metadata.style =
          this.content_metadata?.style ?? 'roundeds';
        this.content_metadata.color = this.content_metadata?.color ?? null;
        this.content_metadata.radius = this.content_metadata?.radius ?? 5;
        break;

      case 'video':
        this.content_metadata.type = 'image';
        this.content_metadata.style = null;
        this.content_metadata.color = null;
        this.content_metadata.radius = 0;
        break;

      case 'file':
      default:
        this.content_metadata.type = this.content_metadata?.type ?? 'text';
        this.content_metadata.style = this.content_metadata?.style ?? null;
        this.content_metadata.color = this.content_metadata?.color ?? null;
        this.content_metadata.radius = this.content_metadata?.radius ?? 0;
        break;
    }
    this.content_metadata.display_title =
      this.content_metadata?.display_title ?? true;
  }

  /**
   * Set config for metadata display
   */
  setDisplayConfig() {
    let retval = {};
    const data = this;
    const defaultColors = this.defaultColors;

    retval.title = data.title ?? null;
    retval.default_title = data.content_metadata?.default_title ?? null;
    // Set type : badge / image / text
    retval.style = data.content_metadata?.type ?? null;

    // Set Title style : bold / italic / font-size
    retval.titleStyles = {};
    retval.titleStyles.bold =
      (data?.title_weight ?? null) === 'bold';
    retval.titleStyles.fontSize =
      data?.title_size ?? null
        ? 'font-size-' + data.title_size
        : 'font-size-md';
    retval.titleStyles.italic =
      (data?.title_style ?? null) === 'italic';

    // Set badge color
    const badgeColorId = data.content_metadata?.color ?? 'primary';
    if (retval.style === 'badge') {
      let id = badgeColorId;
      let hex = defaultColors[id] ?? null;
      if (badgeColorId.startsWith('#')) {
        id = 'custom_hex';
        hex = badgeColorId;
      }
      retval.badgeColor = { id, hex };
    } else {
      const hex = defaultColors[badgeColorId] ?? null;
      retval.badgeColor = { id: badgeColorId, hex };
    }

    // Set background color
    if (
      ['primary', 'secondary', 'tertiary'].includes(
        data.background_color ?? null
      )
    ) {
      const id = data.background_color;
      const hex = defaultColors[data.background_color ?? null] ?? null;
      retval.backgroundColor = { id: id, hex: hex };
    } else {
      const id = 'custom_hex';
      const hex = data.background_color ?? null;
      retval.backgroundColor = { id: id, hex: hex };
    }

    // Set badge style : filled / bordered / ...
    retval.badgeStyle = data.content_metadata?.style ?? null;
    retval.badgeRadius = data.content_metadata?.radius ?? 0;
    retval.socialNetworks = data.content_metadata?.socialNetworks ?? null;
    // Set default title value
    retval.default_title = data.content_metadata?.default_title ?? '---xx---';
    retval.displayTitle = data.content_metadata?.display_title ?? true;

    this.displayConfig = new DisplayConfig(retval);
  }

  /**
   * Set format and stylable
   */
  setFormatAndStylable() {
    let format = null;
    switch (this.item_type) {
      case 'country':
      case 'category':
      case 'cast':
      case 'multiple_custom':
      case 'person':
      case 'tag':
      case 'video':
        format = 'multiselect';
        break;

      case 'format':
      case 'level':
      case 'single_custom':
      case 'version':
        format = 'singleselect';
        break;

      case 'date':
      case 'date_aaaa':
      case 'date_jj_mm_aaaa':
      case 'datetime':
      case 'date_mm_aaaa':
        format = 'date';
        break;

      case 'duration':
      case 'episodes':
      case 'social':
        format = this.item_type;
        break;

      case 'custom':
        format =
          this.details.cardinality === 'N' ? 'multiselect' : 'singleselect';
        break;

      case 'ratinglevel':
      case 'rating_level':
        format = 'ratinglevel';
        break;

      case 'file':
        format = 'file';
        break;

      default:
        format = 'default';
        break;
    }

    this.format = cloneDeep(format);

    this.stylable =
      ['singleselect', 'multiselect'].includes(format) &&
      !['video', 'social'].includes(this.item_type);
  }

  initBlockId() {
    this.block_id =
      this.block_id ??
      `metadata-${this.platform_id}-${this.template_id}-${this.metadata.id
      }-${Date.now()}`;
  }

  initMetadataconfigurationFormat() {
    // Set to have the good display for ParametersSiteContentPagesBlocksMetadataExample
    this.metadata = this.metadata ?? {};
    this.metadata.configuration = this.metadata.configuration ?? {};
    switch (this.item_type) {
      case 'person':
        this.metadata = this.metadata ?? {};
        this.metadata.configuration = this.metadata.configuration ?? {};
        this.metadata.configuration.format = 'roundeds';
        break;
      case 'rating_level':
      case 'ratinglevel':
        this.metadata = this.metadata ?? {};
        this.metadata.configuration = this.metadata.configuration ?? {};
        this.metadata.configuration.format = 'pictograms';
        break;
      case 'video':
        this.metadata.configuration.format = 'videos';
        break;
      default:
        //
        break;
    }
  }
  /**
   * Set item type id
   * @return void
   */
  initItemTypeId() {
    let itemTypeType = null;

    switch (this.metadata?.type ?? 'unknown') {
      case 'unknown':
        itemTypeType = 'text';
        break;

      case 'integer':
        itemTypeType = 'text';
        break;

      case 'cast':
      case 'person_type':
        itemTypeType = 'person';
        break;

      case 'date_jj_mm_aaaa':
      case 'date_mm_aaaa':
      case 'date_aaaa':
      case 'datetime':
        itemTypeType = 'date';
        break;

      case 'file':
      default:
        itemTypeType = this.metadata.type;
        break;
    }

    if (this.metadata?.label === 'duration') {
      itemTypeType = 'duration';
    }

    try {
      this.item_type_id =
        store.getters['references/item_type_id'](itemTypeType);
    } catch (error) {
      this.item_type_id = store.getters['references/item_type_id']('text');
    }
  }

  /**
   * Set default title
   *
   * @return object
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  setDefaultTitle(value) {
    this.displayConfig.default_title = value;
  }

  /**
   * Get clean data for api
   *
   * @return object
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  getCleanDataForApi() {
    let retval = cloneDeep(this);

    retval.title = this.displayConfig.getApiTitle();

    retval.title_size = this.displayConfig.getApiFontSize();
    retval.title_style = this.displayConfig.getApiFontStyle();
    retval.title_weight = this.displayConfig.getApiFontWeight();

    retval.background_color = this.displayConfig.getApiBackgroundColor();

    let content_metadata = {};
    content_metadata.type = this.displayConfig.getApiType();
    content_metadata.style = this.displayConfig.getApiStyle();
    content_metadata.color = this.displayConfig.getApiColor();
    content_metadata.radius = this.displayConfig.getApiRadius();
    content_metadata.display_title = this.displayConfig.getApiDisplayTitle();
    content_metadata.metadatable_id = retval.content_metadata.metadatable_id;
    content_metadata.metadatable_type =
      retval.content_metadata.metadatable_type;

    retval.content_metadata = content_metadata;
    retval.items = [
      {
        item_type_id: this.item_type_id,
        itemable_id: this.metadata.id,
        itemable_type: 'metadata_detail',
        rank: 0,
      },
    ];

    // Remove unnecessary data for api
    delete retval.format;
    delete retval.stylable;
    delete retval.temp;
    delete retval.displayConfig;
    delete retval.defaultColors;
    delete retval.details;

    return retval;
  }
}

export function createBlockMetadata(data) {
  return Object.freeze(new BlockMetadata(data));
}

export default BlockMetadata;
