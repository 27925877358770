import { GenericContentService } from '@/services';
const countryService = new GenericContentService('countries');

const state = {
  all: [],
  one: [],
};

const actions = {
  getAll({ commit, state }) {
    if (state.all.length === 0) {
      return countryService.getAll().then(
        (countries) => {
          commit('getAllSuccess', countries);
          return Promise.resolve(countries);
        },
        (error) => {
          commit('getAllFailure', error);
          return Promise.reject(error);
        }
      );
    }
  },

  get({ commit }) {
    commit('getRequest');
    return countryService.get().then(
      (countries) => {
        commit('getSuccess', countries);
        return Promise.resolve(countries);
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  getAllRequest(state) {
    if (state.all.length > 0) {
      state.all = [];
    }
  },
  getAllSuccess(state, countries) {
    state.all = countries;
  },
  getAllFailure(state) {
    state.all = [];
  },
  getRequest(state) {
    state.one = [];
  },
  getSuccess(state, country) {
    state.one = country;
  },
  getFailure(state) {
    state.one = [];
  },
};

export const countries = {
  namespaced: true,
  state,
  actions,
  mutations,
};
