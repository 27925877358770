import { invoiceService } from '@/services';
// import Movie from '@/models/Customer';
import utils from '@/utils/utils';

const state = {
  allInvoices: {},
  loading: true,
};

const actions = {
  getUserInvoices({ commit, dispatch }, customerId) {
    return invoiceService.getUserInvoices(customerId).then(
      (invoices) => {
        commit('getSuccessInvoices', invoices);
        return Promise.resolve(invoices);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  reset(state) {
    state.allInvoices = {};
    state.loading = false;
  },

  getAllRequest(state) {
    state.loading = true;
  },

  getSuccessInvoices(state, invoices) {
    state.allInvoices = invoices;
  },

  getFailure(state, error) {
    console.log('invoices - getFailure');
  },
};

export const invoices = {
  namespaced: true,
  state,
  actions,
  mutations,
};
