<template>
  <article class="flex flex-col pb-20 h-full" :class="pageClass">
    <PageHeader v-if="$slots['title']" :class="headerClass">
      <Breadcrumb v-bind="breadcrumb" />
      <fieldset :disabled="disabled || disableHeader" class="w-full">
        <PageHeaderRow :editableTitle="editableTitle" @edit:title="$emit('edit:title')">
          <template #title>
            <slot name="title" />
          </template>
          <template #title-metas>
            <slot name="title-metas" />
          </template>
          <template v-if="$slots['action-1']" #action-1>
            <slot name="action-1" />
          </template>
          <template v-if="$slots['action-2']" #action-2>
            <slot name="action-2" />
          </template>
          <template v-if="$slots['action-3']" #action-3>
            <slot name="action-3" />
          </template>
          <template v-if="$slots['info-1'] && !isScrolling" #info-1>
              <slot name="info-1" />
          </template>
          <template v-if="$slots['info-2']" #info-2>
            <slot name="info-2" />
          </template>
          <template v-if="$slots['info-3']" #info-3>
            <slot name="info-3" />
          </template>
          <template v-if="$slots['info-4']" #info-4>
            <slot name="info-4" />
          </template>
        </PageHeaderRow>
      </fieldset>
      <slot name="nav"/>
    </PageHeader>
    <fieldset :disabled="disabled || disableContent">
      <slot name="content-x" />
      <PageContent>
        <slot name="content" v-bind="{ disabled }" />
      </PageContent>
    </fieldset>
    <fieldset :disabled="disabled || disableFooter">
      <PageFooter class="mt-10">
        <slot name="footer" />
      </PageFooter>
    </fieldset>
  </article>
</template>

<script setup>
import { computed, provide, ref, onMounted, onUnmounted, onUpdated } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { themeClasses } from '@/utils/styles/styles.js';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import PageContent from '@/components/page/PageContent.vue';
import PageFooter from '@/components/page/PageFooter.vue';
import PageHeader from '@/components/page/PageHeader.vue';
import PageHeaderRow from '@/components/page/PageHeaderRow.vue';

const emits = defineEmits(['edit:title']);

const props = defineProps({
  breadcrumb: {
    type: [Boolean, Object],
    default: () => {
      return {};
    },
  },
  stickyHeader: {
    type: Boolean,
    default: false,
  },
  editableTitle: {
    type: Boolean,
    default: false,
  },
  themedTitle: {
    type: Boolean,
    default: true,
  },
  actionSlotsNb: {
    type: Number,
    default: 3,
  },
  infosSlotsNb: {
    type: Number,
    default: 3,
  },
  config: {
    type: Object,
    default: () => {
      return {};
    },
  },
  disabled: {
    type: Boolean,
    default: false
  },
  disableHeader: {
    type: Boolean,
    default: false
  },
  disableContent: {
    type: Boolean,
    default: false
  },
  disableFooter: {
    type: Boolean,
    default: false
  },
  smallHeader: {
    type: Boolean,
    default: false
  },
});

provide('actionSlotsNb', props.actionSlotsNb);
provide('infosSlotsNb', props.infosSlotsNb);
provide('editableTitle', props.editableTitle);
provide('themedTitle', props.themedTitle);

const route = useRoute();

const theme = computed(() => route.matched?.[0]?.meta?.theme ?? 'primary');

const pageClass = computed(() => {
  return [
    props.stickyHeader && 'relative',
    props.editableTitle && 'editable-title',
    theme.value && themeClasses[theme.value],
  ];
});

const headerClass = computed(() => {
  return [
    props.smallHeader ? 'top-[20px]' : 'top-[60px]',
    props.stickyHeader && 'md:sticky -mx-8 px-8 -left-8 -right-8'
  ];
});

onBeforeRouteLeave((to, from, next) => {
  if (props?.config?.shouldSave ?? false) {
    discard();
  } else {
    next();
  }
});

const isScrolling = ref(false)

const handleScroll = () => {
  if (props.smallHeader) {
    isScrolling.value = window.scrollY > 0
  }
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
});

onUpdated(() => {
  window.addEventListener('scroll', handleScroll)
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})


</script>
