const durationFrequencies = [
  {
    id: 'hour',
    title: 'frequency.hour.title',
    value: 'frequency.hour.value',
    by: 'frequency.hour.by',
    mode: 'full',
  },
  {
    id: 'day',
    title: 'frequency.day.title',
    value: 'frequency.day.value',
    by: 'frequency.day.by',
    mode: 'light',
  },
  {
    id: 'week',
    title: 'frequency.week.title',
    value: 'frequency.week.value',
    by: 'frequency.week.by',
    mode: 'light',
  },
  {
    id: 'month',
    title: 'frequency.month.title',
    value: 'frequency.month.value',
    by: 'frequency.month.by',
    mode: 'light',
  },
  {
    id: 'bimester',
    title: 'frequency.bimester.title',
    value: 'frequency.bimester.value',
    by: 'frequency.bimester.by',
    mode: 'full',
  },
  {
    id: 'trimester',
    title: 'frequency.trimester.title',
    value: 'frequency.trimester.value',
    by: 'frequency.trimester.by',
    mode: 'full',
  },
  {
    id: 'quadrimester',
    title: 'frequency.quadrimester.title',
    value: 'frequency.quadrimester.value',
    by: 'frequency.quadrimester.by',
    mode: 'full',
  },
  {
    id: 'pentamester',
    title: 'frequency.pentamester.title',
    value: 'frequency.pentamester.value',
    by: 'frequency.pentamester.by',
    mode: 'full',
  },
  {
    id: 'semester',
    title: 'frequency.semester.title',
    value: 'frequency.semester.value',
    by: 'frequency.semester.by',
    mode: 'full',
  },
  {
    id: 'year',
    title: 'frequency.year.title',
    value: 'frequency.year.value',
    by: 'frequency.year.by',
    mode: 'light',
  },
];

export default durationFrequencies;
