import { GenericContentService } from '@/services';
const ratingService = new GenericContentService('rating-levels');

const state = {
  all: [],
  one: [],
};

const actions = {
  getAll({ commit, state }, refresh) {
    if (state.all.length === 0 || refresh === true) {
      commit('getAllRequest');
      return ratingService.getAll().then(
        (ratings) => {
          commit('getAllSuccess', ratings);
          return Promise.resolve(ratings);
        },
        (error) => {
          commit('getAllFailure', error);
          return Promise.reject(error);
        }
      );
    }
  },

  get({ commit }) {
    commit('getRequest');
    return ratingService.get().then(
      (ratings) => {
        commit('getSuccess', ratings);
        return Promise.resolve(ratings);
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  getAllRequest(state) {
    if (state.all.length > 0) {
      state.all = [];
    }
  },
  getAllSuccess(state, ratings) {
    state.all = ratings;
  },
  getAllFailure(state) {
    state.all = [];
  },
  getRequest(state) {
    state.one = [];
  },
  getSuccess(state, rating) {
    state.one = rating;
  },
  getFailure(state) {
    state.one = [];
  },
};

export const ratings = {
  namespaced: true,
  state,
  actions,
  mutations,
};
