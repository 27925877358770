export default {
  getAllSuccess(state, all) {
    state.all = all;
  },

  reset(state) {
    state.loading = false;
  },

  resetSelectedDate(state) {
    state.selectedDate = null;
  },

  hardResetFilters(state) {
    state.selectedFilters = [];
    state.filters = state.filters.map((filter) => {
      const value = filter?.value.filter(val => val === 'all');
      return {
        ...filter,
        isError: false,
        keyword: '',
        items: [],
        value: value
      };
    });
  },

  addFilter(state, value) {
    state.loading = true;
    state.selectedFilters.push(value);
    state.loading = false;
  },

  updateOptions(state, [id, options]) {
    state.loading = true;
    const filterToUpdate = state.filters.find(filter => filter.id === id);
    filterToUpdate.options = options;
    state.loading = false;
  },

  updateFilterValue(state, [id, value, withAll]) {
    const filterToUpdate = state.filters.find(filter => filter.id === id);
    if (value.length >= filterToUpdate?.max) {
      filterToUpdate.disabledOptions =
        filterToUpdate.options.filter(option => !value.includes(option.id))
          .map(option => option.id);
    } else {
      filterToUpdate.disabledOptions = []
    }
    if (withAll) {
      if (value.length > 1) {
        value =
          value.includes('all') && !filterToUpdate.value.includes('all')
            ? ['all']
            : filterToUpdate.options.length - 1 ===
              value.filter((v) => v !== 'all').length
              ? ['all']
              : value.filter((v) => v !== 'all');
      } else if (value?.length === 0 && withAll) {
        value.push('all');
      }
    }
    filterToUpdate.value = value;
  },

  updateFilterOptions(state, [id, value, data]) {
    state.loading = true;
    const filterToUpdate =
      state.filters.find(filter => filter.id === id);
    if (filterToUpdate) {
      filterToUpdate.value = value.filter(val => val !== '')
      const options = filterToUpdate.value.map((id) => {
        let title = id;
        let option = null;
        if (data?.id.toString() === id) {
          title = data?.title && data.title !== ''
            ? `${data.title} (ID: ${id})`
            : id;
        } else {
          option = filterToUpdate.options.find((o) => o.id === id);
        }
        return {
          id: id,
          title: option?.title && option?.title !== ''
            ? option.title
            : title
        }
      });
      filterToUpdate.options = options;
    }
    state.loading = false;
  },

  updateFilterItems(state, [id, items]) {
    const filterToUpdate =
      state.filters.find(filter => filter.id === id);
    if (filterToUpdate) {
      filterToUpdate.items = items;
    }
  },

  updateFilterKeyword(state, [id, keyword]) {
    const filterToUpdate =
      state.filters.find(filter => filter.id === id);
    if (filterToUpdate) {
      filterToUpdate.keyword = keyword;
    }
  },

  updateFilterError(state, [id, bool]) {
    const filterToUpdate =
      state.filters.find(filter => filter.id === id);
    filterToUpdate.isError = bool;
  },



  removeFilter(state, id) {
    state.loading = true;
    state.selectedFilters = state.selectedFilters.filter(
      (filter) => filter.id !== id
    );
    if (state.selectedFilters.length === 0) {
      state.selectedDate = null;
    }
    state.loading = false;
  },

  selectDate(state, date) {
    state.selectedDate = date;
  },

  setLoading(state, value) {
    state.loading = value;
  },
  setError(state, value) {
    state.error = value;
  },

  /**
 * Adds the filters options to the state.queryString
 *
 * @param {string} queryString
 *
 * @returns {void}
 */
  addFiltersToQueryString(state, queryString) {
    state.queryString = queryString;
    if (!state.withSearch) {
      state.filters.forEach((filter) => {
        filter.options.forEach((option) => {
          state.queryString += option.id != 'all'
            ? `&${filter.id}[]=${option.id}`
            : '';
        })
      });
    }
  }
};
