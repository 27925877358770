const usersFacets = [
  // {
  //   id: 'period',
  //   title: 'Période',
  //   cols: 2,
  //   startDate: '',
  //   endDate: '',
  // },
  {
    id: 'customer_status',
    title: 'users.search.status',
    checked: false,
    availableForFreePlatform: false,
    cols: 3,
    filters: [
      {
        facet_id: 'customer_status',
        id: 'registered',
        title: 'users.pages.users.status.registered',
        checked: false,
        highlighted: false,
      },
      {
        facet_id: 'customer_status',
        id: 'subscribed',
        title: 'users.pages.users.status.subscribed',
        checked: false,
        highlighted: false,
      },
      {
        facet_id: 'customer_status',
        id: 'ontrial',
        title: 'users.pages.users.status.ontrial',
        checked: false,
        highlighted: false,
      },
      {
        facet_id: 'customer_status',
        id: 'ongrace',
        title: 'users.pages.users.status.ongrace',
        checked: false,
        highlighted: false,
      },
      {
        facet_id: 'customer_status',
        id: 'unsubscribed',
        title: 'users.pages.users.status.unsubscribed',
        checked: false,
        highlighted: false,
      },
    ],
  },
  {
    id: 'no-plans',
    title: 'users.pages.users.plans.title',
    checked: false,
    availableForFreePlatform: false,
    cols: 3,
    filters: [
      {
        facet_id: 'no-plans',
        checked: false,
        id: 1,
        title: 'no-plans',
        highlighted: false,
      },
    ],
  },
  {
    id: 'payments',
    title: 'users.search.payments',
    checked: false,
    availableForFreePlatform: false,
    cols: 3,
    filters: [
      /*{
      facet_id: 'payments',
      id: 'initiated',
      title: 'transactions.label.short.initiated',
      checked: false
    },*/ {
        facet_id: 'payments',
        id: 'succeeded',
        title: 'transactions.label.short.succeeded',
        checked: false,
        highlighted: false,
      },
      {
        facet_id: 'payments',
        id: 'failed',
        title: 'transactions.label.short.failed',
        checked: false,
        highlighted: false,
      },
      {
        facet_id: 'payments',
        id: 'refunded',
        title: 'transactions.label.short.refunded',
        checked: false,
        highlighted: false,
      },
      /*{
      facet_id: 'payments',
      id: 'cancelled',
      title: 'transactions.label.short.cancelled',
      checked: false
    },*/ {
        facet_id: 'payments',
        id: 'partially_refunded',
        title: 'transactions.label.short.partiallyRefunded',
        checked: false,
        highlighted: false,
      },
    ],
  },
];

export default usersFacets;
