import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/recommendations/';

export const recommendationService = {
  indexAll,
  updateCategoryWeights,
};

async function indexAll(type) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      `${
        import.meta.env.VITE_CONTENT_BASE_URL
      }${api_url_module}${type}/index-all`,
      null,
      requestOptions
    );
    return handleResponse(response, 204);
  } catch (err) {
    return handleResponse(err);
  }
}

async function updateCategoryWeights(type) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      `${
        import.meta.env.VITE_CONTENT_BASE_URL
      }${api_url_module}${type}/update-category-weights`,
      null,
      requestOptions
    );
    return handleResponse(response, 204);
  } catch (err) {
    return handleResponse(err);
  }
}
