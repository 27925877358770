export class Card {
  id;
  brand;
  country;
  exp_month;
  exp_year;
  fingerprint;
  last4;
  name;

  constructor({
    id = undefined,
    brand = null,
    country = null,
    exp_month = null,
    exp_year = null,
    fingerprint = null,
    last4 = null,
    name = null,
  }) {
    this.id = id;
    this.brand = brand;
    this.country = country;
    this.exp_month = exp_month;
    this.exp_year = exp_year;
    this.fingerprint = fingerprint;
    this.last4 = last4;
    this.name = name;
  }
}
