import { cloneDeep, isEmpty, isNil } from 'lodash';
import { difference } from '@/utils/diffTools';
import { personService } from '@/services';
import Participant from '@/models/Participant';
import utils from '@/utils/utils.js';

const alertSaved = {
  id: 'person-saved',
  icon: 'check',
  type: 'valid',
  message: 'notifications.update.success',
};

const alertFailed = {
  id: 'person-saved',
  icon: 'close',
  type: 'error',
  message: 'notifications.update.error',
};

const alertRemoved = {
  id: 'person-removed',
  icon: 'check',
  type: 'valid',
  message: 'notifications.delete.success',
};

const alertRemovedFailed = {
  id: 'program-removed',
  icon: 'close',
  type: 'error',
  message: 'notifications.delete.error',
};

const state = {
  all: [],
  currentNewPerson: {},
  savedPerson: {},
  dataToSend: {},
  loading: false,
};

const actions = {
  refresh({ state, dispatch }) {
    if (isNil(state.savedPerson.id)) {
      return Promise.resolve();
    }
    return dispatch('get', state.savedPerson.id).then(() => {
      return Promise.resolve();
    });
  },

  refreshModalAddParticipantData({ dispatch, rootState }) {
    const queryString = utils.makeQueryString(
      [],
      rootState.searchFilterAndSort.selectedSort?.id,
      1,
      12
    );
    return dispatch('search', queryString).then(() => {
      return Promise.resolve();
    });
  },

  getAll({ commit }, queryString) {
    return personService.getAll(queryString).then(
      (persons) => {
        commit('getAllSuccess', persons);
        return Promise.resolve();
      },
      (error) => {
        commit('getAllFailure', error);
        return Promise.reject();
      }
    );
  },

  get({ commit, dispatch }, id) {
    commit('getRequest');
    return personService.get(id).then(
      (response) => {
        commit('getSuccess', response);
        return Promise.resolve();
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject();
      }
    );
  },

  search({ commit, dispatch, rootState }, queryString) {
    commit('getAllRequest');
    const needle = rootState.searchFilterAndSort.inputKeyword;
    return personService.search(needle, queryString).then(
      (persons) => {
        commit('getAllSuccess', persons);
        return Promise.resolve(persons);
      },
      (error) => {
        commit('getAllFailure');
        const alert = {
          id: 'person-search-failure',
          icon: 'close',
          type: 'error',
          message: "Une erreur s'est produite lors de votre recherche" + error,
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  create({ commit, getters, dispatch }, person) {
    commit('setLoading', true);
    commit('getRequest');
    return personService.create(person).then(
      (persons) => {
        if (persons?.id ?? false) {
          dispatch('displayAlert', alertSaved, { root: true });
          commit('getCreateSuccess', persons);
        } else {
          dispatch('displayAlert', alertFailed, { root: true });
        }
        if (getters.modalAddParticipantNeedsRefresh) {
          return dispatch('refreshModalAddParticipantData');
        }
        commit('setLoading', false);
        return Promise.resolve(persons);
      },
      (error) => {
        const message = error?.errors?.error ?? 'notifications.update.error';
        const alert = {
          id: 'person-saved',
          icon: 'close',
          type: 'error',
          message,
        };
        dispatch('displayAlert', alert, { root: true });
        commit('getFailure');
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },

  update({ commit, dispatch, getters }) {
    if (!getters.shouldSaved) {
      return Promise.resolve();
    }
    commit('setLoading', true);
    commit('beforeUpdate');
    return personService
      .update(state.dataToSend, state.currentNewPerson.id)
      .then(
        (person) => {
          commit('getSuccess', person);
          dispatch('displayAlert', alertSaved, { root: true });
          commit('setLoading', false);
          return Promise.resolve(person);
        },
        (error) => {
          const message = error?.errors?.error ?? 'notifications.update.error';
          const alert = {
            id: 'person-saved',
            icon: 'close',
            type: 'error',
            message,
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },

  deleteOne({ commit, dispatch }, itemId) {
    commit('setLoading', true);
    return personService.deleteOne(itemId).then(
      () => {
        commit('setLoading', false);
        commit('getRequest');
        dispatch('displayAlert', alertRemoved, { root: true });
        return Promise.resolve();
      },
      (err) => {
        commit('setLoading', false);
        dispatch('displayAlert', alertRemovedFailed, { root: true });
        return Promise.reject(err);
      }
    );
  },

  reset({ commit }) {
    commit('reset');
  },

  resetOne({ commit }) {
    commit('resetOne');
  },

  cleanPersonStates({ commit }) {
    commit('cleanPersonStates');
  },
};

const mutations = {
  beforeUpdate(state) {
    state.dataToSend = cloneDeep(state.currentNewPerson);
  },

  reset(state) {
    state.all = [];
    state.currentNewPerson = {};
    state.savedPerson = {};
    state.dataToSend = {};
    state.loading = false;
  },
  setLoading(state, status) {
    state.loading = status === true;
  },

  getAllRequest(state) {
    state.loading = true;
  },

  getAllSuccess(state, persons) {
    state.all = persons;
    state.loading = false;
  },

  getAllFailure(state) {
    state.all = [];
    state.loading = false;
  },

  resetOne(state) {
    state.currentNewPerson = {};
    state.savedPerson = {};
    state.dataToSend = {};
    state.loading = false;
  },

  getRequest(state) {
    state.currentNewPerson = [];
    state.savedPerson = [];
  },

  getCreateSuccess(state, person) {
    state.currentNewPerson = new Participant(person);
  },

  getSuccess(state, person) {
    const newPerson = new Participant(person);
    state.currentNewPerson = newPerson;
    state.savedPerson = cloneDeep(newPerson);
    state.loading = false;
  },

  getFailure(state) {
    state.currentNewPerson = [];
    state.loading = false;
  },

  cleanPersonStates(state) {
    state.all = [];
    state.currentNewPerson = {};
    state.savedPerson = {};
    state.formatedCurrentNewMovie = {};
    state.loading = false;
  },
};

const getters = {
  modalAddParticipantNeedsRefresh: (state) => {
    return state.all?.meta?.pagination?.per_page === 12 ?? false;
  },
  shouldSaved: (state) => {
    const retDiff = difference(state.currentNewPerson, state.savedPerson);

    if (isNil(retDiff) || isEmpty(retDiff)) {
      return false;
    }

    return true;
  },
};

export const persons = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
