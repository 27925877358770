import facets from '@/data/facets.js';
import store from '@/store/index.js';

const state = {
  sorts: [],
  facets,
};

const actions = {
  initPlanFacets({ commit }) {
    commit('initPlanFacets');
  },
  initPlanSort({ commit }) {
    commit('initPlanSort');
  },
};

const mutations = {
  initPlanFacets(state) {
    const statusFacets = {
      title: 'search.status',
      id: 'active',
      checked: false,
      cols: '1',
      unique: true,
      filters: [
        {
          facet_id: 'active',
          title: 'status.online',
          id: '1',
          checked: false,
        },
        {
          facet_id: 'active',
          title: 'status.offline',
          id: '0',
          checked: false,
        },
      ],
    };
    state.facets = [statusFacets];
  },
  initPlanSort(state) {
    state.sorts = [
      {
        id: 'date:desc',
        title: 'search.added',
      },
      {
        id: 'title:asc',
        title: 'A - Z',
      },
      {
        id: 'title:desc',
        title: 'Z - A',
      },
    ];
  },
};
export const planFilter = {
  namespaced: true,
  state,
  actions,
  mutations,
};
