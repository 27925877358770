/*
 * @prettier
 */

// import {mapFields} from "vuex-map-fields";
import { mapGetters, mapMutations } from 'vuex';
import blockTypes from '@/data/animationBlockTypes';

export default {
  // Order by alphabetical order
  methods: {
    /**
     * Genric modal template to delete templates, empty templates
     * @param {string} action
     * @param {int} templateId
     * @returns void
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    deleteModalTemplate(action, templateId = null) {
      this.showModal({
        componentName: 'ModalTemplateDelete',
        componentParams: {
          action: action,
          templateId: templateId,
        },
        size: 'smd',
      });
    },
    /**
     * Genric modal template to edit parameters, copy templates, create templates
     * @param {string} action
     * @param {int} templateId
     * @returns void
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    editModalTemplate(action, templateId = null) {
      this.showModal({
        componentName: 'ModalTemplateParameters',
        componentParams: {
          action: action,
          templateId: templateId,
        },
        size: 'md',
      });
    },
    /**
     * Redirect to default template landinganimation
     * @returns void
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    goToAnimationHome() {
      this.$router.push({
        name: 'animationPage',
        params: { template_type_name: 'home', templateName: 'home' },
      });
    },
    /**
     * Redirect to default template home
     * @returns void
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    goToAnimationLanding() {
      this.$router.push({
        name: 'animationPage',
        params: { template_type_name: 'landing', templateName: 'landing' },
      });
    },
    /**
     * Redirect to templates list
     * @returns void
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    goToTemplateListPage() {
      this.$router.push({ name: 'animationPages' });
    },
    /**
     * Redirect to current template edition page
     *
     * @param {string} templateType
     * @param {string} templateName
     * @returns void
     *
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    async goToTemplatePage(templateType, templateName, templateId = 0) {
      await this.$router.push({
        name: 'animationPage',
        params: {
          templateType,
          templateName,
          templateId,
        },
      });
    },
    /**
     * Copy only pages/template with a template type of cardinality different from one (template type with multiple pages)
     *
     * @param {object} template
     * @returns bool
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    hasOptionCopy(template) {
      return (
        this.templateType &&
        !['program', 'serie', 'live', 'selection', 'collection'].includes(
          this.templateType.find(
            (element) => element.id == template.template_type_id
          )?.name
        )
      );
    },
    /**
     * Copy url
     *
     * @param {object} template
     * @returns bool
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     */
    hasOptionCopyUrl(template) {
      return !['program', 'serie', 'live', 'selection', 'collection'].includes(
        this.templateType.find(
          (element) => element.id == template.template_type_id
        )?.name
      );
    },
    /**
     * Delete only pages/template not default pages
     *
     * @param {template} template
     * @returns bool
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    hasOptionDelete(template) {
      return (
        this.templateType &&
        this.templateType.find(
          (element) =>
            element.id == template.template_type_id && !template.is_default
        )
      );
    },
    /**
     * Edit all pages except program/serie/lives templates
     *
     * @param {template} template
     * @returns bool
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    hasOptionEdit(template) {
      return (
        this.templateType &&
        !['program', 'serie', 'live', 'selection', 'collection'].includes(
          this.templateType.find(
            (element) => element.id == template.template_type_id
          )?.name
        )
      );
    },
    /**
     * Empty only pages/template not default pages
     *
     * @param {template} template
     * @returns bool
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    hasOptionEmpty(template) {
      return import.meta.VITE_ENV !== 'production';
    },
    /**
     * Has the paramters options for the current template
     *
     * @param {template} template
     * @returns bool
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    hasOptionParameters(template) {
      return (
        this.templateType &&
        this.templateType.find(
          (element) => element.id == template?.template_type_id
        )
      );
    },
    /**
     * can publish templates, all pages except program/serie/lives
     *
     * @param {template} template
     * @returns bool
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    hasOptionPublish(template) {
      return !(template?.online ?? true);
    },
    /**
     * Has abality to unpublish templates, all pages except program/serie/lives
     *
     * @param {template} template
     * @returns bool
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    hasOptionUnpublish(template) {
      return template && (template?.online ?? true) && !template.is_default;
    },
    /**
     * Show modal to copy template
     *
     * @param {int} templateId
     * @returns void
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    modalCopyTemplate(templateId) {
      this.editModalTemplate('copy', templateId);
    },
    /**
     * Edit templates parameters
     * @returns void
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    modalCreateTemplates() {
      this.editModalTemplate('create');
    },
    /**
     * Delete templates
     * @returns void
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    modalDeleteTemplates(templateId) {
      this.deleteModalTemplate('delete', templateId);
    },
    /**
     * Edit templates parameters
     * @param {int} templateId
     * @returns void
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    modalEditTemplateParameters(templateId) {
      this.editModalTemplate('edit', templateId);
    },
    /**
     * Empty templates
     * @returns void
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    modalEmptyTemplates(templateId) {
      this.deleteModalTemplate('empty', templateId);
    },
    /**
     * Show modal to set template online status
     *
     * @param {template} template
     * @returns void
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    togglePublication(template) {
      this.showModal({
        componentName: 'ModalAnimationPageTogglePublication',
        componentParams: {
          templateId: template.id,
          templateName: template.title,
          isOnline: template.online,
        },
        size: 'smd',
      });
    },
    /**
     * Is block type available for current template type
     *
     * @param {string} wantedBlockType
     * @returns bool
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    isBlockEnable(wantedBlockType) {
      const currentTemplate = this.currentTemplateTypeName;
      return (
        blockTypes &&
        blockTypes
          .find((blockType) => {
            return blockType.id === wantedBlockType;
          })
          ?.templateTypeNames.includes(currentTemplate)
      );
    },
    ...mapMutations(['showModal']),
  },
  computed: {
    /**
     * Is current template page is template list page
     *
     * @returns bool
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    isTemplateListPage() {
      return this.$route.name === 'animationPages';
    },
    /**
     * Is current template page is template list page
     *
     * @returns bool
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    isNotTemplateListPage() {
      return this.$route.name !== 'animationPages';
    },
    /**
     * Is current template page is default home page
     *
     * @returns bool
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    isHome() {
      return (
        this.$route.name === 'animationPage' &&
        this.$route.params.templateType === 'home'
      );
    },
    /**
     * Is current template page is default landing page
     *
     * @returns bool
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    isLanding() {
      return (
        this.$route.name === 'animationPage' &&
        this.$route.params.templateType === 'landing'
      );
    },
    /**
     * Is current template page is page type
     *
     * @returns bool
     * @author Jody JUSTINE <jjustine@vodfactory.com>
     **/
    isPage() {
      return (
        this.$route.name === 'animationPage' &&
        this.$route.params.templateType === 'page'
      );
    },
    ...mapGetters({
      templateType: 'references/template_type',
      currentTemplateTypeName: 'animation/getTemplateTypeName',
    }),
  },
};
