import { handleResponse } from '@/services/index.js';
import axios from 'axios';

export const authService = {
  login,
  register,
  checkEmailPlatforms,
  sendResetLinkEmail,
  resetPassword,
  getPermissions,
};

/**
 * Logs a user
 *
 * @param {object} user
 */
async function login(user) {
  const requestOptions = {
    method: 'POST',
    headers: {
      transform: 'bo',
    },
  };

  try {
    const response = await axios.post(
      import.meta.env.VITE_USER_BASE_URL + '/users/authenticate/',
      user,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

/**
 * Register a new user
 *
 * @param {object} user
 */
async function register(user) {
  const requestOptions = {
    method: 'POST',
  };

  try {
    const response = await axios.post(
      import.meta.env.VITE_USER_BASE_URL + '/users/',
      user,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

/**
 * Checks given email to retrieve associated platforms if any
 *
 * @param {object} user
 */
async function checkEmailPlatforms(user) {
  const params = {
    email: user.email,
  };

  try {
    const response = await axios.get(
      import.meta.env.VITE_USER_BASE_URL + '/check-platform',
      { params }
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

/**
 * Sends a reset password link email
 *
 * @param {object} user
 */
async function sendResetLinkEmail(user) {
  const requestOptions = {
    method: 'POST',
  };

  try {
    const response = await axios.post(
      import.meta.env.VITE_USER_BASE_URL + '/users/password/email',
      user,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

/**
 * Resets password
 *
 * @param {object} user
 */
async function resetPassword(user) {
  const requestOptions = {
    method: 'POST',
  };

  try {
    const response = await axios.post(
      import.meta.env.VITE_USER_BASE_URL + '/users/password/reset',
      user,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

/**
 * Get user permissions
 *
 * @param {object} user
 */
async function getPermissions(userId) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_USER_BASE_URL + '/users/' + userId + '/permissions'
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}
