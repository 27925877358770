// $unsubscribe = Billing::updateSubscription(['id' => $id, 'cancel_at_period_end' => true]);

import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/subscriptions/';
const currentPath = import.meta.env.VITE_BILLING_BASE_URL + api_url_module;

export const subscriptionService = {
  update,
  destroy,
  create,
  upsell
};
async function update(item, id) {
  const requestOptions = {
    method: 'PUT',
  };

  try {
    const response = await axios.put(currentPath + id, item, requestOptions);
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function destroy(id, options) {
  const requestConfig = {
    method: 'DELETE',
    data: options,
  };

  try {
    const response = await axios.delete(currentPath + id, requestConfig);
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function create(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      transform: 'bo'
    }
  };
  try {
    const response = await axios.post(
      currentPath,
      data,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return await Promise.reject(err);
  }
}

async function upsell(id, data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      transform: 'bo'
    }
  };
  try {
    const response = await axios.post(
      `${currentPath}${id}/upsell`,
      data,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return await Promise.reject(err);
  }
}
