import { GenericContentService, GenericMediaService } from '@/services';
import { isEmpty, isNil } from 'lodash';

const referenceService = new GenericContentService('references');
const mediaRefService = new GenericMediaService('references');

const getDefaultState = () => ({
  all: [],
  one: [],
  loading: true,
});

const state = getDefaultState();

const actions = {
  getAll({ commit }) {
    if (state.all.length === 0) {
      commit('getAllRequest');
      return referenceService.getAll().then(
        (references) => {
          commit('getAllSuccess', references);
          return Promise.resolve();
        },
        (_) => {
          commit('getAllFailure');
          return Promise.reject();
        }
      );
    }
  },
  getVideoReference({ commit }) {
    return mediaRefService.getAll().then(
      (refs) => {
        commit('getMediaRefSuccess', refs);
        return Promise.resolve(refs);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  get({ commit }) {
    commit('getRequest');
    return referenceService.get().then(
      (references) => {
        commit('getSuccess', references);
        return Promise.resolve(references);
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject(error);
      }
    );
  },
  resetAll({ commit }) {
    commit('resetAll');
  },
};

const mutations = {
  resetAll(state) {
    Object.assign(state, getDefaultState());
  },
  getAllRequest(state) {
    if (state.all.length > 0) {
      state.all = [];
    }
  },
  getAllSuccess(state, references) {
    if (references?.persons) {
      references.persons = references.persons.map((x) => {
        return {
          id: x.id,
          title: !isNil(x.name) && !isEmpty(x.name) ? x.name : x.label,
        };
      });
    }
    state.all = references;
    state.loading = false;
  },
  getMediaRefSuccess(state, reference) {
    reference = reference.map((x) => {
      return {
        id: x.id,
        title: x.name,
      };
    });
    state.all.videos = reference;
  },
  getAllFailure(state) {
    state.all = [];
    state.loading = false;
  },
  getRequest(state) {
    state.one = [];
  },
  getSuccess(state, reference) {
    state.one = reference;
  },
  getFailure(state) {
    state.one = [];
  },
};

const getters = {
  item_type: (state) => state.all.items,
  template_type: (state) => state.all.templates,
  badge_type_id: (state) => (type) =>
    state.all.badges.find((x) => x.type == type).id,
  block_type_id: (state) => (type) =>
    state.all.blocks.find((x) => x.name == type).id,
  item_type_id: (state) => (type) =>
    state.all.items.find((x) => x.name == type).id,
  template_type_id: (state) => (type) =>
    state.all.templates
      ? state.all.templates.find((x) => x.name == type).id
      : 0,
  loading: (state) => state.loading,
};

export const references = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
