import { cloneDeep } from 'lodash';
import { picturesModelList } from '@/data/pictures';
const default_custom_properties_list = {
  gradient: false,
  focusZone: 'xx',
  background_color: '',
  isPictureDark: false,
  keepOriginal: false,
  isCustom: false,
};

export class Picture {
  constructor({
    id = null,
    url = null,
    mimeType = null,
    collectionName = 'backdrops',
    itemType = null,
    custom_properties = {},
    key = null,
  } = {}) {
    this.collectionName = collectionName;
    this.picture = {};
    this.setPicture({
      id,
      url,
      mimeType,
      collectionName,
      itemType,
      custom_properties,
      key,
    });
  }

  setPicture(picture) {
    const {
      id,
      url,
      collectionName,
      mimeType,
      itemType,
      custom_properties,
      key,
    } = picture;
    const temp = {};
    temp.id = id;
    temp.url = url;
    temp.mimeType = mimeType;
    temp.collectionName = collectionName;
    temp.itemType = itemType;
    temp.custom_properties = {};
    temp.key = key;
    for (const [keyProp, value] of Object.entries(
      this.getDefaultCustomProperties()
    )) {
      if (keyProp in custom_properties) {
        temp.custom_properties[keyProp] = custom_properties[keyProp];
      } else {
        temp.custom_properties[keyProp] = value; //default value
      }
    }
    this.picture[collectionName] = [temp];
  }

  getPicture() {
    return cloneDeep(this.picture);
  }

  getPictureCurrent() {
    return cloneDeep(this.picture[this.collectionName][0]);
  }

  setGradient(gradient) {
    this.picture[this.collectionName][0].custom_properties.gradient = gradient;
  }

  getDefaultCustomProperties() {
    return cloneDeep(default_custom_properties_list);
  }

  static getDefaultPictureTypesList(modelName) {
    return picturesModelList[modelName];
  }

  static getDefaultPicture(modelName) {
    return picturesModelList[modelName].reduce((obj, pictureType) => {
      obj[pictureType] = [];
      return obj;
    }, {});
  }
}

export function createPicture(data) {
  return Object.freeze(new Picture(data));
}

export default Picture;
