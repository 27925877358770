<template>
  <div data-cy="form-hint"
     class="whitespace-pre-line tracking-normal" :class="formHintClass">
    <slot />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { fontSizeClasses, fontWeightClasses } from '@/utils/styles/styles.js';

const props = defineProps({
  align: String,
  italic: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: 'xs',
    validator: (value) => {
      return ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(value);
    },
  },
  weight: {
    type: String,
    default: 'light',
    validator: (value) => {
      return [
        'thin',
        'light',
        'normal',
        'medium',
        'semibold',
        'bold',
        'heavy',
        'black',
      ].includes(value);
    },
  },
});

const formHintClass = computed(() => {
  return [
    props.align === 'center' && 'text-center',
    props.italic && 'italic',
    fontWeightClasses[props.weight],
    fontSizeClasses[props.size],
  ];
});
</script>
