const state = {
  facets: [],
  sorts: [],
  selectedSort: {},
  selectedFacets: [],
  selectedPeriods: [],
  inputKeyword: '',
};

const getters = {
  isFilteredSearch(state) {
    return (
      state.selectedFacets.length > 0 ||
      state.selectedPeriods.length > 0 ||
      state.inputKeyword.length > 0
    );
  },
};

const actions = {
  setFilter({ commit }, inputFilter) {
    commit('setFilter', inputFilter);
    commit('updateFacets');
  },
  setFilterperiod({ commit }, currentDates) {
    commit('updateFacetPeriod', currentDates);
  },
  resetKeyword({ commit }) {
    commit('resetKeyword');
  },
  resetFilters({ commit }) {
    commit('resetFacets');
    commit('resetKeyword');
  },
  hardResetFilter({ commit }) {
    commit('resetFacets');
    commit('resetKeyword');
    commit('hardResetFilter');
  },
  initSorts({ state, commit, dispatch }, selectedSortsDefinition) {
    commit('initSorts', selectedSortsDefinition);
    if (state.sorts.length > 0) {
      dispatch('setSelectedSort', state.sorts[0]);
    }
  },
  initFacets({ commit }, currentFacets) {
    commit('initFacets', currentFacets);
  },
  setSelectedSort({ commit }, sortData) {
    commit('setSelectedSort', sortData);
  },
  setSelectedFilters({ commit }, filtersData) {
    commit('setSelectedFilters', filtersData);
  },
};

const mutations = {
  initFacets(state, currentFacets) {
    state.facets = currentFacets;
  },
  initSorts(state, selectedSortsDefinition) {
    state.sorts = selectedSortsDefinition;
  },
  setSelectedSort(state, sortData) {
    state.selectedSort = sortData;
  },
  setSelectedFacets(state, facetsData) {
    state.selectedFacets = facetsData;
  },
  setFilter(state, inputFilter) {
    if (undefined !== inputFilter.id) {
      state.facets.forEach((facet) => {
        if (facet.id === inputFilter.id) {
          facet.filters.forEach((x) => {
            x.checked = inputFilter.value.includes(x.id);
          });
        }
      });
    }
  },
  setKeyword(state, inputKeyword) {
    state.inputKeyword = inputKeyword;
  },
  updateFacets(state) {
    let filters = [];
    state.facets.forEach((facet) => {
      if (undefined !== facet.filters) {
        let checkedFilters = facet.filters.filter((x) => x.checked);
        checkedFilters.forEach((y) => {
          const { checked, facet_id, id, title } = y;
          const filter = { checked, facet_id, id, title };
          filters.push(filter);
        });
      }
    });
    state.selectedFacets = filters;
  },
  updateFacetPeriod(state, dates) {
    let facetPeriod = state.facets.find((x) => x.id === 'period');
    facetPeriod.start = dates.start;
    facetPeriod.end = dates.end;

    let periods = [];
    periods.push({ id: facetPeriod.id, start: dates.start, end: dates.end });
    state.selectedPeriods = periods;
  },

  resetFacets(state) {
    const facets = state.facets;
    facets.forEach((facet) => {
      if (undefined !== facet.filters) {
        let checkedFilters = facet.filters.filter((x) => x.checked);
        checkedFilters.forEach((y) => {
          y.checked = false;
        });
      } else if (facet.id === 'period') {
        facet.start = null;
        facet.end = null;
      }
    });
    state.selectedFacets = [];
    state.selectedPeriods = [];
  },
  resetKeyword(state) {
    state.inputKeyword = '';
  },
  hardResetFilter(state) {
    state.facets = [];
    state.sorts = [];
    state.selectedSort = {
      id: 'date:desc',
      label: 'search.added',
      title: 'search.added',
    };
  },
};
export const searchFilterAndSort = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
