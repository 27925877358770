<template>
  <Transition
    data-cy="loader"
    appear-active-class="transition-opacity duration-1000 ease-[cubic-bezier(1.0, 0.5, 0.8, 1.0)]"
    appear-from-class="opacity-0"
    appear-to-class="opacity-100"
    enter-active-class="transition-opacity duration-1000 ease-[cubic-bezier(1.0, 0.5, 0.8, 1.0)]"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition-opacity duration-1000 ease-[cubic-bezier(1.0, 0.5, 0.8, 1.0)]"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      id="loader"
      class="fixed top-0 left-0 bottom-0 right-0 z-50 h-full w-full bg-skin-background bg-opacity-80 will-change-auto bg-skin-background"
      :class="loaderClass"
    />
  </Transition>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { themeClasses } from '@/utils/styles/styles.js';

const route = useRoute();

const theme = computed (() => {
  return route?.meta?.theme ?? 'primary';
});
const loaderClass = computed(() => {
  return [theme.value && themeClasses[theme.value]];
});
</script>
