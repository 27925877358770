import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/invoices/';
const currentPath = import.meta.env.VITE_BILLING_BASE_URL + api_url_module;

export const invoiceService = {
  getUserInvoices,
};

async function getUserInvoices(customerId) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_BILLING_BASE_URL +
        api_url_module +
        '?customer=' +
        customerId
    );
    return handleResponse(response.data);
  } catch (err) {
    console.log(err);
    return handleResponse(err);
  }
}
