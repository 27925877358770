import { AdvertisingBlocksService } from '@/services/content/advertising-blocks.service.js';
import AdvertisingBlocks from '@/models/block/AdvertisingBlocks';
const advertisingBlocksService = new AdvertisingBlocksService();
import { cloneDeep } from 'lodash';
import { hasDifference } from '@/utils/diffTools';
import adsConfigs from '@/data/adsConfigs';

const state = {
  currentAdvertisingBlocks: {},
  savedAdvertisingBlocks: {},
  formattedAdvertisingBlocks: {},
  availablePlatformTypes: {},
  loading: true,
};

const getters = {
  shouldSaved: (state) =>
    hasDifference(state.currentAdvertisingBlocks, state.savedAdvertisingBlocks),
  loading: (state) => state.loading ?? true,
};

const actions = {
  getAdvertisingBlockConfigs({ commit, rootState, state }) {
    const availablePlatformTypes = Object.keys(
      rootState.platforms.currentChildrenPlatforms
    );
    commit('getAdvertisingBlockConfigsRequest', availablePlatformTypes);

    var promiseList = [];
    var platforms = [];

    state.availablePlatformTypes.forEach((platformType) => {
      promiseList.push(
        advertisingBlocksService.get(
          rootState.platforms.currentChildrenPlatforms[platformType].id
        )
      );
    });

    return Promise.all(promiseList).then(
      (response) => {
        commit('getAdvertisingBlockConfigsSuccess', response);
        return Promise.resolve();
      },
      (error) => {
        commit('getAdvertisingBlockConfigsRequest', error);
        return Promise.reject(error);
      }
    );
  },
  setAdvertisingBlockConfigs({ commit, rootState, state }) {
    const availablePlatformTypes = Object.keys(
      rootState.platforms.currentChildrenPlatforms
    );
    commit('updateAdvertisingBlockConfigsRequest', availablePlatformTypes);
    let promiseList = [];
    state.availablePlatformTypes.forEach((platformType) => {
      if (state.formattedAdvertisingBlocks?.[platformType]?.actions?.length > 0) {
        promiseList.push(
          advertisingBlocksService.post({
            data: state.formattedAdvertisingBlocks[platformType],
            platformId:
              rootState.platforms.currentChildrenPlatforms[platformType].id,
          }).then((response) => {
            commit('updateAdvertisingBlockConfigsSuccess', { platformType, response });
          })
        );
      }
    });

    return Promise.all(promiseList).then(
      () => {
        commit('setLoading', false);
        return Promise.resolve();
      },
      (error) => {
        commit('setLoading', false);
        return Promise.reject();
      }
    );
  },
  reset: ({ commit }) => {
    commit('reset');
    return Promise.resolve();
  },
};

const mutations = {
  getAdvertisingBlockConfigsRequest(state, availablePlatformTypes) {
    state.availablePlatformTypes = availablePlatformTypes;
    state.currentAdvertisingBlocks = {};
    state.savedAdvertisingBlocks = {};
    state.loading = true;
  },
  getAdvertisingBlockConfigsSuccess(state, response) {
    let size = state.availablePlatformTypes.length;
    let config = {};
    for (let i = 0; i < size; i++) {
      config[state.availablePlatformTypes[i]] = new AdvertisingBlocks(
        response[i]
      );
    }
    state.currentAdvertisingBlocks = cloneDeep(config);
    state.savedAdvertisingBlocks = cloneDeep(config);
    state.loading = false;
  },
  getAdvertisingBlockConfigsFail(state) {
    state.loading = false;
  },

  updateAdvertisingBlockConfigsRequest(state, availablePlatformTypes) {
    state.availablePlatformTypes = availablePlatformTypes;
    state.loading = true;
    state.formattedAdvertisingBlocks = {};

    state.availablePlatformTypes.forEach((platformType) => {
      state.formattedAdvertisingBlocks[platformType] = { actions: [] };
      adsConfigs.pages.default.forEach((defaultPage) => {
        ['interstitial', 'megaban_footer', 'megaban_header'].forEach(
          (blockType) => {
            if (
              state.currentAdvertisingBlocks[platformType].default[
                defaultPage.id
              ].blocks[blockType] !==
              state.savedAdvertisingBlocks[platformType].default[defaultPage.id]
                .blocks[blockType]
            ) {
              state.formattedAdvertisingBlocks[platformType].actions.push({
                template_type_name: defaultPage.id,
                block_type_name: blockType,
                action: state.currentAdvertisingBlocks[platformType].default[
                  defaultPage.id
                ].blocks[blockType]
                  ? 'enable'
                  : 'disable',
              });
            }
          }
        );
      });
      let size = state.currentAdvertisingBlocks[platformType].page.length;
      for (let index = 0; index < size; index++) {
        ['interstitial', 'megaban_footer', 'megaban_header'].forEach(
          (blockType) => {
            if (
              state.currentAdvertisingBlocks[platformType].page[index].blocks[blockType]
              !==
              state.savedAdvertisingBlocks[platformType].page[index].blocks[blockType]
            ) {
              state.formattedAdvertisingBlocks[platformType].actions.push({
                template_id:
                  state.currentAdvertisingBlocks[platformType].page[index].id,
                block_type_name: blockType,
                action: state.currentAdvertisingBlocks[platformType].page[index]
                  .blocks[blockType]
                  ? 'enable'
                  : 'disable',
              });
            }
          }
        );
      }
    });
  },
  updateAdvertisingBlockConfigsSuccess(state, { platformType, response }) {
    const newConfig = new AdvertisingBlocks(response);
    state.currentAdvertisingBlocks[platformType] = newConfig;
    state.savedAdvertisingBlocks[platformType] = cloneDeep(newConfig);
  },

  setLoading(state, value) {
    state.loading = value;
  },

  updateDefaultPageBlockStatus(state, { mode, page, block, status }) {
    state.currentAdvertisingBlocks[mode].default[page.id].blocks[block] =
      status;
  },
  updateCustomPageBlockStatus(state, { mode, index, block, status }) {
    state.currentAdvertisingBlocks[mode].page[index].blocks[block] = status;
  },
  updateAllPagesBlockStatus(state, { mode, block, status }) {
    state.currentAdvertisingBlocks[mode].page.forEach((page) => {
      page.blocks[block] = status;
    });
    Object.keys(state.currentAdvertisingBlocks[mode].default).forEach(
      (pageType) => {
        state.currentAdvertisingBlocks[mode].default[pageType].blocks[block] =
          status;
      }
    );
  },
  reset: (state) => {
    state.currentAdvertisingBlocks = cloneDeep(state.savedAdvertisingBlocks);
  },
};

export const advertisingBlocks = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
