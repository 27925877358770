export default {
  'poulpie-pirate': {
    width: 320,
    height: 320,
    path: 'error-204.svg',
    text: "Vous n'avez pas encore de sélection",
  },
  'error-animation': {
    width: 280,
    height: 175,
    path: 'error-animation.svg',
    text: "Vous n'avez pas encore de sélection",
  },
  'group-plan-lock': {
    width: 181,
    height: 168,
    path: 'group-plan-lock.svg',
  },
  publish: {
    width: 180,
    height: 150,
    path: 'publish.svg',
  },
  unpublish: {
    width: 180,
    height: 163,
    path: 'unpublish.svg',
  },
  revenue: {
    width: 127,
    height: 82,
    path: 'revenue.svg',
  },
};
