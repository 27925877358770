<template>
  <ul class="chart-legend w-52">
    <li v-if="legendTitle && legend?.length > 0" class="chart-legend__item">
      {{ legendTitle }}
    </li>
    <div :class="legendClass">
      <li v-for="(item, index) in legend" :key="index" class="chart-legend__item">
        <div v-if="item?.title && item.title != ''">
          <span class="item__title">
            <em class="item__dot" :style="`background-color:${item.color}`"></em>
            {{ $t(item.title) }}
          </span>
        </div>
      </li>
    </div>
  </ul>
</template>

<script>
  export default {
    name: 'ChartLegend',
    props: {
      data: {
        type: [Object, Array],
        default: null
      }
    },
    computed: {
      legendTitle() {
        return this.data?.title ? this.$t(this.data.title) : ''
      },
      legend() {
        if (this.data?.options?.length > 0) {
          if (
              (this.data?.type === 'metadata' && 
              this.data?.value.includes('all')
              ) || 
              this.data?.value?.length == 0 
            )
          {
            return [];
          } else if (this.data?.value.includes('all')) {
            return this.data.options.filter((option) => option.id !== 'all');
          } else {
            const options = [] 
            this.data?.value.forEach((item, i) => {
              const option = this.data?.options.find((option) => option.id === item);
              if (option) {
                options.push(
                  {...option,
                    color: option?.color ??  this.data.colors?.[i],
                  }
                );
              }
            })
            return options;
          }
        } else {
          return this.data?.items?.map(item => {
            return {
              title: item.key,
              color: item.color
            }
          })
        }
      },
      legendClass() {
        return [
          this.legend?.length > 4 &&  'overflow-y-scroll h-[200px]'
        ]
      }
    }
  }
</script>
<style lang="scss">
  @import 'chart_legend.scss';
</style>
