import { isEmpty } from 'lodash';
import { programMetadataService } from '@/services';

const alertItemRemoved = {
  id: 'program-removed',
  icon: 'check',
  type: 'valid',
  message: 'notifications.delete.success',
};

const alertItemRemovedFailed = {
  id: 'program-removed',
  icon: 'close',
  type: 'error',
  message: 'notifications.delete.error',
};

const state = {
  loading: true,
  addList: [],
  deleteList: [],
};

const actions = {
  addToNewList({ commit }, programMetadata) {
    commit('addToNewList', programMetadata);
  },

  addToDeleteList({ commit, state, dispatch }, programMetadata) {
    commit('addToDeleteList', programMetadata);
  },

  createOne({ commit, dispatch }, programMetadata) {
    return programMetadataService.createProgramMetadata(programMetadata).then(
      (response) => {
        // commit('getRequest')
        // dispatch('displayAlert', alertItemRemoved, {root: true})
        // dispatch('getAll')
        return Promise.resolve(response);
      },
      (err) => {
        // dispatch('displayAlert', alertItemRemovedFailed, {root: true})
        return Promise.reject(err);
      }
    );
  },

  removeOne({ commit, dispatch }, programMetadataId) {
    return programMetadataService.deleteProgramMetadata(programMetadataId).then(
      (response) => {
        // commit('getRequest')
        // dispatch('displayAlert', alertItemRemoved, {root: true})
        // dispatch('getAll')
        return Promise.resolve();
      },
      (err) => {
        // dispatch('displayAlert', alertItemRemovedFailed, {root: true})
        return Promise.reject(err);
      }
    );
  },

  resetList({ commit }) {
    commit('resetList');
  },

  addFromList({ commit, dispatch }) {
    var promiseList = [];
    for (let j = 0; j < state.addList.length; j++) {
      promiseList.push(dispatch('createOne', state.addList[j]));
    }

    return Promise.all(promiseList).then((values) => {
      return Promise.resolve(values);
    });
  },

  deleteFromList({ commit, dispatch }) {
    var promiseList = [];
    for (let j = 0; j < state.deleteList.length; j++) {
      promiseList.push(dispatch('removeOne', state.deleteList[j]));
    }

    return Promise.all(promiseList);
  },
};

const mutations = {
  //--- Reset LISTs ----
  resetList(state) {
    state.addList = [];
    state.deleteList = [];
  },
  resetAddList(state) {
    state.addList = [];
  },
  resetDeleteList(state) {
    state.deleteList = [];
  },
  //--- New LIST ----
  addToNewList(state, programMetadata) {
    state.addList.push(programMetadata);
  },
  //--- Delete LIST ----
  addToDeleteList(state, programMetadata) {
    state.deleteList.push(programMetadata);
  },
};

const getters = {
  shouldSaved: (state) => {
    if (isEmpty(state.addList) && isEmpty(state.deleteList)) {
      return false;
    }
    return true;
  },
};

export const programMetadatas = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
