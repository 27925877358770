import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const PATH = '/seasons';

export const seasonsService = {
  all,
  get,
  create,
  update,
  deleteSeason,
};

async function all(queryString = '') {
  if (queryString !== '') {
    queryString = '?' + queryString;
  }
  try {
    const response = await axios.get(
      import.meta.env.VITE_CONTENT_BASE_URL + PATH + queryString
    );
    return handleResponse(response.data);
  } catch (response_1) {
    return [];
  }
}

async function get(id) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_CONTENT_BASE_URL + PATH + '/' + id
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function update(season, id) {
  const requestOptions = {
    method: 'PUT',
  };
  try {
    const response = await axios.put(
      import.meta.env.VITE_CONTENT_BASE_URL + PATH + '/' + id,
      season,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function create(season) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL + PATH,
      season,
      requestOptions
    );
    return response.data.results;
  } catch (response_1) {
    return await Promise.reject();
  }
}

async function deleteSeason(id) {
  const requestOptions = {
    method: 'DELETE',
  };

  try {
    const response = await axios.delete(
      import.meta.env.VITE_CONTENT_BASE_URL + PATH + '/' + id,
      requestOptions
    );
    return handleResponse(response);
  } catch (err) {
    return await Promise.reject(err.response);
  }
}
