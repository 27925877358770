import { utils } from '@/utils/utils';

export class Timecode {
  constructor({
    id = null,
    start = 0,
    end = 0,
    type = '',
    serie_id = null,
    video_id = null,
    all_episodes = false,
  } = {}) {
    this.id = id;
    this.start = start;
    this.end = end;
    this.type = type;
    this.serie_id = serie_id;
    this.video_id = video_id;
    this.all_episodes = all_episodes;
    this.startTimecode = utils.toTimecode(start);
    this.endTimecode = utils.toTimecode(end);
  }
}

export default Timecode;
