
const paginationConfigs = {
  "home": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "landing": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.tag": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "account.list": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.date": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.gear": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.meta": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "account.index": {
    "enabled": false,
    "per_page": 1,
    "selected": false
  },
  "content.level": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.serie": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.custom": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.format": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.season": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "landing_forced": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "account.history": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "account.rentals": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.country": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.program": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.version": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.category": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.selection": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "template.page.url": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.collection": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.metas.list": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.ratinglevel": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.selection.index": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.person.metadatadetail": {
    "enabled": false,
    "per_page": null,
    "selected": false
  },
  "content.person.index": {
    "enabled": false,
    "per_page": null,
    "selected": false
  }
};

export default paginationConfigs;
