import { GenericContentService } from '@/services';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { difference, hasDifference } from '@/utils/diffTools';
import Badge from '@/models/Badge';
const badgeService = new GenericContentService('badges', true);

const state = {
  savedOne: null,
  currentOne: null,
  loading: false,
};

const getters = {
  shouldSaved: (state) => hasDifference(state.currentOne, state.savedOne),
  loading: (state) => state.loading,
};

const actions = {
  get({ commit, state }, payload) {
    let { type, refresh = false } = payload;

    if (!refresh && hasDifference(state.savedOne, state.currentOne)) {
      return Promise.resolve(state.currentOne)
    }

    if (refresh && state.currentOne?.type) {
      type = state.currentOne.type;
    }
    commit('getRequest');
    return badgeService.get('', 'type=' + type).then(
      (badge) => {
        badge.badge_type = badge?.type ?? type;
        commit('getSuccess', badge);
        return Promise.resolve(badge);
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject(error);
      }
    );
  },

  replace({ dispatch }) {
    if (state.currentOne?.id) {
      // update
      return dispatch('update', state.currentOne);
    } else {
      // add
      return dispatch('add');
    }
  },

  add({ dispatch }) {
    if (isNil(state.currentOne) || isEmpty(state.currentOne)) {
      console.error('badge not set -- cannot add badge');
      return false;
    }

    if (isNil(state.savedOne) && isNil(state.currentOne)) {
      console.log('No need to update blocks for template ' + name);
      return false;
    }

    if (getters.shouldSaved === false) {
      console.debug('No need to update badges : No difference');
      return false;
    }

    return badgeService.create(state.currentOne).then(
      () => {
        return Promise.resolve();
      },
      () => {
        const alert = {
          id: 'badge-add-failed',
          icon: 'close',
          type: 'error',
          message: 'notifications.create.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject();
      }
    );
  },

  update({ state, commit, dispatch, getters }) {
    if (isNil(state.currentOne) || isEmpty(state.currentOne)) {
      console.error('badge not set -- cannot update badge');
      return false;
    }

    if (isNil(state.currentOne?.id)) {
      console.error('badge id not set -- cannot update badge');
      return false;
    }

    if (isNil(state.savedOne) && isNil(state.currentOne)) {
      console.debug('No need to update badges: No data');
      return false;
    }

    if (getters.shouldSaved === false) {
      console.debug('No need to update badges : No difference');
      return false;
    }

    commit('setLoading');
    return badgeService.update(state.currentOne, state.currentOne?.id).then(
      (response) => {
        commit('getUpdateSuccess', response);
        return Promise.resolve(response);
      },
      (error) => {
        const alert = {
          id: 'badge-update-failed',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  setLoading(state) {
    state.loading = true;
  },
  getRequest(state) {
    state.savedOne = null;
    state.currentOne = null;
    state.loading = true;
  },
  getSuccess(state, item) {
    const badge = new Badge(item);
    state.savedOne = cloneDeep(badge);
    state.currentOne = cloneDeep(badge);
    state.loading = false;
  },
  getFailure(state) {
    state.savedOne = null;
    state.currentOne = null;
    state.loading = false;
  },
  getUpdateSuccess(state, item) {
    state.savedOne = cloneDeep(item);
    state.currentOne = cloneDeep(item);
    state.loading = false;
  },
};

export const badges = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
