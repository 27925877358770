<template>
  <div class="flex flex-col gap-6 theme-purple">
    <PageHeaderRow>
      <template #title>
        <h2 class="page-title text-skin-accent">
          {{ $t('kpis.home.title', { date: displayDate }) }}
          <small class="text-skin-base text-sm font-light"
            >({{ $t('kpis.home.description') }})</small
          >
        </h2>
      </template>
      <template #action-1>
        <router-link to="/statistiques" class="flex">
          <BaseButton
            nude
            font-weight="medium"
            theme="purple"
            :label="$t('kpis.home.see-details')"
            class="dashboard-header-link !px-0 underline"
          />
        </router-link>
      </template>
    </PageHeaderRow>
    <div class="flex flex-wrap gap-6">
      <router-link to="/statistiques/utilisateurs#registered" class="flex flex-1">
        <KpiCard :item="registered" :header="false" kpi-type="registered" />
      </router-link>
      <router-link to="/statistiques/utilisateurs#registrations" class="flex flex-1">
        <KpiCard :item="registrations" :header="false" kpi-type="registrations" />
      </router-link>
      <router-link to="/statistiques/utilisateurs#subscribed" class="flex flex-1">
        <KpiCard :item="subscribed" :header="false" kpi-type="subscribed" />
      </router-link>
      <router-link to="/statistiques/utilisateurs#subscriptions" class="flex flex-1">
        <KpiCard :item="subscriptions" :header="false" kpi-type="subscriptions" />
      </router-link>
      <router-link to="/statistiques/utilisateurs#unsubscribed" class="flex flex-1">
        <KpiCard :item="unsubscribed" :header="false" kpi-type="unsubscribed" />
      </router-link>
    </div>
    <div v-if="false" class="flex flex-wrap">
      <Card class="flex-1">
        <router-link
          class="w-full flex flex-wrap"
          to="/statistiques#transactions"
        >
          <KpiCard class="flex flex-1" wrapper :item="transactions" kpi-type="transactions" />
          <KpiCard
            class="flex flex-2"
            wrapper
            :item="transactionsRepartition"
            kpi-type="transactions"
          />
        </router-link>
      </Card>
    </div>
    <div class="flex flex-wrap">
      <span class="kpi-summary--hint font-size-sm font-style-italic">* {{ $t('kpis.home.asterisk') }}</span>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, inject } from 'vue';
import { useRouter } from 'vue-router';
import BaseButton from '@/components/button/BaseButton.vue';
import Card from '@/components/card/Card.vue';
import KpiCard from '@/components/statistics/KpiCard.vue';
import PageHeaderRow from '@/components/page/PageHeaderRow.vue';
import moment from 'moment';

const store = useStore();
const router = useRouter();

const date = computed(() => {
  return store.state.kpis.summaryDate;
});

const loading = computed(() => {
  return store.state.kpis.loading;
});

const displayDate = computed(() => {
  return moment(date.value).subtract(1, 'days').format('DD/MM/YYYY')
});

const summary = computed(() => {
  return store.getters['kpis/summary'];
});

const subscribed = computed(() => {
  return summary.value?.find((item) => item.id === 'subscribed');
});

const subscriptions = computed(() => {
  return summary.value?.find((item) => item.id === 'subscriptions');
});

const registered = computed(() => {
  return summary.value?.find((item) => item.id === 'registered');
});

const registrations = computed(() => {
  return summary.value?.find((item) => item.id === 'registrations');
});

const unsubscribed = computed(() => {
  return summary.value?.find((item) => item.id === 'unsubscribed');
});

const transactions = computed(() => {
  return summary.value?.find((item) => item.id === 'transactions');
});

const transactionsRepartition = computed(() => {
  return summary.value?.find((item) => item.id === 'transactionsRepartition');
});

function initDashboardSummary() {
  store.dispatch('kpis/initDashboardSummary');
}

function refreshDashboardSummary() {
  store.dispatch('kpis/refreshDashboardSummary');
}

function resetDashboard() {
  store.commit('kpis/resetDashboard');
}

async function handleVisibilityChange() {
  const now = moment().subtract(1, 'days').format('DD/MM/YYYY')
  const summaryDateIsInThePast = displayDate.value !== now;
  if (!document.hidden && summaryDateIsInThePast) {
    await refreshDashboardSummary();
  }
}

const appEnv = inject('$appEnv');

async function created() {
  if (!['local'].includes(appEnv)) {
    await initDashboardSummary();
  }
  document.addEventListener(
      'visibilitychange',
      async () => await handleVisibilityChange(),
      false
  );
}
created();

router.beforeEach((to, from, next) => {
  resetDashboard();
  next();
});

</script>

<style lang="scss">
@import '@/components/statistics/statistics.scss';
</style>
