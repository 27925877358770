const models = [
  {
    id: 'default',
    title: 'Défaut',
    hint: 'Configuration par défaut',
    can_inherit: false,
  },
  {
    id: 'block-fullscreen',
    title: 'Block Fullscreen',
    hint: 'Configuration des images liées à des blocks',
    can_inherit: true,
  },
  {
    id: 'block-multiscreen',
    title: 'Block Carousel',
    hint: 'Configuration des images liées à des blocks',
    can_inherit: true,
  },
  {
    id: 'item-argument',
    title: 'Item (argument)',
    hint: 'Configuration des images liées à des items de type plan',
    can_inherit: true,
  },
  {
    id: 'item-plan',
    title: 'Item (plan)',
    hint: 'Configuration des images liées à des items de type argument',
    can_inherit: true,
  },
  {
    id: 'category',
    title: 'Catégorie',
    hint: 'Configuration des images liées à aux catégories',
    can_inherit: true,
  },
  {
    id: 'format',
    title: 'Format',
    hint: 'Configuration des images liées à aux formats',
    can_inherit: true,
  },
  {
    id: 'custom',
    title: 'Custom',
    hint: 'Configuration des images liées à aux métadonnées customs',
    can_inherit: true,
  },
  {
    id: 'person',
    title: 'Personnes',
    hint: 'Configuration des images liées à aux casting',
    can_inherit: true,
  },
  {
    id: 'tag',
    title: 'Tag',
    hint: 'Configuration des images liées à aux tags',
    can_inherit: true,
  },
  {
    id: 'metadata',
    title: 'Métadata',
    hint: 'Configuration des images liées à aux métadata',
    can_inherit: true,
  },
  {
    id: 'platform',
    title: 'Platform',
    hint: 'Configuration des images liées à aux plateformes',
    can_inherit: true,
  },
  {
    id: 'program',
    title: 'Programme',
    hint: 'Configuration des images liées à aux programmes',
    can_inherit: true,
  },
  {
    id: 'selection',
    title: 'Sélections',
    hint: 'Configuration des images liées à aux sélections',
    can_inherit: true,
  },
  {
    id: 'user',
    title: 'Utilisateurs',
    hint: 'Configuration des images liées à aux utilisateurs',
    can_inherit: true,
  },
];
const collections = [
  {
    id: 'backdrops',
    title: 'Backdrops',
    can_inherit: true,
  },
  {
    id: 'sm_backdrops',
    title: 'Backdrops SM',
    can_inherit: true,
  },
  {
    id: 'md_backdrops',
    title: 'Backdrops MD',
    can_inherit: true,
  },
  {
    id: 'lg_backdrops',
    title: 'Backdrops LG',
    can_inherit: true,
  },
  {
    id: 'sm_tablet_backdrops',
    title: 'Backdrops Tablet SM',
    can_inherit: true,
  },
  {
    id: 'md_tablet_backdrops',
    title: 'Backdrops Tablet MD',
    can_inherit: true,
  },
  {
    id: 'lg_tablet_backdrops',
    title: 'Backdrops Tablet LG',
    can_inherit: true,
  },
  {
    id: 'sm_tv_backdrops',
    title: 'Backdrops TV SM',
    can_inherit: true,
  },
  {
    id: 'md_tv_backdrops',
    title: 'Backdrops TV MD',
    can_inherit: true,
  },
  {
    id: 'lg_tv_backdrops',
    title: 'Backdrops TV LG',
    can_inherit: true,
  },
  {
    id: 'md_mobile_backdrops',
    title: 'Backdrops Mobile MD',
    can_inherit: true,
  },
  {
    id: 'banners',
    title: 'Bandeaux',
    can_inherit: true,
  },
  {
    id: 'mobile_banners',
    title: 'Bandeaux Mobile',
    can_inherit: true,
  },
  {
    id: 'half_banners',
    title: 'Demi-bandeaux',
    can_inherit: true,
  },
  {
    id: 'fullscreens',
    title: 'FullScreen',
    can_inherit: true,
  },
  {
    id: 'portrait_fullscreens',
    title: 'Fullscreen Portrait',
    can_inherit: true,
  },
  {
    id: 'roundeds',
    title: 'Images rondes',
    can_inherit: true,
  },
  {
    id: 'landscape_mobile_backdrops',
    title: 'Landscape Mobile Backdrop',
    can_inherit: true,
  },
  {
    id: 'opengraph',
    title: 'Opengraph',
    can_inherit: true,
  },
  {
    id: 'pictograms',
    title: 'Pictogrammes',
    can_inherit: true,
  },
  {
    id: 'posters',
    title: 'Posters',
    can_inherit: true,
  },
  {
    id: 'thumbnails',
    title: 'Vignettes',
    can_inherit: true,
  },
  {
    id: 'xl_posters',
    title: 'Posters XL',
    can_inherit: true,
  },
  {
    id: 'xl_thumbnails',
    title: 'Vignettes XL',
    can_inherit: true,
  },
  {
    id: 'avod_banners',
    title: 'Bannière Pub'
  },
  {
    id: 'desktop_avod_banners',
    title: 'Bannière Pub Desktop'
  },
  {
    id: 'tablet_avod_banners',
    title: 'Bannière Pub Tablet'
  },
  {
    id: 'mobile_avod_banners',
    title: 'Bannière Pub Mobile'
  },
  {
    id: 'avod_top_banners',
    title: 'Top-bannière Pub'
  },
  {
    id: 'desktop_avod_top_banners',
    title: 'Top-bannière Pub Desktop'
  },
  {
    id: 'tablet_avod_top_banners',
    title: 'Top-bannière Pub Tablet'
  },
  {
    id: 'mobile_avod_top_banners',
    title: 'Top-bannière Pub Mobile'
  },
  {
    id: 'avod_interstitial',
    title: 'Interstitiel Pub'
  },
  {
    id: 'tv_avod_interstitial',
    title: 'Interstitiel Pub TV'
  },
  {
    id: 'tablet_avod_interstitial',
    title: 'Interstitiel Pub Tablet'
  },
  {
    id: 'mobile_avod_interstitial',
    title: 'Interstitiel Pub Mobile'
  },
  {
    id: 'avod_blocks',
    title: 'Pavé Pub'
  },
  {
    id: 'tv_avod_blocks',
    title: 'Pavé Pub TV'
  },
  {
    id: 'desktop_avod_blocks',
    title: 'Pavé Pub Desktop'
  },
  {
    id: 'tablet_avod_blocks',
    title: 'Pavé Pub Tablet'
  },
  {
    id: 'mobile_avod_blocks',
    title: 'Pavé Pub Mobile'
  }
];
const devices = [
  {
    id: '*',
    title: 'Défaut',
    can_inherit: false,
  },
  {
    id: 'desktop',
    title: 'Ordinateur',
    can_inherit: true,
  },
  {
    id: 'tablet',
    title: 'Tablette (portrait)',
    can_inherit: true,
  },
  {
    id: 'tablet-landscape',
    title: 'Tablette (paysage)',
    can_inherit: true,
  },
  {
    id: 'mobile',
    title: 'Mobile',
    can_inherit: true,
  },
  {
    id: 'orange',
    title: 'Orange',
    can_inherit: true,
  },
];
const fits = [
  {
    id: 'contain',
    title: 'Contain',
  },
  {
    id: 'max',
    title: 'Max',
  },
  {
    id: 'fill',
    title: 'Fill',
  },
];

const picturesConfigurationsDefaults = {
  models,
  collections,
  devices,
  fits,
};

export default picturesConfigurationsDefaults;
