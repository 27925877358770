import { cloneDeep } from 'lodash';

const actions = [
  {
    id: 'read',
    label: 'admin.pages.access.details.common.read',
    checked: false,
  },
  {
    id: 'edit',
    label: 'admin.pages.access.details.common.edit',
    checked: false,
  }
];

const adminRoles = [
  {
    id: 'catalogue',
    title: 'nav.catalogue.title',
    icon: 'nav.catalogue.icon',
    color: 'nav.catalogue.color',
    allowAll: false,
    atLeastOne: false,
    children: [
      {
        id: 'index',
        label: 'admin.pages.access.details.nav.catalogue.index',
        actions: cloneDeep(actions)
      },
      {
        id: 'partner',
        label: 'admin.pages.access.details.nav.catalogue.partner',
        actions: cloneDeep(actions)
      },
      {
        id: 'video',
        label: 'admin.pages.access.details.nav.catalogue.video',
        actions: cloneDeep(actions)
      },
      {
        id: 'participant',
        label: 'admin.pages.access.details.nav.catalogue.participant',
        actions: cloneDeep(actions)
      },
    ]
  },
  {
    id: 'animation',
    title: 'nav.animation.title',
    icon: 'nav.animation.icon',
    color: 'nav.animation.color',
    allowAll: false,
    atLeastOne: false,
    children: [
      {
        id: 'landing',
        label: 'admin.pages.access.details.nav.animation.landing',
        actions: cloneDeep(actions)
      },
      {
        id: 'home',
        label: 'admin.pages.access.details.nav.animation.home',
        actions: cloneDeep(actions)
      },
      {
        id: 'page',
        label: 'admin.pages.access.details.nav.animation.pages',
        actions: cloneDeep(actions)
      },
      {
        id: 'selection',
        label: 'admin.pages.access.details.nav.animation.selection',
        actions: cloneDeep(actions)
      },
    ]
  },
  {
    id: 'users',
    title: 'nav.users.title',
    icon: 'nav.users.icon',
    color: 'nav.users.color',
    allowAll: false,
    atLeastOne: false,
    children: [
      {
        id: 'index',
        label: 'admin.pages.access.details.nav.users.index',
        actions: cloneDeep(actions)
      },
    ]
  },
  {
    id: 'marketing',
    title: 'nav.marketing.title',
    icon: 'nav.marketing.icon',
    color: 'nav.marketing.color',
    allowAll: false,
    atLeastOne: false,
    children: [
      {
        id: 'offer',
        label: 'admin.pages.access.details.nav.marketing.offer',
        actions: cloneDeep(actions)
      },
      {
        id: 'promo',
        label: 'admin.pages.access.details.nav.marketing.promo',
        actions: cloneDeep(actions)
      },
      {
        id: 'avod',
        label: 'admin.pages.access.details.nav.marketing.avod',
        actions: cloneDeep(actions)
      },
      /*{
        id: 'gar-esidoc',
        label: 'admin.pages.access.details.nav.marketing.gar-esidoc',
        actions: cloneDeep(actions)
      },
      */
    ]
  },
  {
    id: 'stats',
    title: 'nav.stats.title',
    icon: 'nav.stats.icon',
    color: 'nav.stats.color',
    allowAll: false,
    atLeastOne: false,
    children: [
      {
        id: 'index',
        label: 'admin.pages.access.details.nav.stats.index',
        checked: false,
      },
    ]
  },
  {
    id: 'settings',
    title: 'nav.settings.title',
    icon: 'nav.settings.icon',
    color: 'nav.settings.color',
    allowAll: false,
    atLeastOne: false,
    children: [
      {
        id: 'appearance',
        label: 'admin.pages.access.details.nav.settings.appearance',
        actions: cloneDeep(actions)
      },
      {
        id: 'profile',
        label: 'admin.pages.access.details.nav.settings.profile',
        actions: cloneDeep(actions)
      },
      {
        id: 'metadata',
        label: 'admin.pages.access.details.nav.settings.metadata',
        actions: cloneDeep(actions)
      },
      /*{
        id: 'appendix',
        label: 'admin.pages.access.details.nav.settings.appendix',
        actions: cloneDeep(actions)
      },*/
    ]
  },
  {
    id: 'advanced-settings',
    title: 'nav.advanced-settings.title',
    icon: 'nav.advanced-settings.icon',
    color: 'nav.advanced-settings.color',
    allowAll: false,
    atLeastOne: false,
    children: [
      {
        id: 'features',
        label: 'admin.pages.access.details.nav.advanced-settings.features',
        actions: cloneDeep(actions)
      },
    ]
  },
  {
    id: 'tech',
    title: 'nav.tech.title',
    icon: 'nav.tech.icon',
    color: 'nav.tech.color',
    allowAll: false,
    atLeastOne: false,
    children: [
      {
        id: 'index',
        label: 'admin.pages.access.details.nav.tech.index',
        checked: false,
      },
    ]
  },
  {
    id: 'admins',
    title: 'nav.admins.title',
    icon: 'nav.tech.icon',
    color: 'nav.tech.color',
    allowAll: false,
    atLeastOne: false,
    children: [
      {
        id: 'tech',
        label: 'admin.pages.access.details.nav.admins.tech.title',
        tooltip: 'admin.pages.access.details.nav.admins.tech.tooltip',
        checked: false,
      },
      {
        id: 'otto',
        label: 'admin.pages.access.details.nav.admins.otto.title',
        tooltip: 'admin.pages.access.details.nav.admins.otto.tooltip',
        checked: false,
      },
      {
        id: 'super',
        label: 'admin.pages.access.details.nav.admins.super.title',
        tooltip: 'admin.pages.access.details.nav.admins.super.tooltip',
        checked: false,
      }
    ]
  },
];


export default adminRoles;
