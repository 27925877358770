import { picturesTypes } from '@/data/pictures.js';

const pictureTypesDefault = picturesTypes.reduce((acc, pictureType) => {
  const defaultPic = pictureType.placeholder
    ? pictureType.placeholder
    : pictureType.default;
  acc[pictureType.id] = {
    default: defaultPic,
    url: '',
  };
  return acc;
}, {});

export class SiteParameters {
  constructor({
    logo = {}, // @deprecated : use /models/platform.js
    favicon = {}, // @deprecated : use /models/platform.js
    colors = {
      // @deprecated : use /models/platform.js
      primary: '#004c66', // @deprecated : use /models/platform.js
      secondary: '#fca311', // @deprecated : use /models/platform.js
      tertiary: '', // @deprecated : use /models/platform.js
    },
    formats = [
      {
        id: 'posters',
        label: 'Poster',
        checked: true,
        with_duration: false,
        metadatas: [],
        image: {
          url: new URL(
            '../images/parameters/site/default-format-poster.png',
            import.meta.url
          ).href,
          withDurationUrl: new URL(
            '../images/parameters/site/default-format-poster-duration.png',
            import.meta.url
          ).href,
          width: '102px',
          height: '136px',
          outerHeight: '136px',
        },
      },
      {
        id: 'thumbnails',
        label: 'Vignette',
        checked: false,
        with_duration: false,
        metadatas: [],
        image: {
          url: new URL(
            '../images/parameters/site/default-format-thumbnail.png',
            import.meta.url
          ).href,
          withDurationUrl: new URL(
            '../images/parameters/site/default-format-thumbnail-duration.png',
            import.meta.url
          ).href,
          width: '160px',
          height: '90px',
          outerHeight: '136px',
        },
      },
    ],
    button = {
      label: 'Voir', // @deprecated : use /models/platform.jss
      radius: 5, // @deprecated : use /models/platform.js
    },
    defaultVisuals = pictureTypesDefault,
    header = {
      primaryNav: [
        {
          label: 'Catalogue',
          url: '/catalogue',
          type: 'link',
          categoriesDropdownOption: true,
        },
        {
          label: 'Ma liste',
          url: '/ma-liste',
          type: 'link',
        },
        {
          label: 'Sélections',
          url: '/selections',
          type: 'link',
        },
      ],
      secondaryNav: [],
    },
    landingHeader = {
      primaryNav: [],
      secondaryNav: [
        {
          label: 'Je découvre',
          url: '/presentation',
          type: 'link',
        },
        {
          label: 'Je me connecte',
          url: '#login',
          urlFixed: true,
          type: 'link',
        },
        {
          label: "Je m'abonne",
          url: '#login',
          urlFixed: true,
          type: 'button',
        },
      ],
    },
    footer = {
      primaryNav: [
        {
          //   label: 'Mentions légales',
          //   url: '/mentions-legales',
          //   type: 'link',
          // },{
          //   label: 'Conditions générales d\'utilisation',
          //   url: '/cgu',
          //   type: 'link',
          // },{
          //   label: 'Contact',
          //   url: '/contact',
          //   type: 'link',
        },
      ],
      appsNav: [
        {
          id: 'ios',
          label: 'App Store',
          badge: new URL(`../images/apps/appstore-badge.svg`, import.meta.url)
            .href,
          type: 'app',
          hidden: false,
        },
        {
          id: 'android',
          label: 'Google Play',
          badge: new URL(`../images/apps/googleplay-badge.svg`, import.meta.url)
            .href,
          type: 'app',
          hidden: false,
        },
      ],
      socialNav: [
        {
          id: 'fb',
          label: 'Facebook',
          icon: 'facebook',
          type: 'social',
          hidden: true,
        },
        {
          id: 'ig',
          label: 'Instagram',
          icon: 'instagram',
          type: 'social',
          hidden: true,
        },
        {
          id: 'tw',
          label: 'Twitter',
          icon: 'twitter',
          type: 'social',
          hidden: true,
        },
      ],
    },
    contentPages = {
      bannerTemplate: null, // @deprecated : use /models/platform.js
      bannerWithPoster: false, // @deprecated : use /models/platform.js
      blocks: [
        'casting',
        'level',
        'material',
        'review',
        'metas',
        'tags',
        'social',
      ], // @deprecated : use /models/platform.js
    },
    seriePages = {
      bannerTemplate: null, // @deprecated : use /models/platform.js
      bannerWithPoster: false, // @deprecated : use /models/platform.js
      episodesTemplate: null, // @deprecated : use /models/platform.js
      blocks: [
        'casting',
        'level',
        'material',
        'review',
        'metas',
        'tags',
        'social',
      ],
    },
    cataloguePage = {
      desktop: {
        title: '',
        description: '',
        cover: { url: '' },
        filters: [],
      },
      tablet: {
        title: '',
        description: '',
        cover: { url: '' },
        filters: [],
      },
      mobile: {
        title: '',
        description: '',
        cover: { url: '' },
        filters: [],
      },
      orange: {
        title: '',
        description: '',
        cover: { url: '' },
        filters: [],
        template: {
          id: 'wall-poster',
          groupBy: { id: 'category', label: 'Catégorie' },
          items: [],
        },
        withDynamicBanner: true,
      },
      free: {
        title: '',
        description: '',
        cover: { url: '' },
        filters: [],
        template: {
          id: 'wall-poster',
          groupBy: { id: 'category', label: 'Catégorie' },
          items: [],
        },
        withDynamicBanner: true,
      },
      apple_tv: {
        title: '',
        description: '',
        cover: { url: '' },
        filters: [],
        template: {
          id: 'wall-poster',
          groupBy: { id: 'category', label: 'Catégorie' },
          items: [],
        },
        withDynamicBanner: true,
      },
      android_tv: {
        title: '',
        description: '',
        cover: { url: '' },
        filters: [],
        template: {
          id: 'wall-poster',
          groupBy: { id: 'category', label: 'Catégorie' },
          items: [],
        },
        withDynamicBanner: true,
      },
      samsung_tv: {
        title: '',
        description: '',
        cover: { url: '' },
        filters: [],
        template: {
          id: 'wall-poster',
          groupBy: { id: 'category', label: 'Catégorie' },
          items: [],
        },
        withDynamicBanner: true,
      },
    },
    animationBlocks = {
      carousel: {
        tabletAsDesktop: true,
        appearance: 'classic',
        height: {
          desktop: '720',
          tablet: '720',
          mobile: '300',
          orange: '720',
          free: '720',
          apple_tv: '720',
          android_tv: '720',
          samsung_tv: '720',
        },
        aspect: {
          desktop: 'aspect-gradient-bottom',
          tablet: 'aspect-gradient-bottom',
          mobile: 'aspect-gradient-bottom',
          orange: 'aspect-gradient-bottom',
          free: 'aspect-gradient-bottom',
          apple_tv: 'aspect-gradient-bottom',
          android_tv: 'aspect-gradient-bottom',
          samsung_tv: 'aspect-gradient-bottom',
        },
        color: '',
        controlsType: 'controls-nude',
        controlsColor: '',
        controlsBackgroundColor: '',
        controlsRadius: 5,
        pagination: false,
        textAspect: 'text-without-background',
        textAspectBackgroundColor: '',
        textPosition: 'text-position-center',
        textMetadatas: {
          desktop: [
            { id: 'format', title: 'Format', type: 'singleselect' },
            { id: 'category', title: 'Catégorie', type: 'category' },
            { id: 'cast', title: 'Participants', type: 'participants' },
            { id: 'duration', title: 'Durée', type: 'singleselect' },
            { id: 'synopsis', title: 'Synopsis', type: 'text' },
          ],
          tablet: [
            { id: 'format', title: 'Format', type: 'singleselect' },
            { id: 'category', title: 'Catégorie', type: 'category' },
            { id: 'duration', title: 'Durée', type: 'singleselect' },
            { id: 'synopsis', title: 'Synopsis', type: 'text' },
          ],
          mobile: [
            { id: 'format', title: 'Format', type: 'singleselect' },
            { id: 'category', title: 'Catégorie', type: 'category' },
          ],
          orange: [
            { id: 'format', title: 'Format', type: 'singleselect' },
            { id: 'category', title: 'Catégorie', type: 'category' },
            { id: 'cast', title: 'Participants', type: 'participants' },
            { id: 'duration', title: 'Durée', type: 'singleselect' },
            { id: 'synopsis', title: 'Synopsis', type: 'text' },
          ],
          free: [
            { id: 'format', title: 'Format', type: 'singleselect' },
            { id: 'category', title: 'Catégorie', type: 'category' },
            { id: 'cast', title: 'Participants', type: 'participants' },
            { id: 'duration', title: 'Durée', type: 'singleselect' },
            { id: 'synopsis', title: 'Synopsis', type: 'text' },
          ],
          apple_tv: [
            { id: 'format', title: 'Format', type: 'singleselect' },
            { id: 'category', title: 'Catégorie', type: 'category' },
            { id: 'cast', title: 'Participants', type: 'participants' },
            { id: 'duration', title: 'Durée', type: 'singleselect' },
            { id: 'synopsis', title: 'Synopsis', type: 'text' },
          ],
          android_tv: [
            { id: 'format', title: 'Format', type: 'singleselect' },
            { id: 'category', title: 'Catégorie', type: 'category' },
            { id: 'cast', title: 'Participants', type: 'participants' },
            { id: 'duration', title: 'Durée', type: 'singleselect' },
            { id: 'synopsis', title: 'Synopsis', type: 'text' },
          ],
          samsung_tv: [
            { id: 'format', title: 'Format', type: 'singleselect' },
            { id: 'category', title: 'Catégorie', type: 'category' },
            { id: 'cast', title: 'Participants', type: 'participants' },
            { id: 'duration', title: 'Durée', type: 'singleselect' },
            { id: 'synopsis', title: 'Synopsis', type: 'text' },
          ],
        },
        textButtons: {
          desktop: [
            { id: 'watch', label: 'Voir' },
            { id: 'trailer', label: 'Bande-annonce' },
            { id: 'add_to_list', label: 'Ajouter à ma liste' },
          ],
          tablet: [
            { id: 'watch', label: 'Voir' },
            { id: 'trailer', label: 'Bande-annonce' },
            { id: 'add_to_list', label: 'Ajouter à ma liste' },
          ],
          mobile: [
            { id: 'watch', label: 'Voir' },
            { id: 'add_to_list', label: 'Ajouter à ma liste' },
          ],
          orange: [
            { id: 'watch', label: 'Voir' },
            { id: 'trailer', label: 'Bande-annonce' },
            { id: 'add_to_list', label: 'Ajouter à ma liste' },
          ],
          free: [
            { id: 'watch', label: 'Voir' },
            { id: 'trailer', label: 'Bande-annonce' },
            { id: 'add_to_list', label: 'Ajouter à ma liste' },
          ],
          apple_tv: [
            { id: 'watch', label: 'Voir' },
            { id: 'trailer', label: 'Bande-annonce' },
            { id: 'add_to_list', label: 'Ajouter à ma liste' },
          ],
          android_tv: [
            { id: 'watch', label: 'Voir' },
            { id: 'trailer', label: 'Bande-annonce' },
            { id: 'add_to_list', label: 'Ajouter à ma liste' },
          ],
          samsung_tv: [
            { id: 'watch', label: 'Voir' },
            { id: 'trailer', label: 'Bande-annonce' },
            { id: 'add_to_list', label: 'Ajouter à ma liste' },
          ],
        },
        participantsRole: { id: 7, title: 'Réalisateur' },
      },
      contents: {
        formats: {
          desktop: [],
          tablet: [],
          mobile: [],
          orange: [],
          free: [],
          apple_tv: [],
          android_tv: [],
          samsung_tv: [],
        },
      },
    },
  } = {}) {
    this.logo = logo;
    this.favicon = favicon;
    this.colors = colors;
    this.formats = formats;
    this.button = button;
    this.defaultVisuals = defaultVisuals;
    this.header = header;
    this.landingHeader = landingHeader;
    this.footer = footer;
    this.contentPages = contentPages;
    this.seriePages = seriePages;
    this.cataloguePage = cataloguePage;
    this.animationBlocks = animationBlocks;
  }
}

export default SiteParameters;
