import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/playlists/';
const currentPath = import.meta.env.VITE_CONTENT_BASE_URL + api_url_module;

export const playlistService = {
  getUserPlaylist,
};

async function getUserPlaylist(userId) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_CONTENT_BASE_URL +
        api_url_module +
        '?with=program&user_id=' +
        userId
    );
    return handleResponse(response.data);
  } catch (err) {
    console.log(err);
    return handleResponse(err);
  }
}
