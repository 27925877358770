import { cloneDeep, isNil } from 'lodash';

import DisplayConfig from '@/models/parameters/DisplayConfig';
import store from '@/store';

/**
 * Block social for program/serie/live template
 *
 * @author Jody JUSTINE <jjustine@vodfactory.com>
 */
export class BlockSocial {
  id;
  block_id;
  block_type;
  details;
  rank;
  title;
  title_size;
  title_weight;
  title_style;
  content_social;

  format;
  stylable;

  type;
  template_id;
  platform_id;
  block_type_id;
  items;

  displayConfig;
  socialNetworks;

  constructor({
    platform_id,
    template_id,
    block_id,
    title,
    title_size,
    title_weight,
    title_style,
    rank,
    content_social,
    socialNetworks,
  }) {
    this.platform_id = platform_id;
    this.template_id = template_id;
    this.block_id = block_id ?? 'social';
    this.block_type = 'banner';
    this.block_type_id = store.getters['references/block_type_id'](
      this.block_type
    );
    this.item_type = 'social';
    this.item_type_id = store.getters['references/item_type_id'](
      this.item_type
    );

    this.title = title;
    this.title_size = title_size;
    this.title_weight = title_weight;
    this.title_style = title_style;

    this.rank = rank;

    this.content_social = content_social;
    this.socialNetworks = socialNetworks;

    this.setDefault();
    this.setDisplayConfig();
    this.setFormatAndStylable();
  }

  setDefault() {
    (this.id = 'social'),
      (this.type = 'social'),
      (this.format = 'social'),
      (this.block_type = 'banner'),
      (this.item_type = 'social'),
      (this.details = {
        name: 'socialNetworks',
      });
    this.active = 1;
  }

  /**
   * Set config for metadata display
   */
  setDisplayConfig() {
    let retval = {};
    retval.title = this.title ?? '';
    retval.default_title = null;

    //Set social
    if (isNil(retval.socialNetworks)) {
      retval.socialNetworks = [
        {
          id: 'facebook',
          label: 'facebook',
          icon: 'facebook',
          checked: this.content_social?.facebook ?? true,
        },
        {
          id: 'twitter',
          label: 'Twitter',
          icon: 'twitter',
          checked: this.content_social?.twitter ?? true,
        },
      ];
    }

    // Set Title style : bold / italic / font-size
    retval.titleStyles = {};
    retval.titleStyles.bold =
      (this?.title_weight ?? null) == 'bold' ? true : false;
    retval.titleStyles.fontSize =
      this?.title_size ?? null
        ? 'font-size-' + this.title_size
        : 'font-size-md';
    retval.titleStyles.italic =
      (this?.title_style ?? null) == 'italic' ? true : false;

    this.displayConfig = new DisplayConfig(retval);
  }

  /**
   * Set format and stylable
   */
  setFormatAndStylable() {
    this.format = 'social';
    this.stylable = false;
  }

  /**
   * Get clean data for api
   *
   * @return object
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  getCleanDataForApi() {
    let retval = cloneDeep(this);

    retval.title = this.displayConfig.getApiTitle();

    retval.title_size = this.displayConfig.getApiFontSize();
    retval.title_style = this.displayConfig.getApiFontStyle();
    retval.title_weight = this.displayConfig.getApiFontWeight();

    retval.items = [{ item_type_id: this.item_type_id, rank: 0 }];
    retval.content_social = retval.displayConfig.getApiSocialNetworks();
    // Remove unnecessary data for api
    delete retval.format;
    delete retval.stylable;
    delete retval.temp;
    delete retval.displayConfig;
    delete retval.defaultColors;
    delete retval.details;
    delete retval.socialNetworks;

    return retval;
  }
}

export default BlockSocial;
