import { cloneDeep } from 'lodash';
import videosTypes from '@/data/videosTypes';

const teal = '#90CACB';
const blue = '#5E89A3';
const purple = '#B05574';
const salmonPink = '#F87E7b';
const orange = '#F7B985';
const orange_2 = '#F5A867';
const orange_3 = '#DD975D';
const orange_4 = '#CC8850';
const green = '#6F8A65';
const yellow = '#DEBA1C';
const yellow_1 = '#E5C849';
const pink = '#F87E7B';
const gray = '#808080';
const samsung = '#736B93';

const colors = [
  '#5e89a3',
  '#deba1c',
  '#b05574',
  '#1abc9c',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#2ecc71',
  '#16a085',
  '#27ae60',
  '#2980b9',
  '#8e44ad',
  '#2c3e50',
  '#f1c40f',
  '#e67e22',
  '#e74c3c',
  '#95a5a6',
  '#f39c12',
  '#d35400',
  '#c0392b',
  '#bdc3c7',
  '#7f8c8d',
];

const dataInputOptions = [
  { id: 1, title: '' },
  { id: 2, title: '' },
  { id: 3, title: '' },
];

export const datePresets = [
  {
    id: 'custom',
    title: 'kpis.date.helper.custom',
  },
  {
    id: 'yesterday',
    title: 'kpis.date.helper.yesterday',
  },
  {
    id: 'last-week',
    title: 'kpis.date.helper.last-week',
  },
  {
    id: 'last-four-weeks',
    title: 'kpis.date.helper.last-four-weeks',
  },
  {
    id: 'last-three-months',
    title: 'kpis.date.helper.last-three-months',
  },
];

export const kpisTypes = [
  {
    id: 'subscribed',
    title: 'kpis.types.subscribed.title',
    label: 'kpis.types.subscribed.label',
    icon: 'users',
    iconColor: 'secondary',
    action: 'check-circle',
    summaryId: 'total_subscriptions',
    summaryMessage: 'kpis.types.subscribed.message',
    tooltip: 'kpis.types.subscribed.tooltip',
    valueType: 'count',
    color: blue,
    actionColor: 'success',
  },
  {
    id: 'subscriptions',
    title: 'kpis.types.subscriptions.title',
    label: 'kpis.types.subscriptions.label',
    icon: 'user',
    iconColor: 'yellow',
    action: 'check-circle',
    summaryId: 'new_subscriptions',
    summaryMessage: 'kpis.types.subscriptions.message',
    tooltip: 'kpis.types.subscribed.tooltip',
    valueType: 'count',
    color: yellow,
    actionColor: 'success',
  },
  {
    id: 'registered',
    title: 'kpis.types.registered.title',
    label: 'kpis.types.registered.label',
    icon: 'users',
    iconColor: 'teal',
    action: '',
    summaryId: 'total_registrations',
    summaryMessage: 'kpis.types.registered.message',
    tooltip: '',
    valueType: 'count',
    color: teal,
  },
  {
    id: 'registrations',
    title: 'kpis.types.registrations.title',
    label: 'kpis.types.registrations.label',
    action: '',
    icon: 'user',
    iconColor: 'primary',
    summaryId: 'new_registrations',
    summaryMessage: 'kpis.types.registrations.message',
    tooltip: '',
    valueType: 'count',
    color: orange,
  },
  {
    id: 'unsubscribed',
    title: 'kpis.types.unsubscribed.title',
    label: 'kpis.types.unsubscribed.label',
    icon: 'user',
    iconColor: 'purple',
    action: 'close-circle',
    summaryId: 'unsubscriptions',
    summaryMessage: 'kpis.types.unsubscribed.message',
    tooltip: '',
    valueType: 'count',
    color: purple,
    actionColor: 'red',
  },
  {
    id: 'transactions',
    title: 'kpis.types.transactions.title',
    label: 'kpis.types.transactions.label',
    illustration: 'revenue',
    summaryId: 'total_transactions',
    summaryMessage: '',
    tooltip: 'kpis.types.transactions.tooltip',
    valueType: 'currency',
    color: teal,
  },
  {
    id: 'topViews',
    title: 'kpis.types.topViews.title',
    label: 'kpis.types.topViews.label',
    icon: 'video-flat',
    tooltip: '',
    valueType: '',
    color: purple,
  },
  {
    id: 'topViewsAggregate',
    title: 'kpis.types.topViewsAggregate.title',
    label: 'kpis.types.topViewsAggregate.label',
    icon: 'video-flat',
    tooltip: '',
    valueType: 'count',
    color: salmonPink,
  },
  {
    id: 'transactionsRepartition',
    title: 'kpis.types.share.title',
    label: 'kpis.types.share.label',
    summaryId: 'total_transactions',
    summaryMessage: '',
    tooltip: '',
    valueType: 'currency',
    color: teal,
  },
];
export const granularities = [
  {
    id: 'day',
    title: 'frequency.day.period',
    range: [1, 31],
  },
  {
    id: 'week',
    title: 'frequency.week.period',
    range: [1, 90],
  },
  {
    id: 'month',
    title: 'frequency.month.period',
    range: [27, 900],
  },
  {
    id: 'year',
    title: 'frequency.year.period',
    range: [363, 10000],
  },
];
export const filtersOptions = [
  {
    id: 'source',
    form: 'select',
    withAll: true,
    title: 'kpis.filters.source.title',
    options: [
      {
        id: 'all',
        title: 'kpis.filters.source.all',
        color: '',
      },
      {
        id: 'cb',
        title: 'kpis.filters.source.cb',
        color: yellow_1,
      },
      {
        id: 'prepaid',
        title: 'kpis.filters.source.prepaid',
        color: orange
      },
      {
        id: 'prepaid_giftup',
        title: 'kpis.filters.source.prepaidGiftUp',
        color: orange_2
      },
      {
        id: 'prepaid_pass-culture',
        title: 'kpis.filters.source.prepaidPassCulture',
        color: orange_3
      },
      {
        id: 'prepaid_epay',
        title: 'kpis.filters.source.prepaidEpay',
        color: orange_4
      },
      {
        id: 'app',
        title: 'kpis.filters.source.app',
        color: teal,
      },
      {
        id: 'sepa',
        title: 'kpis.filters.source.sepa',
        color: blue,
      },
      /*{
        id: 'orange',
        title: 'kpis.filters.source.orange',
        color: purple
      },*/
    ],
  },
  {
    id: 'device',
    form: 'select',
    withAll: true,
    title: 'kpis.filters.device.title',
    showAll: true,
    options: [
      {
        id: 'all',
        title: 'kpis.filters.device.all',
        color: '',
      },
      {
        id: 'desktop',
        title: 'kpis.filters.device.desktop',
        color: yellow,
      },
      {
        id: 'tablet',
        title: 'kpis.filters.device.tablet',
        color: purple,
      },
      {
        id: 'phone',
        title: 'kpis.filters.device.phone',
        color: teal,
      },
      {
        id: 'tv',
        title: 'kpis.filters.device.tv',
        color: green,
      },
    ],
  },
  {
    id: 'status',
    form: 'select',
    withAll: true,
    title: 'kpis.filters.status.title',
    options: [
      {
        id: 'all',
        title: 'kpis.filters.status.all',
        color: '',
      },
      {
        id: 'paying',
        title: 'kpis.filters.status.paying',
        color: blue,
      },
      {
        id: 'free',
        title: 'kpis.filters.status.free',
        color: teal,
      },
      {
        id: 'trialing',
        title: 'kpis.filters.status.trialing',
        color: yellow,
      },
      {
        id: 'canceling-trial',
        title: 'kpis.filters.status.canceling-trial',
        color: pink,
      },
      {
        id: 'canceling-paying',
        title: 'kpis.filters.status.canceling-paying',
        color: purple,
      },
    ],
  },
  {
    id: 'type',
    form: 'select',
    withAll: true,
    title: 'kpis.filters.offer.title',
    options: [
      {
        id: 'all',
        title: 'kpis.filters.offer.all',
        color: '',
      },
      {
        id: 'year',
        title: 'kpis.filters.offer.year',
        color: teal,
      },
      {
        id: 'month',
        title: 'kpis.filters.offer.month',
        color: purple,
      },
      {
        id: 'week',
        title: 'kpis.filters.offer.week',
        color: yellow,
      },
    ],
  },
  {
    id: 'video_type',
    form: 'select',
    withAll: true,
    title: 'kpis.filters.videoTypes.title',
    options: [
      {
        id: 'all',
        title: 'kpis.filters.videoTypes.title',
        color: '',
      },
    ].concat(cloneDeep(videosTypes).filter((type) => type.id != 'live')),
  },
  {
    id: 'program_id',
    form: 'input',
    max: 3,
    action: {
      get: 'programs/get',
      search: 'programs/search',
    },
    title: 'kpis.filters.program.title',
    placeholder: 'kpis.filters.program.placeholder',
    colors: colors,
    options: dataInputOptions,
    items: [],
    keyword: '',
  },
  {
    id: 'ina_id',
    platform_id: 122, //INA Madelen
    form: 'input',
    type: 'metadata',
    max: 3,
    title: 'kpis.filters.ina.title',
    placeholder: 'kpis.filters.ina.placeholder',
    colors: colors,
    options: dataInputOptions,
  },
  {
    id: 'category_id',
    name: 'categories',
    form: 'select',
    type: 'metadata',
    max: 6,
    withAll: false,
    placeholder: 'kpis.filters.category.placeholder',
    title: 'kpis.filters.category.title',
    colors: colors,
    options: [],
  },
  /*{
    id: 'tag_id',
    name: 'tags',
    form: 'select',
    type: 'metadata',
    max: 6,
    colors: colors,
    withAll: false,
    title: 'kpis.filters.tag.title',
    placeholder: 'kpis.filters.tag.placeholder',
    options: [],
  },*/
  {
    id: 'genre_id',
    name: 'custom_id',
    platform_id: 122, //INA Madelen
    metadataDetailId: 1369, // Genre metadata
    form: 'select',
    type: 'metadata',
    max: 6,
    colors: colors,
    withAll: false,
    placeholder: 'kpis.filters.genre.placeholder',
    title: 'kpis.filters.genre.title',
    options: [],
  },
];

export const defaultKpisState = {
  all: [],
  selectedFilters: [],
  selectedDate: null,
  filters: filtersOptions.map((option) => {
    if (
      !['program_id', 'ina_id', 'category_id', 'tags_id', 'genre_id'].includes(
        option.id
      )
    ) {
      return { ...option, value: ['all'] };
    } else {
      return { ...option, value: [] };
    }
  }),
  loading: true,
  locale: {
    fr: {
      dateTime: '%A %e %B %Y à %X',
      date: '%d/%m/%Y',
      time: '%H:%M:%S',
      periods: ['AM', 'PM'],
      days: [
        'dimanche',
        'lundi',
        'mardi',
        'mercredi',
        'jeudi',
        'vendredi',
        'samedi',
      ],
      shortDays: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
      months: [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre',
      ],
      shortMonths: [
        'janv.',
        'févr.',
        'mars',
        'avr.',
        'mai',
        'juin',
        'juil.',
        'août',
        'sept.',
        'oct.',
        'nov.',
        'déc.',
      ],
    },
  },
};

export const otherOptions = {
  console: {
    id: 'console',
    title: 'kpis.filters.device.console',
    color: gray,
  },
  sepa: {
    id: 'sepa',
    title: 'kpis.filters.source.sepa',
    color: yellow,
  },
  orangeApp: {
    id: 'orange-app',
    title: 'kpis.filters.device.orange',
    color: orange,
  },
  samsungApp: {
    id: 'samsung-app',
    title: 'kpis.filters.device.samsung',
    color: samsung,
  },
}
