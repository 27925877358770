import * as d3 from 'd3';
import { GenericStatisticsService } from '@/services';
import { cloneDeep } from 'lodash';
import { defaultKpisState, kpisTypes } from '@/data/kpis';
import { getField, updateField } from 'vuex-map-fields';
import genericKpisGetters from '@/store/statistics/genericKpisGetters';
import genericKpisMutations from '@/store/statistics/genericKpisMutations';

const service = new GenericStatisticsService('registrations');

const {
  id,
  color,
  title,
  label,
  icon = '',
  iconColor = '',
  action = '',
  actionColor = '',
  illustration = '',
  valueType,
  queryString = ''
} = kpisTypes.find((type) => type.id === 'registered');

const state = {
  ...cloneDeep(defaultKpisState),
  filters: [], // not fiilterable
  id,
  title,
  label,
  color,
  icon,
  action,
  iconColor,
  actionColor,
  illustration,
  valueType,
  queryString
};

const actions = {
  async search({ commit, dispatch, state, rootState }) {
    commit('setLoading', true);
    commit('resetSelectedDate');
    const start = rootState.kpis.startDateId;
    const end = rootState.kpis.endDateId;
    const unit = rootState.kpis.granularity;
    const queryString = `start_date=${start}&end_date=${end}&interval=${unit}`;
    commit('addFiltersToQueryString', queryString);
    return service.search(state.queryString).then(
      (registered) => {
        commit('getAllSuccess', registered);
        commit('setLoading', false);
        return Promise.resolve(registered);
      },
      (error) => {
        const alert = {
          id: 'registered-search-failure',
          icon: 'close',
          type: 'error',
          message: 'notifications.search.error',
        };
        commit('setLoading', false);
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  ...genericKpisMutations,
  updateField,
};

const getters = {
  getField,
  ...cloneDeep(genericKpisGetters),
  formattedAll: (state, getters) => {
    if (!getters.all) {
      return [];
    }
    return Object.keys(getters.all).sort().map((key) => {
      return {
        date: getters.all?.[key]?.[0]?.date ?? key,
        name: getters.all?.[key]?.[0]?.date ?? key,
        value: getters.all[key]?.[0]?.total,
      };
    });
  },
  filteredAll: (state, getters) => {
    if (!getters.formattedAll.length) {
      return [];
    }
    return getters.formattedAll;
  },
  summaryValue: (state, getters) => {
    return getters.toDate;
  },
  summaryHasFooter: () => {
    return false;
  },
};

export const registeredKpis = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
