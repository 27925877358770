<template>
  <Card v-if="item" class="fixed text-sm text-left" :style="style" spacer="md">
    <p v-if="item.title" class="italic mb-2">
      {{ title }}
    </p>
    <p v-if="item.breadcrumb" class="italic mb-2">
      {{ item.breadcrumb }}
    </p>
    <p v-if="item.total" class="mb-2">
      Total :
      <strong class="font-medium">{{ itemTotalValue }} {{ itemType.toLocaleLowerCase() }}</strong>
    </p>
    <p v-if="item.detail" class="flex mb-2">
      <span
        class="inline-block w-3.5 h-3.5 mr-3 rounded-full bg-black"
        :style="`background-color:${item.detail.color}`"
      />
      {{ $t(item.detail.name) }}&nbsp;:&nbsp;<strong class="font-medium">{{
        itemDetailValue
      }}</strong>
    </p>
    <p
      v-if="item.detail && showHint && forceShowHint"
      class="text-skin-accent flex underline"
    >
      <strong>{{ $t('kpis.tooltip.see-details') }}</strong>
    </p>
  </Card>
</template>

<script>
import { timeFormat, timeParse } from 'd3';
import Card from '@/components/card/Card.vue';
import utils from '@/utils/utils.js';

export default {
  name: 'KpiTooltip',
  components: {
    Card,
  },
  props: {
    item: {
      type: [Object, null],
      required: true,
    },
    kpiType: {
      type: String,
      required: true,
    },
    position: {
      type: Object,
      default: null,
    },
    showHint: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      if (!this.item.title) {
        return '';
      }
      let dateFormat = null;
      if (this.item.title.indexOf('/') > -1) {
        dateFormat = 'tooltipDay';
      } else if (this.item.title.split(' ').length === 2) {
        dateFormat = 'tooltipMonth';
      } else if (this.item.title.length === 4) {
        dateFormat = 'tooltipYear';
      }

      const date =
        this.item.date.split('-').length > 2
          ? this.item.date
          : `${this.item.date}-01`;
      if (!dateFormat) {
        return '';
      }
      return utils.capitalize(
        this.$d(new Date(date), dateFormat, this.$t('date.locale'))
      );
    },
    forceShowHint() {
      return typeof this.item.showHint === 'undefined' || this.item.showHint;
    },
    itemType() {
      if (['transactions', 'topViewsAggregate'].includes(this.kpiType)) {
        return '';
      }
      return this.$t(`kpis.types.${this.kpiType}.label`).toLowerCase();
    },
    itemTotalValue() {
      if (!this.item.total) {
        return null;
      }
      if (this.kpiType === 'transactions') {
        return this.$n(
          this.item.total.value,
          'currency',
          this.$t('price.currency')
        );
      }
      return this.$n(this.item.total.value);
    },
    itemDetailValue() {
      if (this.kpiType === 'transactions') {
        return this.$n(
          this.item.detail.value,
          'currency',
          this.$t('price.currency')
        );
      }
      return this.$n(this.item.detail.value);
    },

    style() {
      return {
        position: 'fixed',
        left: this.position.left + 15 + 'px',
        top: this.position.top + 15 + 'px',
      };
    },
  },
};
</script>
