import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/discount-campaigns/';
const currentPath = import.meta.env.VITE_BILLING_BASE_URL + api_url_module;

export class DiscountCampaignService {
  _endpointName;

  constructor() {
    this._endpointName = 'discount-campaigns';
  }

  async get(id) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_BILLING_BASE_URL +
        '/' +
        this._endpointName +
        '/' +
        id
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async update(discount_campaign, id) {
    const requestOptions = {
      method: 'PUT',
    };
    try {
      const response = await axios.put(
        import.meta.env.VITE_BILLING_BASE_URL +
        '/' +
        this._endpointName +
        '/' +
        id,
        discount_campaign,
        requestOptions
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }

  async create(discount_campaign) {
    const requestOptions = {
      method: 'POST',
    };
    try {
      const response = await axios.post(
        import.meta.env.VITE_BILLING_BASE_URL + '/' + this._endpointName,
        discount_campaign,
        requestOptions
      );
      return response.data.results;
    } catch (response_1) {
      return await Promise.reject();
    }
  }

  async search(queryString = '') {
    const requestOptions = {
      method: 'GET',
    };
    if (queryString !== '') {
      queryString = '?' + queryString;
    }
    try {
      const response = await axios.get(
        import.meta.env.VITE_BILLING_BASE_URL +
        '/' +
        this._endpointName +
        '/' +
        queryString
      );
      return handleResponse(response.data);
    } catch (err) {
      return handleResponse(err);
    }
  }
}
