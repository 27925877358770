import moment from 'moment-timezone';

export class Coupon {
  id;
  plan_id;
  status_code;
  name;
  prefix;
  periods = {
    trial_in_days: 0,
    cycle_count: -1,
    interval_count: 0,
    interval: 'month',
  };
  redemptions;
  redemptions_limit;
  target_type;
  token;
  type;
  valid;
  starts_at;
  ends_at;
  constructor({
    id = 0,
    plan_id = 0,
    status_code = '',
    discount_campaign_name = '',
    promo_duration,
    redemptions = 0,
    redemptions_limit = null,
    target_type = '',
    token = '',
    type = 'prepaid',
    valid = null,
    starts_at = null,
    ends_at = null,

  }) {
    this.id = id;
    this.plan_id = plan_id;
    this.status_code = status_code;
    this.name = discount_campaign_name;
    this.prefix = '';
    this.periods.trial_in_days =
      promo_duration?.trial_in_days ?? periods.trial_in_days;
    this.periods.cycle_count =
      promo_duration?.cycle_count ?? periods.cycle_count;
    this.periods.interval_count =
      promo_duration?.interval_count ?? periods.interval_count;
    this.periods.interval =
      promo_duration?.interval ?? periods.interval;
    this.redemptions = redemptions;
    this.redemptions_limit = redemptions_limit;
    this.target_type = target_type;
    this.token = token;
    this.type = type;
    this.valid = valid;
    this.starts_at =
      starts_at === null
        ? null
        : moment.tz(starts_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
    this.ends_at =
      ends_at === null
        ? null
        : moment.tz(ends_at, 'YYYY-MM-DDTHH:mm:ss', 'UTC');
  }
}
