import store from '@/store';

/**
 * Block grid contents for collection template
 *
 */
export class BlockGridContents {
  id;
  block_id;
  block_type;
  details;
  rank;

  title;
  title_size;
  title_weight;
  title_style;
  description;
  picture;
  cta_label;
  cta_url;
  cta_target;
  background_color;
  content_grid;

  type;
  template_id;
  platform_id;
  block_type_id;
  items;
  picture_default;

  constructor({
    platform_id,
    template_id,
    block_id,
    rank,
    picture_default = 'posters',
    title_size,
    title_weight,
    title_style,
    description,
    picture,
    cta_label,
    cta_url,
    cta_target,
    background_color,
    content_grid = { show_duration: false },
  }) {
    this.platform_id = platform_id;
    this.template_id = template_id;
    this.block_id = block_id ?? 'grid-contents';
    this.block_type = 'grid-contents';
    this.block_type_id = store.getters['references/block_type_id'](
      this.block_type
    );
    this.item_type = 'collection';
    this.item_type_id = store.getters['references/item_type_id'](
      this.item_type
    );

    this.rank = rank;
    this.picture_default = picture_default;
    this.content_grid = content_grid;

    this.title_size = title_size;
    this.title_weight = title_weight;
    this.title_style = title_style;
    this.description = description;
    this.picture = picture;
    this.cta_label = cta_label;
    this.cta_url = cta_url;
    this.cta_target = cta_target;
    this.background_color = background_color;
  }
}

export default BlockGridContents;
