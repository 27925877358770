import { handleResponse } from '@/services/index.js';
import { isNil } from 'lodash';
import axios from 'axios';

const api_url_module = '/users/';
const currentPath = import.meta.env.VITE_USER_BASE_URL + api_url_module;

export const userService = {
  getAll,
  get,
  searchAll,
  create,
  update,
  deleteOne,
  //delete
  importUsers,
  exportUsers,
};

/**
 * @deprecated
 */
async function getAll(queryString = '') {
  if (queryString !== '') {
    queryString = '?' + queryString;
  }
  return axios
    .get(import.meta.env.VITE_USER_BASE_URL + api_url_module + queryString)
    .then((response) => response.data.results)
    .catch(() => []);
}

async function get(id) {
  try {
    /*[GET]/users/{id} requires transform because this header is not set by default in USER service as there is two different
    transformers availables: "transformer" and "transformer BO"*/
    const response = await axios.get(
      import.meta.env.VITE_USER_BASE_URL + api_url_module + id,
      { headers: { transform: 1 } }
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function searchAll(queryString) {
  const requestOptions = {
    method: 'POST',
  };

  try {
    let response = await axios.post(
      import.meta.env.VITE_USER_BASE_URL +
        api_url_module +
        'search?' +
        queryString,
      [],
      requestOptions
    );

    // TODO update this when user service will be updated in production
    response = response.data;

    if (
      typeof response.results === 'undefined' &&
      typeof response.data !== 'undefined'
    ) {
      response = {
        status: 'success',
        results: response,
      };
    }

    return handleResponse(response);
  } catch (err) {
    return handleResponse(err);
  }
}

async function create(item) {
  const requestOptions = {
    method: 'POST',
  };

  item.source = 'bo';

  try {
    const response = await axios.post(
      import.meta.env.VITE_USER_BASE_URL + api_url_module,
      item,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function update(item, id) {
  const requestOptions = {
    method: 'PUT',
  };

  if (!isNil(item.password)) {
    item.password_confirmation = item.password;
  }

  item.source = 'bo';

  try {
    const response = await axios.put(
      import.meta.env.VITE_USER_BASE_URL + api_url_module + id,
      item,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function deleteOne(id) {
  const requestOptions = {
    method: 'DELETE',
  };

  try {
    const response = await axios.delete(
      import.meta.env.VITE_USER_BASE_URL + api_url_module + id,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function importUsers(payload, personalToken) {  
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      'personal-token': personalToken
    },
  };

  try {
    const response = await axios.post(
      import.meta.env.VITE_USER_BASE_URL + api_url_module
      + 'import',
      payload,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function exportUsers(payload, personalToken) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'personal-token': personalToken
    }
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_USER_BASE_URL + api_url_module
      + 'export',
      payload,
      requestOptions
    );

    return handleResponse(
      response.status === 204
        ? { status: 'success', results: 'ok' }
        : response.data,
      response.status
    );
  } catch (err) {
    return handleResponse(err);
  }
}