import { GenericPlatformService } from '@/services';
import { cloneDeep } from 'lodash';
import { hasDifference } from '@/utils/diffTools';
import FontFamily from '@/models/FontFamily';
const fontFamilyService = new GenericPlatformService('font-families', true);

const state = {
  all: [],
  savedOne: [],
  currentOne: [],
  loading: false,
  loadingAll: false,
};

const getters = {
  shouldSaved: (state) => hasDifference(state.currentOne, state.savedOne),
  loading: (state) => state.loading || state.loadingAll,
};

const actions = {
  getAll({ commit, state }, refresh = false) {
    if (state.all.length !== 0 && refresh === false) {
      console.debug(`Do no refresh font family list: ask by user`);
      return Promise.resolve();
    }

    commit('getAllRequest');
    return fontFamilyService.getAll().then(
      (families) => {
        commit('getAllSuccess', families);
        return Promise.resolve();
      },
      (error) => {
        commit('getAllFailure', error);
        return Promise.reject(error);
      }
    );
  },

  get({ commit, state }, payload) {
    const { id, refresh = false } = payload;

    if (refresh === false && hasDifference(state.savedOne, state.currentOne)) {
      console.debug(`Do no refresh font family ${id}: ask by user`);
      return Promise.resolve();
    }

    commit('getRequest');
    return fontFamilyService.get(id).then(
      (family) => {
        commit('getSuccess', family);
        return Promise.resolve();
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject(error);
      }
    );
  },

  /**
   * Create font family
   *
   * @param {*} param0   : font family params
   *
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  create({ commit }, params) {
    return fontFamilyService.create(params).then(
      (response) => {
        commit('getSuccess', response);
        return Promise.resolve();
      },
      (error) => {
        console.error('font family creation failed', error);
        commit('getFailure');
        return Promise.reject(error);
      }
    );
  },

  /**
   * Update font family
   *
   * @return void
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  update({ dispatch }, payload) {
    let { params, fontFamilyId } = payload;

    return fontFamilyService.update(fontFamilyId, params).then(
      () => {
        const alert = {
          id: 'font-family-updated',
          icon: 'check',
          type: 'valid',
          message: 'Votre police a bien été mise à jour.',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (error) => {
        console.error('font family update failed', error);
        const alert = {
          id: 'font-family-updated-failed',
          icon: 'close',
          type: 'error',
          message:
            "Une erreur s'est produite lors de la mise à jour de votre police.",
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },

  toggleFontFamilyPublicationAction({ state, dispatch }, payload) {
    const { params, fontFamilyId } = payload;

    console.debug('payload', payload);
    console.debug('params', params);
    console.debug('fontFamilyId', fontFamilyId);

    return fontFamilyService.update(fontFamilyId, params).then(
      () => {
        let message = 'Le status de la famille de police a été mis à jour !';
        const alert = {
          id: 'font-family-activated',
          icon: 'rocket',
          type: 'valid',
          message: message,
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (err) => {
        const alert = {
          id: 'font-family-activation-failed',
          icon: 'close',
          type: 'error',
          message:
            "Une erreur s'est produite lors de la mise  à jour du status de la famille de police.",
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },

  /**
   * Delete font family
   *
   * @return void
   * @author Jody JUSTINE <jjustine@vodfactory.com>
   */
  delete({ commit, dispatch }, fontId) {
    return fontFamilyService.delete(fontId).then(
      (font) => {
        commit('cleanFontFamilies');
        const alert = {
          id: 'font-family-removed',
          icon: 'check',
          type: 'valid',
          message: 'Votre police a bien été supprimée.',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve();
      },
      (err) => {
        const alert = {
          id: 'font-family-removed-failed',
          icon: 'close',
          type: 'error',
          message: err,
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(err);
      }
    );
  },
};

const mutations = {
  setLoading(state) {
    state.loading = true;
  },
  setLoadingAll(state) {
    state.loadingAll = true;
  },
  getRequest(state) {
    state.savedOne = [];
    state.currentOne = [];
    state.loading = true;
  },
  getAllRequest(state) {
    state.all = [];
    state.loadingAll = true;
  },
  getAllSuccess(state, items) {
    state.all = items.map((item) => new FontFamily(item));
    state.loadingAll = false;
  },
  getAllFailure(state) {
    state.all = [];
    state.loadingAll = false;
  },
  getSuccess(state, item) {
    const family = new FontFamily(item);
    state.savedOne = cloneDeep(family);
    state.currentOne = cloneDeep(family);
    state.loading = false;
  },
  getFailure(state) {
    state.savedOne = [];
    state.currentOne = [];
    state.loading = false;
  },
  getUpdateSuccess(state, item) {
    state.savedOne = cloneDeep(item);
    state.currentOne = cloneDeep(item);
    state.loading = false;
  },
  cleanFontFamilies(state) {
    state.all = [];
  },
};

export const fontFamilies = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
