/**
 * prettier
 * */
const picturesApi = {
  converted: [
    {
      id: 251,
      name: 'e50d1e845fcc408a7ca4d2bee50494c4.jpeg',
      file_name: 'e50d1e845fcc408a7ca4d2bee50494c4.jpeg',
      url: 'https://otto-static.cdn.vodfactory.com/pictures/platform/251/e50d1e845fcc408a7ca4d2bee50494c4.jpeg',
      converted: [],
      custom_properties: {
        gradient: false,
      },
      collectionName: 'posters',
    },
    {
      id: 252,
      name: '0b9cafa07773c7474c12606617cab93a.jpeg',
      file_name: '0b9cafa07773c7474c12606617cab93a.jpeg',
      url: 'https://otto-static.cdn.vodfactory.com/pictures/platform/252/0b9cafa07773c7474c12606617cab93a.jpeg',
      converted: [],
      custom_properties: {
        gradient: false,
      },
      collectionName: 'banners',
    },
    {
      id: 253,
      name: 'd6a23398894bfc236826dcbe4fe431d3.jpeg',
      file_name: 'd6a23398894bfc236826dcbe4fe431d3.jpeg',
      url: 'https://otto-static.cdn.vodfactory.com/pictures/platform/253/d6a23398894bfc236826dcbe4fe431d3.jpeg',
      converted: [],
      custom_properties: {
        gradient: false,
      },
      collectionName: 'backdrops',
    },
    {
      id: 254,
      name: '36de854e7c8f41f107c548e63e9bc7ae.jpeg',
      file_name: '36de854e7c8f41f107c548e63e9bc7ae.jpeg',
      url: 'https://otto-static.cdn.vodfactory.com/pictures/platform/254/36de854e7c8f41f107c548e63e9bc7ae.jpeg',
      converted: [],
      custom_properties: {
        gradient: false,
      },
      collectionName: 'thumbnails',
    },
    {
      id: 255,
      name: '107d1cdab0a000ffe7494923224cccda.jpeg',
      file_name: '107d1cdab0a000ffe7494923224cccda.jpeg',
      url: 'https://otto-static.cdn.vodfactory.com/pictures/platform/255/107d1cdab0a000ffe7494923224cccda.jpeg',
      converted: [],
      custom_properties: {
        gradient: false,
      },
      collectionName: 'roundeds',
    },
    {
      id: 209,
      name: '2dcddda26286a10a5f1f9c8133e4d99e.png',
      file_name: '2dcddda26286a10a5f1f9c8133e4d99e.png',
      url: 'https://otto-static.cdn.vodfactory.com/pictures/platform/209/2dcddda26286a10a5f1f9c8133e4d99e.png',
      converted: {
        orange:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/209/conversions/2dcddda26286a10a5f1f9c8133e4d99e-orange.png',
        desktop:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/209/conversions/2dcddda26286a10a5f1f9c8133e4d99e-desktop.png',
        tablet:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/209/conversions/2dcddda26286a10a5f1f9c8133e4d99e-tablet.png',
        mobile:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/209/conversions/2dcddda26286a10a5f1f9c8133e4d99e-mobile.png',
      },
      custom_properties: {
        gradient: false,
        generated_conversions: {
          main: true,
          mobile: true,
          orange: true,
          tablet: true,
          desktop: true,
        },
      },
      collectionName: 'header_logos',
    },
    {
      id: 209,
      name: '2dcddda26286a10a5f1f9c8133e4d99e.png',
      file_name: '2dcddda26286a10a5f1f9c8133e4d99e.png',
      url: 'https://otto-static.cdn.vodfactory.com/pictures/platform/209/2dcddda26286a10a5f1f9c8133e4d99e.png',
      converted: {
        orange:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/209/conversions/2dcddda26286a10a5f1f9c8133e4d99e-orange.png',
        desktop:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/209/conversions/2dcddda26286a10a5f1f9c8133e4d99e-desktop.png',
        tablet:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/209/conversions/2dcddda26286a10a5f1f9c8133e4d99e-tablet.png',
        mobile:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/209/conversions/2dcddda26286a10a5f1f9c8133e4d99e-mobile.png',
      },
      custom_properties: {
        gradient: false,
        generated_conversions: {
          main: true,
          mobile: true,
          orange: true,
          tablet: true,
          desktop: true,
        },
      },
      collectionName: 'footer_logos',
    },
    {
      id: 14,
      name: 'shadowz',
      file_name: 'shadowz.png',
      url: 'https://otto-static.cdn.vodfactory.com/pictures/platform/14/shadowz.png',
      converted: [],
      custom_properties: {
        gradient: false,
      },
      collectionName: 'square_logos',
    },
    {
      id: 34,
      name: '080293eefecc91511985b85a71e39f76.jpeg',
      file_name: '080293eefecc91511985b85a71e39f76.jpeg',
      url: 'https://otto-static.cdn.vodfactory.com/pictures/platform/34/080293eefecc91511985b85a71e39f76.jpeg',
      converted: {
        orange:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/34/conversions/080293eefecc91511985b85a71e39f76-orange.jpg',
        desktop:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/34/conversions/080293eefecc91511985b85a71e39f76-desktop.jpg',
        tablet:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/34/conversions/080293eefecc91511985b85a71e39f76-tablet.jpg',
        mobile:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/34/conversions/080293eefecc91511985b85a71e39f76-mobile.jpg',
      },
      custom_properties: {
        gradient: false,
        generated_conversions: {
          mobile: true,
          orange: true,
          tablet: true,
          desktop: true,
        },
      },
      collectionName: 'content_backdrops',
    },
    {
      id: 270,
      name: '4e101ac0de898d62a5d0725bbf3aeee5.png',
      file_name: '4e101ac0de898d62a5d0725bbf3aeee5.png',
      url: 'https://otto-static.cdn.vodfactory.com/pictures/platform/270/4e101ac0de898d62a5d0725bbf3aeee5.png',
      converted: {
        favicon16:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/270/conversions/4e101ac0de898d62a5d0725bbf3aeee5-favicon16.png',
        favicon32:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/270/conversions/4e101ac0de898d62a5d0725bbf3aeee5-favicon32.png',
        favicon128:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/270/conversions/4e101ac0de898d62a5d0725bbf3aeee5-favicon128.png',
        favicon152:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/270/conversions/4e101ac0de898d62a5d0725bbf3aeee5-favicon152.png',
        favicon167:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/270/conversions/4e101ac0de898d62a5d0725bbf3aeee5-favicon167.png',
        favicon180:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/270/conversions/4e101ac0de898d62a5d0725bbf3aeee5-favicon180.png',
        favicon192:
          'https://otto-static.cdn.vodfactory.com/pictures/platform/270/conversions/4e101ac0de898d62a5d0725bbf3aeee5-favicon192.png',
      },
      custom_properties: {
        gradient: false,
        generated_conversions: {
          favicon16: true,
          favicon32: true,
          favicon128: true,
          favicon152: true,
          favicon167: true,
          favicon180: true,
          favicon192: true,
        },
      },
      collectionName: 'favicons',
    },
    {
      id: 256,
      name: '612cc8d24efe0d274db52622c2476796.jpeg',
      file_name: '612cc8d24efe0d274db52622c2476796.jpeg',
      url: 'https://otto-static.cdn.vodfactory.com/pictures/platform/256/612cc8d24efe0d274db52622c2476796.jpeg',
      converted: [],
      custom_properties: {
        gradient: false,
      },
      collectionName: 'xl_posters',
    },
    {
      id: 853,
      name: '83213813f0162c0f487cd0a38f20e7bd.jpeg',
      file_name: '83213813f0162c0f487cd0a38f20e7bd.jpeg',
      url: 'https://otto-static.cdn.vodfactory.com/pictures/platform/853/83213813f0162c0f487cd0a38f20e7bd.jpeg',
      converted: [],
      custom_properties: {
        gradient: false,
      },
      collectionName: 'xl_thumbnails',
    },
    {
      id: 257,
      name: '6a426dad4e393de7d7ce0afaa8f95bc9.jpeg',
      file_name: '6a426dad4e393de7d7ce0afaa8f95bc9.jpeg',
      url: 'https://otto-static.cdn.vodfactory.com/pictures/platform/257/6a426dad4e393de7d7ce0afaa8f95bc9.jpeg',
      converted: [],
      custom_properties: {
        gradient: false,
      },
      collectionName: 'half_banners',
    },
    {
      id: 258,
      name: '40aa5165232d6e8c0101c8d55d608bb7.jpeg',
      file_name: '40aa5165232d6e8c0101c8d55d608bb7.jpeg',
      url: 'https://otto-static.cdn.vodfactory.com/pictures/platform/258/40aa5165232d6e8c0101c8d55d608bb7.jpeg',
      converted: [],
      custom_properties: {
        gradient: false,
      },
      collectionName: 'persons',
    },
  ],
};

export default picturesApi;
