import store from "@/store";

const state = {
  sorts: [],
  facets: [],
};

const actions = {
  initPersonsSort({ commit }) {
    commit('initPersonsSort');
  },
  initPersonsFacets({ commit }, jobs) {
    commit('initPersonsFacets', jobs);
  },
};

const mutations = {
  initPersonsSort(state) {
    state.sorts = [
      {
        id: 'date:desc',
        title: 'search.added',
      },
      {
        id: 'title:asc',
        title: 'A - Z',
      },
      {
        id: 'title:desc',
        title: 'Z - A',
      },
    ];

    if (store.getters['auth/can']('animation.beta')) {
      state.sorts.splice(1, 0, {
        id: 'generated_at:desc',
        title: 'search.indexed',
      });
    }
  },
  initPersonsFacets(state, jobs) {
    const functionsFacets = {
      title: 'search.roles',
      id: 'job_id',
      checked: false,
      cols: 'auto',
      filters: jobs.map((job) =>
        Object.assign({}, job, { facet_id: 'job_id', checked: false })
      ),
    };
    state.facets = [functionsFacets];
  },
};
export const personFilter = {
  namespaced: true,
  state,
  actions,
  mutations,
};
