export class Rental {
  constructor({
    id = 'tmp-' + Math.random().toString(36).substr(2, 16),
    active = true,
    available_minutes = 1470,
    $default = false,
    default_active = false,
    count = 0,
    interval = 'day',
    model = null,
    price_details = {
      id: undefined,
      country: {
        id: undefined,
        code: 'UNK',
      },
      currency: 'EUR',
      formatted: null,
      amount: null,
      display_full_price: null,
    },
    price_id = null,
    program_id = null,
    quality = 'SD',
  } = {}) {
    this.id = id;
    this.active = active;
    this.default_active = default_active;
    this.available_minutes = available_minutes;
    this.default = $default;
    this.interval = interval;
    this.model = model;
    this.count = count;
    this.amount = price_details?.amount ?? null;
    this.display_full_price = price_details?.display_full_price ?? null;
    this.price_details = price_details;
    this.price_id = price_id;
    this.program_id = program_id;
    this.quality = quality;
  }
}

export default Rental;
