import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/customs/';
const currentPath = import.meta.env.VITE_USER_BASE_URL + api_url_module;

export const customService = {
  getByMetadataDetails,
};

async function getByMetadataDetails(list) {
  try {
    let queryString = list.join();

    const response = await axios.get(
      import.meta.env.VITE_CONTENT_BASE_URL +
      api_url_module +
      '?metadata_detail_id=in:' +
      queryString,
      { headers: { transform: true } }
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}
