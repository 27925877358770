import facets from '@/data/transactionsFacets';

const state = {
  sorts: [],
  facets,
};

const actions = {
  initTransactionsFacets({ commit }, plans) {
    commit('initTransactionsFacets', plans);
  },
  initTransactionsSort({ commit }) {
    commit('initTransactionsSort');
  },
};

const mutations = {
  initTransactionsFacets(state, plans) {
    const plansFacets = {
      title: 'users.pages.users.plans.title',
      id: 'plans',
      checked: false,
      cols: 'auto',
      filters: plans.map((plan) =>
        Object.assign({}, plan, {
          facet_id: 'plans',
          checked: false,
          title: plan.name,
        })
      ),
    };

    if (state.facets[1]) {
      state.facets.splice(1);
    }
    state.facets.push(plansFacets);
  },
  initTransactionsSort(state) {
    state.sorts = [
      {
        id: 'date:desc',
        title: 'search.sort.date.desc',
      },
      {
        id: 'date:asc',
        title: 'search.sort.date.asc',
      },
    ];
  },
};

export const transactionFilter = {
  namespaced: true,
  state,
  actions,
  mutations,
};
