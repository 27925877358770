import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/program-metadatas/';

export const programMetadataService = {
  createProgramMetadata,
  deleteProgramMetadata,
};

async function createProgramMetadata(programMetadata) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module,
      programMetadata,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function deleteProgramMetadata(id) {
  const requestOptions = {
    method: 'DELETE',
  };

  try {
    const response = await axios.delete(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module + id,
      requestOptions
    );
    return handleResponse(response);
  } catch (err) {
    return handleResponse(err);
  }
}
