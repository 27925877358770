<template>
  <section class="page-content flex flex-auto flex-col gap-4 md:gap-6">
    <slot />
  </section>
</template>
<script>
export default {
  name: 'PageContent',
};
</script>
