import { createI18n } from 'vue-i18n';
import messages from '@intlify/vite-plugin-vue-i18n/messages';

const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
    },
  },
  'fr-FR': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      useGrouping: true,
      notation: 'standard',
      currencyDisplay: 'symbol',
    },
  },
  'it-IT': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      useGrouping: true,
      notation: 'standard',
      currencyDisplay: 'symbol',
    },
  },
  'es-ES': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      useGrouping: true,
      notation: 'standard',
      currencyDisplay: 'symbol',
    },
  },
  'pt-PT': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      useGrouping: true,
      notation: 'standard',
      currencyDisplay: 'symbol',
    },
  },
};
const datetimeFormats = {
  'en-US': {
    tiny: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
    tooltipDay: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
    tooltipMonth: {
      year: 'numeric',
      month: 'long',
    },
    tooltipYear: {
      year: 'numeric',
    },
  },
  'fr-FR': {
    tiny: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
    tooltipDay: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
    tooltipMonth: {
      year: 'numeric',
      month: 'long',
    },
    tooltipYear: {
      year: 'numeric',
    },
  },
  'it-IT': {
    tiny: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
    tooltipDay: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
    tooltipMonth: {
      year: 'numeric',
      month: 'long',
    },
    tooltipYear: {
      year: 'numeric',
    },
  },
  'es-ES': {
    tiny: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
    tooltipDay: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
    tooltipMonth: {
      year: 'numeric',
      month: 'long',
    },
    tooltipYear: {
      year: 'numeric',
    },
  },
  'pt-PT': {
    tiny: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
    tooltipDay: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
    tooltipMonth: {
      year: 'numeric',
      month: 'long',
    },
    tooltipYear: {
      year: 'numeric',
    },
  },
};

export default createI18n({
  allowComposition: true,
  legacy: true,
  locale: import.meta.env.VITE_I18N_LOCALE || 'fr',
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'fr',
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  missingWarn: false,
  fallbackWarn: false,
  formatFallbackMessages: false,
  messages: messages,
  globalInjection: true,
  numberFormats,
  datetimeFormats,
});
