import Movie from '@/models/Movie';
import Picture from '@/models/Picture';

export class Selection {
  constructor({
    id,
    title,
    slug,
    status = 'online',
    description,
    programs = [],
    style = {},
    display_title = false,
    display_count = false,
    display_catalog = false,
    count_online = 0,
    rank = 0,
    picture = {},
    generated_at = null,
  } = {}) {
    this.id = id;
    this.title = title;
    this.slug = slug;
    this.status = status;
    this.rank = rank;
    this.count_online = count_online;
    this.description = description;
    this.display_title = display_title;
    this.display_count = display_count;
    this.display_catalog = display_catalog;
    this.setStyle(style);
    this.setPrograms(programs);
    this.setPicture(picture);
    this.generated_at = generated_at;
    this.type = 'selection';
  }
  setStyle(style) {
    this.style = {};
    this.style.block_type_name = style.block_type_name ?? null;
    this.style.with_duration = style.with_duration ?? true;
    this.style.with_title = style.with_title ?? true;
    var metadatas = [];
    var brutMetadatas = style.metadatas ?? [];
    brutMetadatas.forEach(function (brutMetadata) {
      if (typeof brutMetadata.id !== 'undefined') {
        var metadata = {};
        metadata.id = brutMetadata.id;
        metadata.type = brutMetadata.type ?? 'metadata';
        metadata.textOptions = {};
        metadata.textOptions.italic = brutMetadata.text_options.italic ?? false;
        metadata.textOptions.bold = brutMetadata.text_options.bold ?? false;
        metadata.textOptions.fontSize =
          brutMetadata.text_options.font_size ?? 'font-size-md';
        metadatas.push(metadata);
      }
    });
    this.style.metadatas = metadatas;
  }
  setPicture(picture) {
    this.picture = Picture.getDefaultPicture('selection');
    const pictureTypes = Picture.getDefaultPictureTypesList('selection');
    if (typeof picture === 'object') {
      pictureTypes.forEach((pictureType) => {
        this.picture[pictureType] = picture[pictureType] ?? [];
      });
    } else {
      pictureTypes.forEach((pictureType) => {
        this.picture[pictureType] = [];
      });
    }
  }
  setPrograms(programs) {
    this.programs = [];
    for (var nb = 0; nb < programs.length; nb++) {
      var movie = new Movie(programs[nb]);
      this.programs.push(movie);
    }
  }
}

export function createSelection(data) {
  return Object.freeze(new Selection(data));
}

export default Selection;
