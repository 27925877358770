import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/transactions/';
const currentPath = import.meta.env.VITE_BILLING_BASE_URL + api_url_module;

export const transactionService = {
  getUserTransactions,
  getTransaction,
  refund,
};

async function getUserTransactions(userId, queryString = '') {
  try {
    if (queryString !== '') {
      queryString = '?' + queryString;
    }

    const response = await axios.get(
      currentPath + 'users/' + userId + queryString
    );
    return handleResponse(response.data);
  } catch (err) {
    console.log(err);
    return handleResponse(err);
  }
}

async function getTransaction(id) {
  try {
    const response = await axios.get(currentPath + id);
    return handleResponse(response.data);
  } catch (err) {
    console.log(err);
    return handleResponse(err);
  }
}

async function refund(
  charge_id,
  amount,
  reason = 'requested_by_customer',
  note = null
) {
  const requestOptions = {
    method: 'POST',
  };

  let item = {
    charge_id: charge_id,
    amount: amount,
    reason: reason,
    note: note,
  };

  try {
    const response = await axios.post(currentPath, item, requestOptions);
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}
