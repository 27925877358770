<template>
  <Page data-cy="home">
    <template #title>
      <h1 class="page-title text-skin-base">
        {{ $t('home.title') }}
      </h1>
    </template>
    <template #content>
      <Card class="px-0" 
        v-if="hasAccess([
          'animation.home.access',
          'catalogue.index.create',
          'catalogue.video.create',
          'animation.selection.create'
        ])"
      >
        <div class="flex justify-between divide-x divide-skin-ui divide-solid">
          <div class="flex flex-1 justify-center"
            v-can="'animation.home.access'"
          >
            <PictoButton
              icon="home-flat"
              action-icon="cursor-flat"
              @click="goToTemplatePage('home', 'home')"
            >
              <span v-html="$t('home.nav.animateHome')" />
            </PictoButton>
          </div>
          <div class="flex flex-1 justify-center"
            v-can="'catalogue.index.create'"
          >
            <PictoButton
              icon="movie-flat"
              action-icon="add-circle"
              action-color="success"
              @click="
                showModal({
                  componentName: 'ModalAddMovie',
                  size: 'md',
                })
              "
            >
              <span v-html="$t('home.nav.createProgram')" />
            </PictoButton>
          </div>
          <div class="flex flex-1 justify-center"
            v-can="'catalogue.video.create'"
          >
            <PictoButton
              icon="video-flat"
              action-icon="add-circle"
              action-color="success"
              @click="showModalVideosUpload()"
            >
              <span v-html="$t('home.nav.addVideo')" />
            </PictoButton>
          </div>
          <div class="flex flex-1 justify-center"
            v-can="'animation.selection.create'"
          >
            <PictoButton
              icon="selection-flat"
              action-icon="add-circle"
              action-color="success"
              @click="
                showModal({
                  componentName: 'ModalAddSelection',
                  size: 'md',
                })
              "
            >
              <span v-html="$t('home.nav.createSelection')" />
            </PictoButton>
          </div>
        </div>
      </Card>
      <DashboardHome v-can="'stats.access'" />
      <Card class="theme-teal relative">
        <template #title>
          {{ $t('home.help.title') }}
        </template>
        <div class="text-sm font-light lg:pr-[460px]">
          <p class="whitespace-pre-line mb-4">
            {{ $t('home.help.text1') }}
          </p>
          <BaseButton class="mb-4">
            <a
              href="https://www.vodfactory.com/otto-academy/home"
              class="w-full h-full grid place-items-center"
              target="_blank"
            >
              {{ $t('home.help.link') }}
            </a>
          </BaseButton>
          <p>{{ $t('home.help.text2') }}</p>
        </div>
        <img
          height="300"
          :src="homeImageHelper"
          class="lg:block align-center absolute right-0 bottom-0 max-h-full opacity-5 lg:opacity-100 transition-opacit lg:max-w-[70%] pointer-events-none"
        />
      </Card>
    </template>
  </Page>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapMutations, mapGetters } from 'vuex';
import BaseButton from '@/components/button/BaseButton.vue';
import Card from '@/components/card/Card.vue';
import DashboardHome from '@/components/dashboard/DashboardHome.vue';
import Icon from '@/components/icon/Icon.vue';
import Page from '@/components/page/Page.vue';
import PictoButton from '@/components/button/PictoButton.vue';
import animationMixin from '@/mixins/animationMixin';

export default {
  name: 'Home',
  components: {
    DashboardHome,
    Icon,
    BaseButton,
    Page,
    Card,
    PictoButton,
  },
  mixins: [animationMixin],
  computed: {
    homeImageHelper() {
      return this.assetUrl('home-help.png');
    },
    ...mapFields(['platforms.currentPlatform']),
    ...mapGetters({
      hasAccess: 'auth/hasAccess',
    }),
  },
  created() {
    if ('platformID' in this.$route.query && this.$route.query.platformID) {
      this.setSelectedPlatformID(this.$route.query.platformID);
    } else {
      this.setSelectedPlatformID(parseInt(localStorage.getItem('platform')));
    }
  },
  methods: {
    getPlatformLogo(currentPlatform = '') {
      let platform = '';
      if (currentPlatform !== '') {
        platform = currentPlatform;
      } else if (typeof this.currentPlatform === 'object') {
        platform = this.currentPlatform;
      }
      if (
        platform &&
        'picture' in platform &&
        'header_logos' in platform.picture &&
        platform.picture.header_logos.length > 0
      ) {
        return platform.picture.header_logos[0].url;
      }
      return false;
    },
    ...mapMutations('platforms', ['setSelectedPlatformID']),
    ...mapMutations(['showModal', 'showModalVideosUpload']),
  },
};
</script>
