export class Font {
  constructor({
    id = null,
    related_id = null,
    family_id = 0,
    label = null,
    title = null,
    style = 'normal',
    weight = 'normal',
    display = 'swap',
    stretch = null,
    variant = null,
    available = false,
    status = 'incomplete',
    details = {},
  } = {}) {
    this.id = id,
    this.related_id = related_id,
    this.family_id = family_id,
    this.label = label
    this.title = title
    this.style = style
    this.weight = weight
    this.display = display
    this.stretch = stretch
    this.variant = variant
    this.available = available
    this.status = status
    this.details = details
  }
}

export function createFont(data) {
  return Object.freeze(new Font(data));
}

export default Font