import { GenericContentService } from '@/services';
import { difference } from '@/utils/diffTools';
import { isNil } from 'lodash';

export default {

  resetAll({ commit }) {
    commit('fullCleanMetadataDetailStates');
    return Promise.resolve();
  },

  async refreshAll({ commit }) {
    return await new GenericContentService('metadata-details')
      .getAll()
      .then(
        (response) => {
          commit('setAll', response);
          return Promise.resolve(response);
        },
        (error) => commit('setAll', [])
      );
  },

  async getAll({ commit, dispatch, state }, params = {}) {
    let queryString = '';
    if (state?.all.length > 0 && !params?.force) {
      return Promise.resolve(state.all);
    }
    if (params?.all_group_environments) {
      queryString = 'all_group_environments=1';
    }
    commit('resetAll');
    return new GenericContentService('metadata-details').getAll(queryString).then(
      (metadataDetails) => {
        commit('setAll', metadataDetails);
        if (params.currentMetadataDetailId) {
          commit(
            'setCurrentNewMetadataDetailsById',
            params.currentMetadataDetailId
          );
        }
        return Promise.resolve(metadataDetails);
      },
      (error) => {
        commit('setAll', []);
        return Promise.reject(error);
      }
    );
  },

  async get({ commit, dispatch, state }, id) {
    if (state.currentNewMetadataDetail?.id == id) {
      return Promise.resolve(state.currentNewMetadataDetail);
    }
    commit('setLoading', true);
    commit('resetNewMetadataDetail');
    return new GenericContentService('metadata-details').get(id).then(
      (metadatasDetails) => {
        commit('getSuccess', metadatasDetails);
        return Promise.resolve(metadatasDetails);
      },
      (error) => {
        commit('getFailure', error);
        return Promise.reject(error);
      }
    );
  },

  async create({ commit, dispatch, rootState, state }, params) {
    commit('createRequest', { allMetadatas: rootState.references.all.metadata, params });
    return new GenericContentService('metadata-details')
      .create(state.formatedCurrentNewMetadataDetail)
      .then(
        (metadataDetail) => {
          commit('cleanMetadataDetailStates');
          commit('createSuccess', metadataDetail);
          const alert = {
            id: 'metadata-detail-well-created',
            icon: 'check',
            type: 'valid',
            message: 'notifications.create.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(metadataDetail);
        },
        (error) => {
          commit('setLoading', false);
          if (
            error.errors.error.indexOf('already been created') > -1 ||
            error.errors.error.indexOf('already exists') > -1
          ) {
            commit('setError', {
              location: 'title',
              message: 'error.metadata.title',
            });
          } else {
            let message =
              error.errors.error ??
              error.errors.public ??
              'notifications.create.error';
            if (message !== null) {
              const alert = {
                id: 'metadata-detail-not-created',
                icon: 'close',
                type: 'error',
                message: message,
              };
              dispatch('displayAlert', alert, { root: true });
            }
          }
          return Promise.reject(error);
        }
      );
  },

  async update({ commit, dispatch, state }) {
    if (!state.loading) {
      commit('putRequest');
      return new GenericContentService('metadata-details')
        .update(
          state.formatedCurrentNewMetadataDetail,
          state.currentNewMetadataDetail.id
        )
        .then(
          (metadataDetail) => {
            commit('cleanMetadataDetailStates');
            commit('putSuccess', metadataDetail);
            const alert = {
              id: 'metadata-detail-not-updated',
              icon: 'check',
              type: 'valid',
              message: 'notifications.update.success',
            };
            dispatch('displayAlert', alert, { root: true });
            return Promise.resolve(metadataDetail);
          },
          (error) => {
            commit('setLoading', false);
            const alert = {
              id: 'metadata-detail-not-updated',
              icon: 'close',
              type: 'error',
              message: 'notifications.update.error',
            };
            dispatch('displayAlert', alert, { root: true });
            return Promise.reject(error);
          }
        );
    }
  },

  updateAll({ getters, state }) {
    let promiseList = [];

    if (getters.allToUpdate.length <= 0) {
      return Promise.resolve();
    }

    for (let i = 0; i < getters.allToUpdate.length; i++) {
      let element = getters.allToUpdate[i];

      let filtered = difference(element, getters.savedItem(element.id));
      let retval = new GenericContentService('metadata-details')
        .update(filtered, element.id)
        .then(
          (response) => {
            console.log('element update response');
          },
          (error) => {
            console.log('element update error');
          }
        );
      promiseList.push(retval);
    }

    return Promise.all(promiseList).then(function (values) {
      return Promise.resolve(values);
    });
  },

  async updateAllRank({ commit, dispatch, state }, newValues) {
    commit('updateAll', newValues);
    commit('putAllRequest', newValues);
    return new GenericContentService('metadata-details/rank')
      .create(state.formattedAll)
      .then(
        (success) => {
          commit('setLoading', false);
          const alert = {
            id: 'metadata-well-ranked',
            icon: 'check',
            type: 'valid',
            message: 'notifications.update.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve();
        },
        (error) => {
          commit('putAllFailure');
          const alert = {
            id: 'metadata-not-ranked',
            icon: 'close',
            type: 'error',
            message: 'notifications.update.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },
  remove({ commit, dispatch, state }) {
    commit('setLoading', true);
    return new GenericContentService('metadata-details')
      .delete(state.currentNewMetadataDetail.id)
      .then(
        (metadataDetail) => {
          commit('removeSuccess');
          const alert = {
            id: 'metadata-well-deleted',
            icon: 'check',
            type: 'valid',
            message: 'notifications.delete.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(metadataDetail);
        },
        (error) => {
          commit('setLoading', false);
          const alert = {
            id: 'metadata-not-deleted',
            icon: 'close',
            type: 'error',
            message: 'notifications.delete.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },

  /******************************************************************************************** OPTIONS ********************************************************************************************/
  searchOptions({ commit, state }, params) {
    commit('setSearchLoading', true);
    const foundCurrent = state.all.find(
      (x) => x.id === params.metadataDetailId
    );
    const current = !isNil(foundCurrent)
      ? foundCurrent
      : state.currentNewMetadataDetail;
    if (typeof current === 'undefined') {
      commit('resetOptions');
      return Promise.reject();
    }
    const metadataApi = current.endpoint;
    let searchParams =
      'count=' + params.count +
      '&page=' + (params?.page ?? '1') +
      '&sort=' + (params?.sort ?? 'title:asc') +
      (metadataApi === 'customs' ? '&metadata_detail_id=' + current.id : '');

    if (params?.keyWord && params.keyWord !== '') {
      searchParams += '&query=' + params.keyWord
    }
    if (metadataApi) {
      return new GenericContentService(metadataApi)
        .search(null, searchParams)
        .then(
          (metadataDetails) => {
            commit('searchOptionsSuccess', metadataDetails);
            commit('updateReload', false);
            return Promise.resolve(metadataDetails);
          },
          (error) => {
            commit('resetOptions');
            return Promise.reject(error);
          }
        );
    }
  },

  async createOption({ commit, dispatch, getters, state }) {
    commit('createOptionRequest');
    const endpoint = state.currentNewMetadataDetail.endpoint;
    return new GenericContentService(endpoint)
      .create(state.formattedOption)
      .then(
        async (option) => {
          commit('createOptionSuccess', option);
          // Refresh specific metas list
          switch (endpoint) {
            case 'customs':
              let metadataListid = [];
              getters.allActivated.forEach((meta) => {
                if (meta.metadataType === 'custom') {
                  metadataListid.push(meta.id);
                }
              });
              await dispatch('customs/getAll', metadataListid, { root: true });
              break;
            case 'categories':
            case 'gears':
            case 'levels':
            case 'rating-levels':
            case 'tags':
            case 'versions':
              await dispatch(endpoint + '/getAll', true, { root: true });
              break;
          }
          const alert = {
            id: 'metadata-well-created',
            icon: 'check',
            type: 'valid',
            message: 'notifications.create.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(option);
        },
        (error) => {
          commit('createOptionFailure', error);
          let message = null;
          switch (error.status) {
            case 409:
              message = 'error.metadata.exists';
              break;
            case 422:
              message = 'error.metadata.incomplete';
              break;
            default:
              message = 'notifications.create.error';
              break;
          }
          const alert = {
            id: 'metadata-not-created',
            icon: 'close',
            type: 'error',
            message: message,
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },
  getOption({ commit, state }, { id, withCount }) {
    commit('setOption');
    if (!(state.currentNewMetadataDetail.endpoint &&
      state.currentNewMetadataDetail.hasListValues)
    ) {
      return Promise.reject();
    }
    commit('setLoading', true);
    return new GenericContentService(
      state.currentNewMetadataDetail.endpoint
    )
      .get(id)
      .then(
        (option) => {
          commit('setOption', option);
          if (withCount) {
            let label = state.currentNewMetadataDetail.label;
            return new GenericContentService('programs')
              .search('', 'count=1&' + label + '=' + id)
              .then(
                (result) => {
                  commit(
                    'setOptionsLinkedContentCount',
                    result?.meta?.pagination?.total
                  );
                  return Promise.resolve(option);
                },
                (error) => {
                  commit('setOptionsLinkedContentCount', -1);
                  return Promise.reject(error);
                }
              );
          }
          commit('setLoading', false);
          return Promise.resolve(option);
        },
        (error) => {
          commit('setOption');
          commit('setLoading', false);
          return Promise.reject(error);
        }
      );
  },
  async updateOption({ commit, dispatch, state }) {
    commit('updateOptionRequest');
    return new GenericContentService(
      state.currentNewMetadataDetail.endpoint
    )
      .update(state.formattedOption, state.savedOption.id)
      .then(
        (metadatas) => {
          commit('updateOptionSuccess', metadatas);
          const alert = {
            id: 'metadata-well-created',
            icon: 'check',
            type: 'valid',
            message: 'notifications.update.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(metadatas);
        },
        (error) => {
          commit('updateOptionFailure', error);
          let message = null;
          switch (error.status) {
            case 409:
              message = 'error.metadata.title';
              break;
            default:
              message = 'notifications.create.error';
              break;
          }
          const alert = {
            id: 'metadata-not-created',
            icon: 'close',
            type: 'error',
            message: message,
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },
  removeOption({ commit, dispatch, state }) {
    commit('removeOptionRequest');
    return new GenericContentService(
      state.currentNewMetadataDetail.endpoint
    )
      .delete(state.savedOption.id)
      .then(
        (metadataDetail) => {
          commit('removeOptionSuccess');
          const alert = {
            id: 'metadata-option-well-deleted',
            icon: 'check',
            type: 'valid',
            message: 'notifications.delete.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(metadataDetail);
        },
        (error) => {
          commit('removeOptionFailure');
          const alert = {
            id: 'metadata-option-not-deleted',
            icon: 'close',
            type: 'error',
            message: 'notifications.delete.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },

  searchPersonTypes({ commit }, params) {
    commit('setSearchLoading', true);
    const searchParams =
      'count=' +
      params.count +
      '&page=' +
      (typeof params.page !== 'undefined' ? params.page : '1') +
      '&sort=' +
      (typeof params.sort !== 'undefined' ? params.sort : 'title:asc');
    return new GenericContentService('person-types')
      .search(null, searchParams)
      .then(
        (metadataDetails) => {
          commit('searchPersonTypeSuccess', metadataDetails);
          return Promise.resolve(metadataDetails);
        },
        (error) => {
          commit('searchPersonTypeFailure');
          return Promise.reject(error);
        }
      );
  },
  createPersonTypes({ commit, dispatch, state }, input) {
    commit('createPersonTypeRequest');
    return new GenericContentService('person-types')
      .create(state.formattedRole)
      .then(
        (personType) => {
          commit('createPersonTypeSuccess', personType);
          const alert = {
            id: 'metadata-well-created',
            icon: 'check',
            type: 'valid',
            message: 'notifications.create.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(personType);
        },
        (error) => {
          commit('createPersonTypeFailure', error);
          const alert = {
            id: 'metadata-not-created',
            icon: 'close',
            type: 'error',
            message: 'notifications.create.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },
  updatePersonTypes({ commit, dispatch, state }) {
    commit('updatePersonTypeRequest');
    return new GenericContentService('person-types')
      .update(state.formattedRole, state.formattedRole.id)
      .then(
        (personType) => {
          commit('updatePersonTypeSuccess', personType);
          const alert = {
            id: 'metadata-well-created',
            icon: 'check',
            type: 'valid',
            message: 'notifications.update.success',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.resolve(personType);
        },
        (error) => {
          commit('updatePersonTypeFailure', error);
          const alert = {
            id: 'metadata-not-created',
            icon: 'close',
            type: 'error',
            message: 'notifications.update.error',
          };
          dispatch('displayAlert', alert, { root: true });
          return Promise.reject(error);
        }
      );
  },
  removePersonTypes({ commit, dispatch }, id) {
    commit('removePersonTypeRequest');

    return new GenericContentService('person-types').delete(id).then(
      (metadataDetail) => {
        commit('removePersonTypeSuccess', id);
        const alert = {
          id: 'metadata-option-well-deleted',
          icon: 'check',
          type: 'valid',
          message: 'notifications.delete.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(metadataDetail);
      },
      (error) => {
        commit('removePersonTypeFailure');
        const alert = {
          id: 'metadata-option-not-deleted',
          icon: 'close',
          type: 'error',
          message: 'notifications.delete.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },

  /**************************************************************************************** RELATED PROGRAMS ***************************************************************************************/
  searchPrograms({ commit, state }, params) {
    if (!state.currentNewMetadataDetail.label) {
      return Promise.resolve();
    }
    commit('setSearchLoading', true);
    const searchParams =
      'count=' +
      params.count +
      '&page=' +
      (params?.page ? params.page : '1') +
      '&sort=' +
      (params?.sort ? params.sort : 'title:asc') +
      (params?.keyWord && params?.keyWord != ''
        ? '&query=' + params.keyWord : '') +
      '&' +
      state.currentNewMetadataDetail.label +
      '=in:' +
      params.optionId +
      '&format=in:1,2';
    return new GenericContentService('programs')
      .search(null, searchParams)
      .then(
        (programs) => {
          commit('searchProgramsSuccess', programs);
          return Promise.resolve(programs);
        },
        (error) => {
          commit('resetSearchPrograms');
          return Promise.reject(error);
        }
      );
  },
  updateRelatedPrograms({ commit, state }) {
    commit('updateRelatedProgramsRequest');

    return new GenericContentService('program-metadatas/update-many')
      .create(state.formatedRelatedPrograms)
      .then(
        (programs) => {
          commit('updateRelatedProgramsSuccess');
          return Promise.resolve(programs);
        },
        (error) => {
          commit('setsearchLoading', false);
          return Promise.reject(error);
        }
      );
  },
};