import * as d3 from 'd3';
import { cloneDeep } from 'lodash';

const formatToObject = function ([name, children]) {
  const obj = {
    name,
  };
  if (Array.isArray(children)) {
    obj.value = d3.sum(children.map((c) => c.total));
  } else {
    obj.children = Array.from(children, formatToObject);
    obj.total = d3.sum(
      obj.children.map((c) => {
        return c.children ? c.total : c.value;
      })
    );
  }
  return obj;
};

export default {
  all: (state) => {
    if (!state.all) {
      return [];
    }
    return state.all;
  },
  formattedAll: (state, getters) => {
    if (!getters.all) {
      return [];
    }
    return Object.keys(getters.all)
      .sort()
      .map((key) => {
        const obj = {
          date: getters.all?.[key]?.[0]?.date ?? key,
          name: getters.all?.[key]?.[0]?.date ?? key,
          children: getters.all[key],
        }
        return obj
      });
  },
  existingFiltersIds: (state) => {
    return state.selectedFilters.map((selected) => selected.id);
  },
  unselectedFilters: (state, getters, rootState) => {
    return getters.kpiFilters.filter(
      (filter) => !getters.existingFiltersIds.includes(filter.id)
    );
  },
  kpiFilters: (state, getters, rootState) => {
    const currentPlatformId = rootState.platforms.currentPlatform?.id
    return state.filters.filter(
      (filter) =>
        !filter?.platform_id || filter?.platform_id === currentPlatformId
    );
  },
  canAddFilter: (state, getters) => {
    return getters.unselectedFilters.length > 0;
  },
  barChartType: (state, getters) => {
    return getters.groups.length > 0 ? 'stack' : 'bar';
  },
  valueKey: (state, getters) => {
    return getters.groups.length > 1 ? 'total' : 'value';
  },
  groups: (state, getters) => {
    return getters.filters.map((filter) => {
      const key = Object.keys(filter)[0];
      return (d) => d[key];
    });
  },
  filters: (state) => {
    return state.selectedFilters.map((f) => {
      return { [f.id]: f.value };
    });
  },
  sunburstData: (state, getters) => {
    if (getters.filteredAll.length && state.selectedDate) {
      return {
        name: state.id,
        children: getters.filteredAll.find(
          (obj) => obj.name === state.selectedDate
        ).children,
      };
    } else {
      return null;
    }
  },
  delta: (state, getters) => {
    const items = getters.sortedAll;
    return items.length === 0
      ? 0
      : items[items.length - 1].value - items[0].value;
  },
  toDate: (state, getters) => {
    const items = cloneDeep(getters.sortedAll).filter((item) => item.value != 0);
    return items?.length > 0
      ? items?.[items.length - 1]?.value
      : 0;
  },
  cumulative: (state, getters) => {
    return getters.sortedAll.reduce((total, item) => {
      total += item.value ?? 0;
      return total;
    }, 0);
  },

  cumulativeOptions: (state, getters) => {
    const filters = state.selectedFilters;
    const options = filters?.[0]?.options ?? [];
    const filterValue = filters?.[0]?.value ?? [];
    let values = [];
    if (filterValue.includes('all') &&
      filters?.[0]?.showAll
    ) {
      values = options.filter(o => o.id !== 'all')
        .map(o => o.id)
    } else if (!filterValue.includes('all')) {
      values = filterValue;
    }

    return getters.sortedAll.reduce((total, item) => {
      if (filters.length > 0 &&
        values.length > 0
      ) {
        values.map((itemId) => {
          total += item?.value?.[itemId] ?? 0;
        });
        return total;
      } else if (typeof item.value === 'number') {
        total += item.value ?? 0;
        return total;
      } else {
        return 0;
      }
    }, 0);
  },
  chartData: (state, getters) => {
    return getters.sortedAll;
  },
  dateAttribute: (state, getters) => {
    switch (getters.granularity) {
      case 'day':
        return 'date';
      case 'week':
        return 'date';
      case 'month':
        return 'date';
      case 'year':
        return 'date';
      default:
        return 'date';
    }
  },
  timeParseVal: (state, getters) => '%Y-%m-%d',
  timeFormatVal: (state, getters) => {
    switch (getters.granularity) {
      case 'day':
        return '%d/%m';
      case 'week':
        return 'S%W';
      case 'month':
        return '%b %y';
      case 'year':
        return '%Y';
      default:
        return '%d/%m';
    }
  },
  granularity: (state, getters, rootState) => {
    return rootState.kpis.granularity;
  },
  sortedAll: (state, getters) => {
    const parseTime = d3.timeParse(getters.timeParseVal);
    const data = getters.filteredAll.map((d) => {
      const value = d.children
        ? d.children.reduce((prevVal, currVal) => {
          return prevVal + currVal[getters.valueKey];
        }, 0)
        : d.value ?? 0;

      let dateName;
      if (typeof d.name !== 'undefined') {
        switch (getters.timeFormatVal) {
          case 'day':
            dateName = d.name;
            break;
          case 'week':
            dateName = d.name;
            break;
          case 'month':
            dateName = d.name.split('-').splice(0, 2).join('-');
            break;
          case 'year':
            dateName = d.name.split('-').splice(0, 1).join();
            break;
        }
      }
      const formattedDate = d3.timeFormat(getters.timeFormatVal)(
        parseTime(d.name)
      );
      const obj = {
        date: d.date,
        name: formattedDate,
        value,
      };
      if (d.children) {
        d.children.forEach((child) => {
          obj[child.name] = child[getters.valueKey];
        });
      }
      return obj;
    });
    return [...data];
  },
  summary: (state, getters, rootState) => {
    const start = rootState.kpis.startDateId;
    const end = rootState.kpis.endDateId;
    const {
      id,
      title,
      tooltip,
      label,
      color,
      icon,
      iconColor,
      action,
      actionColor,
      illustration,
      valueType,
    } = state;
    return {
      id,
      type: 'summary',
      title,
      tooltip,
      label,
      color,
      icon,
      iconColor,
      action,
      actionColor,
      illustration,
      valueType,
      value: getters.summaryValue,
      evolution: 'equal',
      evolution_value: 0,
      footer: getters.summaryHasFooter,
      start,
      end,
      show: getters.showChart
    };
  },
  showChart: (state) => {
    const filters = state.selectedFilters
      .filter(f => f.form === 'select' && !f?.withAll && f.value.length === 0)
    return (
      state.loading ||
      state.selectedFilters.length === 0 ||
      state.selectedFilters.length !== filters.length
    )
  },
  tableData: (state, getters) => {
    return {
      type: 'table',
      color: state.color,
      data: state.all.data,
      meta: state.all.meta,
      summary: getters.summary,
      filters: state.selectedFilters,
      canAddFilter: (
        getters.kpiFilters?.length > 0 &&
        getters.kpiFilters?.length > state.selectedFilters?.length
      )
    }
  },
  rowData: (state, getters) => {
    return {
      color: state.color,
      summary: getters.summary,
      filters: state.selectedFilters,
      data: getters.chartData,
      canAddFilter: getters.canAddFilter,
      type: getters.barChartType,
      valueKey: getters.valueKey,
      sunburst: {
        date: state.selectedDate,
        data: getters.sunburstData,
      },
    };
  },
};
