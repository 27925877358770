import BillingUtils from '@/utils/BillingUtils';
import Picture from '@/models/Picture';

export class Plan {
  id;
  billing_plan_id; // no rules
  group_plan_id;
  platform_id; // no rules
  stripe_id; // no rules
  wha_code; // rule ok

  active; // no rules
  devices; // no rules
  rank; // no rules
  status; // no rules
  type;

  full_name; //still used ?
  name; // rule ok
  title; // rule ok

  arguments;
  description;
  description_confirmation;
  description_promotion;
  picture; // no rules

  has_trial_period;
  interval; // rule ok
  interval_count; // rule ok
  trial_period_days; // rule ok

  display_price; // no rules
  price; // rule ok
  price_android; // rule ok
  price_apple; // rule ok
  currency_code; // no rules
  currency; // no rules
  display_price_with_currency; // no rules

  recurrent; // rule ok
  group_plan; // no rules

  orange_reduction_type; // no rules
  template_post_subscription; // rule ok
  template_home; // rule ok

  orange_reduction_amount; // rule ok
  orange_reduction_cycle_count; // rule ok

  front_display; // no rules

  constructor({
    id = undefined,
    billing_plan_id = null,
    group_plan_id = null,
    platform_id = null,
    stripe_id = null,
    wha_code = null,

    active = false,
    // devices = [], // locally calculated value
    rank = 9999,
    // status = [], // locally calculated value
    type = 'stripe',

    full_name = null,
    name = null,
    title = null,

    //arguments" = ['Résiliable en 1 clic'], // field named arguments does not work
    description = null,
    description_confirmation = null,
    description_promotion = null,
    picture = { thumbnails: [] },

    //has_trial_period = true, // locally calculated value
    interval = 'month',
    interval_count = 1,
    trial_period_days = 0,

    display_price = null,
    price = 50,
    price_android = 50,
    price_apple = null,
    currency_code = null,
    currency = null,
    display_price_with_currency = null,
    display_price_android_with_currency = null,
    display_price_apple_with_currency = null,

    recurrent = 1,
    group_plan = {
      access_type: 'complete',
      id: null,
      name: null,
      simultaneous_watch: null,
    },

    template_home = 'home',
    template_post_subscription = 'home',

    cycle_count = 1,
    amount_discount = null,
    front_display = 0,
  }) {
    this.id = id;
    this.billing_plan_id = billing_plan_id;
    this.platform_id = platform_id;
    this.stripe_id = stripe_id;
    this.wha_code = wha_code;

    this.active = active;
    this.setDevices(type);
    this.rank = rank;
    this.status = this.active ? 'online' : 'offline';
    this.type = type;

    this.full_name = full_name;
    this.name = name ?? title;
    this.title = title ?? name;

    this.arguments = arguments[0].arguments ?? ['Résiliable en 1 clic'];
    if (this.arguments[0] === null) {
      this.arguments = ['Résiliable en 1 clic'];
    }
    this.description = description ?? null;
    this.description_confirmation = description_confirmation ?? null;
    this.description_promotion = description_promotion;
    this.setPicture(picture);

    this.has_trial_period =
      trial_period_days !== undefined && trial_period_days > 0;
    this.interval = interval;
    this.interval_count = interval_count;
    this.trial_period_days = trial_period_days;

    this.display_price = display_price;
    this.price = price;
    this.price_android = price_android ?? price;
    this.price_apple = price_apple === null ? 49 : price_apple;
    this.currency_code = currency_code;
    this.currency = currency;
    this.display_price_with_currency = display_price_with_currency;
    this.display_price_android_with_currency =
      display_price_android_with_currency;
    this.display_price_apple_with_currency = display_price_apple_with_currency;

    this.recurrent =
      typeof recurrent === 'boolean' ? recurrent : recurrent == 1;
    this.group_plan = group_plan;

    this.group_plan_id = group_plan_id ?? null;

    this.template_post_subscription = template_post_subscription ?? 'home';
    this.template_home = template_home ?? 'home';

    this.orange_reduction_type =
      amount_discount === null || amount_discount === 0
        ? 'none'
        : amount_discount < price
        ? 'reduction'
        : 'free';
    this.orange_reduction_amount = ((amount_discount ?? 0) / 100).toFixed(2);
    this.orange_reduction_cycle_count = cycle_count;
    this.front_display = front_display;

    this.setAdvancedInterval(interval_count, interval, interval_count);
  }

  setDevices(type) {
    switch (type) {
      case 'stripe':
      case 'prepaid':
        this.devices = ['computer'];
        break;
      case 'app':
        this.devices = ['mobile', 'tv'];
        break;
      case 'orange':
        this.devices = ['orange'];
        break;
      case 'free':
        this.devices = ['free'];
        break;
      default:
        this.devices = [];
        break;
    }
  }

  setPicture(pictures) {
    this.picture = Picture.getDefaultPicture('plan');
    const pictureTypes = Picture.getDefaultPictureTypesList('plan');

    if (pictures.length > 0) {
      let picture = pictures[0];
      picture.collectionName = 'xl_thumnails';
      this.picture.xl_thumbnails.push(picture);
    }
  }

  setAdvancedInterval(interval_count, interval, cycle_count) {
    this.advancedInterval = BillingUtils.getTimeUnitAdvanced(
      interval_count,
      interval,
      cycle_count
    );
  }
}
